/*** IMPORTS FROM imports-loader ***/
var define = false;

import {TweenMax, TimelineMax} from "gsap";

export default function feedbackSvgAnimate() {

	// SVG №0
	TweenMax.staggerFromTo(
		'.feedback-svg-1-cloud', 3, 
		{y: 2, scale: .8}, 
		{y: -2, scale: 1,repeat: -1, yoyo: true, force3D: false, ease: Power1.easeInOut}, 
		1
	);
	TweenMax.staggerTo(
		'.feedback-svg-1-house-window', 5, 
		{fill: 'rgb(200,200,100)', repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeOut},
		1
	);

	var letterTl = new TimelineMax({repeat: -1, ease: Power4.easeInOut}),
		pen = document.querySelector('#feedback-svg-1-pen');
	
	letterTl
		.set(pen, {y: -23, x: -40})
		.set('.feedback-svg-1-quote-text', {opacity: 0});

	letterTl
		.to(pen, .8, {x: 8})
		.to('#feedback-svg-1-quote-text-4', .3, {opacity: 1}, '-=0.5')
		.to(pen, .8, {y: -15, x: -40})
		.to(pen, .8, {x: 8})
		.to('#feedback-svg-1-quote-text-3', .3, {opacity: 1}, '-=0.5')
		.to(pen, .8, {y: -7, x: -40})
		.to(pen, .8, {x: 8})
		.to('#feedback-svg-1-quote-text-2', .3, {opacity: 1}, '-=0.5')
		.to(pen, .8, {y: 0, x: -40})
		.to(pen, .8, {x: 0})
		.to('#feedback-svg-1-quote-text-1', .3, {opacity: 1}, '-=0.5')
}	
