/*** IMPORTS FROM imports-loader ***/
var define = false;

import {TweenMax, TimelineMax} from "gsap";

export default function digestionSvgAnimate() {

	// SVG №0
	TweenMax.staggerFromTo(
		'.cloud', 3, 
		{x: -5, y: 2, scale: .8}, 
		{x: 2, y: -2, scale: 1,repeat: -1, yoyo: true, force3D: false, ease: Power1.easeInOut}, 
		1
	);
	TweenMax.staggerFromTo(
		'.digestion-svg-0-tree', 3, 
		{rotation: -5}, 
		{rotation: 3, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Power1.easeInOut},
		1
	);
	TweenMax.fromTo(
		'#question-mark', 3, 
		{scale: .7, transformOrigin: "50% 50%"}, 
		{scale: .9, repeat: -1, yoyo: true, force3D: false, ease: Elastic.easeOut}
	);
	TweenMax.to(
		'.digestion-svg-0-window', 2, 
		{fill: 'rgb(200,200,100)', repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeOut}
	);

	
	// SVG №1
	TweenMax.from(
		'#digestion-svg-1-bottle', 3, 
		{scaleY: .9, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Elastic.easeOut}
	);
	TweenMax.staggerFrom(
		'.digestion-svg-1-buble', 1,
		{y: 3, x: -2, scale: .8, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut},
		0.5
	);
	TweenMax.staggerFromTo(
		'.digestion-svg-1-tree', 3, 
		{rotation: -5}, 
		{rotation: 10, transformOrigin: "50% 100%",repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut},
		0.5
	)
	TweenMax.staggerFromTo(
		'.digestion-svg-1-vial', 3, 
		{y: -4}, 
		{y: 5, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		1
	);


	// SVG №2
	TweenMax.staggerFrom(
		'.digestion-svg-2-smoke-1', 3, 
		{scale: 0, transformOrigin: "0 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut},
		0.8
	);
	TweenMax.staggerFrom(
		'.digestion-svg-2-smoke-2', 3, 
		{scale: 0, transformOrigin: "0 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut},
		0.8
	);
	TweenMax.staggerFrom(
		'.digestion-svg-2-smoke-3', 3, 
		{scale: 0, transformOrigin: "0 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut},
		0.8
	);
	TweenMax.staggerFromTo(
		'.digestion-svg-2-tree', 3, 
		{rotation: -5}, 
		{rotation: 10, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut},
		0.8
	);


	// SVG №3
	TweenMax.staggerFromTo(
		'.digestion-svg-3-tree', 3, 
		{rotation: -5}, 
		{rotation: 10, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		0.9
	);
	TweenMax.staggerFrom(
		'.digestion-svg-3-buble', 1, 
		{y: 3, x: -2, scale: .5, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		0.2
	);


	// SVG №4
	TweenMax.staggerFrom(
		'.digestion-svg-4-smoke-1', 3, 
		{scale: 0, transformOrigin: "0 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		0.8
	);
	TweenMax.staggerFrom(
		'.digestion-svg-4-smoke-2', 3, 
		{scale: 0, transformOrigin: "0 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		0.8
	);
	TweenMax.staggerFromTo(
		'.digestion-svg-4-tree', 3, 
		{rotation: -5}, 
		{rotation: 10, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		0.8
	);
	TweenMax.to(
		'.digestion-svg-4-window', 5, 
		{fill: 'rgb(200,200,100)', repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeOut}
	);


	// SVG №5
	TweenMax.staggerFromTo(
		'.digestion-svg-5-tree', 3, 
		{rotation: -5}, 
		{rotation: 0, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		0.8
	);
	TweenMax.staggerFrom(
		'.digestion-svg-5-buble', 2, 
		{y: 3, x: -2, scale: .8, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		0.5
	);
	TweenMax.staggerFromTo(
		'.digestion-svg-5-cloud', 3, 
		{x: -5, scale: .8}, 
		{x: 2, scale: 1, repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		1
	);
	TweenMax.staggerTo(
		'.digestion-svg-5-valve', 3, 
		{rotation: 1080, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		1
	);
	TweenMax.staggerTo(
		'.digestion-svg-5-arrow', 3, 
		{rotation: 80, transformOrigin: "0 100%", repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeOut}, 
		1
	);				
	TweenMax.staggerTo(
		'.digestion-svg-5-arrow-left', 3, 
		{rotation: 80, transformOrigin: "0", repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeOut},
		1
	);				


	// SVG №6
	TweenMax.staggerFromTo(
		'.digestion-svg-6-tree', 3, 
		{rotation: -5}, 
		{rotation: 0, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		0.8
	);
	TweenMax.staggerFrom(
		'.digestion-svg-6-buble', 2, 
		{y: 3, x: -2, scale: .8, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		0.5
	);
	TweenMax.staggerTo(
		'.digestion-svg-6-valve', 3, 
		{rotation: 1080, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		1
	);
	TweenMax.staggerTo(
		'.digestion-svg-6-arrow', 3, 
		{rotation: 50, transformOrigin: "0", repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeOut}, 
		1
	);				
	TweenMax.staggerTo(
		'.digestion-svg-6-buble-from-sidepipe', 3, 
		{x: 3, y: -3, scale: 1.2, transformOrigin: "50% 50%",repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeOut}, 
		1
	);
	TweenMax.to(
		'.digestion-svg-6-exclamation-mark-quote', 3, 
		{scale: 1.2, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeOut}
	);
	TweenMax.staggerFromTo(
		'.digestion-svg-6-burst-buble', 3, 
		{scale: .8},
		{scale: 1, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		1
	);


	// SVG №7
	TweenMax.staggerFromTo(
		'.digestion-svg-7-tree', 3, 
		{rotation: -5}, 
		{rotation: 0, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		0.8
	);
	TweenMax.staggerFrom(
		'.digestion-svg-7-smoke', 5, 
		{scale: 0, transformOrigin: "0 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		2
	);
	TweenMax.staggerTo(
		'.digestion-svg-7-window', 5, 
		{fill: 'rgb(200,200,100)', repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeOut}
	);


	// SVG №8
	TweenMax.staggerFromTo(
		'.digestion-svg-8-tree', 3, 
		{rotation: -5}, 
		{rotation: 0, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		0.8
	);
	TweenMax.staggerTo(
		'.digestion-svg-8-window', 5, 
		{fill: 'rgb(200,200,100)', repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeOut},
		1
	);


	// SVG №9
	TweenMax.staggerFromTo(
		'.digestion-svg-9-tree', 3, 
		{rotation: -5}, 
		{rotation: 0, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		0.8
	);
	TweenMax.staggerFromTo(
		'.digestion-svg-9-cloud', 3, 
		{x: 0, scale: .8}, 
		{x: 3, scale: 1, repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		1
	);
	TweenMax.to(
		'#digestion-svg-9-bottle-body', 3, 
		{fill: 'rgb(100,141,255)', repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut},
	);


	// SVG №10
	TweenMax.staggerFromTo(
		'.digestion-svg-10-cloud', 3, 
		{x: 0, scale: .8}, 
		{x: 2, scale: 1, repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		1
	);
	TweenMax.staggerFromTo(
		'.digestion-svg-10-star', 3, 
		{scale: .8}, 
		{scale: 1.3, repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		1
	);
	TweenMax.to(
		'#digestion-svg-10-hunters-trap-right', 3, 
		{rotation: -30, transformOrigin: "10% 70%", repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeIn},
	);
	TweenMax.to(
		'#digestion-svg-10-hunters-trap-left', 3, 
		{rotation: 30, transformOrigin: "89% 66%", repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeIn},
	);
}	
