<template>
  <div class="officialNotice" data-view>
    <div data-reactroot="" id="content">
      <div id="router-container">
        <top-menu></top-menu>
        <message-alert></message-alert>
        <span>
				<div id="official-notice-page-container" class="content-container" style="padding-top: 60px;">
					<div
              id="part_1" class="title-block">
						<h1>Oфіційне
							<br>повідомлення
							</h1>
						</div>
						<section
                class="main-column">
							<p>На цій сторінці наведено опис Правил та умов, що застосовуються до всіх користувачів мережі Інтернет, які відвідують цей веб-сайт <a
                  href="https://www.festal.ua/">https://www.festal.ua/</a> (далі по тексту — Сайт). Відвідування цього Сайту означає вашу беззастережну згоду з обов'язком дотримуватися цих Правил та умов. Враховуючи, що Правила та умови можуть бути змінені у будь-який час без попередження, ми рекомендуємо вам ознайомлюватися з ними на регулярній основі.
							</p>
							<ol
                  class="custom-ordered-list">
								<li class="custom-list-item-v1">Інтелектуальна власність</li>
								<li class="custom-list-item-v1">Характер інформації</li>
								<li class="custom-list-item-v1">Посилання на інші сайти</li>
								<li class="custom-list-item-v1">Персональні дані та інша інформація</li>
								<li class="custom-list-item-v1">Обмеження відповідальності</li>
								<li class="custom-list-item-v1">Доступність Сайту</li>
								<li class="custom-list-item-v1">Інформація про продукцію</li>
								<li class="custom-list-item-v1" style="visibility: hidden;margin: 0;padding: 0;height: 0;"></li>
								<li class="custom-list-item-v1" style="margin-top: 0;">Правила та умови</li>
								<li class="custom-list-item-v1">Авторські права на фото-/відеоматеріали:</li>
							</ol>
							<div
                  class="chapter-title-block">
								<div class="inline-svg-float-block">
									<svg id="official-svg-1" class="inline-svg-illustration"
                       xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                       viewBox="0 0 72.4 64.5">
										<path id="official-svg-1-number" class="st0" d="M54.6,64.1H22.7c-1.8,0-1.9-0.2-1.9-2v-9.3c0-1.8,0.1-2,1.9-2h8.1V20.4L22.6,25
 c-0.6,0.3-1,0.5-1.3,0.5c-0.6,0-0.8-0.5-1.3-1.3l-4.6-7.6c-0.5-0.7-0.7-1-0.7-1.3s0.4-0.8,1.2-1.3L36.2,0.3h10.3c1.7,0,2,0.4,2,2
 v48.4h6.1c1.7,0,2,0.2,2,2v9.3C56.6,63.9,56.4,64.1,54.6,64.1z"></path>
										<g id="official-svg-1-mountains">
											<path id="official-svg-1-mountain-5" class="st1" d="M32.5,61.7l-3.2-4.6c-0.7-0.9-2.1-0.9-2.7,0l-3.2,4.6c-0.8,1.1,0,2.7,1.4,2.7h6.5
 C32.5,64.3,33.2,62.8,32.5,61.7z"></path>
											<path id="official-svg-1-mountain-4" class="st1" d="M22.5,57.6l-6.8-9.6c-0.9-1.3-2.8-1.3-3.7,0l-6.8,9.6c-1.1,1.5,0,3.6,1.8,3.6h13.7
 C22.5,61.1,23.5,59,22.5,57.6z"></path>
											<path id="official-svg-1-mountain-3" class="st2" d="M27.4,60.7L23,54.5c-0.9-1.3-2.8-1.3-3.7,0l-4.4,6.2c-1.1,1.5,0,3.6,1.9,3.6h8.8
 C27.4,64.3,28.5,62.2,27.4,60.7z"></path>
											<path id="official-svg-1-mountain-2" class="st3" d="M21.7,61.4l-5.7-8c-0.7-1.1-2.3-1.1-3.1,0l-5.7,8c-0.9,1.2,0,3,1.5,3h11.4
 C21.7,64.3,22.6,62.6,21.7,61.4z"></path>
											<path id="official-svg-1-mountain-1" class="st2" d="M10.5,62.2l-4-5.6c-0.5-0.7-1.6-0.7-2.1,0l-4,5.6c-0.6,0.9,0,2.1,1.1,2.1h7.9
 C10.5,64.3,11.1,63.1,10.5,62.2z"></path>
										</g>
										<g id="official-svg-1-hut">
											<rect class="st4" x="43.4" y="59.4" width="17.8" height="4.6"></rect>
											<polygon
                          class="st5" points="63.1,58.7 55.4,58.7 48.5,50.4 56.2,50.4"></polygon>
											<polygon class="st4"
                               points="55.4,58.7 41.5,58.7 48.5,50.4 	"></polygon>
											<rect
                          class="st5" x="54.9" y="59.4" width="6.4" height="4.6"></rect>
											<rect class="st6" x="40.8"
                            y="63.5" width="23"
                            height="0.9"></rect>
											<rect
                          class="st6" x="40.8" y="58.7" width="23" height="0.9"></rect>
											<rect class="st6" x="47.7" y="49.9"
                            width="9.3"
                            height="0.9"></rect>
											<rect
                          class="st6" x="47.4" y="60.3" width="2.6" height="3.2"></rect>
											<circle
                          class="official-svg-window st6" cx="48.7" cy="56" r="0.7"
                          data-svg-origin="48 55.29999923706055"
                          transform="matrix(1,0,0,1,0,0)" style="fill: rgb(198, 199, 101);"></circle>
										</g>
										<g
                        id="official-svg-1-trees">
											<path id="official-svg-1-tree-3" class="official-svg-tree st7" d="M64.5,50.8c-0.1,0-0.2,0-0.3,0c-0.2-1.6-1.4-2.9-3-3.2c-2-0.4-4,0.9-4.4,2.9
 c-0.1,0.3-0.1,0.6-0.1,0.9c-1.1,0.6-2,1.7-2.3,3c-0.4,2.3,0.9,4.4,3.1,5c0.4,0.9,1.2,1.7,2.1,2.1v2.7h2.6v-2.8
 c1.2-0.4,2.1-1.5,2.3-2.8c0.1-0.7,0.1-1.4-0.1-2c1.1-0.3,2-1.2,2.3-2.4C67.2,52.7,66.1,51.1,64.5,50.8z"
                            data-svg-origin="60.57490539550781 64.19999694824219"
                            transform="matrix(0.99737,-0.07242,0.07242,0.99737,-3.0613837873227805,3.947765719918138)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="official-svg-1-tree-2" class="official-svg-tree st8" d="M72,57.9c0-0.1-0.1-0.1-0.1-0.2c0.2-0.6,0.1-1.2-0.1-1.8c-0.5-1.2-1.9-1.9-3.2-1.6
 c-0.6-0.8-1.7-1.1-2.6-0.7c-1.1,0.5-1.6,1.8-1.1,2.9c0,0,0,0,0,0.1c-0.7,0.6-1,1.6-0.6,2.5c0.2,0.4,0.5,0.7,0.8,0.9
 c0,0.4,0.1,0.8,0.2,1.2c0.3,0.8,1,1.3,1.7,1.5v1.6h1.5v-1.5c0.2,0,0.3-0.1,0.5-0.2c0.8-0.3,1.3-1,1.6-1.7c0.1,0,0.2-0.1,0.4-0.1
 C72,60.3,72.5,59,72,57.9z" data-svg-origin="68.15850067138672 64.30000305175781"
                          transform="matrix(0.99924,-0.03877,0.03877,0.99924,-1.5094240073480334,2.297817006924827)"
                          style="transform-origin: 0px 0px 0px;"></path>
											<path id="official-svg-1-tree-1"
                            class="official-svg-tree st9" d="M60.7,56.5c0-1.6-1.3-2.8-2.8-2.8c0,0,0,0-0.1,0c-0.3-1.2-1.4-2.2-2.7-2.2
 c-1.5,0-2.7,1.2-2.7,2.7c0,0.1,0,0.2,0,0.3c-0.9,0.5-1.6,1.4-1.6,2.6c0,1.2,0.7,2.1,1.7,2.6c-0.1,0.2-0.1,0.4-0.1,0.6
 c0,0.9,0.8,1.7,1.7,1.7c0.2,0,0.4,0,0.6-0.1v2.4h1.8v-2.6c0.2,0.1,0.4,0.1,0.7,0.1c1.3,0,2.4-1.1,2.4-2.4c0-0.2,0-0.3-0.1-0.5
 C60.2,58.3,60.7,57.5,60.7,56.5z" data-svg-origin="55.75000190734863 64.29999542236328"
                            transform="matrix(0.9999,-0.01346,0.01346,0.9999,0.2360812684358793,0.2761943524116255)"
                            style="transform-origin: 0px 0px 0px;"></path>
										</g>
										<g
                        id="official-svg-1-clouds">
											<path id="official-svg-1-cloud-3" class="feedback-svg-cloud st10" d="M32.8,36.4c0.1-1.5-0.9-3-2.5-3.3c-0.1,0-0.2,0-0.3,0c-0.2-1.6-1.4-2.9-3-3.2
 c-2-0.4-4,0.9-4.4,2.9c-0.1,0.3-0.1,0.6-0.1,0.9c-1,0.5-1.9,1.5-2.2,2.7H32.8z"
                            data-svg-origin="20.299999237060547 29.827693939208984"
                            transform="matrix(0.80469,0,0,0.80469,3.9647026287714624,7.731625446849393)"></path>
											<path
                          id="official-svg-1-cloud-2" class="feedback-svg-cloud st10" d="M63.8,42.1c0.1-0.9-0.5-1.7-1.4-1.9c0,0-0.1,0-0.1,0c-0.1-0.9-0.8-1.6-1.7-1.8
 c-1.1-0.2-2.3,0.5-2.5,1.7c0,0.2,0,0.4,0,0.5c-0.6,0.3-1.1,0.8-1.3,1.5H63.8z"
                          data-svg-origin="56.79999923706055 38.366092681884766"
                          transform="matrix(0.87802,0,0,0.87802,6.928022129164788,5.1190420268376515)"></path>
											<path
                          id="official-svg-1-cloud-1" class="feedback-svg-cloud st10" d="M64.5,25.2c0.2-2.4-1.4-4.6-3.8-5.1c-0.1,0-0.3,0-0.4-0.1c-0.3-2.4-2.1-4.5-4.6-5
 c-3.1-0.6-6.2,1.4-6.8,4.6c-0.1,0.5-0.1,1-0.1,1.4c-1.6,0.8-2.9,2.3-3.4,4.1H64.5z"
                          data-svg-origin="45.400001525878906 14.893896102905273"
                          transform="matrix(0.97975,0,0,0.97975,0.9193500308990394,-1.293398603916173)"></path>
										</g>
									</svg>
								</div>
								<h2>Інтелектуальна
									<br>власність
									</h2>
								</div>
								<p>Цей Сайт належить та підтримується ТОВ «Опелла Хелскеа Україна» (далі по тексту — Опелла Хелскеа), що входить до складу Групи компаній «Санофі». Загальний вигляд та кожен з елементів, включаючи знаки для товарів та послуг, логотипи та назви доменів, що згадуються на Сайті, захищені чинним законодавством про інтелектуальну власність та належать Опелла Хелскеа або іншим компаніям Групи компаній «Санофі» (далі — Правовласник), їх використання вимагає отримання попереднього дозволу в належній формі.
</p>
								<p>Жодна складова Сайту не може бути скопійована, відтворена, змінена, завантажена, перетворена, передана або поширена будь-яким способом та з використанням будь-якого носія, повністю або частково, без отримання попередньої письмової згоди Правовласника, крім випадків, коли це відбувається виключно з метою друку в засобах масової інформації за умови дотримання прав інтелектуальної власності та будь-яких інших прав, що згадуються. Копіювання дозволяється виключно для особистого некомерційного використання на персональному комп'ютері або іншому пристрої з метою повторного ознайомлення.
</p>
								<p>2022 © ТОВ «Опелла Хелскеа Україна» — усі права захищені.
</p>
								<p>У разі будь-якого санкціонованого використання елементів Сайту останні не можуть бути перетворені, змінені або виправлені будь-яким чином.
</p>
								<p>Правовласники залишають за собою право звернення до суду з приводу будь-якого порушення своїх прав інтелектуальної власності.
</p>
								<div
                    class="chapter-title-block">
									<div class="inline-svg-float-block">
										<svg id="official-svg-2" class="inline-svg-illustration"
                         xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                         viewBox="0 0 75.431 66.843">
											<path id="official-svg-2-number" class="st0" d="M43.513,41.719c-2.797,2.123-9.455,6.175-10.998,9.552h22.865c1.641,0,2.025,0.29,2.025,1.93
 v11.289c0,1.833-0.096,2.026-1.736,2.026H14.666c-1.061,0-1.352-0.29-1.352-1.64c0-5.596,0.098-3.087,0.098-5.114
 c0-23.927,27.4-26.532,27.4-39.847c0-3.763-2.025-5.5-5.596-5.5c-7.525,0-7.43,9.841-10.613,9.841c-0.193,0-0.289,0-0.482-0.097
 L13.797,20.3c-0.771-0.29-1.061-0.772-1.061-1.447c0-1.544,0.193-2.895,3.184-8.104c3.859-6.754,11.289-10.42,20.646-10.42
 c12.543,0,21.227,7.14,21.227,17.945C57.793,30.43,52.679,34.868,43.513,41.719z"></path>
											<g id="official-svg-2-mountains">
												<path id="official-svg-2-mountain-1" class="st2" d="M32.457,63.989l-3.248-4.574c-0.669-0.942-2.068-0.942-2.738,0l-3.248,4.574
 c-0.789,1.112,0.006,2.65,1.369,2.65h6.496C32.451,66.639,33.246,65.101,32.457,63.989z"></path>
												<path
                            id="official-svg-2-mountain-2" class="st2" d="M22.469,59.88l-6.835-9.626c-0.899-1.266-2.779-1.266-3.678,0L5.121,59.88
 c-1.06,1.494,0.007,3.561,1.839,3.561H20.63C22.461,63.441,23.529,61.374,22.469,59.88z"></path>
												<path
                            id="official-svg-2-mountain-3" class="st3" d="M27.417,63.054l-4.394-6.189c-0.905-1.275-2.798-1.275-3.704,0l-4.394,6.189
 c-1.068,1.504,0.008,3.586,1.852,3.586h8.788C27.409,66.639,28.484,64.557,27.417,63.054z"></path>
												<path
                            id="official-svg-2-mountain-4" class="st4" d="M21.708,63.682l-5.676-7.995c-0.747-1.052-2.308-1.052-3.055,0l-5.676,7.995
 c-0.881,1.24,0.006,2.958,1.527,2.958h11.353C21.702,66.639,22.589,64.922,21.708,63.682z"></path>
												<path
                            id="official-svg-2-mountain-5" class="st3" d="M10.493,64.576L6.534,59c-0.521-0.734-1.61-0.734-2.131,0l-3.959,5.576
 c-0.614,0.865,0.004,2.063,1.065,2.063h7.918C10.488,66.639,11.107,65.442,10.493,64.576z"></path>
											</g>
											<g
                          id="official-svg-2-trees">
												<path id="official-svg-2-tree-1" class="official-svg-tree st5" d="M67.765,53.183c-0.084-0.017-0.167-0.029-0.25-0.038c-0.193-1.551-1.355-2.873-2.971-3.195
 c-2.019-0.402-3.981,0.909-4.383,2.928c-0.062,0.312-0.083,0.622-0.067,0.925c-1.145,0.579-2.022,1.659-2.291,3.015
 c-0.449,2.254,0.944,4.447,3.137,5.025c0.385,0.942,1.15,1.71,2.142,2.081v2.749h2.626v-2.751c1.162-0.44,2.077-1.451,2.338-2.759
 c0.136-0.683,0.075-1.36-0.142-1.972c1.122-0.278,2.039-1.179,2.28-2.387C70.515,55.135,69.433,53.515,67.765,53.183z"
                              data-svg-origin="63.98123741149902 66.67300415039062"
                              transform="matrix(0.99999,-0.0009,0.0009,0.99999,1.3796804756606864,-0.551943767487753)"
                              style="transform-origin: 0px 0px 0px;"></path>
												<path
                            id="official-svg-2-tree-2" class="official-svg-tree st6" d="M75.098,60.27c-0.027-0.062-0.057-0.121-0.089-0.18c0.157-0.589,0.126-1.233-0.136-1.836
 c-0.543-1.249-1.888-1.901-3.17-1.616c-0.598-0.795-1.681-1.109-2.633-0.695c-1.115,0.485-1.625,1.781-1.141,2.895
 c0.009,0.02,0.02,0.039,0.029,0.059c-0.739,0.569-1.028,1.586-0.639,2.48c0.174,0.401,0.46,0.719,0.804,0.934
 c-0.017,0.41,0.053,0.831,0.228,1.233c0.332,0.763,0.962,1.301,1.694,1.544v1.583h1.545v-1.519
 c0.154-0.038,0.307-0.086,0.458-0.151c0.771-0.335,1.314-0.976,1.552-1.719c0.12-0.029,0.24-0.067,0.357-0.118
 C75.072,62.681,75.583,61.384,75.098,60.27z" data-svg-origin="71.21351623535156 66.67100524902344"
                            transform="matrix(0.99999,-0.00327,0.00327,0.99999,0.7173124738190904,-0.16239018097060942)"
                            style="transform-origin: 0px 0px 0px;"></path>
												<path id="official-svg-2-tree-3"
                              class="official-svg-tree st7" d="M63.981,58.872c0-1.563-1.267-2.831-2.831-2.831c-0.019,0-0.037,0.002-0.055,0.003
 c-0.255-1.246-1.357-2.183-2.678-2.183c-1.51,0-2.733,1.224-2.733,2.733c0,0.113,0.009,0.223,0.022,0.332
 c-0.922,0.484-1.552,1.45-1.552,2.564c0,1.153,0.676,2.146,1.651,2.612c-0.078,0.193-0.122,0.404-0.122,0.626
 c0,0.926,0.75,1.676,1.676,1.676c0.203,0,0.396-0.039,0.576-0.106v2.373h1.754v-2.626c0.216,0.063,0.443,0.097,0.68,0.097
 c1.348,0,2.44-1.093,2.44-2.44c0-0.172-0.018-0.34-0.052-0.502C63.496,60.691,63.981,59.839,63.981,58.872z"
                              data-svg-origin="59.067495346069336 66.6709976196289"
                              transform="matrix(0.99979,-0.02017,0.02017,0.99979,-0.23513144284931542,0.7279963314414708)"
                              style="transform-origin: 0px 0px 0px;"></path>
											</g>
											<g
                          id="official-svg-2-clouds">
												<path id="official-svg-2-cloud-1" class="feedback-svg-cloud st1" d="M32.758,38.741c0.135-1.549-0.914-2.985-2.475-3.295c-0.084-0.017-0.168-0.029-0.251-0.039
 c-0.194-1.557-1.36-2.884-2.983-3.207c-2.027-0.403-3.997,0.913-4.4,2.939c-0.062,0.313-0.083,0.624-0.067,0.929
 c-1.047,0.53-1.865,1.479-2.208,2.673H32.758z" data-svg-origin="20.374000549316406 32.12742233276367"
                              transform="matrix(0.9953,0,0,0.9953,0.09564461368984922,-1.7552907118267425)"></path>
												<path
                            id="official-svg-2-cloud-2" class="feedback-svg-cloud st1" d="M63.84,44.469c0.077-0.879-0.518-1.693-1.404-1.869c-0.048-0.009-0.095-0.016-0.142-0.022
 c-0.11-0.883-0.771-1.636-1.692-1.819c-1.15-0.229-2.267,0.518-2.496,1.667c-0.035,0.177-0.047,0.354-0.038,0.527
 c-0.594,0.301-1.058,0.839-1.252,1.516H63.84z" data-svg-origin="56.81600570678711 40.71772766113281"
                            transform="matrix(0.92197,0,0,0.92197,4.433226667510162,2.7376693611145226)"></path>
												<path
                            id="official-svg-2-cloud-3" class="feedback-svg-cloud st1" d="M64.522,27.546c0.209-2.401-1.417-4.626-3.836-5.108c-0.13-0.026-0.26-0.045-0.389-0.06
 c-0.3-2.413-2.108-4.47-4.623-4.971c-3.141-0.625-6.195,1.415-6.82,4.556c-0.097,0.485-0.129,0.968-0.104,1.44
 c-1.622,0.821-2.89,2.293-3.422,4.142H64.522z" data-svg-origin="45.3280029296875 17.29440689086914"
                            transform="matrix(0.82024,0,0,0.82024,8.147708526611332,4.703669638633733)"></path>
											</g>
											<g
                          id="official-svg-2-hut">
												<rect class="st8" x="44.324" y="61.064" width="20.394" height="5.241"></rect>
												<polygon
                            class="st9" points="66.854,60.257 58.035,60.257 50.111,50.722 58.93,50.722 		"></polygon>
												<polygon
                            class="st8" points="58.035,60.257 42.188,60.257 50.111,50.722 		"></polygon>
												<rect
                            class="st9" x="57.426" y="61.064" width="7.292" height="5.241"></rect>
												<rect class="st10"
                              x="41.338"
                              y="65.783"
                              width="26.343"
                              height="1.042"></rect>
												<rect
                            class="st10" x="41.338" y="60.257" width="26.343" height="1.042"></rect>
												<rect class="st10"
                              x="49.194"
                              y="50.202"
                              width="10.672"
                              height="1.042"></rect>
												<rect
                            class="st10" x="48.898" y="62.12" width="2.926" height="3.663"></rect>
												<circle
                            class="official-svg-window st10" cx="50.361" cy="57.107" r="0.835"
                            data-svg-origin="49.5260009765625 56.27199935913086" transform="matrix(1,0,0,1,0,0)"
                            style="fill: rgb(198, 199, 101);"></circle>
											</g>
										</svg>
									</div>
									<h2>Характер
										<br>інформації
										</h2>
									</div>
              <p>Цей Сайт містить інформацію про лікарський засіб Фестал, його властивості та характеристики, зазначені в інструкції для медичного застосування лікарського засобу та наукових статтях, призначених для ознайомлення широкого кола користувачів мережі Інтернет на території України. Перед застосуванням лікарського засобу необхідно обов'язково проконсультуватися з лікарем та ознайомитися з інструкцією для застосування лікарського засобу.

									</p>
									<p>Інформація, особливо фінансового характеру, опублікована на Сайті, не може вважатися заохоченням до інвестування. У жодному разі її не можна розцінювати як пропозицію підписки або відкрите розміщення, або запрошення до підписки, купівлі або обміну акцій, або інших цінних паперів компаній Групи «Санофі». Опелла Хелскеа звертає вашу увагу на те, що інформація фінансового характеру, якщо така розміщена на Сайті, регулярно оновлюється (якщо фінансові дані надаються в інтерактивному режимі).
</p>
									<p>Сайт може пропонувати висновки експертів, до яких зверталися за консультацією, щодо конкретних питань стосовно змісту цього сайту, або витримки зі статей у пресі. Будь-яка вказана інформація представляє виключно думку експерта-консультанта чи автора статті, не обов'язково відображаючи позицію компаній Групи «Санофі».
</p>
									<p>Відповідні експерти не є співробітниками компаній Групи «Санофі» та не отримують винагороду за використання їхніх висновків від компаній Групи «Санофі». Опелла Хелскеа не несе відповідальності за точність чи повноту будь-якої зазначеної інформації чи висновків. Експертні висновки відображають лише особисту думку автора і в жодному разі не можуть розглядатися як висновки або зобов'язання Опелла Хелскеа чи іншої компанії Групи «Санофі».
</p>
									<p>Сайт також містить інформацію щодо здоров'я, фізичного стану, медичних питань та медичного лікування виключно стосовно людини. Зазначена інформація публікується на Сайті з ознайомчою метою та не може використовуватися замість консультації вашого лікаря. Цю інформацію не можна використовувати в будь-якому випадку для встановлення діагнозу щодо захворювання або фізичного стану, або для призначення чи застосування ліків, представлених на Сайті. У всіх випадках ви повинні звернутися до лікаря.
</p>
									<div
                      class="chapter-title-block">
										<div class="inline-svg-float-block">
											<svg id="official-svg-3" class="inline-svg-illustration"
                           xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                           viewBox="0 0 73.888 68.401">
												<path id="official-svg-3-number" class="st0" d="M31.034,68.058c-2.99,0-14.762-0.869-20.455-5.693c-0.482-0.386-0.771-0.675-0.771-1.061
 c0-0.193,0-0.386,0.098-0.579l3.086-10.613c0.193-0.482,0.482-0.675,0.773-0.675c0.482,0,0.965,0.386,1.447,0.579
 c5.402,2.509,11.48,4.246,14.664,4.246c5.789,0,8.684-2.509,8.684-7.526c0-4.728-1.93-7.14-9.842-7.14h-3.184
 c-1.641,0-2.025-0.096-2.025-1.93v-9.648c0-1.64,0.289-2.026,1.93-2.026h3.377c5.016,0,8.297-1.351,8.297-6.657
 c0-3.377-2.316-5.21-5.693-5.21c-7.332,0-8.682,6.946-10.516,6.946c-0.193,0-0.387-0.096-0.578-0.289l-8.49-7.429
 c-0.291-0.29-0.387-0.579-0.387-0.868c0-0.482,0.289-1.062,0.578-1.544C15.983,3.994,23.604,0.328,31.998,0.328
 c12.639,0,21.805,7.236,21.805,18.524c0,5.692-2.99,9.745-6.947,12.832c5.5,3.28,9.262,8.683,9.262,15.437
 C56.118,59.664,45.409,68.058,31.034,68.058z"></path>
												<g id="official-svg-3-mountains">
													<path id="official-svg-3-mountain-1" class="st1" d="M32.457,65.55l-3.248-4.574c-0.669-0.942-2.068-0.942-2.738,0l-3.248,4.574
 c-0.789,1.112,0.006,2.65,1.369,2.65h6.496C32.451,68.2,33.246,66.661,32.457,65.55z"></path>
													<path
                              id="official-svg-3-mountain-2" class="st1" d="M22.469,61.441l-6.835-9.626c-0.899-1.266-2.779-1.266-3.678,0l-6.835,9.626
 c-1.06,1.494,0.007,3.561,1.839,3.561H20.63C22.461,65.002,23.529,62.934,22.469,61.441z"></path>
													<path
                              id="official-svg-3-mountain-3" class="st2" d="M27.417,64.614l-4.394-6.189c-0.905-1.275-2.798-1.275-3.704,0l-4.394,6.189
 c-1.068,1.504,0.008,3.586,1.852,3.586h8.788C27.409,68.2,28.484,66.118,27.417,64.614z"></path>
													<path
                              id="official-svg-3-mountains" class="st3" d="M21.708,65.242l-5.676-7.995c-0.747-1.052-2.308-1.052-3.055,0l-5.676,7.995
 C6.42,66.483,7.307,68.2,8.828,68.2h11.353C21.702,68.2,22.589,66.483,21.708,65.242z"></path>
													<path
                              id="official-svg-3-mountain-4" class="st2" d="M10.493,66.137l-3.959-5.576c-0.521-0.734-1.61-0.734-2.131,0l-3.959,5.576
 C-0.171,67.002,0.448,68.2,1.509,68.2h7.918C10.488,68.2,11.107,67.002,10.493,66.137z"></path>
												</g>
												<g
                            id="official-svg-3-hut">
													<rect class="st4" x="42.696" y="63.742" width="16.448" height="4.227"></rect>
													<polygon
                              class="st5"
                              points="60.867,63.092 53.754,63.092 47.363,55.402 54.476,55.402 		"></polygon>
													<polygon
                              class="st4" points="53.754,63.092 40.973,63.092 47.363,55.402 		"></polygon>
													<rect
                              class="st5" x="53.263" y="63.742" width="5.881" height="4.227"></rect>
													<rect class="st6"
                                x="40.288"
                                y="67.549"
                                width="21.247"
                                height="0.84"></rect>
													<rect
                              class="st6" x="40.288" y="63.092" width="21.247" height="0.84"></rect>
													<rect class="st6"
                                x="46.624"
                                y="54.982"
                                width="8.607"
                                height="0.84"></rect>
													<rect
                              class="st6" x="46.385" y="64.594" width="2.36" height="2.955"></rect>
													<circle
                              class="official-svg-window st6" cx="47.565" cy="60.551" r="0.674"
                              data-svg-origin="46.89099884033203 59.87699890136719" transform="matrix(1,0,0,1,0,0)"
                              style="fill: rgb(198, 199, 101);"></circle>
												</g>
												<g id="official-svg-3-trees">
													<path id="official-svg-3-tree-1" class="official-svg-tree st3" d="M65.916,54.711c-0.084-0.017-0.167-0.029-0.25-0.038c-0.193-1.551-1.355-2.873-2.971-3.195
 c-2.019-0.402-3.981,0.909-4.383,2.928c-0.062,0.312-0.083,0.622-0.067,0.925c-1.145,0.579-2.022,1.659-2.291,3.015
 c-0.449,2.254,0.944,4.447,3.137,5.025c0.385,0.942,1.15,1.71,2.142,2.081v2.749h2.626v-2.751c1.162-0.44,2.077-1.451,2.338-2.759
 c0.136-0.683,0.075-1.36-0.142-1.972c1.122-0.278,2.039-1.179,2.28-2.387C68.666,56.664,67.584,55.043,65.916,54.711z"
                                data-svg-origin="62.13223838806152 68.20100402832031"
                                transform="matrix(0.99881,-0.04865,0.04865,0.99881,-1.804559744372943,2.4937578999479975)"
                                style="transform-origin: 0px 0px 0px;"></path>
													<path
                              id="official-svg-3-tree-2" class="official-svg-tree st7" d="M73.505,61.798c-0.027-0.062-0.057-0.121-0.089-0.18c0.157-0.589,0.126-1.233-0.136-1.836
 c-0.543-1.249-1.888-1.901-3.17-1.616c-0.598-0.795-1.681-1.109-2.633-0.695c-1.115,0.485-1.625,1.781-1.141,2.895
 c0.009,0.02,0.02,0.039,0.029,0.059c-0.739,0.569-1.028,1.586-0.639,2.48c0.174,0.401,0.46,0.719,0.804,0.934
 c-0.017,0.41,0.053,0.831,0.228,1.233c0.332,0.763,0.962,1.301,1.694,1.544v1.583h1.545v-1.519
 c0.154-0.038,0.307-0.086,0.458-0.151c0.771-0.335,1.314-0.976,1.552-1.719c0.12-0.029,0.24-0.067,0.357-0.118
 C73.479,64.209,73.99,62.913,73.505,61.798z" data-svg-origin="69.62051391601562 68.1989974975586"
                              transform="matrix(0.99648,-0.08374,0.08374,0.99648,-4.53121939271742,5.673656474652571)"
                              style="transform-origin: 0px 0px 0px;"></path>
													<path id="official-svg-3-tree-3"
                                class="official-svg-tree st2" d="M73.505,61.798c-0.027-0.062-0.057-0.121-0.089-0.18c0.157-0.589,0.126-1.233-0.136-1.836
 c-0.543-1.249-1.888-1.901-3.17-1.616c-0.598-0.795-1.681-1.109-2.633-0.695c-1.115,0.485-1.625,1.781-1.141,2.895
 c0.009,0.02,0.02,0.039,0.029,0.059c-0.739,0.569-1.028,1.586-0.639,2.48c0.174,0.401,0.46,0.719,0.804,0.934
 c-0.017,0.41,0.053,0.831,0.228,1.233c0.332,0.763,0.962,1.301,1.694,1.544v1.583h1.545v-1.519
 c0.154-0.038,0.307-0.086,0.458-0.151c0.771-0.335,1.314-0.976,1.552-1.719c0.12-0.029,0.24-0.067,0.357-0.118
 C73.479,64.209,73.99,62.913,73.505,61.798z" data-svg-origin="69.62051391601562 68.1989974975586"
                                transform="matrix(0.99849,-0.0549,0.0549,0.99849,-2.7038608340098325,3.5290665082215718)"
                                style="transform-origin: 0px 0px 0px;"></path>
													<path
                              id="official-svg-3-tree-4" class="official-svg-tree st8" d="M62.132,60.401c0-1.563-1.267-2.831-2.831-2.831c-0.019,0-0.037,0.002-0.055,0.003
 c-0.255-1.246-1.357-2.183-2.678-2.183c-1.51,0-2.733,1.224-2.733,2.733c0,0.113,0.009,0.223,0.022,0.332
 c-0.922,0.484-1.552,1.45-1.552,2.564c0,1.153,0.676,2.146,1.651,2.612c-0.078,0.193-0.122,0.404-0.122,0.626
 c0,0.926,0.75,1.676,1.676,1.676c0.203,0,0.396-0.039,0.576-0.106v2.373h1.754v-2.626c0.216,0.063,0.443,0.097,0.68,0.097
 c1.348,0,2.44-1.093,2.44-2.44c0-0.172-0.018-0.34-0.052-0.502C61.647,62.219,62.132,61.367,62.132,60.401z"
                              data-svg-origin="57.218496322631836 68.20000457763672"
                              transform="matrix(0.99969,-0.02475,0.02475,0.99969,-0.5730713226659399,0.9603139729251726)"
                              style="transform-origin: 0px 0px 0px;"></path>
												</g>
												<g id="official-svg-3-clouds">
													<path id="official-svg-3-cloud-1" class="feedback-svg-cloud st9" d="M61.736,46.013c0.077-0.879-0.518-1.693-1.404-1.869c-0.048-0.009-0.095-0.016-0.142-0.022
 c-0.11-0.883-0.771-1.636-1.692-1.819c-1.15-0.229-2.267,0.518-2.496,1.667c-0.035,0.177-0.047,0.354-0.038,0.527
 c-0.594,0.301-1.058,0.839-1.252,1.516H61.736z" data-svg-origin="54.712005615234375 42.26172637939453"
                                transform="matrix(0.80469,0,0,0.80469,10.685558652242015,10.160061060375625)"></path>
													<path
                              id="official-svg-3-cloud-2" class="feedback-svg-cloud st9" d="M62.418,29.09c0.209-2.401-1.417-4.626-3.836-5.108c-0.13-0.026-0.26-0.045-0.389-0.06
 c-0.3-2.413-2.108-4.47-4.623-4.971c-3.141-0.625-6.195,1.415-6.82,4.556c-0.097,0.485-0.129,0.968-0.104,1.44
 c-1.622,0.821-2.89,2.293-3.422,4.142H62.418z" data-svg-origin="43.2239990234375 18.838407516479492"
                              transform="matrix(0.87802,0,0,0.87802,5.272127214219822,2.737206872357249)"></path>
												</g>
											</svg>
										</div>
										<h2>Посилання
											<br>на інші сайти
											</h2>
										</div>
										<p>Жоден сайт третьої особи, на який можна потрапити з цього Сайту, не приводить до відповідальності Опелла Хелскеа або компаній Групи «Санофі». Ми жодним чином не можемо контролювати зміст сайту такої третьої особи, який залишається повністю незалежним від Опелла Хелскеа. Крім цього, існування на Сайті посилання на сайт третьої особи в жодному разі не означає, що Опелла Хелскеа схвалює зміст такого сайту в будь-якій формі, або, зокрема, можливе застосування такого змісту.
</p>
										<p>При цьому ви самостійно відповідаєте за вжиття заходів безпеки проти будь-якого інфікування із цього Сайту, а саме одного або кількох комп'ютерних вірусів, троянів та інших шкідливих програм.
</p>
										<p>Зовнішні сайти можуть містити гіпертекстові посилання на цей Сайт. Жодне з таких посилань не може бути створене без явної попередньої згоди від Опелла Хелскеа. У будь-якому випадку Опелла Хелскеа не несе відповідальності за недоступність таких сайтів, а також не здійснює детального розгляду, контролю та затвердження таких сайтів і не несе відповідальності за їх зміст, рекламу, продукти та інші компоненти, представлені на цих сайтах або доступні через них.
</p>
										<div
                        class="chapter-title-block">
											<div class="inline-svg-float-block">
												<svg id="official-svg-4" class="inline-svg-illustration"
                             xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                             viewBox="0 0 63.627 69.158">
													<g id="official-svg-4-hut">
														<rect class="st0" x="20.984" y="63.024" width="21.689" height="5.573"></rect>
														<polygon
                                class="st1"
                                points="44.945,62.166 35.566,62.166 27.139,52.026 36.518,52.026 	"></polygon>
														<polygon
                                class="st0" points="35.566,62.166 18.713,62.166 27.139,52.026 	"></polygon>
														<rect class="st1"
                                  x="34.918"
                                  y="63.024"
                                  width="7.755"
                                  height="5.573"></rect>
														<rect
                                class="st2" x="17.809" y="68.043" width="28.016" height="1.108"></rect>
														<rect class="st2"
                                  x="17.809"
                                  y="62.166"
                                  width="28.016"
                                  height="1.108"></rect>
														<rect
                                class="st2" x="26.164" y="51.472" width="11.349" height="1.108"></rect>
														<rect class="st2"
                                  x="25.849"
                                  y="64.147"
                                  width="3.112"
                                  height="3.896"></rect>
														<circle
                                class="official-svg-window st2" cx="27.405" cy="58.816" r="0.889"
                                data-svg-origin="26.516000747680664 57.927001953125" transform="matrix(1,0,0,1,0,0)"
                                style="fill: rgb(198, 199, 101);"></circle>
													</g>
													<path id="official-svg-4-number" class="st3" d="M55.359,55.708h-4.728V66.9c0,1.833-0.387,1.93-2.026,1.93H35.87c-1.834,0-2.026-0.29-2.026-1.93
 V55.708H8.276c-1.641,0-2.026-0.386-2.026-2.026V41.043L31.625,1.582c0.578-0.868,0.771-1.254,1.254-1.254
 c0.289,0,0.675,0.096,1.351,0.386l12.253,4.824c1.062,0.386,1.641,0.771,1.641,1.351c0,0.386-0.29,0.772-0.676,1.351L23.23,41.333
 h11.096l2.122-12.157c0.29-1.64,0.387-1.93,2.026-1.93h10.131c1.64,0,2.026,0.386,2.026,2.026v12.156h4.728
 c1.833,0,2.025,0.193,2.025,2.412v9.841C57.384,55.323,57.192,55.708,55.359,55.708z"></path>
													<g id="official-svg-4-trees">
														<path id="official-svg-4-tree-1" class="official-svg-tree st4" d="M55.655,55.33c-0.084-0.017-0.167-0.029-0.25-0.038c-0.193-1.551-1.355-2.873-2.971-3.195
 c-2.019-0.402-3.981,0.909-4.383,2.928c-0.062,0.312-0.083,0.622-0.067,0.925c-1.145,0.579-2.022,1.659-2.291,3.015
 c-0.449,2.254,0.944,4.447,3.137,5.025c0.385,0.942,1.15,1.71,2.142,2.081v2.749h2.626v-2.751c1.162-0.44,2.077-1.451,2.338-2.759
 c0.136-0.683,0.075-1.36-0.142-1.972c1.122-0.278,2.039-1.179,2.28-2.387C58.405,57.282,57.322,55.662,55.655,55.33z"
                                  data-svg-origin="51.87121772766113 68.82000732421875"
                                  transform="matrix(0.99998,-0.00539,0.00539,0.99998,1.069419344500461,-0.32896596632330954)"
                                  style="transform-origin: 0px 0px 0px;"></path>
														<path
                                id="official-svg-4-tree-2" class="official-svg-tree st5" d="M63.244,62.417c-0.027-0.062-0.057-0.121-0.089-0.18c0.157-0.589,0.126-1.233-0.136-1.836
 c-0.543-1.249-1.888-1.901-3.17-1.616c-0.598-0.795-1.681-1.109-2.633-0.695c-1.115,0.485-1.625,1.781-1.141,2.895
 c0.009,0.02,0.02,0.039,0.029,0.059c-0.739,0.569-1.028,1.586-0.639,2.48c0.174,0.401,0.46,0.719,0.804,0.934
 c-0.017,0.41,0.053,0.831,0.228,1.233c0.332,0.763,0.962,1.301,1.694,1.544v1.583h1.545V67.3c0.154-0.038,0.307-0.086,0.458-0.151
 c0.771-0.335,1.314-0.976,1.552-1.719c0.12-0.029,0.24-0.067,0.357-0.118C63.218,64.828,63.728,63.532,63.244,62.417z"
                                data-svg-origin="59.35930252075195 68.81800079345703"
                                transform="matrix(0.99999,-0.00018,0.00018,0.99999,0.9225139066713295,-0.3849610434479267)"
                                style="transform-origin: 0px 0px 0px;"></path>
														<path id="official-svg-4-tree-3"
                                  class="official-svg-tree st6" d="M51.87,61.02c0-1.563-1.267-2.831-2.831-2.831c-0.019,0-0.037,0.002-0.055,0.003
 c-0.255-1.246-1.357-2.183-2.678-2.183c-1.51,0-2.733,1.224-2.733,2.733c0,0.113,0.009,0.223,0.022,0.332
 c-0.922,0.484-1.552,1.45-1.552,2.564c0,1.153,0.676,2.146,1.651,2.612c-0.078,0.193-0.122,0.404-0.122,0.626
 c0,0.926,0.75,1.676,1.676,1.676c0.203,0,0.396-0.039,0.576-0.106v2.373h1.754v-2.626c0.216,0.063,0.443,0.097,0.68,0.097
 c1.348,0,2.44-1.093,2.44-2.44c0-0.172-0.018-0.34-0.052-0.502C51.386,62.838,51.87,61.986,51.87,61.02z"
                                  data-svg-origin="46.95649528503418 68.81900024414062"
                                  transform="matrix(0.99994,-0.01003,0.01003,0.99994,0.40933067123989186,-0.0022703307205809153)"
                                  style="transform-origin: 0px 0px 0px;"></path>
													</g>
													<g
                              id="official-svg-4-clouds">
														<path id="official-svg-4-cloud-1" class="feedback-svg-cloud st7" d="M12.558,41.092c0.135-1.549-0.914-2.985-2.475-3.295c-0.084-0.017-0.168-0.029-0.251-0.039
 c-0.194-1.557-1.36-2.884-2.983-3.207c-2.027-0.403-3.997,0.913-4.4,2.939c-0.062,0.313-0.083,0.624-0.067,0.929
 c-1.047,0.53-1.865,1.479-2.208,2.673H12.558z" data-svg-origin="0.17400002479553223 34.47842025756836"
                                  transform="matrix(0.97975,0,0,0.97975,0.0035235005021095056,-0.8968119897842515)"></path>
														<path
                                id="official-svg-4-cloud-2" class="feedback-svg-cloud st7" d="M61.752,46.82c0.077-0.879-0.518-1.693-1.404-1.869c-0.048-0.009-0.095-0.016-0.142-0.022
 c-0.11-0.883-0.771-1.636-1.692-1.819c-1.15-0.229-2.267,0.518-2.496,1.667c-0.035,0.177-0.047,0.354-0.038,0.527
 c-0.594,0.301-1.058,0.839-1.252,1.516H61.752z" data-svg-origin="54.728004455566406 43.0687255859375"
                                transform="matrix(0.9953,0,0,0.9953,0.2569175764719702,-1.7039273715548977)"></path>
														<path
                                id="official-svg-4-cloud-3" class="feedback-svg-cloud st7" d="M58.697,29.897c0.209-2.401-1.417-4.626-3.836-5.108c-0.13-0.026-0.26-0.045-0.389-0.06
 c-0.3-2.413-2.108-4.47-4.623-4.971c-3.141-0.625-6.195,1.415-6.82,4.556c-0.097,0.485-0.129,0.968-0.104,1.44
 c-1.622,0.821-2.89,2.293-3.422,4.142H58.697z" data-svg-origin="39.50299835205078 19.64540672302246"
                                transform="matrix(0.92197,0,0,0.92197,3.0823311769697526,1.0934429856936267)"></path>
													</g>
												</svg>
											</div>
											<h2>Персональні дані
												<br>та інша інформація
												</h2>
											</div>
											<p>4.1. Опелла Хелскеа не розкриватиме третім особам ваші персональні дані, які можуть бути повідомлені вами через форму зворотного зв'язку на цьому Сайті або електронною поштою. Такі дані будуть використовуватися виключно з метою надання вам якомога більш ефективної та повної відповіді.
</p>
											<p>Використовуючи форму зворотного зв'язку Сайту або адресу електронної пошти Опелла Хелскеа, зазначену на цьому Сайті, ви надаєте свою однозначну згоду на обробку Опелла Хелскеа наданих вами у відповідній комунікації персональних даних в обсязі та на умовах, зазначених у формі.
</p>
											<p style="text-align: left;text-indent: 0;">Згідно із Законом України «Про захист персональних даних», ви маєте право:
</p>
											<ol
                          class="custom-ordered-list">
												<li class="custom-list-item-v2">знати про джерела збору, місцезнаходження своїх персональних даних, мету їх обробки, місцезнаходження або місце проживання (перебування) власника або розпорядника персональних даних або дати відповідне доручення щодо отримання цієї інформації;
</li>
												<li
                            class="custom-list-item-v2">отримувати інформацію про умови надання доступу до персональних даних, включаючи інформацію про третіх осіб, яким передаються ваші персональні дані;
</li>
												<li
                            class="custom-list-item-v2">на доступ до своїх персональних даних;
</li>
												<li
                            class="custom-list-item-v2">отримувати не пізніше ніж через тридцять календарних днів з дня надходження запиту, крім випадків, передбачених законом, відповідь про те, чи обробляються ваші персональні дані, а також отримувати зміст таких персональних даних;
</li>
												<li
                            class="custom-list-item-v2">пред’являти мотивовану вимогу власнику персональних даних із запереченням проти обробки своїх персональних даних;
</li>
												<li
                            class="custom-list-item-v2">пред’являти мотивовану вимогу про зміну або знищення своїх персональних даних будь-яким власником та розпорядником персональних даних, якщо ці дані обробляються незаконно або є недостовірними;
</li>
												<li
                            class="custom-list-item-v2">на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв'язку з умисним приховуванням, не наданням або несвоєчасним їх наданням, а також на захист від надання відомостей, що є недостовірними або ганьблять честь, гідність та ділову репутацію фізичної особи;
</li>
												<li
                            class="custom-list-item-v2">звертатися зі скаргами щодо обробки своїх персональних даних до Уповноваженого Верховної Ради України з прав людини або до суду;
</li>
												<li
                            class="custom-list-item-v2">застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних;
</li>
												<li
                            class="custom-list-item-v2">вносити застереження щодо обмеження права на обробку своїх персональних даних при наданні згоди;
</li>
												<li
                            class="custom-list-item-v2">відкликати згоду на обробку персональних даних;
</li>
												<li
                            class="custom-list-item-v2">знати механізм автоматичної обробки персональних даних;
</li>
												<li
                            class="custom-list-item-v2">на захист від автоматизованого рішення, яке має для нього правові наслідки.
</li>
											</ol>
											<p>Відповідні запити ви можете надсилати на адресу Опелла Хелскеа шляхом заповнення та направлення форми зворотного зв'язку на Сайті або з використанням такої поштової адреси:

												<br>
                        ТОВ «Опелла Хелскеа Україна»<br>
вул. Жилянська, 48-50А,<br>
Бізнес Центр Прайм,<br>
01033, Київ, Україна

															</p>
															<p>4.2. Сайт не призначений для отримання від вас конфіденційної інформації. Таким чином, окрім персональних даних, будь-яка інформація незалежно від її форми — документ, дані, зображення, запитання, пропозиція, концепція, коментар тощо, яку ви надсилаєте через форму зворотного зв'язку на Сайті, в жодному разі не буде вважатися конфіденційною. Відповідно, самим фактом надсилання нам такої інформації ви підтверджуєте та даєте згоду на використання, відтворення, опублікування, зміну або пересилання такої інформації з метою обробки вашого запиту.
</p>
															<div
                                  class="chapter-title-block">
																<div class="inline-svg-float-block">
																	<svg id="official-svg-5" class="inline-svg-illustration"
                                       xmlns="http://www.w3.org/2000/svg"
                                       xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                                       viewBox="0 0 73.413 67.046">
																		<path id="official-svg-5-number" class="st0" d="M30.415,66.707c-2.315,0-19.103-1.736-19.103-5.403c0-0.193,0.096-0.386,0.096-0.579
 l2.026-10.902c0.097-0.675,0.482-0.868,0.868-0.868c0.482,0,0.965,0.193,1.447,0.386c5.21,1.544,9.069,2.895,14.762,2.895
 c7.333,0,11.578-3.763,11.578-10.42c0-5.5-2.605-7.815-7.333-7.815c-4.342,0-6.367,1.64-8.973,4.342
 c-0.482,0.482-0.868,0.771-1.447,0.771c-0.192,0-0.386,0-0.675-0.096L14.11,35.93c-1.255-0.386-1.544-0.772-1.544-1.737
 l3.088-31.935c0.192-1.64,0.289-1.93,2.122-1.93h33.575c1.641,0,2.123,0,2.123,1.833v11.481c0,1.833-0.289,1.93-2.026,1.93h-20.84
 l-0.965,7.14c1.93-1.351,4.631-1.93,7.333-1.93c12.735,0,21.226,8.297,21.226,20.937C58.202,56.963,47.011,66.707,30.415,66.707z"></path>
																		<g
                                        id="official-svg-5-hut">
																			<rect class="st1" x="42.363" y="62.297" width="16.788" height="4.314"></rect>
																			<polygon
                                          class="st2"
                                          points="60.91,61.634 53.65,61.634 47.127,53.785 54.387,53.785 	"></polygon>
																			<polygon
                                          class="st1" points="53.65,61.634 40.605,61.634 47.127,53.785 	"></polygon>
																			<rect class="st2"
                                            x="53.149"
                                            y="62.297"
                                            width="6.003"
                                            height="4.314"></rect>
																			<rect
                                          class="st3" x="39.905" y="66.183" width="21.686" height="0.858"></rect>
																			<rect class="st3"
                                            x="39.905"
                                            y="61.634"
                                            width="21.686"
                                            height="0.858"></rect>
																			<rect
                                          class="st3" x="46.373" y="53.356" width="8.785" height="0.858"></rect>
																			<rect class="st3"
                                            x="46.129"
                                            y="63.167"
                                            width="2.409"
                                            height="3.016"></rect>
																			<circle
                                          class="official-svg-window st3" cx="47.333" cy="59.04" r="0.688"
                                          data-svg-origin="46.64500045776367 58.35200119018555"
                                          transform="matrix(1,0,0,1,0,0)"
                                          style="fill: rgb(198, 199, 101);"></circle>
																		</g>
																		<g id="official-svg-5-mountains">
																			<path id="official-svg-5-mountain-1" class="st4" d="M32.457,64.195l-3.248-4.574c-0.669-0.942-2.068-0.942-2.738,0l-3.248,4.574
 c-0.789,1.112,0.006,2.65,1.369,2.65h6.496C32.451,66.846,33.246,65.307,32.457,64.195z"></path>
																			<path
                                          id="official-svg-5-mountain-2" class="st4" d="M22.469,60.086l-6.835-9.626c-0.899-1.266-2.779-1.266-3.678,0l-6.835,9.626
 c-1.06,1.494,0.007,3.561,1.839,3.561H20.63C22.461,63.648,23.529,61.58,22.469,60.086z"></path>
																			<path
                                          id="official-svg-5-mountain-3" class="st5" d="M27.417,63.26l-4.394-6.189c-0.905-1.275-2.798-1.275-3.704,0l-4.394,6.189
 c-1.068,1.504,0.008,3.586,1.852,3.586h8.788C27.409,66.846,28.484,64.764,27.417,63.26z"></path>
																			<path
                                          id="official-svg-5-mountain-4" class="st6" d="M21.708,63.888l-5.676-7.995c-0.747-1.052-2.308-1.052-3.055,0l-5.676,7.995
 c-0.881,1.24,0.006,2.958,1.527,2.958h11.353C21.702,66.846,22.589,65.128,21.708,63.888z"></path>
																			<path
                                          id="official-svg-5-mountain-5" class="st5" d="M10.493,64.783l-3.959-5.576c-0.521-0.734-1.61-0.734-2.131,0l-3.959,5.576
 c-0.614,0.865,0.004,2.063,1.065,2.063h7.918C10.488,66.846,11.107,65.648,10.493,64.783z"></path>
																		</g>
																		<g
                                        id="official-svg-5-trees">
																			<path id="official-svg-5-tree-1" class="official-svg-tree st6" d="M65.441,53.35c-0.084-0.017-0.167-0.029-0.25-0.038c-0.193-1.551-1.355-2.873-2.971-3.195
 c-2.019-0.402-3.981,0.909-4.383,2.928c-0.062,0.312-0.083,0.622-0.067,0.925c-1.145,0.579-2.022,1.659-2.291,3.015
 c-0.449,2.254,0.944,4.447,3.137,5.025c0.385,0.942,1.15,1.71,2.142,2.081v2.749h2.626v-2.751c1.162-0.44,2.077-1.451,2.338-2.759
 c0.136-0.683,0.075-1.36-0.142-1.972c1.122-0.278,2.039-1.179,2.28-2.387C68.191,55.302,67.108,53.682,65.441,53.35z"
                                            data-svg-origin="61.6572208404541 66.84000396728516"
                                            transform="matrix(0.99944,-0.03323,0.03323,0.99944,-0.7474482885633469,1.4763016709153902)"
                                            style="transform-origin: 0px 0px 0px;"></path>
																			<path
                                          id="official-svg-5-tree-2" class="official-svg-tree st5" d="M73.03,60.437c-0.027-0.062-0.057-0.121-0.089-0.18c0.157-0.589,0.126-1.233-0.136-1.836
 c-0.543-1.249-1.888-1.901-3.17-1.616c-0.598-0.795-1.681-1.109-2.633-0.695c-1.115,0.485-1.625,1.781-1.141,2.895
 c0.009,0.02,0.02,0.039,0.029,0.059c-0.739,0.569-1.028,1.586-0.639,2.48c0.174,0.401,0.46,0.719,0.804,0.934
 c-0.017,0.41,0.053,0.831,0.228,1.233c0.332,0.763,0.962,1.301,1.694,1.544v1.583h1.545V65.32c0.154-0.038,0.307-0.086,0.458-0.151
 c0.771-0.335,1.314-0.976,1.552-1.719c0.12-0.029,0.24-0.067,0.357-0.118C73.004,62.848,73.515,61.552,73.03,60.437z"
                                          data-svg-origin="69.14551544189453 66.83800506591797"
                                          transform="matrix(0.99783,-0.06573,0.06573,0.99783,-3.30886466106786,4.293999490486351)"
                                          style="transform-origin: 0px 0px 0px;"></path>
																			<path id="official-svg-5-tree-3"
                                            class="official-svg-tree st7" d="M61.656,59.04c0-1.563-1.267-2.831-2.831-2.831c-0.019,0-0.037,0.002-0.055,0.003
 c-0.255-1.246-1.357-2.183-2.678-2.183c-1.51,0-2.733,1.224-2.733,2.733c0,0.113,0.009,0.223,0.022,0.332
 c-0.922,0.484-1.552,1.45-1.552,2.564c0,1.153,0.676,2.146,1.651,2.612c-0.078,0.193-0.122,0.404-0.122,0.626
 c0,0.926,0.75,1.676,1.676,1.676c0.203,0,0.396-0.039,0.576-0.106v2.373h1.754v-2.626c0.216,0.063,0.443,0.097,0.68,0.097
 c1.348,0,2.44-1.093,2.44-2.44c0-0.172-0.018-0.34-0.052-0.502C61.172,60.858,61.656,60.006,61.656,59.04z"
                                            data-svg-origin="56.742496490478516 66.83899688720703"
                                            transform="matrix(0.99737,-0.07242,0.07242,0.99737,-3.5940963401800516,3.808053108704744)"
                                            style="transform-origin: 0px 0px 0px;"></path>
																		</g>
																		<g
                                        id="official-svg-5-clouds">
																			<path id="official-svg-5-cloud-1" class="feedback-svg-cloud st8" d="M60.46,44.662c0.077-0.879-0.518-1.693-1.404-1.869c-0.048-0.009-0.095-0.016-0.142-0.022
 c-0.11-0.883-0.771-1.636-1.692-1.819c-1.15-0.229-2.267,0.518-2.496,1.667c-0.035,0.177-0.047,0.354-0.038,0.527
 c-0.594,0.301-1.058,0.839-1.252,1.516H60.46z" data-svg-origin="53.436004638671875 40.91072463989258"
                                            transform="matrix(0.82024,0,0,0.82024,9.60512183380127,8.948702754020697)"></path>
																			<path
                                          id="official-svg-5-cloud-2" class="feedback-svg-cloud st8" d="M22.765,27.739c0.209-2.401-1.417-4.626-3.836-5.108c-0.13-0.026-0.26-0.045-0.389-0.06
 c-0.3-2.413-2.108-4.47-4.623-4.971c-3.141-0.625-6.195,1.415-6.82,4.556c-0.097,0.485-0.129,0.968-0.104,1.44
 c-1.622,0.821-2.89,2.293-3.422,4.142H22.765z" data-svg-origin="3.5709986686706543 17.487407684326172"
                                          transform="matrix(0.80469,0,0,0.80469,0.697435878872871,5.321498984124921)"></path>
																		</g>
																	</svg>
																</div>
																<h2>Обмеження
																	<br>відповідальності
																	</h2>
																</div>
																<p>Опелла Хелскеа прагне робити все можливе, щоб забезпечити точність та актуальність інформації на Сайті. Опелла Хелскеа залишає за собою право коригувати зміст Сайту в будь-який час без попередження.
</p>
																<p>З огляду на це Опелла Хелскеа не несе відповідальності за:
</p>
																<ul
                                    class="custom-unordered-list">
																	<li>
																		<div class="custom-list-dot"></div>
																		<span
                                        class="custom-list-item">будь-яку неточність, помилку чи неповноту інформації, опублікованої на Сайті;
</span>
																	</li>
																	<li>
																		<div
                                        class="custom-list-dot"></div>
																		<span class="custom-list-item">будь-які збитки, що настали внаслідок шахрайського втручання з боку третьої особи внаслідок зміни інформації або компонентів Сайту;
</span>
																	</li>
																	<li>
																		<div
                                        class="custom-list-dot"></div>
																		<span class="custom-list-item">у ширшому розумінні — за будь-яке пошкодження власності або збитки, що настали з будь-якої причини і які мають будь-яке походження, характер або будь-які наслідки, навіть якщо Опелла Хелскеа отримала попередження про можливість настання таких пошкоджень або збитків, спричинених (i) будь-яким доступом на Сайт або неможливістю доступу на Сайт, (ii) використанням Сайту, включаючи будь-які збитки або віруси, які можуть інфікувати ваш комп'ютер або іншу власність, та/або (iii) довірою до інформації, яка прямо чи опосередковано була отримана із Сайту.
</span>
																	</li>
																</ul>
																<p>Компоненти цього Сайту або будь-якого іншого сайту вказуються в тому вигляді, «як вони є», без прямих або непрямих гарантій. Опелла Хелскеа не надає жодних прямих чи непрямих гарантій щодо, без обмеження, ринкової вартості чи відповідності вимогам для використання з будь-якою конкретною метою.
</p>
																<div
                                    class="chapter-title-block">
																	<div class="inline-svg-float-block">
																		<svg id="official-svg-6" class="inline-svg-illustration"
                                         xmlns="http://www.w3.org/2000/svg"
                                         xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                                         viewBox="0 0 70.748 68.483">
																			<path id="official-svg-6-number" class="st0" d="M35.933,68.155c-15.244,0-23.155-10.902-23.155-28.944c0-22.866,11.867-38.882,31.066-38.882
 c7.43,0,12.832,2.991,12.832,5.017c0,0.193,0,0.482-0.097,0.675l-2.894,9.648c-0.193,0.579-0.676,0.868-1.158,0.868
 c-0.289,0-0.482-0.096-0.771-0.193c-2.316-1.158-5.114-2.123-7.912-2.123c-5.017,0-10.227,2.412-14.375,10.709
 c4.052-2.991,7.139-3.763,9.551-3.763c12.543,0,20.358,8.683,20.358,22.77C59.379,58.217,50.019,68.155,35.933,68.155z
 M36.802,33.518c-4.245,0-7.236,3.087-7.236,10.227c0,7.622,2.702,10.709,6.851,10.709c4.728,0,7.043-3.28,7.043-10.902
 C43.459,36.412,41.24,33.518,36.802,33.518z"></path>
																			<g id="official-svg-6-hut">
																				<rect class="st1" x="42.465" y="63.433" width="17.807" height="4.576"></rect>
																				<polygon
                                            class="st2"
                                            points="62.138,62.729 54.437,62.729 47.519,54.403 55.219,54.403 	"></polygon>
																				<polygon
                                            class="st1" points="54.437,62.729 40.6,62.729 47.519,54.403 	"></polygon>
																				<rect class="st2"
                                              x="53.906"
                                              y="63.433"
                                              width="6.367"
                                              height="4.576"></rect>
																				<rect
                                            class="st3" x="39.858" y="67.554" width="23.002" height="0.91"></rect>
																				<rect class="st3"
                                              x="39.858"
                                              y="62.729"
                                              width="23.002"
                                              height="0.91"></rect>
																				<rect
                                            class="st3" x="46.718" y="53.949" width="9.318" height="0.91"></rect>
																				<rect class="st3"
                                              x="46.459" y="64.355"
                                              width="2.555"
                                              height="3.199"></rect>
																				<circle
                                            class="official-svg-window st3" cx="47.737" cy="59.978" r="0.73"
                                            data-svg-origin="47.00699996948242 59.24800109863281"
                                            transform="matrix(1,0,0,1,0,0)"
                                            style="fill: rgb(198, 199, 101);"></circle>
																			</g>
																			<g id="official-svg-6-mountains">
																				<path id="official-svg-6-mountains-5" class="st4" d="M32.457,65.614l-3.248-4.574c-0.669-0.942-2.068-0.942-2.738,0l-3.248,4.574
 c-0.789,1.112,0.006,2.65,1.369,2.65h6.496C32.451,68.264,33.246,66.726,32.457,65.614z"></path>
																				<path
                                            id="official-svg-6-mountains-4" class="st4" d="M22.469,61.505l-6.835-9.626c-0.899-1.266-2.779-1.266-3.678,0l-6.835,9.626
 c-1.06,1.494,0.007,3.561,1.839,3.561H20.63C22.461,65.066,23.529,62.999,22.469,61.505z"></path>
																				<path
                                            id="official-svg-6-mountains-3" class="st5" d="M27.417,64.679l-4.394-6.189c-0.905-1.275-2.798-1.275-3.704,0l-4.394,6.189
 c-1.068,1.504,0.008,3.586,1.852,3.586h8.788C27.409,68.264,28.484,66.182,27.417,64.679z"></path>
																				<path
                                            id="official-svg-6-mountains-2" class="st6" d="M21.708,65.307l-5.676-7.995c-0.747-1.052-2.308-1.052-3.055,0l-5.676,7.995
 c-0.881,1.24,0.006,2.958,1.527,2.958h11.353C21.702,68.264,22.589,66.547,21.708,65.307z"></path>
																				<path
                                            id="official-svg-6-mountains-1" class="st5" d="M10.493,66.201l-3.959-5.576c-0.521-0.734-1.61-0.734-2.131,0l-3.959,5.576
 c-0.614,0.865,0.004,2.063,1.065,2.063h7.918C10.488,68.264,11.107,67.067,10.493,66.201z"></path>
																			</g>
																			<g
                                          id="official-svg-6-trees">
																				<path id="official-svg-6-tree-3" class="official-svg-tree st6" d="M62.776,54.65c-0.084-0.017-0.167-0.029-0.25-0.038c-0.193-1.551-1.355-2.873-2.971-3.195
 c-2.019-0.402-3.981,0.909-4.383,2.928c-0.062,0.312-0.083,0.622-0.067,0.925c-1.145,0.579-2.022,1.659-2.291,3.015
 c-0.449,2.254,0.944,4.447,3.137,5.025c0.385,0.942,1.15,1.71,2.142,2.081v2.749h2.626v-2.751c1.162-0.44,2.077-1.451,2.338-2.759
 c0.136-0.683,0.075-1.36-0.142-1.972c1.122-0.278,2.039-1.179,2.28-2.387C65.526,56.603,64.444,54.982,62.776,54.65z"
                                              data-svg-origin="58.992238998413086 68.14000701904297"
                                              transform="matrix(0.99924,-0.03877,0.03877,0.99924,-1.1578389309827966,1.7288600396561868)"
                                              style="transform-origin: 0px 0px 0px;"></path>
																				<path
                                            id="official-svg-6-tree-2" class="official-svg-tree st5" d="M70.365,61.737c-0.027-0.062-0.057-0.121-0.089-0.18c0.157-0.589,0.126-1.233-0.136-1.836
 c-0.543-1.249-1.888-1.901-3.17-1.616c-0.598-0.795-1.681-1.109-2.633-0.695c-1.115,0.485-1.625,1.781-1.141,2.895
 c0.009,0.02,0.02,0.039,0.029,0.059c-0.739,0.569-1.028,1.586-0.639,2.48c0.174,0.401,0.46,0.719,0.804,0.934
 c-0.017,0.41,0.053,0.831,0.228,1.233c0.332,0.763,0.962,1.301,1.694,1.544v1.583h1.545V66.62c0.154-0.038,0.307-0.086,0.458-0.151
 c0.771-0.335,1.314-0.976,1.552-1.719c0.12-0.029,0.24-0.067,0.357-0.118C70.339,64.148,70.85,62.852,70.365,61.737z"
                                            data-svg-origin="66.48051261901855 68.13800048828125"
                                            transform="matrix(0.9999,-0.01346,0.01346,0.9999,0.024095205687691745,0.5050891252290306)"
                                            style="transform-origin: 0px 0px 0px;"></path>
																				<path id="official-svg-6-tree-1"
                                              class="official-svg-tree st7" d="M58.992,60.34c0-1.563-1.267-2.831-2.831-2.831c-0.019,0-0.037,0.002-0.055,0.003
 c-0.255-1.246-1.357-2.183-2.678-2.183c-1.51,0-2.733,1.224-2.733,2.733c0,0.113,0.009,0.223,0.022,0.332
 c-0.922,0.484-1.552,1.45-1.552,2.564c0,1.153,0.676,2.146,1.651,2.612c-0.078,0.193-0.122,0.404-0.122,0.626
 c0,0.926,0.75,1.676,1.676,1.676c0.203,0,0.396-0.039,0.576-0.106v2.373H54.7v-2.626c0.216,0.063,0.443,0.097,0.68,0.097
 c1.348,0,2.44-1.093,2.44-2.44c0-0.172-0.018-0.34-0.052-0.502C58.507,62.158,58.992,61.306,58.992,60.34z"
                                              data-svg-origin="54.0784969329834 68.13899993896484"
                                              transform="matrix(0.99999,-0.0009,0.0009,0.99999,1.0361577955960959,-0.42815258851122806)"
                                              style="transform-origin: 0px 0px 0px;"></path>
																			</g>
																			<g
                                          id="official-svg-6-clouds">
																				<path id="official-svg-6-cloud-3" class="feedback-svg-cloud st8" d="M19.011,40.412c0.135-1.549-0.914-2.985-2.475-3.295c-0.084-0.017-0.168-0.029-0.251-0.039
 c-0.194-1.557-1.36-2.884-2.983-3.207c-2.027-0.403-3.997,0.913-4.4,2.939c-0.062,0.313-0.083,0.624-0.067,0.929
 c-1.047,0.53-1.865,1.479-2.208,2.673H19.011z" data-svg-origin="6.626999855041504 33.79841995239258"
                                              transform="matrix(0.87802,0,0,0.87802,0.8083098989857547,4.561912833637643)"></path>
																				<path
                                            id="official-svg-6-cloud-2" class="feedback-svg-cloud st8" d="M62.161,46.14c0.077-0.879-0.518-1.693-1.404-1.869c-0.048-0.009-0.095-0.016-0.142-0.022
 c-0.11-0.883-0.771-1.636-1.692-1.819c-1.15-0.229-2.267,0.518-2.496,1.667c-0.035,0.177-0.047,0.354-0.038,0.527
 c-0.594,0.301-1.058,0.839-1.252,1.516H62.161z" data-svg-origin="55.13700485229492 42.38872528076172"
                                            transform="matrix(0.97975,0,0,0.97975,1.116524348258963,-0.7366283130645828)"></path>
																				<path
                                            id="official-svg-6-cloud-1" class="feedback-svg-cloud st8" d="M62.843,32.217c0.209-2.401-1.417-4.626-3.836-5.108c-0.13-0.026-0.26-0.045-0.389-0.06
 c-0.3-2.413-2.108-4.47-4.623-4.971c-3.141-0.625-6.195,1.415-6.82,4.556c-0.097,0.485-0.129,0.968-0.104,1.44
 c-1.622,0.821-2.89,2.293-3.422,4.142H62.843z" data-svg-origin="43.64899826049805 21.96540641784668"
                                            transform="matrix(0.9953,0,0,0.9953,0.20490779738956633,-1.8029957309828828)"></path>
																			</g>
																		</svg>
																	</div>
																	<h2>Доступність
																		<br>Сайту
																		</h2>
																	</div>
																	<p>Ви визнаєте, що (i) технічно неможливо створити Сайт, позбавлений будь-яких недоліків, і Опелла Хелскеа не бере на себе зобов'язання це зробити; (ii) недоліки можуть призвести до тимчасової недоступності Сайту; а також (iii) на роботу Сайту можуть впливати події та/або фактори, які Опелла Хелскеа не може контролювати, зокрема засоби передачі та комунікації між вами та Опелла Хелскеа, між Опелла Хелскеа та іншими мережами.
</p>
																	<p>Опелла Хелскеа та/або її постачальники можуть у будь-який час змінити або припинити тимчасово чи постійно роботу всього Сайту або його окремих частин для технічного обслуговування та/або вдосконалення та/або внесення змін на Сайті. Опелла Хелскеа не несе відповідальності за будь-які зміни, призупинення чи перебої у роботі Сайту.
</p>
																	<div
                                      class="chapter-title-block">
																		<div class="inline-svg-float-block">
																			<svg id="official-svg-7" class="inline-svg-illustration"
                                           xmlns="http://www.w3.org/2000/svg"
                                           xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px"
                                           y="0px" viewBox="0 0 64.457 67.133">
																				<path class="st0" id="official-svg-7-number" d="M37.605,65.261c-0.482,1.158-0.676,1.544-1.544,1.544c-0.193,0-17.522-0.067-17.522-0.067
 c-0.644,0-0.736-0.579-0.736-1.158c0-0.386,0.097-0.771,0.386-1.351l21.538-48.078h-22.19c-1.641,0-1.93-0.096-1.93-1.93V2.354
 c0-1.833,0.289-2.026,1.93-2.026h40.908c1.64,0,2.026,0.386,2.026,2.026v6.464L37.605,65.261z"></path>
																				<g
                                            id="official-svg-7-clouds">
																					<path id="official-svg-7-cloud-1" class="feedback-svg-cloud st1" d="M33.179,39.032c0.135-1.549-0.914-2.985-2.475-3.295c-0.084-0.017-0.168-0.029-0.251-0.039
 c-0.194-1.557-1.36-2.884-2.983-3.207c-2.027-0.403-3.997,0.913-4.4,2.939c-0.062,0.313-0.083,0.624-0.067,0.929
 c-1.047,0.53-1.865,1.479-2.208,2.673H33.179z" data-svg-origin="20.795001983642578 32.41842269897461"
                                                transform="matrix(0.92197,0,0,0.92197,1.6225877936681172,2.0900930378172324)"></path>
																					<path
                                              id="official-svg-7-cloud-2" class="feedback-svg-cloud st1" d="M53.195,48.511c0.077-0.879-0.518-1.693-1.404-1.869c-0.048-0.009-0.095-0.016-0.142-0.022
 c-0.11-0.883-0.771-1.636-1.692-1.819c-1.15-0.229-2.267,0.518-2.496,1.667c-0.035,0.177-0.047,0.354-0.038,0.527
 c-0.594,0.301-1.058,0.839-1.252,1.516H53.195z" data-svg-origin="46.17100524902344 44.759727478027344"
                                              transform="matrix(0.82024,0,0,0.82024,8.299238193511968,9.640561014175422)"></path>
																					<path
                                              id="official-svg-7-cloud-3" class="feedback-svg-cloud st1" d="M59.363,27.836c0.209-2.401-1.417-4.626-3.836-5.108c-0.13-0.026-0.26-0.045-0.389-0.06
 c-0.3-2.413-2.108-4.47-4.623-4.971c-3.141-0.625-6.195,1.415-6.82,4.556c-0.097,0.485-0.129,0.968-0.104,1.44
 c-1.622,0.821-2.89,2.293-3.422,4.142H59.363z" data-svg-origin="40.16899871826172 17.584407806396484"
                                              transform="matrix(0.80469,0,0,0.80469,7.845228610780495,5.34044364685482)"></path>
																				</g>
																				<g
                                            id="official-svg-7-mountains">
																					<path id="official-svg-7-mountain-5" class="st2" d="M56.012,64.236l-3.248-4.574c-0.669-0.942-2.068-0.942-2.738,0l-3.248,4.574
 c-0.789,1.112,0.006,2.65,1.369,2.65h6.496C56.007,66.887,56.801,65.348,56.012,64.236z"></path>
																					<path
                                              id="official-svg-7-mountain-4" class="st2" d="M46.024,60.127l-6.835-9.626c-0.899-1.266-2.779-1.266-3.678,0l-6.835,9.626
 c-1.06,1.494,0.007,3.561,1.839,3.561h13.669C46.017,63.689,47.085,61.621,46.024,60.127z"></path>
																					<path
                                              id="official-svg-7-mountain-3" class="st3" d="M50.972,63.301l-4.394-6.189c-0.905-1.275-2.798-1.275-3.704,0l-4.394,6.189
 c-1.068,1.504,0.008,3.586,1.852,3.586h8.788C50.965,66.887,52.04,64.805,50.972,63.301z"></path>
																					<path
                                              id="official-svg-7-mountain-2" class="st4" d="M45.264,63.929l-5.676-7.995c-0.747-1.052-2.308-1.052-3.055,0l-5.676,7.995
 c-0.881,1.24,0.006,2.958,1.527,2.958h11.353C45.258,66.887,46.144,65.169,45.264,63.929z"></path>
																					<path
                                              id="official-svg-7-mountain-1" class="st3" d="M34.048,64.824l-3.959-5.576c-0.521-0.734-1.61-0.734-2.131,0l-3.959,5.576
 c-0.614,0.865,0.004,2.063,1.065,2.063h7.918C34.044,66.887,34.663,65.689,34.048,64.824z"></path>
																				</g>
																				<g
                                            id="official-svg-7-trees">
																					<path id="official-svg-7-tree-3" class="official-svg-tree st4" d="M58.191,56.362c-0.065-0.013-0.13-0.022-0.195-0.03c-0.151-1.21-1.057-2.243-2.319-2.494
 c-1.576-0.314-3.107,0.71-3.421,2.285c-0.048,0.243-0.065,0.485-0.052,0.722c-0.893,0.452-1.578,1.295-1.788,2.353
 c-0.35,1.76,0.737,3.471,2.449,3.922c0.301,0.735,0.898,1.334,1.672,1.624v2.145h2.05v-2.147c0.907-0.344,1.621-1.132,1.825-2.153
 c0.106-0.533,0.059-1.062-0.111-1.539c0.875-0.217,1.592-0.92,1.779-1.863C60.338,57.886,59.493,56.621,58.191,56.362z"
                                                data-svg-origin="55.23843765258789 66.88899993896484"
                                                transform="matrix(0.99999,-0.00327,0.00327,0.99999,0.9048469899890099,-0.29458007710860734)"
                                                style="transform-origin: 0px 0px 0px;"></path>
																					<path
                                              id="official-svg-7-tree-2" class="official-svg-tree st3" d="M64.115,61.894c-0.021-0.048-0.045-0.095-0.07-0.14c0.123-0.46,0.099-0.962-0.106-1.433
 c-0.424-0.975-1.474-1.483-2.474-1.261c-0.467-0.62-1.312-0.865-2.055-0.542c-0.87,0.378-1.269,1.39-0.89,2.26
 c0.007,0.016,0.015,0.03,0.023,0.046c-0.577,0.444-0.803,1.238-0.499,1.936c0.136,0.313,0.359,0.561,0.627,0.729
 c-0.013,0.32,0.042,0.648,0.178,0.962c0.259,0.595,0.751,1.016,1.322,1.205v1.235h1.206v-1.186c0.12-0.029,0.24-0.067,0.357-0.118
 c0.602-0.262,1.025-0.762,1.212-1.341c0.094-0.023,0.187-0.052,0.279-0.092C64.094,63.775,64.493,62.764,64.115,61.894z"
                                              data-svg-origin="61.083173751831055 66.89100646972656"
                                              transform="matrix(0.99979,-0.02017,0.02017,0.99979,-0.606794150885376,0.936613852774812)"
                                              style="transform-origin: 0px 0px 0px;"></path>
																					<path id="official-svg-7-tree-1"
                                                class="official-svg-tree st5" d="M55.238,60.803c0-1.22-0.989-2.21-2.21-2.21c-0.015,0-0.029,0.002-0.043,0.002
 c-0.199-0.972-1.059-1.704-2.09-1.704c-1.178,0-2.133,0.955-2.133,2.133c0,0.088,0.007,0.174,0.017,0.259
 c-0.72,0.378-1.211,1.132-1.211,2.001c0,0.9,0.527,1.675,1.289,2.039c-0.061,0.151-0.095,0.316-0.095,0.488
 c0,0.722,0.586,1.308,1.308,1.308c0.158,0,0.309-0.031,0.449-0.082v1.852h1.369v-2.049c0.168,0.049,0.346,0.076,0.53,0.076
 c1.052,0,1.905-0.853,1.905-1.905c0-0.134-0.014-0.265-0.041-0.392C54.859,62.222,55.238,61.557,55.238,60.803z"
                                                data-svg-origin="51.40299987792969 66.88899993896484"
                                                transform="matrix(0.99881,-0.04865,0.04865,0.99881,-2.336586921258995,2.2076628723839065)"
                                                style="transform-origin: 0px 0px 0px;"></path>
																				</g>
																				<g
                                            id="official-svg-7-hut">
																					<rect class="st6" x="2.474" y="62.439" width="16.898" height="4.197"></rect>
																					<polygon
                                              class="st7"
                                              points="21.142,61.793 13.835,61.793 7.269,54.157 14.577,54.157 	"></polygon>
																					<polygon
                                              class="st6" points="13.835,61.793 0.704,61.793 7.269,54.157 	"></polygon>
																					<rect class="st7"
                                                x="13.33"
                                                y="62.439"
                                                width="6.042"
                                                height="4.197"></rect>
																					<rect
                                              class="st8" x="0" y="66.219" width="21.828" height="0.834"></rect>
																					<rect class="st8" x="0"
                                                y="61.793"
                                                width="21.828"
                                                height="0.834"></rect>
																					<rect
                                              class="st8" x="6.51" y="53.74" width="8.842" height="0.834"></rect>
																					<rect class="st8" x="6.264"
                                                y="63.285"
                                                width="2.425"
                                                height="2.934"></rect>
																					<ellipse
                                              class="st8" cx="7.476" cy="59.27" rx="0.692" ry="0.669"></ellipse>
																				</g>
																			</svg>
																		</div>
																		<h2>Інформація
																			<br>про продукцію
																			</h2>
																		</div>
																		<p>Інформація, що міститься та була опублікована на Сайті, може містити прямі або опосередковані посилання на продукти, програми та послуги компаній Групи «Санофі», які не були оголошені або недоступні в деяких країнах або деяких регіонах, або які можуть постачатися під іншим найменуванням і можуть підлягати різному регулюванню чи іншим умовам застосування залежно від країни. Такі посилання не означають, що компанія Групи «Санофі» має намір продавати таку продукцію, програми або послуги у вашій країні. Звертайтеся до Опелла Хелскеа, щоб отримати будь-яку інформацію про доступні для вас продукцію, програми та послуги.
</p>

																			<div
                                          class="chapter-title-block">
																				<div class="inline-svg-float-block">
																					<svg id="official-svg-9" class="inline-svg-illustration"
                                               xmlns="http://www.w3.org/2000/svg"
                                               xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px"
                                               y="0px" viewBox="0 0 57.857 68.697">
																						<path id="official-svg-9-number" class="st0" d="M19.339,68.348c-7.429,0-12.06-1.93-12.06-3.666c0-0.193,0.097-0.387,0.097-0.676l2.025-10.709
 c0.097-0.579,0.482-0.772,0.868-0.772c0.869,0,5.886,1.641,9.069,1.641c5.5,0,10.228-2.123,14.376-10.42
 c-3.184,2.604-7.14,3.859-9.552,3.859c-12.253,0-20.357-8.78-20.357-22.866c0-14.28,9.455-24.41,23.542-24.41
 c15.243,0,23.059,11.192,23.059,29.234C50.407,52.428,39.022,68.348,19.339,68.348z M26.769,14.222
 c-4.728,0-6.947,3.28-6.947,10.902c0,7.14,2.123,10.034,6.562,10.034c4.245,0,7.043-3.088,7.043-10.228
 C33.426,17.309,30.918,14.222,26.769,14.222z"></path>
																						<g id="official-svg-9-mountains">
																							<path id="official-svg-9-mountain-4" class="st1" d="M42.129,61.738l-6.835-9.626c-0.899-1.266-2.779-1.266-3.678,0l-6.835,9.626
 c-1.06,1.494,0.007,3.561,1.839,3.561H40.29C42.121,65.299,43.189,63.231,42.129,61.738z"></path>
																							<path
                                                  id="official-svg-9-mountain-2" class="st2" d="M41.368,65.539l-5.676-7.995c-0.747-1.052-2.308-1.052-3.055,0l-5.676,7.995
 c-0.881,1.24,0.006,2.958,1.527,2.958h11.353C41.362,68.497,42.249,66.78,41.368,65.539z"></path>
																							<path
                                                  id="official-svg-9-mountain-1" class="st3" d="M30.153,66.434l-3.959-5.576c-0.521-0.734-1.61-0.734-2.131,0l-3.959,5.576
 c-0.614,0.865,0.004,2.063,1.065,2.063h7.918C30.149,68.497,30.767,67.299,30.153,66.434z"></path>
																						</g>
																						<g
                                                id="official-svg-9-clouds">
																							<path id="official-svg-9-cloud-3" class="feedback-svg-cloud st4" d="M56.316,40.684c0.135-1.549-0.914-2.985-2.475-3.295c-0.084-0.017-0.168-0.029-0.251-0.039
 c-0.194-1.557-1.36-2.884-2.983-3.207c-2.027-0.403-3.997,0.913-4.4,2.939c-0.062,0.313-0.083,0.624-0.067,0.929
 c-1.047,0.53-1.865,1.479-2.208,2.673H56.316z" data-svg-origin="43.93199920654297 34.0704231262207"
                                                    transform="matrix(0.92197,0,0,0.92197,3.4279162714216582,2.2189949600431857)"></path>
																							<path
                                                  id="official-svg-9-cloud-2" class="feedback-svg-cloud st4" d="M7.198,46.62c0.077-0.879-0.518-1.693-1.404-1.869c-0.048-0.009-0.095-0.016-0.142-0.022
 c-0.11-0.883-0.771-1.636-1.692-1.819c-1.15-0.229-2.267,0.518-2.496,1.667c-0.035,0.177-0.047,0.354-0.038,0.527
 c-0.594,0.301-1.058,0.839-1.252,1.516H7.198z" data-svg-origin="0.17399954795837402 42.86872482299805"
                                                  transform="matrix(0.82024,0,0,0.82024,0.03127641874551773,9.300653286933905)"></path>
																							<path
                                                  id="official-svg-9-cloud-1" class="feedback-svg-cloud st4" d="M25.322,27.488c0.165-1.89-1.115-3.641-3.019-4.02c-0.102-0.02-0.204-0.035-0.306-0.047
 c-0.236-1.899-1.659-3.518-3.638-3.912c-2.472-0.492-4.875,1.113-5.367,3.586c-0.076,0.382-0.102,0.762-0.082,1.133
 c-1.277,0.646-2.275,1.805-2.693,3.26H25.322z" data-svg-origin="10.217000961303711 19.420330047607422"
                                                  transform="matrix(0.80469,0,0,0.80469,1.9954370488590651,5.699009460131323)"></path>
																						</g>
																						<g
                                                id="official-svg-9-hut">
																							<rect x="37.461" y="63.654" class="st5" width="17.807"
                                                    height="4.576"></rect>
																							<polygon
                                                  class="st6"
                                                  points="57.134,62.95 49.433,62.95 42.515,54.624 50.215,54.624 	"></polygon>
																							<polygon
                                                  class="st5"
                                                  points="49.433,62.95 35.596,62.95 42.515,54.624 	"></polygon>
																							<rect class="st6"
                                                    x="48.902"
                                                    y="63.654"
                                                    width="6.367"
                                                    height="4.576"></rect>
																							<rect
                                                  class="st7" x="34.854" y="67.775" width="23.002" height="0.91"></rect>
																							<rect class="st7"
                                                    x="34.854" y="62.95"
                                                    width="23.002"
                                                    height="0.91"></rect>
																							<rect
                                                  class="st7" x="41.714" y="54.169" width="9.318" height="0.91"></rect>
																							<rect class="st7"
                                                    x="41.455" y="64.576"
                                                    width="2.555"
                                                    height="3.199"></rect>
																							<circle
                                                  class="official-svg-window st7" cx="42.733" cy="60.199" r="0.73"
                                                  data-svg-origin="42.00300216674805 59.46900177001953"
                                                  transform="matrix(1,0,0,1,0,0)"
                                                  style="fill: rgb(198, 199, 101);"></circle>
																						</g>
																					</svg>
																				</div>
																				<h2>Правила
																					<br>та умови
																					</h2>
																				</div>
																				<p
                                            class="non-indented-paragraph">9.1. Видавець Сайту:
																					<br>ТОВ «Опелла Хелскеа Україна»
																						<br>вул. Жилянська, 48-50А,
																							<br>Бізнес Центр Прайм,
																								<br>01033, Київ, Україна
																									<br>ТОВ «Опелла Хелскеа Україна» зареєстроване в Єдиному державному реєстрі юридичних осіб, фізичних осіб-підприємців та громадських формувань України за номером 43726391.
																									</p>


																												<div
                                                            class="chapter-title-block">
																													<div class="inline-svg-float-block two-digits">
																														<svg id="official-svg-10" class="inline-svg-illustration"
                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                 xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                 xml:space="preserve" x="0px" y="0px"
                                                                 viewBox="0 0 101.787 66.769">
																															<path id="official-svg-10-number-1" class="st0" d="M40.754,66.441H8.433c-1.833,0-1.93-0.193-1.93-2.026v-9.455c0-1.833,0.097-2.026,1.93-2.026
 h8.201V22.156l-8.298,4.728c-0.578,0.289-1.061,0.482-1.351,0.482c-0.579,0-0.771-0.482-1.351-1.351l-4.631-7.719
 c-0.482-0.675-0.676-0.965-0.676-1.351s0.386-0.771,1.255-1.351l20.55-13.701h10.42c1.737,0,2.026,0.386,2.026,2.026v49.013h6.175
 c1.737,0,2.026,0.193,2.026,2.026v9.455C42.781,66.247,42.492,66.441,40.754,66.441z"></path>
																															<path
                                                                  id="official-svg-10-number-0" class="st0" d="M69.357,66.44c-16.74,0-24.922-12.789-24.922-32.445c0-17.209,6.207-33.667,26.05-33.667
 c18.621,0,24.451,15.799,24.451,31.88C94.936,50.078,90.516,66.44,69.357,66.44z M70.109,13.871c-4.984,0-8.652,4.232-8.652,19.937
 c0,15.235,3.573,19.184,8.37,19.184c5.266,0,8.276-3.668,8.276-18.056C78.103,17.633,75.469,13.871,70.109,13.871z"></path>
																															<g
                                                                  id="official-svg-10-mountains">
																																<path id="official-svg-10-mountain-5" class="st1" d="M68.867,62.938l-4.251-5.987c-0.876-1.233-2.707-1.233-3.583,0l-4.251,5.987
 c-1.033,1.455,0.007,3.469,1.791,3.469h8.501C68.86,66.407,69.9,64.393,68.867,62.938z"></path>
																																<path
                                                                    id="official-svg-10-mountain-4" class="st1" d="M55.796,57.561l-8.945-12.598c-1.177-1.657-3.637-1.657-4.814,0l-8.945,12.598
 c-1.388,1.955,0.01,4.661,2.407,4.661h17.889C55.786,62.221,57.183,59.515,55.796,57.561z"></path>
																																<path
                                                                    id="official-svg-10-mountain-3" class="st2" d="M62.271,61.714l-5.75-8.099c-1.185-1.669-3.662-1.669-4.847,0l-5.75,8.099
 c-1.397,1.968,0.01,4.693,2.423,4.693h11.501C62.261,66.407,63.668,63.682,62.271,61.714z"></path>
																																<path
                                                                    id="official-svg-10-mountain-2" class="st3" d="M54.8,62.536l-7.429-10.463c-0.977-1.376-3.021-1.376-3.998,0l-7.429,10.463
 c-1.153,1.623,0.008,3.871,1.999,3.871h14.858C54.792,66.407,55.953,64.159,54.8,62.536z"></path>
																															</g>
																															<g
                                                                  id="official-svg-10-trees">
																																<path id="official-svg-10-tree-3"
                                                                      class="official-svg-tree st3" d="M79.922,49.332c-0.106-0.021-0.211-0.036-0.317-0.049c-0.244-1.963-1.715-3.637-3.761-4.044
 c-2.556-0.509-5.039,1.151-5.548,3.706c-0.079,0.395-0.105,0.787-0.085,1.171c-1.449,0.733-2.559,2.1-2.9,3.816
 c-0.568,2.854,1.195,5.629,3.971,6.36c0.488,1.192,1.456,2.164,2.712,2.634v3.479h3.324v-3.483c1.47-0.557,2.629-1.836,2.959-3.492
 c0.172-0.865,0.095-1.722-0.18-2.496c1.42-0.352,2.581-1.492,2.886-3.022C83.403,51.804,82.032,49.752,79.922,49.332z"
                                                                      data-svg-origin="75.13166427612305 66.4050064086914"
                                                                      transform="matrix(0.99648,-0.08374,0.08374,0.99648,-3.474586213989868,5.7531130616903035)"
                                                                      style="transform-origin: 0px 0px 0px;"></path>
																																<path
                                                                    id="official-svg-10-tree-2"
                                                                    class="official-svg-tree st2" d="M89.528,58.303c-0.034-0.078-0.073-0.154-0.113-0.227c0.199-0.746,0.16-1.561-0.172-2.324
 c-0.687-1.581-2.39-2.406-4.013-2.045c-0.757-1.006-2.128-1.404-3.333-0.88c-1.411,0.613-2.057,2.254-1.444,3.665
 c0.011,0.026,0.025,0.049,0.037,0.074c-0.935,0.721-1.302,2.007-0.809,3.139c0.221,0.507,0.582,0.91,1.018,1.183
 c-0.021,0.52,0.067,1.051,0.289,1.56c0.42,0.966,1.218,1.647,2.144,1.954v2.004h1.956v-1.923c0.195-0.048,0.389-0.109,0.58-0.191
 c0.976-0.424,1.663-1.236,1.965-2.175c0.152-0.037,0.304-0.085,0.452-0.149C89.495,61.355,90.141,59.714,89.528,58.303z"
                                                                    data-svg-origin="84.6105842590332 66.40599822998047"
                                                                    transform="matrix(0.99849,-0.0549,0.0549,0.99849,-2.334248131358784,4.244062852217857)"
                                                                    style="transform-origin: 0px 0px 0px;"></path>
																																<path id="official-svg-10-tree-1"
                                                                      class="official-svg-tree st4" d="M75.132,56.534c0-1.979-1.604-3.583-3.583-3.583c-0.024,0-0.047,0.003-0.07,0.004
 c-0.322-1.577-1.717-2.763-3.39-2.763c-1.911,0-3.46,1.549-3.46,3.46c0,0.143,0.011,0.282,0.028,0.421
 c-1.167,0.613-1.964,1.835-1.964,3.245c0,1.46,0.855,2.716,2.09,3.306c-0.099,0.245-0.154,0.512-0.154,0.792
 c0,1.171,0.95,2.121,2.121,2.121c0.257,0,0.501-0.05,0.729-0.134v3.004h2.221v-3.324c0.273,0.079,0.561,0.123,0.86,0.123
 c1.706,0,3.089-1.383,3.089-3.089c0-0.218-0.023-0.43-0.066-0.635C74.518,58.836,75.132,57.757,75.132,56.534z"
                                                                      data-svg-origin="68.91250419616699 66.40699768066406"
                                                                      transform="matrix(0.99969,-0.02475,0.02475,0.99969,-0.23333307407848736,1.1224567382450754)"
                                                                      style="transform-origin: 0px 0px 0px;"></path>
																															</g>
																															<g
                                                                  id="official-svg-10-clouds">
																																<path id="official-svg-10-cloud-3"
                                                                      class="feedback-svg-cloud st5" d="M10.445,44.616c0.077-0.879-0.518-1.693-1.404-1.869c-0.048-0.009-0.095-0.016-0.142-0.022
 c-0.11-0.883-0.771-1.636-1.692-1.819c-1.15-0.229-2.267,0.518-2.496,1.667c-0.035,0.177-0.047,0.354-0.038,0.527
 c-0.594,0.301-1.058,0.839-1.252,1.516H10.445z" data-svg-origin="3.420999526977539 40.86472702026367"
                                                                      transform="matrix(0.87802,0,0,0.87802,0.41726691452662035,5.423806009610478)"></path>
																																<path
                                                                    id="official-svg-10-cloud-2"
                                                                    class="feedback-svg-cloud st5" d="M52.915,25.484c0.165-1.89-1.115-3.641-3.019-4.02c-0.102-0.02-0.204-0.035-0.306-0.047
 c-0.236-1.899-1.659-3.518-3.638-3.912c-2.472-0.492-4.875,1.113-5.367,3.586c-0.076,0.382-0.102,0.762-0.082,1.133
 c-1.277,0.646-2.275,1.805-2.693,3.26H52.915z" data-svg-origin="37.80999755859375 17.41632843017578"
                                                                    transform="matrix(0.97975,0,0,0.97975,0.7656524505615181,-1.2423193492889455)"></path>
																																<path
                                                                    id="official-svg-10-cloud-1"
                                                                    class="feedback-svg-cloud st5" d="M101.639,38.68c0.2-2.296-1.354-4.422-3.666-4.882c-0.124-0.025-0.248-0.043-0.372-0.057
 c-0.287-2.306-2.015-4.273-4.419-4.752c-3.003-0.598-5.921,1.352-6.519,4.355c-0.092,0.464-0.123,0.925-0.1,1.376
 c-1.551,0.785-2.763,2.192-3.271,3.96H101.639z" data-svg-origin="83.29200744628906 28.881193161010742"
                                                                    transform="matrix(0.9953,0,0,0.9953,0.3910097016228633,-1.7705299543274724)"></path>
																															</g>
																															<g
                                                                  id="official-svg-10-hut">
																																<rect class="st6" x="22.714" y="60.771" width="21.128"
                                                                      height="5.429"></rect>
																																<polygon
                                                                    class="st7"
                                                                    points="46.055,59.936 36.919,59.936 28.71,50.057 37.847,50.057 	"></polygon>
																																<polygon
                                                                    class="st6"
                                                                    points="36.919,59.936 20.501,59.936 28.71,50.057 	"></polygon>
																																<rect class="st7"
                                                                      x="36.288"
                                                                      y="60.771"
                                                                      width="7.554"
                                                                      height="5.429"></rect>
																																<rect
                                                                    class="st8" x="19.621" y="65.66" width="27.291"
                                                                    height="1.079"></rect>
																																<rect class="st8"
                                                                      x="19.621"
                                                                      y="59.936"
                                                                      width="27.291"
                                                                      height="1.079"></rect>
																																<rect
                                                                    class="st8" x="27.76" y="49.518" width="11.056"
                                                                    height="1.079"></rect>
																																<rect class="st8"
                                                                      x="27.453"
                                                                      y="61.865"
                                                                      width="3.032"
                                                                      height="3.795"></rect>
																																<circle
                                                                    class="official-svg-window st8" cx="28.969"
                                                                    cy="56.671" r="0.866"
                                                                    data-svg-origin="28.10300064086914 55.80500030517578"
                                                                    transform="matrix(1,0,0,1,0,0)"
                                                                    style="fill: rgb(198, 199, 101);"></circle>
																															</g>
																														</svg>
																													</div>
																													<h2>Авторські права на
																														<br>фото-/відеоматеріали:
																														</h2>
																													</div>
																													<p
                                                              class="non-indented-paragraph">ТОВ «Опелла Хелскеа Україна»
																														<br>вул. Жилянська, 48-50А,

																															<br>Бізнес Центр Прайм,

																																<br>01033, Київ, Україна

																																</p>
																															</section>
																															<div
                                                                  class="bottom-note-block">
<!--																																<p class="footnote">-->
<!--																																	<sup>1</sup> Формула оновлена за рахунок збільшення вмісту ліпази (на 3 000 МО) та α-амілази (на 2 500 МО) порівняно із Фестал<sup>®</sup> НЕО (РП МОЗ України № UA / 11255/01/02 (втратило чинність 06.01.2016 року). Наказ МОЗ України № 905 від 01.12.2014. Зміни до реєстраційного посвідчення затверджені Наказом МОЗ України № 616 від 03.09.14.). Інструкція для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000. Наказ МОЗ України №462 от 24.07.2015.-->
<!--																																	<br>-->
<!--																																		<sup>2</sup>  Панкреатин – фермент підшлункової залози, що сприяє травленню. Показання: Для поліпшення перетравлювання їжі особам з нормальною функцією шлунково-кишкового тракту в разі порушень режиму раціонального харчування (важкість у шлунку). Метеоризм.-->
<!--																																		<br>-->
<!--																																			<sup>3</sup> До складу препарату входять основні панкреатичні травні ферменти: ліпаза, α-амілаза, протеази (трипсин і хімотрипсин), які чинять ліполітичну, амілолітичну і протеолітичну дію.-->
<!--																																		</p>-->
																																		<p
                                                                        class="adversting-text">Реклама лікарського засобу. Перед застосуванням лікарського засобу необхідно проконсультуватися з лікарем та обов’язково ознайомитися з інструкцією для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000. Зберігати у недоступному для дітей місці.
																																		</p>
																																		<table
                                                                        class="adversting-text-table">
																																			<tbody>
																																				<tr class="top-line">
																																					<td>

																																					</td>
																																					<td>
<!--																																						<router-link to="/feedback">Зворотний зв'язок</router-link>.-->
																																					</td>
																																				</tr>
																																				<tr class="bottom-line">
																																					<td><router-link to="/official-notice">Офіційне повідомлення</router-link>.<br>Всі права захищені<sup>®</sup> 2021. Дата останнього перегляду 08.07.2022. Сайт призначений виключно для відвідувачів з України.
																																					</td>
																																					<td>

																																							<a
                                                                                  href="http://www.sanofi.ua" style="padding-right: 5px; display: inline-block;"
                                                                                  target="_blank">www.sanofi.ua</a>



																																							<a
                                                                                  href="http://www.festal.ua"
                                                                                  target="_blank">www.festal.ua</a>
																																						<br>MAT-UA-2200466-1.0-07/2022
																																					</td>
																																					<td>
																																						<router-link class="non-line"
                                                                                to="/instruction">Інструкція для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000, таблетки, вкриті оболонкою, кишковорозчинні.
																																												</router-link><br>РП №UA/14533/01/01. Наказ МОЗ України №1994 від 21.09.2021.

																																					</td>
																																				</tr>
																																			</tbody>
																																		</table>
																																	</div>
																																	<footer
                                                                      id="self-medication-block">
																																		<div class="word">
																																			<div class="letter s"></div>
																																			<div
                                                                          class="letter a"></div>
																																			<div class="letter m bigger-letter"></div>
																																			<div class="letter o"></div>
																																			<div
                                                                          class="letter l"></div>
																																			<div class="letter i smaller-letter"></div>
																																			<div class="letter k"></div>
																																			<div
                                                                          class="letter u"></div>
																																			<div class="letter v"></div>
																																			<div class="letter a"></div>
																																			<div
                                                                          class="letter n"></div>
																																			<div class="letter n"></div>
																																			<div class="letter ia"></div>
																																		</div>
																																		<div
                                                                        class="word">
																																			<div class="letter m bigger-letter"></div>
																																			<div class="letter o"></div>
																																			<div
                                                                          class="letter ge bigger-letter"></div>
																																			<div class="letter e"></div>
																																		</div>
																																		<div class="word">
																																			<div
                                                                          class="letter b"></div>
																																			<div class="letter u"></div>
																																			<div class="letter t"></div>
																																			<div
                                                                          class="letter ii"></div>
																																		</div>
																																		<div class="word">
																																			<div class="letter sh bigger-letter"></div>
																																			<div
                                                                          class="letter k"></div>
																																			<div class="letter i smaller-letter"></div>
																																			<div class="letter d"></div>
																																			<div
                                                                          class="letter l"></div>
																																			<div class="letter ii"></div>
																																			<div class="letter v"></div>
																																			<div
                                                                          class="letter ii"></div>
																																			<div class="letter m bigger-letter"></div>
																																		</div>
																																		<div class="word">
																																			<div
                                                                          class="letter d"></div>
																																			<div class="letter l"></div>
																																			<div class="letter ia"></div>
																																		</div>
																																		<div
                                                                        class="word">
																																			<div class="letter v"></div>
																																			<div class="letter a"></div>
																																			<div
                                                                          class="letter sh bigger-letter"></div>
																																			<div class="letter o"></div>
																																			<div class="letter g"></div>
																																			<div
                                                                          class="letter o"></div>
																																		</div>
																																		<div class="word">
																																			<div class="letter z"></div>
																																			<div
                                                                          class="letter d"></div>
																																			<div class="letter o"></div>
																																			<div class="letter r"></div>
																																			<div
                                                                          class="letter o"></div>
																																			<div class="letter v"></div>
																																			<div
                                                                          class="letter znak smaller-letter"></div>
																																			<div class="letter ia"></div>
																																		</div>
																																		<svg
                                                                        id="self-medication-svg"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        xml:space="preserve" x="0px" y="0px"
                                                                        viewBox="0 0 568.5 58.4">
																																			<g id="self-medication-svg-letters">
																																				<path class="self-medication-svg-letter" d="M8.8,17.2c0-5,1.3-7.9,4.8-7.9c3.5,0,4.8,2.9,4.8,7.9V18c0,0-0.1,0.2-0.2,0.2S18,18,18,18v-0.8
 c0-4.4-1.1-6.8-4.4-6.8c-3.3,0-4.3,2.4-4.3,6.8v23.2c0,4.4,1.1,6.8,4.3,6.8c3,0,4.6-2.5,4.6-2.5s0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6s-1.3,2.5-4.6,2.5c-3.5,0-4.8-2.9-4.8-7.9V17.2z"></path>
																																				<path class="self-medication-svg-letter"
                                                                              d="M24.1,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2L30,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9l-1.7,12c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L24.1,9.5z M21.1,34.8h6.6l-3.3-24.2L21.1,34.8z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M43.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																																				<path class="self-medication-svg-letter" d="M45.4,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M45.9,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M60.1,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L60.1,9.7z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M70,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6
 h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6H70z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M76.2,28.5L74,30.1V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L76.2,28.5z"></path>
																																				<path class="self-medication-svg-letter" d="M87,37c0,0-0.1-0.1-0.1-0.1L82,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L86.6,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L87,37z"></path>
																																				<path class="self-medication-svg-letter" d="M93.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M93.7,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M93.7,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																																				<path class="self-medication-svg-letter"
                                                                              d="M107.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L103,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L107.8,9.5z M104.8,34.8h6.6l-3.3-24.2L104.8,34.8z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M124.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M135.9,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M146.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L137.7,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M146,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																																				<path class="self-medication-svg-letter"
                                                                              d="M166.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2
 c-0.2,0-0.3-0.2-0.3-0.2l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 l5.7,30.5l5.8-30.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																																				<path class="self-medication-svg-letter" d="M168.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M168.7,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M179.1,48c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.5-20.2l-4.2-18.2c0,0,0.1-0.2,0.3-0.2
 c0.2,0,0.3,0.2,0.3,0.2l4.1,17.7l2.1,1.7V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2v19.3l2.1-1.7l4.1-17.7
 c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-4.2,18.2l4.5,20.2c0,0-0.1,0.2-0.3,0.2c-0.1,0-0.3-0.2-0.3-0.2L188,28.3l-2.1,1.7v18
 c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V30l-2.1-1.7L179.1,48z"></path>
																																				<path class="self-medication-svg-letter" d="M194.2,47h8.1c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.5c-2.4,0-4-0.3-4-0.3V9.7c0,0,1.6-0.3,4-0.3h4.4
 c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-8v17.1h7.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.2V47z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M209.5,26.8c0,0,1.7-0.2,4-0.2c3.2,0,4.5,3.1,4.5,8.4v4.8c0,5.3-1.3,8.4-4.5,8.4c-2.6,0-4.4-0.3-4.4-0.3V9.7
 c0,0,1.6-0.3,4-0.3h4.3c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.9V26.8z M209.5,47.1h4c3,0,4.1-2.5,4.1-7.3V35
 c0-4.8-1.1-7.3-4.1-7.3h-4V47.1z"></path>
																																				<path class="self-medication-svg-letter" d="M223.5,37c0,0-0.1-0.1-0.1-0.1l-4.9-27.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L223.1,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L223.5,37z"></path>
																																				<path class="self-medication-svg-letter" d="M234.1,10.5V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5h-4.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h9.8c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6H234.1z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M249.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L240.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																																				<path class="self-medication-svg-letter" d="M272.1,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C272.1,9.4,272.1,9.5,272.1,9.5"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M276.7,28.5l-2.2,1.7V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L276.7,28.5z"></path>
																																				<path class="self-medication-svg-letter" d="M284.4,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6
 H284.4z"></path>
																																				<path class="self-medication-svg-letter" d="M287.1,47.1c1.8,0,2.1-2.3,2.3-6.8L291,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M297.4,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H297.4z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M303.9,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L303.9,9.7z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M322.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L313.1,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46L322,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z
 "></path>
																																				<path class="self-medication-svg-letter" d="M324.4,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M324.8,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M324.8,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																																				<path class="self-medication-svg-letter"
                                                                              d="M344.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L335.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																																				<path class="self-medication-svg-letter" d="M359.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2V48z"></path>
																																				<path class="self-medication-svg-letter" d="M366.5,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2H367v8.2c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 V47.1z M376.9,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H376.9z"></path>
																																				<path class="self-medication-svg-letter"
                                                                              d="M383.3,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L383.3,9.7z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M400.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L392,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M400.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M405.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M405.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M405.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																																				<path class="self-medication-svg-letter"
                                                                              d="M419.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L415,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L419.8,9.5z M416.8,34.8h6.6l-3.3-24.2L416.8,34.8z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M441.6,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C441.6,9.4,441.6,9.5,441.6,9.5"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M443.6,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M444,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M455.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.7c0,0,1.6-0.3,4-0.3h3.7c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-7.2V48z"></path>
																																				<path class="self-medication-svg-letter" d="M463.9,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M464.3,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M489.5,17.8v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3c0,5.4-1.3,8.7-4.5,8.7c-3.2,0-4.5-2.5-4.5-2.5
 s-0.1-0.2-0.1-0.6c0-0.4,0.1-0.6,0.1-0.6s1.5,2.5,4.5,2.5s4.1-2.6,4.1-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-1.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h1.8c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.8-7.4c-3.2,0-4.2,2.4-4.2,6.8V18c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2v-0.8c0-5,1.3-7.9,4.7-7.9C488.3,9.3,489.5,12.4,489.5,17.8"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M490.3,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M500.7,47V10.5h-6.1L493,40.4c-0.2,3.1-0.4,5.4-1.1,6.6H500.7z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M504,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M504.4,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M520,9.3c3.2,0,4.5,2.9,4.5,7.9V22c0,5-1.3,7.9-4.5,7.9c-2.3,0-3.8-0.2-3.8-0.2V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5C515.8,9.5,517.5,9.3,520,9.3 M516.2,28.8h3.8c3,0,4.1-2.4,4.1-6.8v-4.8c0-4.4-1.1-6.8-4.1-6.8h-3.8
 V28.8z"></path>
																																				<path class="self-medication-svg-letter" d="M526.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M526.8,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																																				<path
                                                                            class="self-medication-svg-letter" d="M538.1,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M538.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M538.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																																				<path class="self-medication-svg-letter"
                                                                              d="M548.1,18.7c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2s1.1-2.7,1.1-9.2c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2C549.2,16.3,548.1,18.7,548.1,18.7"></path>
																																				<path class="self-medication-svg-letter" d="M559.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L551,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M559.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																																			</g>
																																		</svg>
																																	</footer>
																																</div>
																															</span>
      </div>
    </div>
  </div>
</template>

<script>
import actualPaddingSetter from '../modules/actualPaddingSetter';
import officialNoticeSvgAnimate from '../modules/svg_animation/officialNoticeSvgAnimate';

export default {
  name: "officialNotice",
  mounted() {
    let trigger25 = true;
    let trigger50 = true;
    let trigger75 = true;
    let trigger90 = true;

    window.addEventListener("scroll", () => {

      var h = document.documentElement,
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';

      var perce
      nt = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);
      if (percent >= 25 && percent < 50 && trigger25) {
        trigger25 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол сторінки 25%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 50 && percent < 75 && trigger50) {
        trigger50 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 50%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 75 && percent < 90 && trigger75) {
        trigger75 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 75%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 90 && trigger90) {
        trigger90 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 90%',
          'event_label': 'UA: ' + this.$route.path
        })
      }
    })
    window.scrollTo(0, 0);
    actualPaddingSetter();
    officialNoticeSvgAnimate();
  }
}
</script>

<style lang="less">

</style>
