/*** IMPORTS FROM imports-loader ***/
var define = false;

import scrollTo from "gsap/ScrollToPlugin"

export default function scrollToDestination(headerHeight) {
    var scrollDestination = sessionStorage.getItem('scrollDestination');

    setTimeout(function () {
        TweenMax.to(window, 1, {scrollTo: {y: scrollDestination, offsetY: headerHeight, autoKill: false}});
    }, 100);
}

