/*** IMPORTS FROM imports-loader ***/
var define = false;

import {TweenMax, TimelineMax} from "gsap";

export default function enzymesSvgAnimate() {

    // SVG №0
    TweenMax.staggerFromTo(
        '.ferment-svg-1-tree', 3,
        {rotation: -5},
        {rotation: 3, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Power1.easeInOut},
        1
    );
    TweenMax.staggerFromTo(
        '.ferment-1-vial', 3,
        {y: -4},
        {y: 5, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut},
        1
    );
    TweenMax.staggerFromTo(
        '.ferment-1-cloud', 3,
        {x: -5, y: 2, scale: .8},
        {x: 0, y: -2, scale: 1, repeat: -1, yoyo: true, force3D: false, ease: Power1.easeInOut},
        1
    );
    TweenMax.staggerFromTo(
        '.ferment-1-bulb', 3,
        {scale: .7, transformOrigin: "50% 50%"},
        {scale: 1, repeat: -1, yoyo: true, force3D: false, ease: Elastic.easeOut},
        1
    );


    // SVG №1
    TweenMax.staggerFromTo(
        '.ferment-svg-2-tree', 3,
        {rotation: -5},
        {rotation: 3, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Power1.easeInOut},
        1
    );
    TweenMax.staggerFromTo(
        '.ferment-svg-2-cloud', 3,
        {x: -5, y: 2, scale: .8},
        {x: 0, y: -2, scale: 1, repeat: -1, yoyo: true, force3D: false, ease: Power1.easeInOut},
        1
    );
    TweenMax.staggerFromTo(
        '.ferment-svg-2-tablet', 3,
        {scale: .8},
        {transformOrigin: "50% 50%", scale: 1, repeat: -1, yoyo: true, force3D: false, ease: Power1.easeInOut},
        1
    );
    TweenMax.staggerTo(
        '.ferment-svg-2-tablet-face', 3,
        {
            fill: 'rgb(100,141,255)',
            transformOrigin: "50% 50%",
            scale: 1,
            repeat: -1,
            yoyo: true,
            force3D: false,
            ease: Power1.easeInOut
        },
        1
    );
    TweenMax.to(
        '.ferment-svg-2-bottle-body', 3,
        {fill: 'rgb(100,141,255)', repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}
    );
    TweenMax.fromTo(
        '.ferment-svg-2-big-tablet-face', 3,
        {rotation: 0},
        {rotation: 40, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeIn}
    );
    TweenMax.fromTo(
        '.ferment-svg-2-big-tablet', 3,
        {x: 0},
        {x: 20, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeIn}
    );
}	
