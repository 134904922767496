/*** IMPORTS FROM imports-loader ***/
var define = false;

import {TweenMax, TimelineMax} from "gsap";

export default function officialNoticeSvgAnimate() { 

	TweenMax.staggerFromTo(
		'.feedback-svg-cloud', 3, 
		{y: 2, scale: .8}, 
		{y: -2, scale: 1,repeat: -1, yoyo: true, force3D: false, ease: Power1.easeInOut}, 
		1
	);

	TweenMax.staggerTo(
		'.official-svg-window', 5, 
		{fill: 'rgb(200,200,100)', repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeOut}
	);
	TweenMax.staggerFromTo(
		'.official-svg-tree', 3, 
		{rotation: -5}, 
		{rotation: 0, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}, 
		0.8
	);
}

