<template>
  <div class="digestion" data-view>
    <div id="content">
      <top-menu></top-menu>
      <message-alert></message-alert>
      <span>
			<div id="digestion-page-container" class="content-container" style="padding-top: 60px;">
				<div
            id="part_1" class="title-block">
					<h1>Процес травлення:
						<br>що, як, чому
						</h1>
					</div>
					<section class="main-column">
						<p>Їжа забезпечує людину широким спектром корисних речовин. Але щоб ці речовини наситили клітини її організму своєю внутрішньою енергією та поповнили всі життєвонеобхідні ресурси, їжа проходить через надскладний процес травлення<sup>1</sup>.
						</p>
            <p>Травлення — це чітка та злагоджена робота кислот, гормонів та травних ферментів. Кожен фермент відповідає за свою «ділянку» у системі травлення: одні розщеплюють білки, інші — жири, треті — перетворюють вуглеводи<sup>1</sup>.</p>
						<div
                class="inline-svg-float-block">
							<div class="svg-left-block svg-left-block-1">
								<svg id="digestion-svg-0" class="inline-svg-illustration"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                     viewBox="0 0 87.6 98.4">
									<path id="question-cloud-1" class="cloud" d="M37.1,55.5c0.2-2.4-1.4-4.6-3.8-5.1c-0.1,0-0.3,0-0.4-0.1c-0.3-2.4-2.1-4.5-4.6-4.9
 c-3.1-0.6-6.2,1.4-6.8,4.5c-0.1,0.5-0.1,1-0.1,1.4c-1.6,0.8-2.9,2.3-3.4,4.1H37.1z"
                        data-svg-origin="17.999996185302734 45.29372787475586"
                        transform="matrix(0.99633,0,0,0.99633,1.9377656304794657,-1.7609698190527707)"></path>
									<g
                      id="question-mountains">
										<path id="question-mountain-1" class="mountain-1" d="M34.6,87.7L24,72.9c-1.4-2-4.3-2-5.7,0L7.8,87.7c-1.6,2.3,0,5.5,2.8,5.5h21.1
 C34.5,93.2,36.2,90,34.6,87.7z"></path>
										<path id="question-mountain-2" class="mountain-2" d="M42.2,92.6l-6.8-9.5c-1.4-2-4.3-2-5.7,0l-6.8,9.5c-1.6,2.3,0,5.5,2.9,5.5h13.6
 C42.2,98.2,43.8,95,42.2,92.6z"></path>
										<path id="question-mountain-3" class="mountain-3" d="M33.4,93.6l-8.8-12.3c-1.2-1.6-3.6-1.6-4.7,0l-8.8,12.3c-1.4,1.9,0,4.6,2.4,4.6H31
 C33.4,98.2,34.7,95.5,33.4,93.6z"></path>
										<path id="question-mountain-4" class="mountain-2" d="M16.1,95L10,86.4c-0.8-1.1-2.5-1.1-3.3,0L0.6,95c-0.9,1.3,0,3.2,1.6,3.2h12.2
 C16.1,98.2,17,96.3,16.1,95z"></path>
									</g>
									<path id="question-mark" class="question-mark" d="M53.5,51.6L53.4,56c-0.1,2.4-0.5,2.9-2.8,2.9H33.3c-2.5,0-2.8-0.4-2.8-2.9V40.9c0-2,0.5-2.2,3-3
 C45.5,34.4,51,33.9,51,26.8c0-3.2-1.8-5.5-8.7-5.5c-8,0-15.5,5.6-19,5.6c-0.5,0-1-0.4-1.4-1.3L16.6,11c-0.1-0.5-0.3-0.9-0.3-1.3
 c0-1.1,0.9-1.6,2.4-2.6C25.4,2.3,41.2,0.5,45,0.5C62.7,0.5,75.2,11,75.2,27C75.2,38.1,68.2,46.6,53.5,51.6z M42.5,97.8
 c-8.3,0-15.2-6.8-15.2-15.1s7-15.1,15.2-15.1s15.1,6.8,15.1,15.1S50.8,97.8,42.5,97.8z"
                        data-svg-origin="45.74999809265137 49.150001525878906"
                        transform="matrix(0.89962,0,0,0.89962,4.592270726806198,4.933554593219284)"
                        style="transform-origin: 0px 0px 0px;"></path>
									<g id="question-trees">
										<path id="question-tree-1" class="digestion-svg-0-tree tree-1" d="M75.4,77.3c-0.1,0-0.3,0-0.4-0.1c-0.3-2.4-2.1-4.4-4.6-4.9c-3.1-0.6-6.1,1.4-6.8,4.5
 c-0.1,0.5-0.1,1-0.1,1.4c-1.8,0.9-3.1,2.6-3.5,4.7c-0.7,3.5,1.5,6.9,4.8,7.8c0.6,1.5,1.8,2.6,3.3,3.2v4.2h4.1v-4.2
 c1.8-0.7,3.2-2.2,3.6-4.3c0.2-1.1,0.1-2.1-0.2-3c1.7-0.4,3.1-1.8,3.5-3.7C79.7,80.3,78,77.8,75.4,77.3z"
                          data-svg-origin="69.54531288146973 98.0999984741211"
                          transform="matrix(0.99876,0.04978,-0.04978,0.99876,7.190866932955474,-4.282483962638889)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="question-tree-2" class="digestion-svg-0-tree tree-2" d="M87.2,88.3c0-0.1-0.1-0.2-0.1-0.3c0.2-0.9,0.2-1.9-0.2-2.8c-0.8-1.9-2.9-2.9-4.9-2.5
 c-0.9-1.2-2.6-1.7-4.1-1.1c-1.7,0.7-2.5,2.7-1.8,4.5c0,0,0,0.1,0,0.1c-1.1,0.9-1.6,2.4-1,3.8c0.3,0.6,0.7,1.1,1.2,1.4
 c0,0.6,0.1,1.3,0.4,1.9c0.5,1.2,1.5,2,2.6,2.4v2.4h2.4v-2.3c0.2-0.1,0.5-0.1,0.7-0.2c1.2-0.5,2-1.5,2.4-2.7c0.2,0,0.4-0.1,0.6-0.2
 C87.1,92,87.9,90,87.2,88.3z" data-svg-origin="81.14826583862305 98.10000610351562"
                        transform="matrix(0.99999,0.00096,-0.00096,0.99999,1.5309070907547904,-0.691647655258155)"
                        style="transform-origin: 0px 0px 0px;"></path>
										<path id="question-tree-3"
                          class="digestion-svg-0-tree tree-2" d="M71.6,86.1c0-2.4-2-4.4-4.4-4.4c0,0-0.1,0-0.1,0c-0.4-1.9-2.1-3.4-4.1-3.4
 c-2.3,0-4.2,1.9-4.2,4.2c0,0.2,0,0.3,0,0.5c-1.4,0.7-2.4,2.2-2.4,4c0,1.8,1,3.3,2.5,4c-0.1,0.3-0.2,0.6-0.2,1
 c0,1.4,1.2,2.6,2.6,2.6c0.3,0,0.6-0.1,0.9-0.2v3.7H65v-4.1c0.3,0.1,0.7,0.2,1,0.2c2.1,0,3.8-1.7,3.8-3.8c0-0.3,0-0.5-0.1-0.8
 C70.9,88.9,71.6,87.6,71.6,86.1z" data-svg-origin="63.99999809265137 98.09999084472656"
                          transform="matrix(0.99744,-0.07143,0.07143,0.99744,-5.147362428798923,4.084459370072384)"
                          style="transform-origin: 0px 0px 0px;"></path>
									</g>
									<path
                      id="question-cloud-2" class="cloud" d="M85,64.3c0.1-1.4-0.8-2.6-2.2-2.9c-0.1,0-0.1,0-0.2,0c-0.2-1.4-1.2-2.5-2.6-2.8
 c-1.8-0.4-3.5,0.8-3.9,2.6c-0.1,0.3-0.1,0.5-0.1,0.8c-0.9,0.5-1.6,1.3-1.9,2.3H85z"
                      data-svg-origin="74.10000610351562 58.52292251586914"
                      transform="matrix(0.92638,0,0,0.92638,4.878401689318492,3.7805748730965183)"></path>
									<path
                      id="question-cloud-3" class="cloud" d="M86.1,38.2c0.3-3.7-2.2-7.1-5.9-7.9c-0.2,0-0.4-0.1-0.6-0.1c-0.5-3.7-3.3-6.9-7.1-7.7
 c-4.8-1-9.6,2.2-10.5,7c-0.1,0.7-0.2,1.5-0.2,2.2c-2.5,1.3-4.5,3.5-5.3,6.4H86.1z"
                      data-svg-origin="56.5 22.31678009033203"
                      transform="matrix(0.82259,0,0,0.82259,5.814225177777665,5.5072431657221035)"></path>
									<g
                      id="question-hut">
										<rect id="question-frontwall" x="42.9" y="91" class="frontwall" width="25.7" height="6.6"></rect>
										<polygon
                        id="question-roof-side" class="sidewall"
                        points="71.3,90 60.2,90 50.2,78 61.3,78 	"></polygon>
										<polygon
                        id="question-roof-frontwall" class="frontwall" points="60.2,90 40.2,90 50.2,78 	"></polygon>
										<rect
                        id="question-sidewall" x="59.4" y="91" class="sidewall" width="9.2" height="6.6"></rect>
										<rect
                        id="question-roof-decor-1" x="39.3" y="89.8" class="hut-decor" width="33" height="1.3"></rect>
										<rect
                        id="question-roof-decor-2" x="39.3" y="97.1" class="hut-decor" width="33" height="1.3"></rect>
										<rect
                        id="question-roof-decor-3" x="49" y="77.3" class="hut-decor" width="13.4" height="1.3"></rect>
										<rect
                        id="question-window-1" x="48.7" y="92.5" class="hut-decor" width="3.7" height="4.6"></rect>
										<circle
                        id="question-window-2" class="digestion-svg-0-window hut-decor" cx="50.5" cy="86.2" r="1.1"
                        data-svg-origin="49.400001525878906 85.0999984741211" transform="matrix(1,0,0,1,0,0)"
                        style="fill: rgb(165, 176, 112);"></circle>
									</g>
								</svg>
							</div>
							<h2>Детальніше про процес травлення</h2>
						</div>
						<p
                class="dotted-path-connection dotted-path-connection-1">Як відбувається травлення у людини? По суті, організм підготовлюється до травлення ще до того, як ми почнемо їсти — спрацьовує рефлекс необхідності в їжі і починається вироблення шлункового соку.</p>
						<p
                class="dotted-path-connection dotted-path-connection-2">А в першу активну фазу процес травлення вступає з моменту пережовування їжі в роті. Слина, що виділяється, зволожує сухі шматочки їжі, розчиняє ті, що можуть розчинятися, та обволікає тверді. Так формується харчова грудка, яка потрапляє в стравохід, а потім — у порожнину шлунку<sup>1</sup>.</p>
						<div
                class="inline-svg-float-block">
							<div class="svg-left-block svg-left-block-2">
								<svg id="digestion-svg-1" class="inline-svg-illustration"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                     viewBox="0 0 88.2 129.1">
									<path id="digestion-svg-1-ground-shadow" class="st0" d="M10.2,128.1l1.7-1.7c1.4-1.4,2-3.4,1.4-5.3c-0.3-1.1-0.3-2.4,0-3.6c0.3-1.3,1-2.4,2-3.3l0,0
 c1.3-1.3,3.1-2.1,5-2.1h45.9c1.9,0,3.6-0.7,5-2.1L86.1,95c1.3-1.3,2.1-3.1,2.1-5V61.7c0-1.9-0.7-3.6-2.1-5l-5.9-5.9
 c-1.3-1.3-3.1-2.1-5-2.1h-26c-1.9,0-3.6-0.7-5-2.1l-0.2-0.2c-1.3-1.3-2.1-3.1-2.1-5v-9.7c0-6.5-5.8-6.2-8.5-6.2c0,0-1.2,2.2-1.2,4.9
 v15c0,1.9,0.7,3.6,2.1,5l2.8,2.8c1.3,1.3,2.1,3.1,2.1,5v17.9c0,1.9-0.7,3.6-2.1,5l-9.3,9.3c-1.3,1.3-3.1,2.1-5,2.1h-2.8
 c-1.9,0-3.6,0.7-5,2.1l-1.9,1.9c-1.3,1.3-2.1,3.1-2.1,5v0.4c0,1.9-0.7,3.6-2.1,5l-3.6,3.6c-1.3,1.3-2.1,3.1-2.1,5L0,120.3
 c0,3.3,4.1,6.6,5.7,7.9h0C6.9,129.4,8.9,129.4,10.2,128.1z"></path>
									<path id="digestion-svg-1-ground" class="st1" d="M6.5,124.6l1.7-1.7c1.4-1.4,2-3.4,1.4-5.3c-0.3-1.1-0.3-2.4,0-3.6c0.3-1.3,1-2.4,2-3.3l0,0
 c1.3-1.3,3.1-2.1,5-2.1h45.9c1.9,0,3.6-0.7,5-2.1l15.1-15.1c1.3-1.3,2.1-3.1,2.1-5V58.2c0-1.9-0.7-3.6-2.1-5l-5.9-5.9
 c-1.3-1.3-3.1-2.1-5-2.1h-26c-1.9,0-3.6-0.7-5-2.1l-0.2-0.2c-1.3-1.3-2.1-3.1-2.1-5v-7.5c0-2.7-2.2-4.9-4.9-4.9h0
 c-2.7,0-4.9,2.2-4.9,4.9v11.5c0,1.9,0.7,3.6,2.1,5l2.8,2.8c1.3,1.3,2.1,3.1,2.1,5v17.9c0,1.9-0.7,3.6-2.1,5l-9.3,9.3
 c-1.3,1.3-3.1,2.1-5,2.1h-2.8c-1.9,0-3.6,0.7-5,2.1L9.7,93c-1.3,1.3-2.1,3.1-2.1,5v0.4c0,1.9-0.7,3.6-2.1,5L2.1,107
 c-1.3,1.3-2.1,3.1-2.1,5v7.8c0,1.9,0.7,3.6,2.1,5l0,0C3.3,125.9,5.3,125.9,6.5,124.6z"></path>
									<g
                      id="digestion-svg-1-mountains">
										<path id="digestion-svg-1-mountain-1" class="st2" d="M69.4,51.9l-2.9-4.1c-0.6-0.8-1.8-0.8-2.4,0l-2.9,4.1c-0.7,1,0,2.4,1.2,2.4h5.8
 C69.4,54.3,70.1,52.9,69.4,51.9z"></path>
										<path id="digestion-svg-1-mountain-2" class="st3" d="M60.4,48.2l-6.1-8.6c-0.8-1.1-2.5-1.1-3.3,0l-6.1,8.6c-0.9,1.3,0,3.2,1.6,3.2h12.2
 C60.4,51.4,61.4,49.6,60.4,48.2z"></path>
										<path id="digestion-svg-1-mountain-3" class="st4" d="M64.9,51.1l-3.9-5.5c-0.8-1.1-2.5-1.1-3.3,0l-3.9,5.5c-1,1.3,0,3.2,1.7,3.2h7.9
 C64.9,54.3,65.8,52.4,64.9,51.1z"></path>
										<path id="digestion-svg-1-mountain-4" class="st5" d="M59.8,51.6l-5.1-7.1c-0.7-0.9-2.1-0.9-2.7,0l-5.1,7.1c-0.8,1.1,0,2.6,1.4,2.6h10.2
 C59.8,54.3,60.6,52.7,59.8,51.6z"></path>
										<path id="digestion-svg-1-mountain-5" class="st6" d="M49.7,52.4l-3.5-5c-0.5-0.7-1.4-0.7-1.9,0l-3.5,5c-0.5,0.8,0,1.8,1,1.8h7.1
 C49.7,54.3,50.3,53.2,49.7,52.4z"></path>
									</g>
									<g id="digestion-svg-1-bottle"
                     data-svg-origin="61.44999885559082 75.69999885559082"
                     transform="matrix(1,0,0,0.99981,0,0.014288617405469495)"
                     style="transform-origin: 0px 0px 0px;">
										<polygon id="digestion-svg-1-bottle-body" class="st7"
                             points="40.3,75.4 57.1,47.4 57.1,29.2 65.7,29.2 65.7,47.4 82.5,75.4 		"></polygon>
										<rect
                        id="digestion-svg-1-bottle-bottom" x="39.7" y="70.7" class="st8" width="43.5" height="5"></rect>
										<rect
                        id="digestion-svg-1-bottle-top" x="54.6" y="27.1" class="st8" width="13.8"
                        height="5"></rect>
									</g>
									<g
                      id="digestion-svg-1-bubles">
										<circle id="digestion-svg-1-buble-1" class="digestion-svg-1-buble st9" cx="66" cy="18.3" r="4.6"
                            data-svg-origin="66.00000143051147 18.29999876022339"
                            transform="matrix(0.98001,0,0,0.98001,1.118983499468673,0.6653955034493052)"
                            style="transform-origin: 0px 0px 0px;"></circle>
										<circle
                        id="digestion-svg-1-buble-2" class="digestion-svg-1-buble st9" cx="58" cy="7.2" r="3.4"
                        data-svg-origin="57.999998569488525 7.199999809265137"
                        transform="matrix(0.86567,0,0,0.86567,6.4476273850955845,2.9820277288574912)"
                        style="transform-origin: 0px 0px 0px;"></circle>
										<circle id="digestion-svg-1-buble-3"
                            class="digestion-svg-1-buble st9"
                            cx="67.7"
                            cy="2.1" r="1.8"
                            data-svg-origin="67.69999384880066 2.0999999046325684"
                            transform="matrix(0.88714,0,0,0.88714,6.5119930847491165,1.929897625638872)"
                            style="transform-origin: 0px 0px 0px;"></circle>
									</g>
									<g
                      id="digestion-svg-1-mountains-2">
										<path id="digestion-svg-1-mountain-6" class="st6" d="M74.1,81.6l-8-11.2c-1-1.5-3.2-1.5-4.3,0l-8,11.2c-1.2,1.7,0,4.1,2.1,4.1H72
 C74.1,85.7,75.4,83.3,74.1,81.6z"></path>
										<path id="digestion-svg-1-mountain-7" class="st5" d="M58.4,82.8L52.8,75c-0.7-1-2.3-1-3,0l-5.6,7.8c-0.9,1.2,0,2.9,1.5,2.9h11.1
 C58.4,85.7,59.3,84,58.4,82.8z"></path>
									</g>
									<g id="digestion-svg-1-vials">
										<g id="digestion-svg-1-vial-1" class="digestion-svg-1-vial"
                       data-svg-origin="31.999999046325684 73.6500015258789"
                       transform="matrix(1,0,0,1,0,4.898572404319506)" style="transform-origin: 0px 0px 0px;">
											<path id="digestion-svg-1-vial-body-1" class="st9" d="M35.8,58.1h-7.5v28.5h0c0,0,0,0,0,0c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0
 h0V58.1z"></path>
											<rect id="digestion-svg-1-vial-top-1" x="26.9" y="56.9" class="st5" width="10.2"
                            height="3.1"></rect>
										</g>
										<g
                        id="digestion-svg-1-vial-2" class="digestion-svg-1-vial"
                        data-svg-origin="43.69999885559082 73.6500015258789"
                        transform="matrix(1,0,0,1,0,3.0327041568665667)" style="transform-origin: 0px 0px 0px;">
											<path id="digestion-svg-1-vial-body-2" class="st9" d="M47.4,58.1h-7.5v28.5h0c0,0,0,0,0,0c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0
 h0V58.1z"></path>
											<rect id="digestion-svg-1-vial-top-2" x="38.6" y="56.9" class="st5" width="10.2"
                            height="3.1"></rect>
										</g>
										<g
                        id="digestion-svg-1-vial-3" class="digestion-svg-1-vial"
                        data-svg-origin="55.30000114440918 73.6500015258789"
                        transform="matrix(1,0,0,1,0,-0.7175714900257701)" style="transform-origin: 0px 0px 0px;">
											<path id="digestion-svg-1-vial-body-3" class="st9" d="M59,58.1h-7.5v28.5h0c0,0,0,0,0,0c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0h0
 V58.1z"></path>
											<rect id="digestion-svg-1-vial-top-3" x="50.2" y="56.9" class="st5" width="10.2"
                            height="3.1"></rect>
										</g>
										<path id="digestion-svg-1-vial-holder" class="st10"
                          d="M22.9,78.7V94h41.5V78.7H22.9z M62.3,92H24.9V80.7h37.4V92z"></path>
									</g>
									<g
                      id="digestion-svg-1-trees">
										<path id="digestion-svg-1-tree-1" class="digestion-svg-1-tree st11" d="M81.9,46.8c0-0.1-0.1-0.1-0.1-0.2c0.2-0.7,0.1-1.4-0.2-2.1C81,43,79.5,42.2,78,42.6
 c-0.7-0.9-2-1.3-3.1-0.8c-1.3,0.6-1.9,2.1-1.3,3.4c0,0,0,0,0,0.1c-0.9,0.7-1.2,1.8-0.7,2.9c0.2,0.5,0.5,0.8,0.9,1.1
 c0,0.5,0.1,1,0.3,1.4c0.4,0.9,1.1,1.5,2,1.8v1.8h1.8v-1.8c0.2,0,0.4-0.1,0.5-0.2c0.9-0.4,1.5-1.1,1.8-2c0.1,0,0.3-0.1,0.4-0.1
 C81.9,49.6,82.5,48.1,81.9,46.8z" data-svg-origin="77.39631652832031 54.29999923706055"
                          transform="matrix(0.98531,0.17074,-0.17074,0.98531,11.498093802477765,-12.87873775893132)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="digestion-svg-1-tree-2" class="digestion-svg-1-tree st6" d="M85.4,52.9c0-0.1-0.1-0.1-0.1-0.2c0.2-0.6,0.1-1.2-0.1-1.8c-0.5-1.3-1.9-1.9-3.2-1.6
 c-0.6-0.8-1.7-1.1-2.6-0.7c-1.1,0.5-1.6,1.8-1.1,2.9c0,0,0,0,0,0.1c-0.7,0.6-1,1.6-0.6,2.5c0.2,0.4,0.5,0.7,0.8,0.9
 c0,0.4,0.1,0.8,0.2,1.2c0.3,0.8,1,1.3,1.7,1.5v1.6h1.6v-1.5c0.2,0,0.3-0.1,0.5-0.2c0.8-0.3,1.3-1,1.6-1.7c0.1,0,0.2-0.1,0.4-0.1
 C85.3,55.3,85.9,54,85.4,52.9z" data-svg-origin="81.55184936523438 59.30000305175781"
                        transform="matrix(0.98837,0.15202,-0.15202,0.98837,10.895628326362202,-12.10151575570093)"
                        style="transform-origin: 0px 0px 0px;"></path>
										<path id="digestion-svg-1-tree-3"
                          class="digestion-svg-1-tree st11" d="M19.5,100.1c0-0.1-0.1-0.1-0.1-0.2c0.2-0.6,0.1-1.2-0.1-1.8c-0.5-1.3-1.9-1.9-3.2-1.6
 c-0.6-0.8-1.7-1.1-2.6-0.7c-1.1,0.5-1.6,1.8-1.1,2.9c0,0,0,0,0,0.1c-0.7,0.6-1,1.6-0.6,2.5c0.2,0.4,0.5,0.7,0.8,0.9
 c0,0.4,0.1,0.8,0.2,1.2c0.3,0.8,1,1.3,1.7,1.5v1.6H16V105c0.2,0,0.3-0.1,0.5-0.2c0.8-0.3,1.3-1,1.6-1.7c0.1,0,0.2-0.1,0.4-0.1
 C19.5,102.5,20,101.2,19.5,100.1z" data-svg-origin="15.658494472503662 106.5"
                          transform="matrix(0.99312,0.11703,-0.11703,0.99312,13.504629482384278,-1.4940311050319082)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="digestion-svg-1-tree-4" class="digestion-svg-1-tree st6" d="M12.7,93.2c-0.1,0-0.2,0-0.3,0c-0.2-1.8-1.6-3.3-3.5-3.7c-2.4-0.5-4.6,1.1-5.1,3.4
 c-0.1,0.4-0.1,0.7-0.1,1.1c-1.3,0.7-2.4,1.9-2.7,3.5c-0.5,2.6,1.1,5.2,3.7,5.9c0.4,1.1,1.3,2,2.5,2.4v3.2h3.1v-3.2
 c1.4-0.5,2.4-1.7,2.7-3.2c0.2-0.8,0.1-1.6-0.2-2.3c1.3-0.3,2.4-1.4,2.7-2.8C15.9,95.5,14.7,93.6,12.7,93.2z"
                        data-svg-origin="8.242702662944794 109"
                        transform="matrix(0.99768,0.068,-0.068,0.99768,9.110665844947345,-1.022001949730896)"
                        style="transform-origin: 0px 0px 0px;"></path>
										<path id="digestion-svg-1-tree-5"
                          class="digestion-svg-1-tree st5" d="M44.7,91.1c0-1.6-1.3-2.8-2.8-2.8c0,0,0,0-0.1,0c-0.3-1.2-1.4-2.2-2.7-2.2
 c-1.5,0-2.7,1.2-2.7,2.7c0,0.1,0,0.2,0,0.3c-0.9,0.5-1.6,1.5-1.6,2.6c0,1.2,0.7,2.1,1.7,2.6c-0.1,0.2-0.1,0.4-0.1,0.6
 c0,0.9,0.8,1.7,1.7,1.7c0.2,0,0.4,0,0.6-0.1v2.4h1.8v-2.6c0.2,0.1,0.4,0.1,0.7,0.1c1.3,0,2.4-1.1,2.4-2.4c0-0.2,0-0.3-0.1-0.5
 C44.2,92.9,44.7,92.1,44.7,91.1z" data-svg-origin="39.75000190734863 98.89999389648438"
                          transform="matrix(0.99996,0.00821,-0.00821,0.99996,1.9105065336529607,-0.803385749096126)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="digestion-svg-1-tree-6" class="digestion-svg-1-tree st11" d="M59.8,93.3c0-0.1-0.1-0.1-0.1-0.2c0.2-0.7,0.1-1.4-0.2-2.1c-0.6-1.5-2.2-2.2-3.7-1.9
 c-0.7-0.9-2-1.3-3.1-0.8c-1.3,0.6-1.9,2.1-1.3,3.4c0,0,0,0,0,0.1c-0.9,0.7-1.2,1.8-0.7,2.9c0.2,0.5,0.5,0.8,0.9,1.1
 c0,0.5,0.1,1,0.3,1.4c0.4,0.9,1.1,1.5,2,1.8v1.8h1.8V99c0.2,0,0.4-0.1,0.5-0.2c0.9-0.4,1.5-1.1,1.8-2c0.1,0,0.3-0.1,0.4-0.1
 C59.7,96.1,60.3,94.6,59.8,93.3z" data-svg-origin="55.21730041503906 100.80001068115234"
                        transform="matrix(0.99831,-0.05809,0.05809,0.99831,-4.6726487279046545,2.915065412785209)"
                        style="transform-origin: 0px 0px 0px;"></path>
									</g>
								</svg>
							</div>
							<p>У шлунку харчова грудка обробляється шлунковим соком (сукупність соляної кислоти та травних ферментів). Активований соляною кислотою пепсин (основний фермент шлункового соку) перетравлює білки<sup>1</sup>.
							</p>
						</div>
						<p
                class="dotted-path-connection dotted-path-connection-3">Важливо відзначити, що роботу ферментів забезпечує певне хімічне середовище: для пепсину необхідне тільки кисле pH, а для ферментів підшлункової залози та кишечника — лужне. Тому, коли харчова грудка потрапляє в дванадцятипалу кишку, в дію вступає жовч<sup>1</sup>. Вона нейтралізує подальшу дію пепсину і створює необхідне слаболужне середовище. Крім активації вироблення кишкових гормонів, полегшення розщеплення жирів і стимуляції скорочень м’язів кишечника, жовч підсилює виділення секрету підшлункової залози. Також жовч сприяє активації травних ферментів<sup>1</sup>.
						</p>
						<div
                class="inline-svg-float-block">
							<div class="svg-right-block svg-right-block-1">
								<svg id="digestion-svg-2" class="inline-svg-illustration"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                     viewBox="0 0 102.7 81.4">
									<path id="digestion-svg-2-ground-shadow" class="st0" d="M90.2,81.4H3.3c-1.5,0-3.2-0.6-3.3-4.9l0.5-3.7c0-1.5,1.2-2.7,2.7-2.7h83
 c0.2,0,0.4-0.1,0.6-0.2l0.2-0.2c0.3-0.3,0.3-0.6,0-1v0c-0.2-0.2-0.4-0.2-0.6-0.2H14.1c-0.7,0-1.4-0.3-1.9-0.8l-9.6-9.6
 c-0.5-0.5-1.3-1.2-1.3-2c0-0.3,0-1.2,0-1.8c0-0.4,1,0.6,1.3,0.3l9.4-9.4c0.5-0.5,1.2-0.8,1.9-0.8h72.6c0.2,0,0.3-0.1,0.5-0.2
 l0.1-0.1c0.3-0.3,0.3-0.7,0-0.9l0,0c-0.1-0.1-0.3-0.2-0.5-0.2H13.8c-0.7,0-1.4-0.3-1.9-0.8l-9.2-9.2c-1.5-1.5-1.3-1.9-1.3-4.3
 c0-0.4,1.1,0.7,1.3,0.4l9.4-9.4c0.5-0.5,1.2-0.8,1.9-0.8h69.9c0.7,0,1.4-0.3,1.9-0.8v0c1.1-1.1,1.1-2.8,0-3.9l-3.3-3.3
 c-1.1-1.1-1.1-2.8,0-3.9l4.1-4.1c1.1-1.1,2.8-1.1,3.9,0l10.6,9c2.4,2.6,1.7,5,0.6,6.1L90.5,29.3c-0.5,0.5-1.2,0.8-1.9,0.8H17.8
 c-0.2,0-0.3,0.1-0.5,0.2l-0.2,0.2c-0.3,0.3-0.3,0.7,0,0.9l0,0c0.1,0.1,0.3,0.2,0.5,0.2h72.8c0.7,0,1.4,0.3,1.9,0.8l8.7,7.1
 c2.2,2.1,1.6,4.9,0.5,6l-9.3,9.3c-0.5,0.5-1.2,0.8-1.9,0.8H17.9c-0.2,0-0.4,0.1-0.6,0.3l0,0c-0.3,0.3-0.3,0.6,0,0.9l0.1,0.1
 c0.2,0.2,0.4,0.3,0.6,0.3h72.4c0.7,0,1.4,0.3,1.9,0.8l9,7.5c1.7,1.9,1.4,4.5,0.3,5.6l-9.6,9.6C91.6,81.1,90.9,81.4,90.2,81.4z"></path>
									<path
                      id="digestion-svg-2-ground" class="st1" d="M89.7,79.3H2.7c-1.5,0-2.7-1.2-2.7-2.7v-5.8C0,69.2,1.2,68,2.7,68h83c0.2,0,0.4-0.1,0.6-0.2
 l0.2-0.2c0.3-0.3,0.3-0.6,0-1l0,0c-0.2-0.2-0.4-0.2-0.6-0.2H13.6c-0.7,0-1.4-0.3-1.9-0.8l-9.6-9.6c-1.1-1.1-1.1-2.5,0-3.6l9.4-9.4
 c0.5-0.5,1.2-0.8,1.9-0.8H86c0.2,0,0.3-0.1,0.5-0.2l0.1-0.1c0.3-0.3,0.3-0.7,0-0.9l0,0c-0.1-0.1-0.3-0.2-0.5-0.2H13.3
 c-0.7,0-1.4-0.3-1.9-0.8l-9.2-9.2C1,29.7,1,28,2.1,26.9l9.4-9.4c0.5-0.5,1.2-0.8,1.9-0.8h69.9c0.7,0,1.4-0.3,1.9-0.8l0,0
 c1.1-1.1,1.1-2.8,0-3.9L82,8.8c-1.1-1.1-1.1-2.8,0-3.9l4.1-4.1c1.1-1.1,2.8-1.1,3.9,0l11.3,11.3c1.1,1.1,1.1,2.8,0,3.9L89.9,27.2
 C89.4,27.7,88.7,28,88,28H17.2c-0.2,0-0.3,0.1-0.5,0.2l-0.2,0.2c-0.3,0.3-0.3,0.7,0,0.9l0,0c0.1,0.1,0.3,0.2,0.5,0.2h72.8
 c0.7,0,1.4,0.3,1.9,0.8l9.2,9.2c1.1,1.1,1.1,2.8,0,3.9l-9.3,9.3c-0.5,0.5-1.2,0.8-1.9,0.8H17.3c-0.2,0-0.4,0.1-0.6,0.3h0
 c-0.3,0.3-0.3,0.6,0,0.9l0.1,0.1c0.2,0.2,0.4,0.3,0.6,0.3h72.4c0.7,0,1.4,0.3,1.9,0.8l9.4,9.4c1.1,1.1,1.1,2.6,0,3.7l-9.6,9.6
 C91.1,79,90.4,79.3,89.7,79.3z"></path>
									<path id="digestion-svg-2-road" class="st2" d="M84.8,75.8H0v-5h84.8c1,0,1.9-0.4,2.6-1.1l0.4-0.4c1.4-1.4,1.4-3.7,0-5.1l-0.4-0.4
 c-0.7-0.7-1.6-1.1-2.6-1.1H17.9c-2.3,0-4.5-0.9-6.1-2.5l-0.1-0.1c-1.6-1.6-2.5-3.8-2.5-6.1c0-2.3,0.9-4.5,2.5-6.1l0.1-0.1
 c1.6-1.6,3.8-2.5,6.1-2.5h66.9c1,0,1.9-0.4,2.6-1.1l0.1-0.1c0.7-0.7,1.1-1.6,1.1-2.6c0-1-0.4-1.9-1.1-2.6l-0.4-0.4
 c-0.7-0.7-1.6-1.1-2.6-1.1H17.9c-2.3,0-4.5-0.9-6.1-2.5c-1.6-1.6-2.5-3.8-2.5-6.1c0-2.3,0.9-4.5,2.5-6.1c1.6-1.6,3.8-2.5,6.1-2.5
 h65.4c1,0,1.9-0.4,2.6-1.1l3.1-3.1c0.7-0.7,1.1-1.6,1.1-2.6c0-1-0.4-1.9-1.1-2.6l-6.5-6.5L85.9,1l6.5,6.5c1.6,1.6,2.5,3.8,2.5,6.1
 c0,2.3-0.9,4.5-2.5,6.1l-3.1,3.1c-1.6,1.6-3.8,2.5-6.1,2.5H17.9c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6c0,1,0.4,1.9,1.1,2.6
 c0.7,0.7,1.6,1.1,2.6,1.1h66.6c2.3,0,4.5,0.9,6.1,2.5l0.4,0.4c1.6,1.6,2.5,3.8,2.5,6.1c0,2.3-0.9,4.5-2.5,6.1l-0.1,0.1
 c-1.6,1.6-3.8,2.5-6.1,2.5H17.9c-1,0-1.9,0.4-2.6,1.1l-0.1,0.1c-0.7,0.7-1.1,1.6-1.1,2.6c0,1,0.4,1.9,1.1,2.6l0.1,0.1
 c0.7,0.7,1.6,1.1,2.6,1.1h66.9c2.3,0,4.5,0.9,6.1,2.5l0.4,0.4c3.4,3.4,3.4,8.8,0,12.2l-0.4,0.4C89.3,74.9,87.1,75.8,84.8,75.8z"></path>
									<g
                      id="digestion-svg-2-area-1">
										<path id="digestion-svg-2-smoke-1" class="digestion-svg-2-smoke-1 st3" d="M53.2,19.4c0.1-1.7-1-3.3-2.7-3.6c-0.1,0-0.2,0-0.3,0C50,14,48.7,12.6,47,12.2
 c-2.2-0.4-4.4,1-4.8,3.2c-0.1,0.3-0.1,0.7-0.1,1c-2.9,0-5.9,1.6-6.2,2.9H53.2z"
                          data-svg-origin="35.900001525878906 19.399999618530273"
                          transform="matrix(0.98873,0,0,0.98873,0.4045834266328825,0.2186328130566686)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="digestion-svg-2-smoke-2" class="digestion-svg-2-smoke-1 st3" d="M62.9,15.7c0.1-1.7-1-3.3-2.7-3.6c-0.1,0-0.2,0-0.3,0c-0.2-1.7-1.5-3.2-3.3-3.5
 c-2.2-0.4-4.4,1-4.8,3.2c-0.1,0.3-0.1,0.7-0.1,1c-2.9,0-5.9,1.6-6.2,2.9H62.9z"
                        data-svg-origin="45.500003814697266 15.700000762939453"
                        transform="matrix(0.84235,0,0,0.84235,7.172695633800963,2.474974010587495)"
                        style="transform-origin: 0px 0px 0px;"></path>
										<path id="digestion-svg-2-smoke-3"
                          class="digestion-svg-2-smoke-1 st3" d="M80.5,11.9c0.2-2.5-1.5-4.8-4-5.4c-0.1,0-0.3,0-0.4-0.1c-0.3-2.5-2.2-4.7-4.8-5.2
 C67.9,0.6,64.7,2.7,64,6c-0.1,0.5-0.1,1-0.1,1.5c-4.3,0-8.7,2.4-9.2,4.3H80.5z"
                          data-svg-origin="54.70000076293945 11.899999976158142"
                          transform="matrix(0.55033,0,0,0.55033,24.596674570647398,5.351013212463886)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<g
                        id="digestion-svg-2-building-1">
											<g id="digestion-svg-2-pipes-1">
												<polygon id="digestion-svg-2-pipe-body-1" class="st4"
                                 points="41.3,38.5 34.7,38.5 35.8,21.1 40.2,21.1 			"></polygon>
												<polygon
                            id="digestion-svg-2-pipe-body-2" class="st4"
                            points="60,31 53.4,31 54.5,13.6 58.9,13.6 			"></polygon>
												<polygon
                            id="digestion-svg-2-pipe-body-3" class="st4"
                            points="50.8,34.7 44.2,34.7 45.3,17.4 49.7,17.4 			"></polygon>
												<rect
                            id="digestion-svg-2-pipe-top-1" x="35" y="20.5" class="st5" width="6" height="1.2"></rect>
												<rect
                            id="digestion-svg-2-pipe-top-2" x="44.5" y="16.7" class="st5" width="6" height="1.2"></rect>
												<rect
                            id="digestion-svg-2-pipe-top-3" x="53.7" y="13" class="st5" width="6" height="1.2"></rect>
											</g>
											<rect
                          id="digestion-svg-2-wall-1" x="27.3" y="30.9" class="st4" width="24.5" height="13.1"></rect>
											<rect
                          id="digestion-svg-2-wall-2" x="51.9" y="30.9" class="st6" width="16.7" height="13.1"></rect>
											<rect
                          id="digestion-svg-2-roof-1" x="26.3" y="28.4" class="st5" width="43.3" height="3.5"></rect>
											<rect
                          id="digestion-svg-2-door-1" x="34.3" y="33.1" class="st5" width="4.7" height="10.8"></rect>
											<rect
                          id="digestion-svg-2-door-2" x="40.2" y="33.1" class="st5" width="4.7" height="10.8"></rect>
										</g>
									</g>
									<g
                      id="digestion-svg-2-area-2">
										<g id="digestion-svg-2-building-2">
											<g id="digestion-svg-2-pipes-2">
												<polygon id="digestion-svg-2-pipe-body-4" class="st4"
                                 points="22.4,63.2 17.2,63.2 18,49.5 21.5,49.5 			"></polygon>
												<rect
                            id="digestion-svg-2-pipe-top-4" x="17.4" y="49" class="st5" width="4.7" height="1"></rect>
											</g>
											<rect
                          id="digestion-svg-2-wall-3" x="14.8" y="63.1" class="st4" width="14.4" height="6"></rect>
											<rect
                          id="digestion-svg-2-wall-4" x="29.2" y="63.1" class="st6" width="7" height="6"></rect>
											<rect
                          id="digestion-svg-2-roof-2" x="13.6" y="61.4" class="st5" width="24.1" height="2.7"></rect>
											<rect
                          id="digestion-svg-2-door-3" x="18.3" y="64.9" class="st5" width="3.7" height="4.3"></rect>
											<rect
                          id="digestion-svg-2-door-4" x="23" y="64.9" class="st5" width="3.7" height="4.3"></rect>
										</g>
										<path
                        id="digestion-svg-2-smoke-4" class="st3 digestion-svg-2-smoke-2" d="M38.5,48.1c0.2-2-1.2-3.8-3.2-4.2c-0.1,0-0.2,0-0.3,0c-0.2-2-1.7-3.7-3.8-4.1
 c-2.6-0.5-5.1,1.2-5.6,3.8c-0.1,0.4-0.1,0.8-0.1,1.2c-3.4,0-6.9,1.9-7.3,3.4H38.5z"
                        data-svg-origin="18.200000762939453 48.20000076293945"
                        transform="matrix(0.98873,0,0,0.98873,0.2051091465297894,0.5432011321314363)"
                        style="transform-origin: 0px 0px 0px;"></path>
									</g>
									<g id="digestion-svg-2-trees">
										<path id="digestion-svg-tree-1" class="digestion-svg-2-tree st7" d="M42.7,62.9c-0.1,0-0.2,0-0.3,0c-0.2-1.6-1.4-3-3.1-3.3c-2.1-0.4-4.1,0.9-4.6,3
 c-0.1,0.3-0.1,0.6-0.1,1c-1.2,0.6-2.1,1.7-2.4,3.1c-0.5,2.3,1,4.6,3.3,5.2c0.4,1,1.2,1.8,2.2,2.2v2.9h2.7v-2.9
 c1.2-0.5,2.2-1.5,2.4-2.9c0.1-0.7,0.1-1.4-0.1-2.1c1.2-0.3,2.1-1.2,2.4-2.5C45.5,64.9,44.4,63.2,42.7,62.9z"
                          data-svg-origin="38.643022537231445 77"
                          transform="matrix(0.98531,0.17074,-0.17074,0.98531,15.212344826019732,-6.103762199008358)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="digestion-svg-tree-2" class="digestion-svg-2-tree st8" d="M47.5,66.4c-0.1,0-0.1,0-0.2,0c-0.2-1.2-1.1-2.2-2.3-2.5c-1.6-0.3-3.1,0.7-3.4,2.3
 c0,0.2-0.1,0.5-0.1,0.7c-0.9,0.5-1.6,1.3-1.8,2.4c-0.4,1.8,0.7,3.5,2.5,3.9c0.3,0.7,0.9,1.3,1.7,1.6v2.2h2.1v-2.2
 c0.9-0.3,1.6-1.1,1.8-2.2c0.1-0.5,0.1-1.1-0.1-1.5c0.9-0.2,1.6-0.9,1.8-1.9C49.6,67.9,48.8,66.6,47.5,66.4z"
                        data-svg-origin="44.56319999694824 77"
                        transform="matrix(0.99113,0.13286,-0.13286,0.99113,11.753421416411728,-5.7194212170324)"
                        style="transform-origin: 0px 0px 0px;"></path>
										<path id="digestion-svg-tree-3"
                          class="digestion-svg-2-tree st7" d="M80.2,42.3c-0.1,0-0.2,0-0.3,0c-0.2-1.6-1.4-3-3.1-3.3c-2.1-0.4-4.1,0.9-4.6,3
 c-0.1,0.3-0.1,0.6-0.1,1c-1.2,0.6-2.1,1.7-2.4,3.1c-0.5,2.3,1,4.6,3.3,5.2c0.4,1,1.2,1.8,2.2,2.2v2.9h2.7v-2.9
 c1.2-0.5,2.2-1.5,2.4-2.9c0.1-0.7,0.1-1.4-0.1-2.1c1.2-0.3,2.1-1.2,2.4-2.5C83.1,44.3,82,42.6,80.2,42.3z"
                          data-svg-origin="76.16231536865234 56.400001525878906"
                          transform="matrix(0.99838,0.05678,-0.05678,0.99838,4.8229932359455905,-4.871679805838323)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="digestion-svg-tree-4" class="digestion-svg-2-tree st8" d="M85,45.8c-0.1,0-0.1,0-0.2,0c-0.2-1.2-1.1-2.2-2.3-2.5c-1.6-0.3-3.1,0.7-3.4,2.3
 c0,0.2-0.1,0.5-0.1,0.7c-0.9,0.5-1.6,1.3-1.8,2.4c-0.4,1.8,0.7,3.5,2.5,3.9c0.3,0.7,0.9,1.3,1.7,1.6v2.2h2.1v-2.2
 c0.9-0.3,1.6-1.1,1.8-2.2c0.1-0.5,0.1-1.1-0.1-1.5c0.9-0.2,1.6-0.9,1.8-1.9C87.2,47.3,86.3,46,85,45.8z"
                        data-svg-origin="82.07290649414062 56.400001525878906"
                        transform="matrix(0.999,-0.04453,0.04453,0.999,-1.3031000593887798,3.2294092856984626)"
                        style="transform-origin: 0px 0px 0px;"></path>
										<path id="digestion-svg-tree-5"
                          class="digestion-svg-2-tree st8" d="M58.4,46.7c0-0.1-0.1-0.2-0.1-0.3c0.2-0.9,0.2-1.9-0.2-2.8c-0.8-1.9-2.9-2.9-4.9-2.5
 c-0.9-1.2-2.6-1.7-4-1.1c-1.7,0.7-2.5,2.7-1.7,4.4c0,0,0,0.1,0,0.1c-1.1,0.9-1.6,2.4-1,3.8c0.3,0.6,0.7,1.1,1.2,1.4
 c0,0.6,0.1,1.3,0.3,1.9c0.5,1.2,1.5,2,2.6,2.4v2.4h2.4v-2.3c0.2-0.1,0.5-0.1,0.7-0.2c1.2-0.5,2-1.5,2.4-2.6c0.2,0,0.4-0.1,0.5-0.2
 C58.4,50.4,59.2,48.4,58.4,46.7z" data-svg-origin="52.476715087890625 56.40000915527344"
                          transform="matrix(0.99977,-0.02122,0.02122,0.99977,0.24217617445273731,0.5200484048019831)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="digestion-svg-tree-6" class="digestion-svg-2-tree st8" d="M26.4,14.7c-0.1,0-0.2,0-0.2,0c-0.2-1.4-1.2-2.6-2.7-2.9c-1.8-0.4-3.6,0.8-4,2.7
 c-0.1,0.3-0.1,0.6-0.1,0.8c-1,0.5-1.8,1.5-2.1,2.7c-0.4,2.1,0.9,4,2.9,4.6c0.4,0.9,1,1.6,2,1.9V27h2.4v-2.5
 c1.1-0.4,1.9-1.3,2.1-2.5c0.1-0.6,0.1-1.2-0.1-1.8c1-0.3,1.9-1.1,2.1-2.2C28.9,16.5,27.9,15,26.4,14.7z"
                        data-svg-origin="22.976553916931152 27"
                        transform="matrix(0.9971,0.07602,-0.07602,0.9971,3.428706840661194,-2.227914588440406)"
                        style="transform-origin: 0px 0px 0px;"></path>
									</g>
									<g id="digestion-svg-2-area-3">
										<g id="digestion-svg-2-building-3">
											<g id="digestion-svg-2-pipes-3">
												<polygon id="digestion-svg-2-pipe-body-5" class="st4"
                                 points="66.1,60.2 62.1,60.2 62.8,49.8 65.4,49.8 			"></polygon>
												<rect
                            id="digestion-svg-2-pipe-top-5" x="62.3" y="49.4" class="st5" width="3.6"
                            height="0.7"></rect>
											</g>
											<rect
                          id="digestion-svg-2-wall-5" x="60.3" y="60.1" class="st4" width="11" height="4.6"></rect>
											<rect
                          id="digestion-svg-2-wall-6" x="71.3" y="60.1" class="st6" width="5.3" height="4.6"></rect>
											<rect
                          id="digestion-svg-2-roof-3" x="59.4" y="59.1" class="st5" width="18.3" height="2.1"></rect>
											<rect
                          id="digestion-svg-2-door-5" x="63" y="61.5" class="st5" width="2.8" height="3.2"></rect>
											<rect
                          id="digestion-svg-2-door-6" x="66.6" y="61.5" class="st5" width="2.8" height="3.2"></rect>
										</g>
										<path
                        id="digestion-svg-2-smoke-5" class="digestion-svg-2-smoke-3 st3" d="M78.4,48.7c0.1-1.5-0.9-2.9-2.4-3.2c-0.1,0-0.2,0-0.2,0c-0.2-1.5-1.3-2.8-2.9-3.1
 c-2-0.4-3.9,0.9-4.3,2.9c-0.1,0.3-0.1,0.6-0.1,0.9c-2.6,0-5.2,1.4-5.5,2.6H78.4z" data-svg-origin="63 48.80000305175781"
                        transform="matrix(0.98873,0,0,0.98873,0.7099931697634574,0.5499629976378415)"
                        style="transform-origin: 0px 0px 0px;"></path>
									</g>
									<g id="digestion-svg-2-mountains">
										<path id="digestion-svg-2-mountain-1" class="st9" d="M86.1,73.2l-7.3-10.3c-1-1.4-3-1.4-3.9,0l-7.3,10.3c-1.1,1.6,0,3.8,2,3.8h14.6
 C86.1,77,87.2,74.8,86.1,73.2z"></path>
										<path id="digestion-svg-2-mountain-2" class="st10" d="M71.7,74.3l-5.1-7.2c-0.7-0.9-2.1-0.9-2.7,0l-5.1,7.2c-0.8,1.1,0,2.7,1.4,2.7h10.2
 C71.7,77,72.5,75.4,71.7,74.3z"></path>
									</g>
								</svg>
							</div>
							<p>В кінці механічного та хімічного оброблення харчова грудка стає хімусом — рідкою або напіврідкою масою з щільними часточками розміром не більше 1–2 мм.  Хімус потрапляє в тонку кишку, де під дією ферментів відбувається основний процес перетравлення їжі<sup>1</sup>.
							</p>
						</div>
						<p
                class="dotted-path-connection dotted-path-connection-4">Завершує процес травлення остаточне розщеплення речовин в тонкій кишці та їх всмоктування в кров і лімфатичні судини, а потім формування калових мас в товстій кишці та їх виведення з організму через пряму кишку<sup>1</sup>.
						</p>
						<div
                class="inline-svg-float-block">
							<div class="svg-left-block svg-left-block-3">
								<svg id="digestion-svg-3" class="inline-svg-illustration"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                     viewBox="0 0 124.8 84.7">
									<g id="digestion-svg-3-mountains">
										<path id="digestion-svg-3-mountain-1" class="st0" d="M123.8,77.7l-8.2-11.5c-1.7-2.4-5.2-2.4-6.9,0l-8.2,11.5c-2,2.8,0,6.7,3.4,6.7h16.3
 C123.8,84.4,125.8,80.5,123.8,77.7z"></path>
										<path id="digestion-svg-3-mountain-2" class="st0" d="M98.7,67.4L81.5,43.2c-2.3-3.2-7-3.2-9.2,0L55.1,67.4c-2.7,3.8,0,8.9,4.6,8.9h34.3
 C98.7,76.4,101.4,71.2,98.7,67.4z"></path>
										<path id="digestion-svg-3-mountain-3" class="st1" d="M111.1,75.4l-11-15.6c-2.3-3.2-7-3.2-9.3,0l-11,15.6c-2.7,3.8,0,9,4.7,9h22.1
 C111.1,84.4,113.8,79.2,111.1,75.4z"></path>
										<path id="digestion-svg-3-mountain-4" class="st2" d="M96.8,77L82.5,56.9c-1.9-2.6-5.8-2.6-7.7,0L60.6,77c-2.2,3.1,0,7.4,3.8,7.4H93
 C96.8,84.4,99,80.1,96.8,77z"></path>
										<path id="digestion-svg-3-mountain-5" class="st1" d="M68.6,79.2l-9.9-14c-1.3-1.8-4-1.8-5.4,0l-9.9,14c-1.5,2.2,0,5.2,2.7,5.2h19.9
 C68.6,84.4,70.2,81.4,68.6,79.2z"></path>
									</g>
									<g id="digestion-svg-3-barrels">
										<rect id="digestion-svg-3-barrel-body-1" x="18.8" y="29.4" class="st3" width="34.2"
                          height="53.9"></rect>
										<rect
                        id="digestion-svg-3-barrel-part-1" x="17.3" y="82" class="st4" width="37.1" height="2.5"></rect>
										<rect
                        id="digestion-svg-3-barrel-part-2" x="17.3" y="64.1" class="st4" width="37.1"
                        height="2.5"></rect>
										<rect
                        id="digestion-svg-3-barrel-part-3" x="17.3" y="46.1" class="st4" width="37.1"
                        height="2.5"></rect>
										<rect
                        id="digestion-svg-3-barrel-part-4" x="17.3" y="28.1" class="st4" width="37.1"
                        height="2.5"></rect>
										<rect
                        id="digestion-svg-3-barrel-body-2" x="59.7" y="41" class="st3" width="27" height="42.6"></rect>
										<rect
                        id="digestion-svg-3-barrel-part-5" x="58.6" y="82.6" class="st4" width="29.3" height="2"></rect>
										<rect
                        id="digestion-svg-3-barrel-part-6" x="58.6" y="68.4" class="st4" width="29.3" height="2"></rect>
										<rect
                        id="digestion-svg-3-barrel-part-7" x="58.6" y="54.2" class="st4" width="29.3" height="2"></rect>
										<rect
                        id="digestion-svg-3-barrel-part-8" x="58.6" y="40" class="st4" width="29.3" height="2"></rect>
										<path
                        id="farctory-3-barrel-outflow-1" class="st8" d="M21.9,28.4v13c0,0.5,0.4,0.9,0.9,0.9h2.5c0.5,0,0.9-0.4,0.9-0.9v-5.1c0-0.5,0.4-0.9,0.9-0.9h0
 c0.5,0,0.9,0.4,0.9,0.9v3.1c0,0.5,0.4,0.9,0.9,0.9h0.6c0.5,0,0.9-0.4,0.9-0.9V28.4H21.9z"></path>
										<path
                        id="farctory-3-barrel-outflow-2" class="st8" d="M62.1,40.3v17.8c0,0.5,0.4,1,1,1h2.5c0.5,0,1-0.4,1-1v-4c0-0.5,0.4-1,1-1h1.3c0.5,0,1-0.4,1-1
 v-0.8c0-0.5,0.4-1,1-1h0c0.5,0,1,0.4,1,1v0.6c0,0.5,0.4,1,1,1h1.2c0.5,0,1-0.4,1-1v-5.7c0-0.5,0.4-1,1-1H76c0.5,0,1-0.4,1-1v-4.1
 H62.1z"></path>
									</g>
									<g id="digestion-svg-3-trees">
										<path id="digestion-svg-3-tree-1" class="digestion-svg-3-tree st5" d="M12.6,67.8c-0.1,0-0.2,0-0.3,0c-0.2-1.9-1.7-3.5-3.7-3.9c-2.5-0.5-4.9,1.1-5.4,3.6
 c-0.1,0.4-0.1,0.8-0.1,1.1c-1.4,0.7-2.5,2-2.8,3.7c-0.6,2.8,1.2,5.5,3.9,6.2c0.5,1.2,1.4,2.1,2.6,2.6v3.4h3.2V81
 c1.4-0.5,2.6-1.8,2.9-3.4c0.2-0.8,0.1-1.7-0.2-2.4c1.4-0.3,2.5-1.4,2.8-2.9C16,70.2,14.6,68.2,12.6,67.8z"
                          data-svg-origin="7.893125489354134 84.49999237060547"
                          transform="matrix(0.98531,0.17074,-0.17074,0.98531,16.31747485611183,-0.8575229900148109)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="digestion-svg-3-tree-2" class="digestion-svg-3-tree st6" d="M23.6,59.9c-0.2,0-0.3-0.1-0.5-0.1c-0.4-2.8-2.5-5.2-5.4-5.8c-3.7-0.7-7.2,1.7-8,5.3
 c-0.1,0.6-0.2,1.1-0.1,1.7c-2.1,1.1-3.7,3-4.2,5.5c-0.8,4.1,1.7,8.1,5.7,9.1c0.7,1.7,2.1,3.1,3.9,3.8v5h4.8v-5
 c2.1-0.8,3.8-2.6,4.2-5c0.2-1.2,0.1-2.5-0.3-3.6c2-0.5,3.7-2.1,4.1-4.3C28.6,63.4,26.6,60.5,23.6,59.9z"
                        data-svg-origin="16.614123344421387 84.4000015258789"
                        transform="matrix(0.99212,0.12524,-0.12524,0.99212,13.318274595883745,-2.5228336655664734)"
                        style="transform-origin: 0px 0px 0px;"></path>
										<path id="digestion-svg-3-tree-3"
                          class="digestion-svg-3-tree st7" d="M29.6,67.8c-0.1,0-0.2,0-0.3,0c-0.2-1.9-1.7-3.5-3.7-3.9c-2.5-0.5-4.9,1.1-5.4,3.6
 c-0.1,0.4-0.1,0.8-0.1,1.1c-1.4,0.7-2.5,2-2.8,3.7c-0.6,2.8,1.2,5.5,3.9,6.2c0.5,1.2,1.4,2.1,2.6,2.6v3.4h3.2V81
 c1.4-0.5,2.6-1.8,2.9-3.4c0.2-0.8,0.1-1.7-0.2-2.4c1.4-0.3,2.5-1.4,2.8-2.9C33,70.2,31.7,68.2,29.6,67.8z"
                          data-svg-origin="24.895699501037598 84.49999237060547"
                          transform="matrix(0.99944,0.03316,-0.03316,0.99944,4.590176950204951,-1.5300990385739546)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="digestion-svg-3-tree-4" class="digestion-svg-3-tree st6" d="M95,69.3c-0.1,0-0.2,0-0.3,0c-0.2-1.7-1.5-3.2-3.3-3.6c-2.3-0.4-4.5,1-4.9,3.3
 c-0.1,0.3-0.1,0.7-0.1,1c-1.3,0.6-2.3,1.9-2.6,3.4c-0.5,2.5,1.1,5,3.5,5.6c0.4,1.1,1.3,1.9,2.4,2.3v3.1h2.9v-3.1
 c1.3-0.5,2.3-1.6,2.6-3.1c0.2-0.8,0.1-1.5-0.2-2.2c1.3-0.3,2.3-1.3,2.5-2.7C98,71.5,96.8,69.7,95,69.3z"
                        data-svg-origin="90.66122436523438 84.40000915527344"
                        transform="matrix(0.99634,-0.08538,0.08538,0.99634,-5.265912179146831,7.371437101128411)"
                        style="transform-origin: 0px 0px 0px;"></path>
										<path id="digestion-svg-3-tree-5"
                          class="digestion-svg-3-tree st5" d="M85.8,73.3c-0.1,0-0.1,0-0.2,0c-0.2-1.3-1.1-2.3-2.4-2.6c-1.7-0.3-3.3,0.7-3.6,2.4
 c-0.1,0.3-0.1,0.5-0.1,0.8c-0.9,0.5-1.7,1.4-1.9,2.5c-0.4,1.8,0.8,3.6,2.6,4.1c0.3,0.8,0.9,1.4,1.8,1.7v2.2h2.1v-2.2
 c0.9-0.4,1.7-1.2,1.9-2.3c0.1-0.6,0.1-1.1-0.1-1.6c0.9-0.2,1.7-1,1.9-2C88,74.9,87.2,73.6,85.8,73.3z"
                          data-svg-origin="82.67667388916016 84.4000015258789"
                          transform="matrix(0.99955,0.02999,-0.02999,0.99955,3.747528317696994,-2.9432941027755604)"
                          style="transform-origin: 0px 0px 0px;"></path>
									</g>
									<g
                      id="digestion-svg-3-bubles">
										<circle id="digestion-svg-3-buble-1" class="digestion-svg-3-buble st8" cx="40.8" cy="19.3" r="4.8"
                            data-svg-origin="40.80000019073486 19.299999237060547"
                            transform="matrix(0.95004,0,0,0.95004,1.8383299974096128,1.2638518285562)"
                            style="transform-origin: 0px 0px 0px;"></circle>
										<circle
                        id="digestion-svg-3-buble-2" class="digestion-svg-3-buble st8" cx="32.3" cy="7.6" r="3.6"
                        data-svg-origin="32.29999876022339 7.599999904632568"
                        transform="matrix(0.86069,0,0,0.86069,3.9422220346893075,1.8944954644723033)"
                        style="transform-origin: 0px 0px 0px;"></circle>
										<circle id="digestion-svg-3-buble-3"
                            class="digestion-svg-3-buble st8"
                            cx="75.2"
                            cy="32.5" r="3.6"
                            data-svg-origin="75.19999837875366 32.49999952316284"
                            transform="matrix(0.73604,0,0,0.73604,18.793622246943535,10.162281798053241)"
                            style="transform-origin: 0px 0px 0px;"></circle>
										<circle
                        id="digestion-svg-3-buble-4" class="digestion-svg-3-buble st8" cx="42.6" cy="2.2" r="1.9"
                        data-svg-origin="42.59999692440033 2.200000047683716"
                        transform="matrix(0.58828,0,0,0.58828,15.892209459375184,3.376065510572593)"
                        style="transform-origin: 0px 0px 0px;"></circle>
										<circle id="digestion-svg-3-buble-5"
                            class="digestion-svg-3-buble st8"
                            cx="66.5"
                            cy="27.1" r="1.9"
                            data-svg-origin="66.49999845027924 27.100000739097595"
                            transform="matrix(0.56811,0,0,0.56811,26.992677200608192,14.295322519105795)"
                            style="transform-origin: 0px 0px 0px;"></circle>
									</g>
								</svg>
							</div>
						</div>
					</section>
					<div
              id="part_2" class="title-block">
						<h1>Підшлункова залоза:
							<br>анатомія та функції
							</h1>
						</div>
						<section
                class="main-column">
							<p>В процесі травлення вирішальне слово за підшлунковою залозою. Саме вона виробляє ферменти, які розщеплюють поживні речовини: жири, білки, вуглеводи<sup>1</sup>.
							</p>
							<h2>Що таке підшлункова залоза</h2>
							<div
                  class="rounded-dashed-border-block">
								<p class="connected-paragraph">У цієї складної залози, як у живої істоти, присутня голівка, тіло і хвіст. Підшлунковий сік з ферментами потрапляє по протоках в дванадцятипалу кишку<sup>1</sup>.
								</p>
								<p
                    class="connected-paragraph">Анатомія підшлункової залози у людини є дивовижною. Цей ніжний орган складається з двох залоз - екзокринної і ендокринної.</p>
							</div>
							<div
                  class="inline-svg-center-block">
								<svg id="digestion-svg-4" class="inline-svg-illustration"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                     viewBox="0 0 125.9 84.7">
									<path id="digestion-svg-4-ground-shadow" class="st0" d="M13.6,29.3l-8.1,8.1c-1.8,1.8-2.8,4.2-2.8,6.7L0,64.2c0,5.3,1,6.5,2.8,8.3l9.4,9.5
 c1.8,1.8,4.2,2.8,6.7,2.8h48c2.5,0,4.9-1,6.7-2.8L98,57.4c1.8-1.8,4.2-2.8,6.7-2.8h5.2c2.5,0,4.9-1,6.7-2.8l7.3-7.3
 c2.8-2.8,2-5.9,2-12.8c0-4-105.5-5.3-105.5-5.3C17.8,26.5,15.4,27.5,13.6,29.3z"></path>
									<path id="digestion-svg-4-ground"
                        class="st1" d="M10.8,25.6l-8.1,8.1C1,35.5,0,37.9,0,40.4v24.5c0,2.5,1,4.9,2.8,6.7l6.7,6.7
 c1.8,1.8,4.2,2.8,6.7,2.8h48c2.5,0,4.9-1,6.7-2.8l24.5-24.5C97,52,99.4,51,101.9,51h5.2c2.5,0,4.9-1,6.7-2.8L123,39
 c3.7-3.7,3.7-9.7,0-13.3l0,0c-1.8-1.8-4.2-2.8-6.7-2.8l-98.8,0C15,22.9,12.6,23.8,10.8,25.6z"></path>
									<g
                      id="digestion-svg-4-mountains">
										<path id="digestion-svg-4-mountain-1" class="st2" d="M67.1,36.5l-5.6-7.8c-1.1-1.6-3.5-1.6-4.7,0l-5.6,7.8c-1.3,1.9,0,4.5,2.3,4.5h11.1
 C67.1,41,68.4,38.4,67.1,36.5z"></path>
										<path id="digestion-svg-4-mountain-2" class="st2" d="M50,29.5L38.3,13c-1.5-2.2-4.7-2.2-6.3,0L20.4,29.5c-1.8,2.6,0,6.1,3.1,6.1h23.4
 C50,35.6,51.8,32,50,29.5z"></path>
										<path id="digestion-svg-4-mountain-3" class="st3" d="M58.5,34.8L51,24.3c-1.5-2.2-4.8-2.2-6.3,0l-7.5,10.6c-1.8,2.6,0,6.1,3.2,6.1h15
 C58.5,41,60.3,37.4,58.5,34.8z"></path>
										<path id="digestion-svg-4-mountain-4" class="st4" d="M48.7,36L39,22.3c-1.3-1.8-3.9-1.8-5.2,0L24.1,36c-1.5,2.1,0,5.1,2.6,5.1h19.4
 C48.7,41,50.2,38.1,48.7,36z"></path>
										<path id="digestion-svg-4-mountain-5" class="st3" d="M29.6,37.5L22.8,28c-0.9-1.3-2.8-1.3-3.6,0l-6.8,9.5c-1,1.5,0,3.5,1.8,3.5h13.5
 C29.5,41,30.6,39,29.6,37.5z"></path>
									</g>
									<g class="factory-4-roads">
										<path id="digestion-svg-4-road-1" class="st5" d="M45.2,77H24.4c-2.4,0-4.6-0.9-6.3-2.6l-3.5-3.5C12.9,69.3,12,67,12,64.7v-6.2h5v6.2
 c0,1,0.4,2,1.1,2.8l3.5,3.5c0.7,0.7,1.7,1.1,2.8,1.1h20.8c1,0,2-0.4,2.8-1.1l8.3-8.3c0.7-0.7,1.1-1.7,1.1-2.8v-2.2
 c0-2.4,0.9-4.6,2.6-6.3l9.6-9.6c1.7-1.7,3.9-2.6,6.3-2.6h3.4c2.1,0,3.9-1.7,3.9-3.9h5c0,4.9-4,8.9-8.9,8.9h-3.4c-1,0-2,0.4-2.8,1.1
 l-9.6,9.6c-0.7,0.7-1.1,1.7-1.1,2.8v2.2c0,2.4-0.9,4.6-2.6,6.3l-8.3,8.3C49.8,76.1,47.5,77,45.2,77z"></path>
										<path
                        id="digestion-svg-4-road-2" class="st5" d="M61.8,55.1h-9.3c-2.3,0-4.5-0.9-6.2-2.6l-4.7-4.7c-0.7-0.7-1.7-1.1-2.7-1.1H19.1v-5h19.9
 c2.3,0,4.5,0.9,6.2,2.6l4.7,4.7c0.7,0.7,1.7,1.1,2.7,1.1h9.3V55.1z"></path>
									</g>
									<g id="digestion-svg-4-area-1">
										<g id="digestion-svg-4-smokes-1">
											<path id="digestion-svg-4-smoke-1" class="digestion-svg-4-smoke-1 st6" d="M22.2,34.5c0.1-1.1-0.6-2-1.7-2.2c-0.1,0-0.1,0-0.2,0c-0.1-1.1-0.9-2-2-2.2
 c-1.4-0.3-2.7,0.6-3,2c0,0.2-0.1,0.4,0,0.6c-1.8,0-3.6,1-3.9,1.8H22.2z" data-svg-origin="11.399999618530273 34.5"
                            transform="matrix(0.98873,0,0,0.98873,0.12847495022956323,0.38880578344188876)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="digestion-svg-4-smoke-2" class="digestion-svg-4-smoke-1 st6" d="M27.2,36.7c0.1-1.1-0.6-2-1.7-2.2c-0.1,0-0.1,0-0.2,0c-0.1-1.1-0.9-2-2-2.2
 c-1.4-0.3-2.7,0.6-3,2c0,0.2-0.1,0.4,0,0.6c-1.8,0-3.6,1-3.9,1.8H27.2z"
                          data-svg-origin="16.399999618530273 36.70000076293945"
                          transform="matrix(0.84235,0,0,0.84235,2.5853229845262646,5.7854486409470915)"
                          style="transform-origin: 0px 0px 0px;"></path>
											<path id="digestion-svg-4-smoke-3"
                            class="digestion-svg-4-smoke-1 st6" d="M33.1,34.5c0.1-1.1-0.6-2-1.7-2.2c-0.1,0-0.1,0-0.2,0c-0.1-1.1-0.9-2-2-2.2
 c-1.4-0.3-2.7,0.6-3,2c0,0.2-0.1,0.4,0,0.6c-1.8,0-3.6,1-3.9,1.8H33.1z" data-svg-origin="22.299997329711914 34.5"
                            transform="matrix(0.55033,0,0,0.55033,10.027527780527118,15.513441697468345)"
                            style="transform-origin: 0px 0px 0px;"></path>
										</g>
										<g
                        id="digestion-svg-4-building-1">
											<polygon id="digestion-svg-4-pipe-1" class="st7"
                               points="13.6,43.4 10.9,43.4 11.3,36.2 13.2,36.2 		"></polygon>
											<polygon
                          id="digestion-svg-4-pipe-2" class="st7"
                          points="18.7,45.7 15.9,45.7 16.4,38.4 18.2,38.4 		"></polygon>
											<polygon
                          id="digestion-svg-4-pipe-3" class="st7"
                          points="24.6,43.4 21.8,43.4 22.3,36.2 24.1,36.2 		"></polygon>
											<rect
                          id="digestion-svg-4-pipe-top-1" x="21.6" y="35.9" class="st8" width="3.1" height="0.6"></rect>
											<rect
                          id="digestion-svg-4-pipe-top-2" x="15.8" y="38.1" class="st8" width="3.1" height="0.6"></rect>
											<rect
                          id="digestion-svg-4-pipe-top-3" x="10.7" y="35.9" class="st8" width="3.1" height="0.6"></rect>
											<polygon
                          id="digestion-svg-4-wall-1" class="st9"
                          points="29.3,49.1 29.3,44.5 18.2,44.5 18.2,59.3 44.3,59.3 44.3,49.1 		"></polygon>
											<polygon
                          id="digestion-svg-4-wall-2" class="st7"
                          points="20.3,49.1 20.3,44.5 9.1,44.5 9.1,59.3 35.3,59.3 35.3,49.1 		"></polygon>
											<rect
                          id="digestion-svg-4-roof-1" x="8.1" y="42.4" class="st8" width="22.1" height="2.1"></rect>
											<rect
                          id="digestion-svg-4-roof-2" x="19.2" y="48.3" class="st8" width="25.7" height="2.1"></rect>
											<rect
                          id="digestion-svg-4-door-1" x="11.8" y="54" class="st8" width="5.3" height="5.3"></rect>
											<rect
                          id="digestion-svg-4-door-2" x="12.9" y="46.2" class="digestion-svg-4-window st8" width="3.1"
                          height="3.1" data-svg-origin="12.899999618530273 46.20000076293945"
                          transform="matrix(1,0,0,1,0,0)"
                          style="fill: rgb(169, 179, 110);"></rect>
											<rect id="digestion-svg-4-door-3" x="21.5" y="54"
                            class="st8" width="5.3" height="5.3"></rect>
											<rect
                          id="digestion-svg-4-door-4" x="27.6" y="54" class="st8" width="5.3" height="5.3"></rect>
										</g>
									</g>
									<g
                      id="digestion-svg-4-mountains">
										<path id="digestion-svg-4-mountain-1" class="st10" d="M49.9,60.6l-5.8-8.1c-0.8-1.1-2.4-1.1-3.1,0l-5.8,8.1c-0.9,1.3,0,3,1.6,3h11.6
 C49.8,63.6,50.8,61.9,49.9,60.6z"></path>
										<path id="digestion-svg-4-mountain-2" class="st11" d="M38.4,61.5l-4-5.7c-0.5-0.7-1.6-0.7-2.2,0l-4,5.7c-0.6,0.9,0,2.1,1.1,2.1h8.1
 C38.4,63.6,39.1,62.4,38.4,61.5z"></path>
									</g>
									<g id="digestion-svg-4-area-2">
										<g id="digestion-svg-4-smokes-2">
											<path id="digestion-svg-4-smoke-4" class="digestion-svg-4-smoke-2 st12" d="M96.5,14.8c0.1-1.3-0.8-2.6-2.1-2.9c-0.1,0-0.1,0-0.2,0C94,10.5,93,9.4,91.6,9.1
 c-1.8-0.4-3.5,0.8-3.8,2.6c-0.1,0.3-0.1,0.5-0.1,0.8c-2.3,0-4.6,1.3-4.9,2.3H96.5z"
                            data-svg-origin="82.79999542236328 14.80000114440918"
                            transform="matrix(0.98873,0,0,0.98873,0.9331338286717994,0.16679205912733153)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="digestion-svg-4-smoke-5" class="digestion-svg-4-smoke-2 st12" d="M104.2,11.8c0.1-1.3-0.8-2.6-2.1-2.9c-0.1,0-0.1,0-0.2,0c-0.2-1.4-1.2-2.5-2.6-2.8
 c-1.8-0.4-3.5,0.8-3.8,2.6c-0.1,0.3-0.1,0.5-0.1,0.8c-2.3,0-4.6,1.3-4.9,2.3H104.2z"
                          data-svg-origin="90.5 11.800000190734863"
                          transform="matrix(0.84235,0,0,0.84235,14.266569240359217,1.860171489031723)"
                          style="transform-origin: 0px 0px 0px;"></path>
											<path id="digestion-svg-4-smoke-6"
                            class="digestion-svg-4-smoke-2 st12" d="M118.1,8.8c0.2-2-1.2-3.8-3.2-4.2c-0.1,0-0.2,0-0.3,0c-0.2-2-1.7-3.7-3.8-4.1
 c-2.6-0.5-5.1,1.2-5.7,3.8C105,4.6,105,5,105,5.4c-3.4,0-6.9,1.9-7.3,3.4H118.1z"
                            data-svg-origin="97.69999694824219 8.800000041723251"
                            transform="matrix(0.55033,0,0,0.55033,43.9322668550548,3.957051814057759)"
                            style="transform-origin: 0px 0px 0px;"></path>
										</g>
										<g
                        id="digestion-svg-4-building-2">
											<polygon id="digestion-svg-4-pipe-4" class="st7"
                               points="87.1,30.8 81.9,30.8 82.7,17.1 86.2,17.1 		"></polygon>
											<polygon
                          id="digestion-svg-4-pipe-5" class="st7"
                          points="94.6,27.9 89.4,27.9 90.3,14.1 93.8,14.1 		"></polygon>
											<polygon
                          id="digestion-svg-4-pipe-6" class="st7"
                          points="101.9,24.9 96.7,24.9 97.5,11.2 101,11.2 		"></polygon>
											<rect
                          id="digestion-svg-4-pipe-top-4" x="82.1" y="16.6" class="st8" width="4.7" height="1"></rect>
											<rect
                          id="digestion-svg-4-pipe-top-5" x="89.6" y="13.6" class="st8" width="4.7" height="1"></rect>
											<rect
                          id="digestion-svg-4-pipe-top-6" x="96.9" y="10.7" class="st8" width="4.7" height="1"></rect>
											<rect
                          id="digestion-svg-4-wall-3" x="76" y="24.8" class="st7" width="19.4" height="10.4"></rect>
											<rect
                          id="digestion-svg-4-wall-4" x="95.5" y="24.8" class="st9" width="13.2" height="10.4"></rect>
											<rect
                          id="digestion-svg-4-roof-3" x="75.2" y="22.1" class="st8" width="34.3" height="2.7"></rect>
											<rect
                          id="digestion-svg-4-door-5" x="81.6" y="26.6" class="st8" width="3.8" height="8.6"></rect>
											<rect
                          id="digestion-svg-4-door-6" x="86.2" y="26.6" class="st8" width="3.8"
                          height="8.6"></rect>
										</g>
									</g>
									<g
                      id="digestion-svg-4-barrels">
										<g id="digestion-svg-4-barrel-1">
											<rect id="digestion-svg-4-barrel-body-1" x="100.8" y="30" class="st13" width="6.6"
                            height="10.3"></rect>
											<rect
                          id="digestion-svg-4-barrel-part-1" x="100.6" y="40.1" class="st8" width="7.1"
                          height="0.5"></rect>
											<rect
                          id="digestion-svg-4-barrel-part-2" x="100.6" y="36.6" class="st8" width="7.1"
                          height="0.5"></rect>
											<rect
                          id="digestion-svg-4-barrel-part-3" x="100.6" y="33.2" class="st8" width="7.1"
                          height="0.5"></rect>
											<rect
                          id="digestion-svg-4-barrel-part-4" x="100.6" y="29.7" class="st8" width="7.1"
                          height="0.5"></rect>
										</g>
										<g
                        id="digestion-svg-4-barrel-2">
											<rect id="digestion-svg-4-barrel-body-2" x="108.9" y="28.6" class="st13" width="6.6"
                            height="10.3"></rect>
											<rect
                          id="digestion-svg-4-barrel-part-5" x="108.7" y="38.7" class="st8" width="7.1"
                          height="0.5"></rect>
											<rect
                          id="digestion-svg-4-barrel-part-6" x="108.7" y="35.3" class="st8" width="7.1"
                          height="0.5"></rect>
											<rect
                          id="digestion-svg-4-barrel-part-7" x="108.7" y="31.8" class="st8" width="7.1"
                          height="0.5"></rect>
											<rect
                          id="digestion-svg-4-barrel-part-8" x="108.7" y="28.4" class="st8" width="7.1"
                          height="0.5"></rect>
										</g>
										<g id="digestion-svg-4-barrel-3">
											<rect id="digestion-svg-4-barrel-body-3" x="104.4" y="32.5" class="st13" width="6.6"
                            height="10.3"></rect>
											<rect
                          id="digestion-svg-4-barrel-part-9" x="104.1" y="42.6" class="st8" width="7.1"
                          height="0.5"></rect>
											<rect
                          id="digestion-svg-4-barrel-part-10" x="104.1" y="39.2" class="st8" width="7.1"
                          height="0.5"></rect>
											<rect
                          id="digestion-svg-4-barrel-part-11" x="104.1" y="35.7" class="st8" width="7.1"
                          height="0.5"></rect>
											<rect
                          id="digestion-svg-4-barrel-part-12" x="104.1" y="32.3" class="st8" width="7.1"
                          height="0.5"></rect>
										</g>
									</g>
									<g
                      id="digestion-svg-4-trees">
										<path id="digestion-svg-4-tree-1" class="digestion-svg-4-tree st6" d="M63.3,68.8c0-0.1-0.1-0.1-0.1-0.2c0.2-0.6,0.1-1.3-0.1-1.9c-0.6-1.3-2-2-3.3-1.7
 c-0.6-0.8-1.8-1.2-2.8-0.7c-1.2,0.5-1.7,1.9-1.2,3c0,0,0,0,0,0.1c-0.8,0.6-1.1,1.7-0.7,2.6c0.2,0.4,0.5,0.8,0.8,1
 c0,0.4,0.1,0.9,0.2,1.3c0.3,0.8,1,1.4,1.8,1.6v1.7h1.6v-1.6c0.2,0,0.3-0.1,0.5-0.2c0.8-0.4,1.4-1,1.6-1.8c0.1,0,0.3-0.1,0.4-0.1
 C63.2,71.3,63.8,69.9,63.3,68.8z" data-svg-origin="59.20021629333496 75.60000610351562"
                          transform="matrix(0.98531,0.17074,-0.17074,0.98531,14.765112374737438,-9.414428595485006)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="digestion-svg-4-tree-2" class="digestion-svg-4-tree st14" d="M78.2,50.3c-0.1,0-0.2,0-0.3,0c-0.2-1.6-1.4-3-3.1-3.3c-2.1-0.4-4.1,0.9-4.6,3
 c-0.1,0.3-0.1,0.6-0.1,1c-1.2,0.6-2.1,1.7-2.4,3.1c-0.5,2.3,1,4.6,3.3,5.2c0.4,1,1.2,1.8,2.2,2.2v2.9h2.7v-2.9
 c1.2-0.5,2.2-1.5,2.4-2.9c0.1-0.7,0.1-1.4-0.1-2.1c1.2-0.3,2.1-1.2,2.4-2.5C81.1,52.3,80,50.6,78.2,50.3z"
                        data-svg-origin="74.16231536865234 64.4000015258789"
                        transform="matrix(0.99113,0.13286,-0.13286,0.99113,10.711976119634217,-9.92098176705925)"
                        style="transform-origin: 0px 0px 0px;"></path>
										<path id="digestion-svg-4-tree-3"
                          class="digestion-svg-4-tree st6" d="M86.1,57.7c0-0.1-0.1-0.1-0.1-0.2c0.2-0.6,0.1-1.3-0.1-1.9c-0.6-1.3-2-2-3.3-1.7
 c-0.6-0.8-1.7-1.2-2.7-0.7c-1.2,0.5-1.7,1.9-1.2,3c0,0,0,0,0,0.1c-0.8,0.6-1.1,1.7-0.7,2.6c0.2,0.4,0.5,0.7,0.8,1
 c0,0.4,0.1,0.9,0.2,1.3c0.3,0.8,1,1.4,1.8,1.6v1.6h1.6v-1.6c0.2,0,0.3-0.1,0.5-0.2c0.8-0.3,1.4-1,1.6-1.8c0.1,0,0.2-0.1,0.4-0.1
 C86.1,60.2,86.6,58.8,86.1,57.7z" data-svg-origin="82.05803680419922 64.39999389648438"
                          transform="matrix(0.99838,0.05678,-0.05678,0.99838,4.768204413103233,-4.967930878480415)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="digestion-svg-4-tree-4" class="digestion-svg-4-tree st6" d="M74.3,56.2c0-1.6-1.3-2.9-2.9-2.9c0,0,0,0-0.1,0C71,52,69.9,51,68.5,51c-1.6,0-2.8,1.3-2.8,2.8
 c0,0.1,0,0.2,0,0.3c-1,0.5-1.6,1.5-1.6,2.7c0,1.2,0.7,2.2,1.7,2.7c-0.1,0.2-0.1,0.4-0.1,0.7c0,1,0.8,1.7,1.7,1.7
 c0.2,0,0.4,0,0.6-0.1v2.5h1.8v-2.7c0.2,0.1,0.5,0.1,0.7,0.1c1.4,0,2.5-1.1,2.5-2.5c0-0.2,0-0.4-0.1-0.5
 C73.8,58.1,74.3,57.2,74.3,56.2z" data-svg-origin="69.20000076293945 64.30000305175781"
                        transform="matrix(0.999,-0.04453,0.04453,0.999,-1.6554717473286358,2.6507089730933675)"
                        style="transform-origin: 0px 0px 0px;"></path>
										<path id="digestion-svg-4-tree-5"
                          class="digestion-svg-4-tree st14" d="M58.5,33.1c-0.1,0-0.2,0-0.3,0c-0.2-1.8-1.5-3.3-3.4-3.6c-2.3-0.5-4.5,1-5,3.3
 c-0.1,0.4-0.1,0.7-0.1,1.1c-1.3,0.7-2.3,1.9-2.6,3.4c-0.5,2.6,1.1,5.1,3.6,5.7c0.4,1.1,1.3,2,2.4,2.4v3.1h3v-3.1
 c1.3-0.5,2.4-1.7,2.7-3.2c0.2-0.8,0.1-1.6-0.2-2.3c1.3-0.3,2.3-1.3,2.6-2.7C61.6,35.3,60.4,33.5,58.5,33.1z"
                          data-svg-origin="54.14271926879883 48.5"
                          transform="matrix(0.99977,-0.02122,0.02122,0.99977,0.6196490319963601,0.46583144626651674)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="digestion-svg-4-tree-6" class="digestion-svg-4-tree st6" d="M63.7,36.9c-0.1,0-0.1,0-0.2,0c-0.2-1.3-1.2-2.5-2.6-2.7c-1.7-0.3-3.4,0.8-3.8,2.5
 c-0.1,0.3-0.1,0.5-0.1,0.8c-1,0.5-1.7,1.4-2,2.6c-0.4,1.9,0.8,3.8,2.7,4.3c0.3,0.8,1,1.5,1.8,1.8v2.4h2.3v-2.4c1-0.4,1.8-1.2,2-2.4
 c0.1-0.6,0.1-1.2-0.1-1.7c1-0.2,1.8-1,2-2.1C66.1,38.6,65.1,37.2,63.7,36.9z"
                        data-svg-origin="60.35601234436035 48.599998474121094"
                        transform="matrix(0.9971,0.07602,-0.07602,0.9971,5.108155224616933,-4.976558618954208)"
                        style="transform-origin: 0px 0px 0px;"></path>
										<path id="digestion-svg-4-tree-7"
                          class="digestion-svg-4-tree st14" d="M102.7,38.8c0-0.1-0.1-0.1-0.1-0.2c0.2-0.6,0.1-1.3-0.1-1.9c-0.6-1.3-2-2-3.3-1.7
 c-0.6-0.8-1.8-1.2-2.8-0.7c-1.2,0.5-1.7,1.9-1.2,3c0,0,0,0,0,0.1C94.5,38,94.2,39,94.6,40c0.2,0.4,0.5,0.8,0.8,1
 c0,0.4,0.1,0.9,0.2,1.3c0.3,0.8,1,1.4,1.8,1.6v1.7H99v-1.6c0.2,0,0.3-0.1,0.5-0.2c0.8-0.4,1.4-1,1.6-1.8c0.1,0,0.3-0.1,0.4-0.1
 C102.7,41.3,103.2,40,102.7,38.8z" data-svg-origin="98.65665435791016 45.599998474121094"
                          transform="matrix(0.98947,0.14467,-0.14467,0.98947,8.62297418547312,-14.206410465262111)"
                          style="transform-origin: 0px 0px 0px;"></path>
									</g>
								</svg>
							</div>
							<p
                  class="dotted-path-connection dotted-path-connection-5">У екзокринної частини травні ферменти по протоках потрапляють в дванадцятипалу кишку.</p>
							<p
                  class="dotted-path-connection dotted-path-connection-6">Ендокринну частину складають групи клітин, які є панкреатичними острівцями Лангерганса (залози внутрішньої секреції) і виділяють інсулін. Вивідних проток у них немає, тому інсулін надходить відразу в кров<sup>1</sup>.
							</p>
							<h2>Функції підшлункової залози</h2>
							<div
                  class="inline-svg-center-block">
								<svg id="digestion-svg-5" class="inline-svg-illustration"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                     viewBox="0 0 136.1 96.5">
									<g id="digestion-svg-5-trees">
										<path id="digestion-svg-5-tree-1" class="digestion-svg-5-tree st0" d="M122.3,69.3c-0.2,0-0.3-0.1-0.5-0.1c-0.4-3.4-3.1-6.2-6.7-6.5c-3.4-0.3-6.6,1.8-7.7,5
 c-0.3,1-0.4,1.9-0.4,2.8c-2.3,1.2-4,3.3-4.6,6c-0.9,4.5,1.9,8.9,6.3,10.1c0.8,1.9,2.3,3.4,4.3,4.2v5.5h5.3v-5.5
 c2.3-0.9,4.2-2.9,4.7-5.5c0.3-1.4,0.2-2.7-0.3-3.9c2.2-0.6,4.1-2.4,4.6-4.8C127.8,73.2,125.6,69.9,122.3,69.3z"
                          data-svg-origin="114.80130767822266 96.30000305175781"
                          transform="matrix(0.99999,-0.00098,0.00098,0.99999,2.788176989250161,-1.1106038208466131)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="digestion-svg-5-tree-2" class="digestion-svg-5-tree st1" d="M121,76.3c0.1,0,0.2,0,0.4-0.1c0.3-2.3,2-4.3,4.4-4.7c3-0.6,5.9,1.3,6.5,4.3
 c0.1,0.5,0.1,0.9,0.1,1.4c1.7,0.9,3,2.5,3.4,4.5c0.7,3.3-1.4,6.6-4.6,7.4c-0.6,1.4-1.7,2.5-3.2,3.1v4.1H124v-4.1
 c-1.7-0.7-3.1-2.1-3.5-4.1c-0.2-1-0.1-2,0.2-2.9c-1.7-0.4-3-1.7-3.4-3.5C116.9,79.2,118.5,76.8,121,76.3z"
                        data-svg-origin="126.5868148803711 96.30001068115234"
                        transform="matrix(0.9999,-0.01375,0.01375,0.9999,0.8225760392759831,0.8409057992866735)"
                        style="transform-origin: 0px 0px 0px;"></path>
									</g>
									<g id="digestion-svg-5-mountains">
										<path id="digestion-svg-5-mountain-1" class="st3" d="M85.9,84.8L72.1,65.4c-2.9-4-8.8-4-11.7,0L46.6,84.8c-3.4,4.7,0,11.3,5.8,11.3h27.7
 C85.9,96.1,89.3,89.6,85.9,84.8z"></path>
										<path id="digestion-svg-5-mountain-2" class="st0" d="M70.4,74.9L48.8,44.5c-2.8-4-8.7-4-11.6,0L15.7,74.9c-3.3,4.7,0,11.2,5.8,11.2h43
 C70.3,86.1,73.7,79.6,70.4,74.9z"></path>
										<path id="digestion-svg-5-mountain-3" class="st4" d="M68,86.8L50.1,61.7c-2.4-3.3-7.3-3.3-9.6,0L22.6,86.8c-2.8,3.9,0,9.3,4.8,9.3h35.7
 C67.9,96.1,70.7,90.7,68,86.8z"></path>
										<path id="digestion-svg-5-mountain-4" class="st1" d="M32.7,89.6L20.2,72.1c-1.6-2.3-5.1-2.3-6.7,0L1,89.6c-1.9,2.7,0,6.5,3.4,6.5h24.9
 C32.6,96.1,34.6,92.4,32.7,89.6z"></path>
									</g>
									<g id="digestion-svg-5-pipes">
										<g id="digestion-svg-5-pipe-1">
											<path id="digestion-svg-5-pipe-body-1" class="st5" d="M67.3,30.4h-9.4v35.5h0c0,0,0,0,0,0c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0,0,0,0,0,0
 h0V30.4z"></path>
											<rect id="digestion-svg-5-pipe-top-1" x="56.3" y="28.9" class="st6" width="12.7"
                            height="3.8"></rect>
										</g>
										<g
                        id="digestion-svg-5-pipe-2">
											<path id="digestion-svg-5-pipe-body-2" class="st5" d="M81.8,30.4h-9.4v35.5h0c0,0,0,0,0,0c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0,0,0,0,0,0
 h0V30.4z"></path>
											<rect id="digestion-svg-5-pipe-top-2" x="70.8" y="28.9" class="st6" width="12.7"
                            height="3.8"></rect>
										</g>
										<g
                        id="digestion-svg-5-pipe-3">
											<path id="digestion-svg-5-pipe-body-3" class="st5" d="M96.3,30.4h-9.4v35.5h0c0,0,0,0,0,0c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0,0,0,0,0,0
 h0V30.4z"></path>
											<rect id="digestion-svg-5-pipe-top-3" x="85.3" y="28.9" class="st6" width="12.7"
                            height="3.8"></rect>
										</g>
									</g>
									<g
                      id="digestion-svg-5-body">
										<rect id="digestion-svg-5-facet-1" x="78.3" y="48.1" class="st7" width="27.2" height="48.1"></rect>
										<rect
                        id="digestion-svg-5-facet-2" x="38.1" y="72.4" class="st8" width="40.3" height="23.8"></rect>
										<rect
                        id="digestion-svg-5-facet-3" x="46.4" y="48.1" class="st8" width="40.3" height="16"></rect>
										<polygon
                        id="digestion-svg-5-facet-4" class="st9"
                        points="78.4,72.4 38.1,72.4 46.4,64.1 86.6,64.1 	"></polygon>
										<rect
                        id="digestion-svg-5-facet-5"
                        x="45.3" y="47.5" class="st6"
                        width="61.5"
                        height="1.1"></rect>
									</g>
									<g
                      id="digestion-svg-5-bubles">
										<circle id="digestion-svg-5-buble-1" class="digestion-svg-5-buble st10" cx="62.6" cy="17.7" r="4.4"
                            data-svg-origin="62.59999704360962 17.70000123977661"
                            transform="matrix(0.96945,0,0,0.96945,1.6068140616298816,0.998913018261681)"
                            style="transform-origin: 0px 0px 0px;"></circle>
										<circle
                        id="digestion-svg-5-buble-2" class="digestion-svg-5-buble st10" cx="54.8" cy="6.9" r="3.3"
                        data-svg-origin="54.799999952316284 6.900000095367432"
                        transform="matrix(0.9972,0,0,0.9972,0.1250846172701643,0.061146275291546925)"
                        style="transform-origin: 0px 0px 0px;"></circle>
										<circle id="digestion-svg-5-buble-3"
                            class="digestion-svg-5-buble st10"
                            cx="79.4"
                            cy="22.1" r="3.3"
                            data-svg-origin="79.39999842643738 22.100001096725464"
                            transform="matrix(0.99494,0,0,0.99494,0.3511263211125464,0.18770586734483363)"
                            style="transform-origin: 0px 0px 0px;"></circle>
										<circle
                        id="digestion-svg-5-buble-4" class="digestion-svg-5-buble st10" cx="64.3" cy="1.9" r="1.7"
                        data-svg-origin="64.30000233650208 1.899999976158142"
                        transform="matrix(0.96299,0,0,0.96299,2.009359506448787,0.6253807394083662)"
                        style="transform-origin: 0px 0px 0px;"></circle>
										<circle id="digestion-svg-5-buble-5"
                            class="digestion-svg-5-buble st10"
                            cx="92.3"
                            cy="15.5" r="3.3"
                            data-svg-origin="92.29999995231628 15.499999761581421"
                            transform="matrix(0.90623,0,0,0.90623,7.7168301093342935,2.85982158725671)"
                            style="transform-origin: 0px 0px 0px;"></circle>
										<circle
                        id="digestion-svg-5-buble-6" class="digestion-svg-5-buble st10" cx="94.1" cy="25.4" r="1.7"
                        data-svg-origin="94.10000157356262 25.399998903274536"
                        transform="matrix(0.8333,0,0,0.8333,14.019291838353947,6.7345941057892995)"
                        style="transform-origin: 0px 0px 0px;"></circle>
									</g>
									<g id="digestion-svg-5-keyboard">
										<polygon id="digestion-svg-5-key-1" class="st6"
                             points="46.2,70.7 43.7,70.7 45.8,68.5 48.3,68.5 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-2" class="st6"
                        points="49.4,70.7 46.9,70.7 49,68.5 51.5,68.5 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-3" class="st6"
                        points="52.6,70.7 50.2,70.7 52.3,68.5 54.8,68.5 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-4" class="st6"
                        points="58.2,70.7 55.8,70.7 57.9,68.5 60.4,68.5 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-5" class="st6"
                        points="61.5,70.7 59,70.7 61.1,68.5 63.6,68.5 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-6" class="st6"
                        points="64.7,70.7 62.2,70.7 64.4,68.5 66.8,68.5 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-7" class="st6"
                        points="70.3,70.7 67.8,70.7 69.9,68.5 72.4,68.5 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-8" class="st6"
                        points="73.6,70.7 71.1,70.7 73.2,68.5 75.7,68.5 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-9" class="st6"
                        points="76.8,70.7 74.3,70.7 76.4,68.5 78.9,68.5 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-10" class="st6"
                        points="48.9,67.9 46.5,67.9 48.6,65.7 51.1,65.7 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-11" class="st6"
                        points="52.2,67.9 49.7,67.9 51.8,65.7 54.3,65.7 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-12" class="st6"
                        points="55.4,67.9 53,67.9 55.1,65.7 57.6,65.7 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-13" class="st6"
                        points="61,67.9 58.5,67.9 60.7,65.7 63.1,65.7 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-14" class="st6"
                        points="64.3,67.9 61.8,67.9 63.9,65.7 66.4,65.7 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-15" class="st6"
                        points="67.5,67.9 65,67.9 67.2,65.7 69.6,65.7 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-16" class="st6"
                        points="73.1,67.9 70.6,67.9 72.7,65.7 75.2,65.7 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-17" class="st6"
                        points="76.3,67.9 73.9,67.9 76,65.7 78.5,65.7 		"></polygon>
										<polygon
                        id="digestion-svg-5-key-18" class="st6"
                        points="79.6,67.9 77.1,67.9 79.2,65.7 81.7,65.7 		"></polygon>
									</g>
									<g
                      id="digestion-svg-5-dashboard">
										<g id="digestion-svg-5-dash-1">
											<path id="XMLID_35_" class="st11" d="M78.3,51.9c-2.5,0-4.6,2-4.6,4.6c0,2.5,2,4.6,4.6,4.6c2.5,0,4.6-2,4.6-4.6
 C82.9,53.9,80.9,51.9,78.3,51.9z"></path>
											<path id="XMLID_32_" class="st6" d="M78.3,51.9c-2.5,0-4.6,2-4.6,4.6c0,2.5,2,4.6,4.6,4.6c2.5,0,4.6-2,4.6-4.6
 C82.9,53.9,80.9,51.9,78.3,51.9z M78.3,60.2c-2.1,0-3.7-1.7-3.7-3.7c0-2.1,1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7
 C82.1,58.5,80.4,60.2,78.3,60.2z"></path>
										</g>
										<g id="digestion-svg-5-dash-2">
											<path id="XMLID_30_" class="st11" d="M54.7,51.9c-2.5,0-4.6,2-4.6,4.6c0,2.5,2,4.6,4.6,4.6s4.6-2,4.6-4.6
 C59.3,53.9,57.2,51.9,54.7,51.9z"></path>
											<path id="XMLID_27_" class="st6" d="M54.7,51.9c-2.5,0-4.6,2-4.6,4.6c0,2.5,2,4.6,4.6,4.6c2.5,0,4.6-2,4.6-4.6
 C59.3,53.9,57.2,51.9,54.7,51.9z M54.7,60.2c-2.1,0-3.7-1.7-3.7-3.7c0-2.1,1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7
 C58.4,58.5,56.8,60.2,54.7,60.2z"></path>
										</g>
										<g id="digestion-svg-5-dash-3">
											<path id="XMLID_25_" class="st11" d="M66.5,51.9c-2.5,0-4.6,2-4.6,4.6c0,2.5,2,4.6,4.6,4.6c2.5,0,4.6-2,4.6-4.6
 C71.1,53.9,69,51.9,66.5,51.9z"></path>
											<path id="XMLID_22_" class="st6" d="M66.5,51.9c-2.5,0-4.6,2-4.6,4.6c0,2.5,2,4.6,4.6,4.6c2.5,0,4.6-2,4.6-4.6
 C71.1,53.9,69,51.9,66.5,51.9z M66.5,60.2c-2.1,0-3.7-1.7-3.7-3.7c0-2.1,1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7
 C70.3,58.5,68.6,60.2,66.5,60.2z"></path>
										</g>
										<g id="digestion-svg-5-arrow-1" class="digestion-svg-5-arrow-left"
                       data-svg-origin="54.102745056152344 56.08950424194336"
                       transform="matrix(0.1824,0.98322,-0.98322,0.1824,99.3829130522243,-7.3364044623511475)"
                       style="transform-origin: 0px 0px 0px;">
											<rect id="XMLID_21_" x="55.6" y="55.3"
                            transform="matrix(-0.418 0.9085 -0.9085 -0.418 130.8815 30.1135)" class="st6" width="0.5"
                            height="3.4"></rect>
											<polygon
                          id="XMLID_20_" class="st6" points="57.1,56.9 57.9,57.9 56.6,58.1 		"></polygon>
										</g>
										<g
                        id="digestion-svg-5-arrow-2" class="digestion-svg-5-arrow"
                        data-svg-origin="65.97618103027344 56.95220947265625"
                        transform="matrix(0.1824,0.98322,-0.98322,0.1824,109.93885576516078,-18.305307476225522)"
                        style="transform-origin: 0px 0px 0px;">
											<rect id="XMLID_19_" x="67.2" y="54"
                            transform="matrix(0.6315 0.7753 -0.7753 0.6315 68.0416 -31.7837)" class="st6" width="0.5"
                            height="3.4"></rect>
											<polygon
                          id="XMLID_18_" class="st6" points="67.9,54.4 69.2,54.3 68.8,55.5 		"></polygon>
										</g>
										<g
                        id="digestion-svg-5-arrow-3" class="digestion-svg-5-arrow"
                        data-svg-origin="77.90843963623047 57.02632522583008"
                        transform="matrix(0.49402,0.86945,-0.86945,0.49402,89.00160409987538,-38.883363739867434)"
                        style="transform-origin: 0px 0px 0px;">
											<rect id="XMLID_17_" x="78.7" y="53.7"
                            transform="matrix(0.877 0.4806 -0.4806 0.877 36.3308 -31.1139)" class="st6" width="0.5"
                            height="3.4"></rect>
											<polygon
                          id="XMLID_16_" class="st6" points="78.9,54 80,53.4 80.1,54.7 		"></polygon>
										</g>
									</g>
									<g
                      id="digestion-svg-5-gaps">
										<rect id="digestion-svg-5-gap-1" x="43.7" y="92" class="st6" width="30.2" height="1.1"></rect>
										<rect
                        id="digestion-svg-5-gap-2" x="43.7" y="89.7" class="st6" width="30.2" height="1.1"></rect>
										<rect
                        id="digestion-svg-5-gap-3" x="43.7" y="87.5" class="st6" width="30.2" height="1.1"></rect>
									</g>
									<g
                      id="digestion-svg-5-sidepipe">
										<path id="digestion-svg-5-sidepipe-connection-1" class="st6" d="M92.7,74.3c-3.7,0-6.6,3-6.6,6.6s3,6.6,6.6,6.6s6.6-3,6.6-6.6S96.3,74.3,92.7,74.3z
 M92.7,86.4c-3,0-5.4-2.4-5.4-5.4c0-3,2.4-5.4,5.4-5.4c3,0,5.4,2.4,5.4,5.4C98.1,83.9,95.7,86.4,92.7,86.4z"></path>
										<path
                        id="digestion-svg-5-sidepipe-body-1" class="st5"
                        d="M92.7,75.5c-3,0-5.4,2.4-5.4,5.4c0,3,2.4,5.4,5.4,5.4h14.5V75.5H92.7z"></path>
										<path
                        id="digestion-svg-5-sidepipe-connection-2" class="st6" d="M108.1,74.3c-3.7,0-6.6,3-6.6,6.6s3,6.6,6.6,6.6s6.6-3,6.6-6.6S111.7,74.3,108.1,74.3z
 M108.1,86.4c-3,0-5.4-2.4-5.4-5.4c0-3,2.4-5.4,5.4-5.4s5.4,2.4,5.4,5.4C113.5,83.9,111.1,86.4,108.1,86.4z"></path>
										<path
                        id="digestion-svg-5-sidepipe-body-2" class="st5" d="M113.5,80.9c0-3-2.4-5.4-5.4-5.4c-3,0-5.4,2.4-5.4,5.4v15.2h10.8V81.5
 C113.5,81.3,113.5,81.1,113.5,80.9z"></path>
										<rect id="digestion-svg-5-sidepipe-connection-3" x="101.5" y="95.4"
                          class="st6" width="13.2" height="1.1"></rect>
									</g>
									<g
                      id="digestion-svg-5-clouds">
										<path id="digestion-svg-5-cloud-1" class="digestion-svg-5-cloud st10" d="M42.8,32.9c0.2-2.7-1.6-5.2-4.3-5.7c-0.1,0-0.3-0.1-0.4-0.1c-0.3-2.7-2.4-5-5.2-5.6
 c-3.5-0.7-6.9,1.6-7.6,5.1c-0.1,0.5-0.1,1.1-0.1,1.6c-1.8,0.9-3.2,2.6-3.8,4.6H42.8z"
                          data-svg-origin="21.39999771118164 21.374479293823242"
                          transform="matrix(0.99774,0,0,0.99774,1.96934632147991,0.04817693430433323)"></path>
										<path
                        id="digestion-svg-5-cloud-2" class="digestion-svg-5-cloud st10" d="M135.3,52.9c0.2-2-1.2-3.9-3.2-4.3c-0.1,0-0.2,0-0.3-0.1c-0.3-2-1.8-3.7-3.9-4.2
 c-2.6-0.5-5.2,1.2-5.7,3.8c-0.1,0.4-0.1,0.8-0.1,1.2c-1.4,0.7-2.4,1.9-2.9,3.5H135.3z"
                        data-svg-origin="119.20000457763672 44.212764739990234"
                        transform="matrix(0.95628,0,0,0.95628,5.6810293110533525,1.9328797396982011)"></path>
										<path
                        id="digestion-svg-5-cloud-3" class="digestion-svg-5-cloud st10" d="M133.7,25.4c0.3-3.6-2.2-7-5.8-7.8c-0.2,0-0.4-0.1-0.6-0.1c-0.5-3.7-3.2-6.8-7-7.5
 c-4.8-0.9-9.4,2.1-10.4,6.9c-0.1,0.7-0.2,1.5-0.2,2.2c-2.5,1.2-4.4,3.5-5.2,6.3H133.7z"
                        data-svg-origin="104.5 9.840842247009277"
                        transform="matrix(0.87294,0,0,0.87294,10.830471523484242,1.2503493126520304)"></path>
									</g>
									<g
                      id="digestion-svg-5-valves">
										<path id="digestion-svg-5-valve-axis-1" class="st12"
                          d="M93.2,65.4c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h2.6v-1.9H93.2z"></path>
										<path
                        id="digestion-svg-5-valve-1" class="digestion-svg-5-valve st6" d="M95.8,60.9c-3,0-5.5,2.4-5.5,5.5c0,3,2.4,5.5,5.5,5.5c3,0,5.5-2.4,5.5-5.5
 C101.2,63.3,98.8,60.9,95.8,60.9z M91.3,66.4c0-2.3,1.8-4.3,4.1-4.5v2.6c-0.9,0.2-1.5,0.9-1.5,1.9c0,0.4,0.1,0.7,0.3,1l-1.8,1.8
 C91.7,68.4,91.3,67.4,91.3,66.4z M92.9,69.8l1.8-1.8c0.3,0.2,0.7,0.3,1,0.3c0.4,0,0.7-0.1,1-0.3l1.8,1.8c-0.8,0.6-1.7,1-2.8,1
 C94.7,70.8,93.7,70.4,92.9,69.8z M99.2,69.2l-1.8-1.8c0.2-0.3,0.3-0.7,0.3-1.1c0-0.9-0.6-1.6-1.4-1.8v-2.6c2.3,0.2,4,2.1,4,4.4
 C100.3,67.5,99.9,68.5,99.2,69.2z" data-svg-origin="95.80000305175781 66.4000015258789"
                        transform="matrix(0.97752,-0.21083,0.21083,0.97752,-11.846036499269104,21.690595845057388)"
                        style="transform-origin: 0px 0px 0px;"></path>
										<path id="digestion-svg-5-valve-axis-2"
                          class="st12"
                          d="M97.7,54.8c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h1.4v-1H97.7z"></path>
										<path
                        id="digestion-svg-5-valve-2" class="digestion-svg-5-valve st6" d="M99.1,52.4c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9s2.9-1.3,2.9-2.9S100.7,52.4,99.1,52.4
 z M96.7,55.3c0-1.3,1-2.3,2.2-2.4v1.4c-0.5,0.1-0.8,0.5-0.8,1c0,0.2,0.1,0.4,0.1,0.5l-1,1C96.9,56.4,96.7,55.8,96.7,55.3z
 M97.6,57.1l1-1c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.1l1,1c-0.4,0.3-0.9,0.5-1.5,0.5C98.5,57.7,98,57.5,97.6,57.1z
 M100.9,56.8l-1-1c0.1-0.2,0.2-0.4,0.2-0.6c0-0.5-0.3-0.9-0.8-1v-1.4c1.2,0.1,2.1,1.1,2.1,2.4C101.5,55.9,101.3,56.4,100.9,56.8z"
                        data-svg-origin="99.0999984741211 55.30000305175781"
                        transform="matrix(-0.55809,0.82977,-0.82977,-0.55809,200.2941931136534,3.9323575309470016)"
                        style="transform-origin: 0px 0px 0px;"></path>
									</g>
								</svg>
							</div>
							<div
                  class="rounded-dashed-border-block">
								<p class="connected-paragraph">Функції підшлункової залози є вкрай важливими для травної системи організму. Вона виробляє велику кількість субстанцій та ферментів, що стимулюють поглинання поживних речовин, допомагає регулювати вуглеводневий обмін, понижуючи або підвищуючи рівень глюкози в крові<sup>2</sup>.
								</p>
							</div>
							<p
                  class="half-connected-paragraph">Підшлункова залоза регулює активність своєї секреції та впливає на роботу шлунку шляхом стимуляції або придушення вироблення шлункового соку. Також вона «аналізує» якість та кількість їжі, хімічний склад харчової грудки та, за необхідності, швидко змінює склад шлункового соку. І для цього їй потрібно лише близько 40 хвилин!<sup>1</sup> На жаль, за таку кропітку роботу підшлункова залоза може розплачуватися зношуванням ацинозних клітин та панкреатичних острівців<sup>3</sup>.
							</p>
						</section>
						<div
                id="part_3" class="title-block">
							<h1>Порушення роботи
								<br>підшлункової залози
								</h1>
							</div>
							<section class="main-column">
								<p>Швидкі перекуси, недоліки в харчуванні, переїдання та захоплення дієтами… Ці «безневинні» звички можуть призвести до порушення роботи підшлункової залози: зниження рівня вироблення ферментів та порушення функції травлення, що в свою чергу викликає важкість в животі, здуття живота, метеоризм, нудоту, порушення стільця<sup>4</sup>.
								</p>
								<div
                    class="inline-svg-center-block">
									<svg id="digestion-svg-6" class="inline-svg-illustration"
                       xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                       viewBox="0 0 139.6 111.6">
										<g id="digestion-svg-6-burst-1" class="digestion-svg-6-burst">
											<polygon id="digestion-svg-6-burst-pressure-1" class="st0"
                               points="66.9,41.7 61.8,41.7 57.8,17.7 69.9,17.7 	"></polygon>
											<circle
                          id="digestion-svg-6-burst-buble-1" class="digestion-svg-6-burst-buble st1" cx="58" cy="17.7"
                          r="6.8"
                          data-svg-origin="58.000000953674316 17.700000762939453"
                          transform="matrix(0.99774,0,0,0.99774,-1.229271134898135,-1.320105182126352)"
                          style="transform-origin: 0px 0px 0px;"></circle>
											<circle id="digestion-svg-6-burst-buble-2"
                              class="digestion-svg-6-burst-buble st1"
                              cx="66.3" cy="13.9" r="7.9"
                              data-svg-origin="66.30000162124634 13.899999618530273"
                              transform="matrix(0.95628,0,0,0.95628,1.3184825940202405,-0.9723242086492441)"
                              style="transform-origin: 0px 0px 0px;"></circle>
											<circle
                          id="digestion-svg-6-burst-buble-3" class="digestion-svg-6-burst-buble st1" cx="66.8" cy="21.8"
                          r="5.5" data-svg-origin="66.80000305175781 21.799999237060547"
                          transform="matrix(0.87294,0,0,0.87294,7.387417621830332,1.669845647120022)"
                          style="transform-origin: 0px 0px 0px;"></circle>
										</g>
										<g id="digestion-svg-6-burst-2"
                       class="digestion-svg-6-burst">
											<polygon id="digestion-svg-6-burst-pressure-2" class="st0"
                               points="100.8,46.3 95.9,45.3 96.8,20.9 108.6,23.3 	"></polygon>
											<circle
                          id="digestion-svg-6-burst-buble-4" class="digestion-svg-6-burst-buble st1" cx="106.6" cy="21"
                          r="6.8" data-svg-origin="106.59999561309814 21"
                          transform="matrix(0.82994,0,0,0.82994,16.768226538193254,2.2112267326763506)"
                          style="transform-origin: 0px 0px 0px;"></circle>
											<circle id="digestion-svg-6-burst-buble-5"
                              class="digestion-svg-6-burst-buble st1"
                              cx="105" cy="26.1" r="5.5"
                              data-svg-origin="105 26.100000381469727"
                              transform="matrix(0.9248,0,0,0.9248,6.7956642465276875,0.8626365699650425)"
                              style="transform-origin: 0px 0px 0px;"></circle>
											<circle
                          id="digestion-svg-6-burst-buble-6" class="digestion-svg-6-burst-buble st1" cx="96.8" cy="21.5"
                          r="6.8" data-svg-origin="96.80000019073486 21.5"
                          transform="matrix(0.98622,0,0,0.98622,-0.026473259193417142,-1.0638138223307694)"
                          style="transform-origin: 0px 0px 0px;"></circle>
										</g>
										<g id="digestion-svg-6-burst-3"
                       class="digestion-svg-6-burst">
											<polygon id="digestion-svg-6-burst-pressure-3" class="st0"
                               points="82.9,50 79.1,50 75.8,32.1 84.8,31.9 	"></polygon>
											<circle
                          id="digestion-svg-6-burst-buble-7" class="digestion-svg-6-burst-buble st1" cx="76.7" cy="31.9"
                          r="3.9" data-svg-origin="76.69999551773071 31.90000009536743"
                          transform="matrix(0.99774,0,0,0.99774,-0.6071223272053059,-0.7080991232539153)"
                          style="transform-origin: 0px 0px 0px;"></circle>
											<circle id="digestion-svg-6-burst-buble-8"
                              class="digestion-svg-6-burst-buble st1"
                              cx="80.9" cy="29.5" r="3.9"
                              data-svg-origin="80.90000009536743 29.500000476837158"
                              transform="matrix(0.95628,0,0,0.95628,2.756760734195635,0.5096717211602035)"
                              style="transform-origin: 0px 0px 0px;"></circle>
											<circle
                          id="digestion-svg-6-burst-buble-9" class="digestion-svg-6-burst-buble st1" cx="79.9" cy="33.8"
                          r="2.9" data-svg-origin="79.90000009536743 33.799999713897705"
                          transform="matrix(0.87294,0,0,0.87294,9.571865816444936,3.7145314193056413)"
                          style="transform-origin: 0px 0px 0px;"></circle>
											<circle id="digestion-svg-6-burst-buble-10"
                              class="digestion-svg-6-burst-buble st1"
                              cx="83.8" cy="33.2" r="3.4"
                              data-svg-origin="83.80000162124634 33.20000123977661"
                              transform="matrix(0.82994,0,0,0.82994,13.570895656394743,4.965939658014978)"
                              style="transform-origin: 0px 0px 0px;"></circle>
										</g>
										<g
                        id="digestion-svg-6-exclamation-mark-quote" class="digestion-svg-6-exclamation-mark-quote"
                        data-svg-origin="24.850939750671387 33.75000286102295"
                        transform="matrix(1.19872,0,0,1.19872,-4.938525402313314,-6.706999740434597)"
                        style="transform-origin: 0px 0px 0px;">
											<path id="digestion-svg-6-exclamation-mark-buble" class="st0" d="M34.5,6.3H15.3c-4.7,0-8.6,3.8-8.6,8.6v30.2c0,4.7,3.8,8.6,8.6,8.6h6.3
 c1.5,2.8,5.3,7.4,12.8,7.5c-2.9-2.2-4.1-5.2-4.6-7.5h4.6c4.7,0,8.6-3.8,8.6-8.6V14.9C43.1,10.1,39.2,6.3,34.5,6.3z"></path>
											<path
                          id="digestion-svg-6-exclamation-mark" class="st2" d="M24.6,44.1c-2.4,0-4.4-2-4.4-4.4s2-4.4,4.4-4.4s4.4,2,4.4,4.4S27,44.1,24.6,44.1z M27.6,32.1
 c0,0.6-0.1,0.8-0.8,0.8h-4.7c-0.6,0-0.7-0.1-0.8-0.8l-0.9-15.2c0-0.5,0.1-0.5,0.8-0.5h6.5c0.6,0,0.8,0.1,0.8,0.6v0.2L27.6,32.1z"></path>
										</g>
										<g
                        id="digestion-svg-6-trees">
											<path id="digestion-svg-6-tree-1" class="digestion-svg-6-tree st3" d="M124,82.3c-0.2,0-0.4-0.1-0.5-0.1c-0.4-3.3-2.9-6.2-6.4-6.9c-4.3-0.9-8.6,2-9.4,6.3
 c-0.1,0.7-0.2,1.3-0.1,2c-2.5,1.2-4.3,3.6-4.9,6.5c-1,4.8,2,9.6,6.7,10.8c0.8,2,2.5,3.7,4.6,4.5v5.9h5.6v-5.9
 c2.5-0.9,4.5-3.1,5-5.9c0.3-1.5,0.2-2.9-0.3-4.2c2.4-0.6,4.4-2.5,4.9-5.1C129.9,86.5,127.6,83,124,82.3z"
                            data-svg-origin="115.91653823852539 111.30001068115234"
                            transform="matrix(0.99999,-0.00098,0.00098,0.99999,2.9912097499192853,-1.1923074432134513)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="digestion-svg-6-tree-2" class="digestion-svg-6-tree st4" d="M123.3,89.8c0.1,0,0.3,0,0.4-0.1c0.3-2.5,2.2-4.6,4.7-5.1c3.2-0.6,6.3,1.4,7,4.7
 c0.1,0.5,0.1,1,0.1,1.5c1.8,0.9,3.2,2.6,3.6,4.8c0.7,3.6-1.5,7.1-5,8c-0.6,1.5-1.8,2.7-3.4,3.3v4.4h-4.2v-4.4
 c-1.8-0.7-3.3-2.3-3.7-4.4c-0.2-1.1-0.1-2.2,0.2-3.1c-1.8-0.4-3.2-1.9-3.6-3.8C119,92.9,120.7,90.4,123.3,89.8z"
                          data-svg-origin="129.28575134277344 111.30001068115234"
                          transform="matrix(0.9999,-0.01375,0.01375,0.9999,0.7793976581538846,0.8202912426069524)"
                          style="transform-origin: 0px 0px 0px;"></path>
										</g>
										<g id="digestion-svg-6-mountains">
											<path id="digestion-svg-6-mountain-1" class="st3" d="M57.9,102.4L40.8,78.4c-2.2-3.2-7-3.2-9.2,0l-17.1,24.1c-2.7,3.7,0,8.9,4.6,8.9h34.2
 C57.9,111.3,60.5,106.2,57.9,102.4z"></path>
											<path id="digestion-svg-6-mountain-2" class="st1" d="M64,102L52.5,85.7c-2.4-3.4-7.4-3.4-9.7,0L31.2,102c-2.8,4,0,9.4,4.9,9.4h23.1
 C64,111.4,66.9,105.9,64,102z"></path>
											<path id="digestion-svg-6-mountain-3" class="st4" d="M33.8,104.7L20.9,86.5c-1.7-2.4-5.3-2.4-7,0L1,104.7c-2,2.8,0,6.7,3.5,6.7h25.8
 C33.8,111.4,35.9,107.5,33.8,104.7z"></path>
										</g>
										<g id="digestion-svg-6-pipes">
											<g id="digestion-svg-6-pipe-1">
												<path id="digestion-svg-6-pipe-body-1" class="st5" d="M69.6,40.6H59.5v38.1h0c0,0,0,0,0,0.1c0,2.8,2.3,5,5,5c2.8,0,5-2.3,5-5c0,0,0,0,0-0.1h0V40.6z
 "></path>
												<rect id="digestion-svg-6-pipe-top-1" x="57.8" y="39" class="st2" width="13.6"
                              height="4.1"></rect>
											</g>
											<g
                          id="digestion-svg-6-pipe-2">
												<path id="digestion-svg-6-pipe-body-2" class="st5" d="M82.6,48.5l-3-6l-4.5,3.3v33h0c0,0,0,0,0,0.1c0,2.8,2.3,5,5,5c2.8,0,5-2.3,5-5c0,0,0,0,0-0.1
 h0V45.4L82.6,48.5z"></path>
											</g>
											<g id="digestion-svg-6-pipe-3">
												<path id="digestion-svg-6-pipe-body-3" class="st5" d="M103.7,44.8l-10-1.5l-5.6,37.7l0,0c0,0,0,0,0,0.1c-0.4,2.8,1.5,5.3,4.3,5.7
 c2.8,0.4,5.3-1.5,5.7-4.3c0,0,0,0,0-0.1l0,0L103.7,44.8z"></path>
												<rect id="digestion-svg-6-pipe-top-2" x="91.9" y="42.4"
                              transform="matrix(0.9892 0.1464 -0.1464 0.9892 7.5757 -13.9726)"
                              class="st2" width="13.6" height="4.1"></rect>
											</g>
										</g>
										<g
                        id="digestion-svg-6-body">
											<rect id="digestion-svg-6-facet-1" x="81.5" y="59.6" class="st6" width="29.3"
                            height="51.7"></rect>
											<polygon
                          id="digestion-svg-6-facet-2" class="st7"
                          points="81.5,111.2 38.3,111.2 42.2,103.1 38.7,97.8 38.3,85.7 81.5,85.7 	"></polygon>
											<polygon
                          id="digestion-svg-6-facet-3" class="st7"
                          points="90.4,76.7 63.5,77.8 47.1,76.7 47.1,59.6 90.4,59.6 	"></polygon>
											<polygon
                          id="digestion-svg-6-facet-4" class="st8"
                          points="81.5,85.7 58.6,87.8 38.3,85.7 47.1,76.7 63.5,77.8 90.4,76.7 	"></polygon>
											<rect
                          id="digestion-svg-6-facet-5" x="45.9" y="59" class="st2" width="66" height="1.2"></rect>
										</g>
										<g
                        id="digestion-svg-6-keyboard">
											<polygon id="digestion-svg-6-key-1" class="st2"
                               points="46,84.1 43.3,83.9 45.8,81.8 48.5,82 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-2" class="st2"
                          points="49.5,84.4 46.8,84.2 49.3,82 51.9,82.2 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-3" class="st2"
                          points="53,84.7 50.3,84.4 52.8,82.3 55.4,82.5 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-4" class="st2"
                          points="59.9,85.2 57.2,85.5 59.2,82.9 61.9,82.6 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-5" class="st2"
                          points="63.3,84.8 60.7,85.1 62.7,82.5 65.3,82.3 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-6" class="st2"
                          points="66.8,84.4 64.1,84.7 66.2,82.2 68.8,81.9 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-7" class="st2"
                          points="72.9,84 70.3,84.1 72.5,81.7 75.1,81.6 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-8" class="st2"
                          points="76.4,83.9 73.8,84 76,81.6 78.6,81.5 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-9"
                          class="st2"
                          points="79.9,83.8 77.3,83.9 79.4,81.5 82.1,81.3 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-10" class="st2"
                          points="49.2,81.4 46.6,81.1 49,79 51.7,79.2 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-11"
                          class="st2"
                          points="52.7,81.6 50,81.4 52.5,79.3 55.2,79.5 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-12" class="st2"
                          points="56.2,81.9 53.5,81.7 56,79.6 58.6,79.8 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-13" class="st2"
                          points="62.5,81.9 59.9,82.2 61.9,79.6 64.5,79.3 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-14" class="st2"
                          points="66,81.5 63.3,81.8 65.3,79.2 68,78.9 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-15"
                          class="st2"
                          points="69.4,81.1 66.8,81.4 68.8,78.8 71.5,78.6 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-16" class="st2"
                          points="75.8,80.9 73.2,81 75.4,78.6 78,78.5 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-17"
                          class="st2"
                          points="79.3,80.8 76.6,80.9 78.8,78.5 81.5,78.4 		"></polygon>
											<polygon
                          id="digestion-svg-6-key-18" class="st2"
                          points="82.8,80.6 80.1,80.7 82.3,78.3 85,78.2 		"></polygon>
										</g>
										<g
                        id="digestion-svg-6-dashboard">
											<g id="digestion-svg-6-dash-1">
												<path id="XMLID_38_" class="st5"
                              d="M56,63.7c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9S58.8,63.7,56,63.7z"></path>
												<path
                            id="XMLID_35_" class="st2" d="M56,63.7c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9S58.8,63.7,56,63.7z M56,72.6
 c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C60.1,70.8,58.3,72.6,56,72.6z"></path>
											</g>
											<g id="digestion-svg-6-dash-2">
												<path id="XMLID_33_" class="st5"
                              d="M68.7,63.7c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9S71.5,63.7,68.7,63.7z"></path>
												<path
                            id="XMLID_30_" class="st2" d="M68.7,63.7c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9S71.5,63.7,68.7,63.7z
 M68.7,72.6c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C72.8,70.8,71,72.6,68.7,72.6z"></path>
											</g>
											<g
                          id="digestion-svg-6-dash-3">
												<path id="XMLID_28_" class="st5"
                              d="M81.5,63.7c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9S84.2,63.7,81.5,63.7z"></path>
												<path
                            id="XMLID_25_" class="st2" d="M81.5,63.7c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9S84.2,63.7,81.5,63.7z
 M81.5,72.6c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C85.5,70.8,83.7,72.6,81.5,72.6z"></path>
											</g>
											<g
                          id="digestion-svg-6-arrow-1" class="digestion-svg-6-arrow"
                          data-svg-origin="68.15386962890625 67.81898498535156"
                          transform="matrix(0.64703,0.76245,-0.76245,0.64703,75.76504719021906,-28.02690509850889)"
                          style="transform-origin: 0px 0px 0px;">
												<rect id="XMLID_24_" x="69.1" y="67.6"
                              transform="matrix(-0.8373 0.5468 -0.5468 -0.8373 165.4168 89.7346)" class="st2"
                              width="0.5" height="3.7"></rect>
												<polygon
                            id="XMLID_23_" class="st2" points="70.7,70.1 70.7,71.5 69.4,70.9 	"></polygon>
											</g>
											<g
                          id="digestion-svg-6-arrow-2" class="digestion-svg-6-arrow"
                          data-svg-origin="80.82466125488281 67.82795715332031"
                          transform="matrix(0.79072,0.61217,-0.61217,0.79072,58.43704849206628,-35.28388713889176)"
                          style="transform-origin: 0px 0px 0px;">
												<rect id="XMLID_22_" x="81.8" y="67.6"
                              transform="matrix(-0.8373 0.5468 -0.5468 -0.8373 188.7213 82.7992)" class="st2"
                              width="0.5" height="3.7"></rect>
												<polygon
                            id="XMLID_21_" class="st2" points="83.3,70.1 83.4,71.5 82.1,70.9 	"></polygon>
											</g>
											<g
                          id="digestion-svg-6-arrow-3" class="digestion-svg-6-arrow"
                          data-svg-origin="55.375789642333984 67.84192657470703"
                          transform="matrix(0.93131,0.3642,-0.3642,0.93131,28.511489218406126,-15.508624968836529)"
                          style="transform-origin: 0px 0px 0px;">
												<rect id="XMLID_20_" x="56.4" y="67.6"
                              transform="matrix(-0.8373 0.5468 -0.5468 -0.8373 142.005 96.7019)" class="st2" width="0.5"
                              height="3.7"></rect>
												<polygon
                            id="XMLID_19_" class="st2" points="57.9,70.1 58,71.5 56.7,70.9 	"></polygon>
											</g>
										</g>
										<g
                        id="digestion-svg-6-gaps">
											<polygon id="digestion-svg-6-gap-1" class="st2"
                               points="76.7,107.9 50.2,107.9 45.9,106.7 45.9,105.5 50,106.7 76.7,106.7 	"></polygon>
											<polygon
                          id="digestion-svg-6-gap-2" class="st2"
                          points="76.7,105.5 54.4,105.5 45.2,104.3 45.6,103.1 54.5,104.3 76.7,104.3 	"></polygon>
											<polygon
                          id="digestion-svg-6-gap-3" class="st2"
                          points="76.7,103.1 55.4,103.1 44,102.2 44.2,100.9 55.4,101.9 76.7,101.9 	"></polygon>
										</g>
										<g
                        id="digestion-svg-6-valves">
											<path id="digestion-svg-6-valve-axis-1" class="st9"
                            d="M97.4,78.2c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h2.8v-2H97.4z"></path>
											<path
                          id="digestion-svg-6-valve-1" class="digestion-svg-6-valve st2" d="M101.2,73.3c-3.2-0.1-6.9,2.6-6.9,5.9c0,3.2,2.6,5.9,5.9,5.9s5.6-2.8,4.9-5.9
 C104.3,76.1,105.5,73.5,101.2,73.3z M95.4,79.2c0-2.5,2.9-4.6,5.4-4.8l-1,2.8c-0.9,0.2-1.6,1-1.6,2c0,0.4,0.1,0.7,0.3,1l-2,2
 C95.8,81.4,95.4,80.4,95.4,79.2z M97.1,82.9l2-2c0.3,0.2,0.7,0.3,1.1,0.3c0.4,0,0.7-0.1,1-0.3l2,2c-0.8,0.7-1.9,1.1-3,1.1
 C99,84,98,83.6,97.1,82.9z M103.9,82.3l-2-2c0.2-0.3,0.3-0.7,0.3-1.1c0-1-0.7-1.8-1.5-2l1-2.8c2.4,0.3,2.3,2.3,2.3,4.8
 C104,80.4,104.6,81.5,103.9,82.3z" data-svg-origin="99.76293563842773 79.19865798950195"
                          transform="matrix(0.97752,-0.21083,0.21083,0.97752,-14.45536550960631,22.81381572323167)"
                          style="transform-origin: 0px 0px 0px;"></path>
											<path id="digestion-svg-6-valve-axis-2"
                            class="st10"
                            d="M102.3,66.8c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h1.5v-1.1H102.3z"></path>
											<path
                          id="digestion-svg-6-valve-2" class="digestion-svg-6-valve st2" d="M103.8,64.2c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1s3.1-1.4,3.1-3.1
 C106.9,65.6,105.5,64.2,103.8,64.2z M101.2,67.3c0-1.3,1-2.4,2.3-2.6v1.5c-0.5,0.1-0.9,0.5-0.9,1.1c0,0.2,0.1,0.4,0.2,0.6l-1.1,1.1
 C101.4,68.5,101.2,67.9,101.2,67.3z M102.1,69.3l1.1-1.1c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l1.1,1.1
 c-0.4,0.4-1,0.6-1.6,0.6C103.2,69.9,102.6,69.6,102.1,69.3z M105.7,69l-1.1-1.1c0.1-0.2,0.2-0.4,0.2-0.6c0-0.5-0.4-0.9-0.8-1.1v-1.5
 c1.3,0.1,2.3,1.2,2.3,2.6C106.4,67.9,106.1,68.5,105.7,69z" data-svg-origin="103.80000305175781 67.29999542236328"
                          transform="matrix(-0.55809,0.82977,-0.82977,-0.55809,217.57454843071656,18.729604841204633)"
                          style="transform-origin: 0px 0px 0px;"></path>
										</g>
										<g id="digestion-svg-6-sidepipe">
											<path id="digestion-svg-6-sidepipe-connection-1" class="st2" d="M96.9,87.8c-3.9,0-7.1,3.2-7.1,7.1c0,3.9,3.2,7.1,7.1,7.1c3.9,0,7.1-3.2,7.1-7.1
 C104,91,100.8,87.8,96.9,87.8z M96.9,100.7c-3.2,0-5.8-2.6-5.8-5.8c0-3.2,2.6-5.8,5.8-5.8c3.2,0,5.8,2.6,5.8,5.8
 C102.7,98.1,100.1,100.7,96.9,100.7z"></path>
											<g id="digestion-svg-6-sidepipe-part-1">
												<path id="XMLID_5_" class="st2" d="M101.9,88.7c-3.2,0-5.8,2.8-5.8,6.4s2.6,6.4,5.8,6.4h5.1l-3.1-3.2l2.3-1.3l0.7-1.9l-2.9-3.8
 l3.8-2.5H101.9z"></path>
												<path id="XMLID_4_" class="st11" d="M96.9,89.1c-3.2,0-5.8,2.7-5.8,6c0,3.3,2.6,6,5.8,6h5.1l-3.1-3l2.3-1.2l0.7-1.8l-2.9-3.6
 l3.8-2.4H96.9z"></path>
											</g>
											<g id="digestion-svg-6-sidepipe-part-2">
												<polygon id="XMLID_3_" class="st2" points="116.4,104.2 115.1,101.9 113.3,101.2 109.5,104.2 107.1,104.6 107.1,110.4
 119.5,110.4 119.5,101 		"></polygon>
												<rect id="XMLID_2_" x="106.2" y="110.4" class="st2" width="14.2"
                              height="1.2"></rect>
												<polygon id="XMLID_1_" class="st5" points="119.1,103.5 116.2,106.7 115,104.4 113.3,103.8 109.8,106.7 107.5,105.7 107.5,110.4
 119.1,110.4 		"></polygon>
											</g>
										</g>
										<g id="digestion-svg-6-bubles-from-sidepipe">
											<circle id="digestion-svg-6-buble-from-sidepipe-1" class="digestion-svg-6-buble-from-sidepipe st0"
                              cx="119.6" cy="90.7" r="4.2" data-svg-origin="119.60000133514404 90.69999980926514"
                              transform="matrix(1.19872,0,0,1.19872,-20.786729550606207,-21.005327738902327)"
                              style="transform-origin: 0px 0px 0px;"></circle>
											<circle
                          id="digestion-svg-6-buble-from-sidepipe-2" class="digestion-svg-6-buble-from-sidepipe st0"
                          cx="119.6" cy="78.1" r="2.7" data-svg-origin="119.60000157356262 78.10000157356262"
                          transform="matrix(1.15098,0,0,1.15098,-15.793241745087755,-14.056891101337712)"
                          style="transform-origin: 0px 0px 0px;"></circle>
											<circle
                          id="digestion-svg-6-buble-from-sidepipe-3"
                          class="digestion-svg-6-buble-from-sidepipe st0"
                          cx="128.1" cy="75" r="1.3"
                          data-svg-origin="128.1000030040741 74.99999690055847"
                          transform="matrix(1.08543,0,0,1.08543,-9.66261220373624,-7.689080860203295)"
                          style="transform-origin: 0px 0px 0px;"></circle>
											<circle
                          id="digestion-svg-6-buble-from-sidepipe-4" class="digestion-svg-6-buble-from-sidepipe st0"
                          cx="106.1" cy="94.7" r="2.9" data-svg-origin="106.09999704360962 94.69999551773071"
                          transform="matrix(1.01384,0,0,1.01384,-1.2610579089363747,-1.5185296536474815)"
                          style="transform-origin: 0px 0px 0px;"></circle>
											<circle
                          id="digestion-svg-6-buble-from-sidepipe-5"
                          class="digestion-svg-6-buble-from-sidepipe st0"
                          cx="111.1" cy="90.7" r="1.8"
                          data-svg-origin="111.09999537467957 90.69999384880066"
                          transform="matrix(1.17051,0,0,1.17051,-16.3862036125782,-18.0231186723976)"
                          style="transform-origin: 0px 0px 0px;"></circle>
											<circle
                          id="digestion-svg-6-buble-from-sidepipe-6" class="digestion-svg-6-buble-from-sidepipe st0"
                          cx="124.7" cy="83.9" r="1.2" data-svg-origin="124.70000004768372 83.90000462532043"
                          transform="matrix(1.19221,0,0,1.19221,-21.08626722486059,-19.010318369762732)"
                          style="transform-origin: 0px 0px 0px;"></circle>
										</g>
										<g id="digestion-svg-6-bubles">
											<circle id="digestion-svg-6-buble-1" class="st1" cx="111.1" cy="35.4" r="3"></circle>
											<circle
                          id="digestion-svg-6-buble-2" class="st1" cx="47.1" cy="2.4" r="2.2"></circle>
											<circle
                          id="digestion-svg-6-buble-3" class="st1" cx="112.8" cy="13.1" r="1.1"></circle>
											<circle
                          id="digestion-svg-6-buble-4" class="st1" cx="92.2" cy="32.3" r="1.1"></circle>
											<circle
                          id="digestion-svg-6-buble-5" class="st1" cx="57.2" cy="33.7"
                          r="1.1"></circle>
										</g>
									</svg>
								</div>
								<h2>
                  ТОП-3 факторів ризику виникнення порушення роботи підшлункової залози - куріння, ожиріння та зловживання алкоголем:
								</h2>
                <p>Алкоголь – перший фактор ризику. Вчені вважають, що ризик ймовірності порушення роботи підшлункової залози вищий у поціновувачів міцних спиртних напоїв, ніж у тих, хто не п’є алкоголь взагалі<sup>4</sup></p>

                <div
                    class="inline-svg-center-block inline-svg-bigger  dotted-path-connection dotted-path-connection-7">
									<svg id="digestion-svg-9" class="inline-svg-illustration"
                       xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                       viewBox="0 0 162.1 137.3">
										<path id="digestion-svg-9-ground-shadow" class="st0" d="M25.3,69.9l-9.8,9.8c-2.2,2.2-3.4,5.1-3.4,8.1l-3.3,24.5c0,6.4,1.2,7.9,3.4,10l11.4,11.5
 c2.2,2.2,5.1,3.4,8.1,3.4h58.4c3,0,6-1.2,8.1-3.4l29.8-29.8c2.2-2.2,5.1-3.4,8.1-3.4h6.4c3,0,6-1.2,8.1-3.4l8.9-8.9
 c3.4-3.4,2.4-7.1,2.4-15.5c0-4.9-128.5-6.4-128.5-6.4C30.4,66.5,27.5,67.7,25.3,69.9z"></path>
										<path
                        id="digestion-svg-9-ground" class="st1" d="M22,65.4l-9.8,9.8c-2.2,2.2-3.4,5.1-3.4,8.1v29.9c0,3,1.2,6,3.4,8.1l8.1,8.1
 c2.2,2.2,5.1,3.4,8.1,3.4h58.4c3,0,6-1.2,8.1-3.4l29.8-29.8c2.2-2.2,5.1-3.4,8.1-3.4h6.4c3,0,6-1.2,8.1-3.4l11.3-11.3
 c4.5-4.5,4.5-11.8,0-16.2l0,0c-2.2-2.2-5.1-3.4-8.1-3.4l-120.4,0C27,62.1,24.1,63.3,22,65.4z"></path>
										<path
                        id="digestion-svg-9-road" class="st2" d="M82.1,121.4h-15c-3.3,0-6.3-1.3-8.7-3.6l-5.7-5.7c-2.3-2.3-3.6-5.4-3.6-8.7v-9h5v9
 c0,1.9,0.8,3.8,2.1,5.1l5.7,5.7c1.4,1.4,3.2,2.1,5.1,2.1h15c1.9,0,3.8-0.8,5.1-2.1L99.5,102c1.4-1.4,2.1-3.2,2.1-5.1V86.3
 c0-3.3,1.3-6.3,3.6-8.7l3.6-3.6c2.3-2.3,5.4-3.6,8.7-3.6h12.9v5h-12.9c-1.9,0-3.8,0.8-5.1,2.1l-3.6,3.6c-1.4,1.4-2.1,3.2-2.1,5.1
 v10.6c0,3.3-1.3,6.3-3.6,8.7l-12.2,12.2C88.5,120.1,85.4,121.4,82.1,121.4z"></path>
										<g id="digestion-svg-9-bottle">
											<path id="digestion-svg-9-bottle-body" class="st3" d="M58.2,23.3V2H45.2v21.3c0,1.3-0.5,2.5-1.5,3.6l-7.9,8.9c-1,1.1-1.5,2.3-1.5,3.6v55.1h34.8V39.4
 c0-1.3-0.5-2.5-1.5-3.6l-7.9-8.9C58.7,25.7,58.2,24.5,58.2,23.3z" data-svg-origin="34.29999923706055 2"
                            transform="matrix(1,0,0,1,0,0)" style="fill: rgb(100, 142, 255);"></path>
											<rect
                          id="digestion-svg-9-bottle-top" x="44.8" y="0" class="st4" width="13.8" height="12.2"></rect>
											<rect
                          id="digestion-svg-9-bottle-label" x="34.3" y="45.8" class="st5" width="34.8"
                          height="27.8"></rect>
											<path
                          id="digestion-svg-9-bottle-label-image" class="st4" d="M51.7,65.4c2.5,0,5,0.3,7.4,0.7c1.2-1.6,2-3.6,2-5.8c0-5.2-4.2-9.4-9.4-9.4
 c-5.2,0-9.4,4.2-9.4,9.4c0,2.2,0.8,4.2,2,5.8C46.7,65.6,49.2,65.4,51.7,65.4z"></path>
											<rect
                          id="digestion-svg-9-bottle-label-stripe-top" x="34.3" y="43.5" class="st4" width="34.8"
                          height="1.3"></rect>
											<rect id="digestion-svg-9-bottle-label-stripe-bottom" x="34.3" y="75"
                            class="st4" width="34.8" height="1.3"></rect>
										</g>
										<g
                        id="digestion-svg-9-glass">
											<polygon id="digestion-svg-9-glass-top" class="st6"
                               points="82.3,66.7 60.8,66.7 60.9,69.6 82.1,69.6"></polygon>
											<polygon
                          id="digestion-svg-9-glass-side-1" class="st6"
                          points="71.5,98.3 66.9,98.3 66.2,69.6 71.5,69.6"></polygon>
											<polygon
                          id="digestion-svg-9-glass-side-2" class="st6"
                          points="66.9,98.3 62.2,98.3 60.9,69.6 66.2,69.6"></polygon>
											<polygon
                          id="digestion-svg-9-glass-side-3" class="st6"
                          points="71.5,98.3 76.2,98.3 76.8,69.6 71.5,69.6"></polygon>
											<polygon
                          id="digestion-svg-9-glass-side-4" class="st6"
                          points="76.2,98.3 80.8,98.3 82.1,69.6 76.8,69.6"></polygon>
										</g>
										<g id="digestion-svg-9-clouds">
											<path id="digestion-svg-9-cloud-1" class="digestion-svg-9-cloud st7" d="M115.9,22.1c0.4-4.7-2.8-9.1-7.5-10c-0.3-0.1-0.5-0.1-0.8-0.1c-0.6-4.7-4.1-8.8-9.1-9.8
 C92.4,1,86.4,5,85.1,11.2c-0.2,1-0.3,1.9-0.2,2.8c-3.2,1.6-5.7,4.5-6.7,8.1H115.9z"
                            data-svg-origin="78.20000457763672 1.9874522686004639"
                            transform="matrix(0.99774,0,0,0.99774,3.1424494335845727,0.004479611225197688)"></path>
											<path
                          id="digestion-svg-9-cloud-2" class="digestion-svg-9-cloud st7" d="M146.7,44.8c0.3-2.9-1.7-5.6-4.7-6.2c-0.2,0-0.3-0.1-0.5-0.1c-0.4-2.9-2.6-5.5-5.6-6.1
 c-3.8-0.8-7.6,1.7-8.3,5.6c-0.1,0.6-0.2,1.2-0.1,1.8c-2,1-3.5,2.8-4.2,5.1H146.7z"
                          data-svg-origin="123.29999542236328 32.250972747802734"
                          transform="matrix(0.95628,0,0,0.95628,7.734625129017448,1.4099378805280445)"></path>
											<path
                          id="digestion-svg-9-cloud-3" class="digestion-svg-9-cloud st7" d="M23.8,32.6c0.3-2.9-1.7-5.6-4.7-6.2c-0.2,0-0.3-0.1-0.5-0.1c-0.4-2.9-2.6-5.5-5.6-6.1
 c-3.8-0.8-7.6,1.7-8.3,5.6c-0.1,0.6-0.2,1.2-0.1,1.8c-2,1-3.5,2.8-4.2,5.1H23.8z"
                          data-svg-origin="0.39999818801879883 20.050968170166016"
                          transform="matrix(0.87294,0,0,0.87294,1.1449654641220377,2.547618754603455)"></path>
										</g>
										<g
                        id="digestion-svg-9-mountains">
											<path id="digestion-svg-9-mountain-1" class="st8" d="M151.2,78.9l-4-5.6c-0.8-1.2-2.5-1.2-3.4,0l-4,5.6c-1,1.4,0,3.2,1.7,3.2h8
 C151.2,82.2,152.2,80.3,151.2,78.9z"></path>
											<path id="digestion-svg-9-mountain-2" class="st8" d="M139,73.9l-8.4-11.8c-1.1-1.6-3.4-1.6-4.5,0l-8.4,11.8c-1.3,1.8,0,4.4,2.3,4.4h16.8
 C139,78.3,140.3,75.7,139,73.9z"></path>
											<path id="digestion-svg-9-mountain-3" class="st9" d="M145,77.8l-5.4-7.6c-1.1-1.6-3.4-1.6-4.5,0l-5.4,7.6c-1.3,1.8,0,4.4,2.3,4.4h10.8
 C145,82.2,146.4,79.6,145,77.8z"></path>
											<path id="digestion-svg-9-mountain-4" class="st10" d="M138,78.6l-7-9.8c-0.9-1.3-2.8-1.3-3.7,0l-7,9.8c-1.1,1.5,0,3.6,1.9,3.6h13.9
 C138,82.2,139.1,80.1,138,78.6z"></path>
											<path id="digestion-svg-9-mountain-5" class="st9" d="M124.3,79.6l-4.9-6.8c-0.6-0.9-2-0.9-2.6,0l-4.9,6.8c-0.8,1.1,0,2.5,1.3,2.5h9.7
 C124.3,82.2,125.1,80.7,124.3,79.6z"></path>
										</g>
										<g id="digestion-svg-9-trees">
											<path id="digestion-svg-9-tree-1" class="digestion-svg-9-tree st11" d="M42.7,76.6c-0.2,0-0.4-0.1-0.7-0.1c-0.5-4.1-3.6-7.6-7.9-8.5c-5.4-1.1-10.6,2.4-11.7,7.8
 c-0.2,0.8-0.2,1.7-0.2,2.5c-3,1.5-5.4,4.4-6.1,8c-1.2,6,2.5,11.8,8.3,13.4c1,2.5,3.1,4.6,5.7,5.5v7.3h7v-7.3
 c3.1-1.2,5.5-3.9,6.2-7.3c0.4-1.8,0.2-3.6-0.4-5.2c3-0.7,5.4-3.1,6.1-6.4C50,81.8,47.1,77.5,42.7,76.6z"
                            data-svg-origin="32.53592300415039 112.50000762939453"
                            transform="matrix(0.99999,-0.00098,0.00098,0.99999,3.721687638819386,-1.5903356594760538)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="digestion-svg-9-tree-2" class="digestion-svg-9-tree st12" d="M65.4,102.6c0.2,0,0.3-0.1,0.5-0.1c0.4-2.9,2.5-5.4,5.6-6c3.8-0.8,7.4,1.7,8.2,5.5
 c0.1,0.6,0.2,1.2,0.1,1.7c2.1,1.1,3.8,3.1,4.3,5.6c0.8,4.2-1.8,8.3-5.9,9.4c-0.7,1.8-2.2,3.2-4,3.9v5.1h-4.9v-5.1
 c-2.2-0.8-3.9-2.7-4.4-5.2c-0.3-1.3-0.1-2.5,0.3-3.7c-2.1-0.5-3.8-2.2-4.3-4.5C60.3,106.3,62.3,103.2,65.4,102.6z"
                          data-svg-origin="72.51907730102539 127.69999694824219"
                          transform="matrix(0.9999,-0.01375,0.01375,0.9999,0.9375820178757834,-0.1316205653707776)"
                          style="transform-origin: 0px 0px 0px;"></path>
											<path id="digestion-svg-9-tree-3"
                            class="digestion-svg-9-tree st11" d="M77.5,108.5c0.1,0,0.2,0,0.4-0.1c0.3-2.2,1.9-4.1,4.3-4.6c2.9-0.6,5.7,1.3,6.3,4.2
 c0.1,0.4,0.1,0.9,0.1,1.3c1.6,0.8,2.9,2.4,3.3,4.3c0.6,3.2-1.4,6.4-4.5,7.2c-0.6,1.3-1.6,2.4-3.1,3v3.9h-3.8v-3.9
 c-1.7-0.6-3-2.1-3.4-4c-0.2-1-0.1-1.9,0.2-2.8c-1.6-0.4-2.9-1.7-3.3-3.4C73.6,111.3,75.1,109,77.5,108.5z"
                            data-svg-origin="82.97095108032227 127.70000457763672"
                            transform="matrix(0.99923,-0.03923,0.03923,0.99923,-2.8877640743131554,2.4743961577260727)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="digestion-svg-9-tree-4" class="digestion-svg-9-tree st13" d="M84.1,58.7c0.1,0,0.2,0,0.3,0c0.2-2,1.7-3.6,3.7-4c2.5-0.5,5,1.1,5.5,3.7
 c0.1,0.4,0.1,0.8,0.1,1.2c1.4,0.7,2.6,2.1,2.9,3.8c0.6,2.8-1.2,5.6-4,6.3c-0.5,1.2-1.5,2.2-2.7,2.6v3.5h-3.3v-3.5
 c-1.5-0.6-2.6-1.8-2.9-3.5c-0.2-0.9-0.1-1.7,0.2-2.5c-1.4-0.4-2.6-1.5-2.9-3C80.6,61.1,82,59.1,84.1,58.7z"
                          data-svg-origin="88.82356643676758 75.80000305175781"
                          transform="matrix(0.99733,-0.07296,0.07296,0.99733,-3.477150564298915,5.914423719178615)"
                          style="transform-origin: 0px 0px 0px;"></path>
											<path id="digestion-svg-9-tree-5"
                            class="digestion-svg-9-tree st14" d="M92.1,68c0.1,0,0.2,0,0.2,0c0.2-1.5,1.3-2.8,2.9-3.1c2-0.4,3.8,0.9,4.2,2.8
 c0.1,0.3,0.1,0.6,0.1,0.9c1.1,0.6,2,1.6,2.2,2.9c0.4,2.2-0.9,4.3-3,4.9c-0.4,0.9-1.1,1.7-2.1,2V81h-2.5v-2.7
 c-1.1-0.4-2-1.4-2.3-2.7c-0.1-0.7-0.1-1.3,0.1-1.9c-1.1-0.3-2-1.1-2.2-2.3C89.5,69.9,90.5,68.3,92.1,68z"
                            data-svg-origin="95.7233772277832 81"
                            transform="matrix(0.99787,-0.0652,0.0652,0.99787,-3.6917495043597617,5.825580328079932)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="digestion-svg-9-tree-6" class="digestion-svg-9-tree st11" d="M113.5,83.1c0.1,0,0.2,0,0.3,0c0.2-1.9,1.7-3.5,3.6-3.9c2.5-0.5,4.9,1.1,5.3,3.6
 c0.1,0.4,0.1,0.8,0.1,1.1c1.4,0.7,2.5,2,2.8,3.7c0.5,2.7-1.2,5.4-3.8,6.1c-0.5,1.1-1.4,2.1-2.6,2.5v3.4H116v-3.4
 c-1.4-0.5-2.5-1.8-2.9-3.4c-0.2-0.8-0.1-1.7,0.2-2.4c-1.4-0.3-2.5-1.4-2.8-2.9C110.2,85.5,111.5,83.5,113.5,83.1z"
                          data-svg-origin="118.07213592529297 99.59999084472656"
                          transform="matrix(0.99946,-0.0328,0.0328,0.99946,-1.447005620463715,3.185224167677706)"
                          style="transform-origin: 0px 0px 0px;"></path>
										</g>
									</svg>
								</div>


                <p class="wider-paragraph">Ожиріння та жовчно-кам’яна хвороба – другий фактор ризику. Порушення роботи підшлункової залози швидше виникне у людей з індексом маси тіла >25 кг/м<sup>2</sup>, ніж у тих, чиї значення в нормі<sup>6</sup>.
								</p>
								<div
                    class="inline-svg-center-block dotted-path-connection dotted-path-connection-8">
									<svg id="digestion-svg-8" class="inline-svg-illustration"
                       xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                       viewBox="0 0 157.1 105.2">
										<path id="digestion-svg-8-ground-shadow" class="st0" d="M17,36.5l-10.1,10c-2.2,2.2-3.4,5.2-3.4,8.3L0,79.8c0,6.6,1.2,8,3.4,10.2l11.7,11.7
 c2.2,2.2,5.2,3.4,8.3,3.4h59.9c3.1,0,6.1-1.2,8.3-3.4l30.6-30.4c2.2-2.2,5.2-3.4,8.3-3.4h6.5c3.1,0,6.1-1.2,8.3-3.4l9.1-9.1
 c3.5-3.5,2.5-7.3,2.5-15.8c0-4.9-131.7-6.6-131.7-6.6C22.2,33.1,19.2,34.3,17,36.5z"></path>
										<path
                        id="digestion-svg-8-ground" class="st1" d="M13.5,31.9L3.4,42C1.2,44.2,0,47.1,0,50.2v30.5c0,3.1,1.2,6.1,3.4,8.3l8.3,8.3
 c2.2,2.2,5.2,3.4,8.3,3.4h59.9c3.1,0,6.1-1.2,8.3-3.4l30.6-30.4c2.2-2.2,5.2-3.4,8.3-3.4h6.5c3.1,0,6.1-1.2,8.3-3.4l11.5-11.5
 c4.6-4.6,4.6-12,0-16.5l0,0c-2.2-2.2-5.2-3.4-8.3-3.4l-123.4,0C18.7,28.5,15.7,29.7,13.5,31.9z"></path>
										<g
                        id="digestion-svg-8-roads">
											<path id="digestion-svg-8-road-1" class="st2" d="M55.6,93h-6c-2.2,0-4.3-0.9-5.9-2.4l-6.1-6.1l3.5-3.5l6.1,6.1c0.6,0.6,1.5,1,2.3,1h6
 c0.9,0,1.7-0.3,2.3-1l1.3-1.3c0.6-0.6,1-1.5,1-2.3V71.1c0-0.9-0.4-1.7-1-2.3l-2-2c-0.6-0.6-1.5-1-2.3-1H32.4
 c-2.2,0-4.3-0.9-5.9-2.4l-0.6-0.6c-1.6-1.6-2.4-3.7-2.4-5.9v-4.7h5v4.7c0,0.9,0.3,1.7,1,2.3l0.6,0.6c0.6,0.6,1.5,1,2.3,1H55
 c2.2,0,4.3,0.9,5.9,2.4l2,2c1.6,1.6,2.4,3.7,2.4,5.9v12.2c0,2.2-0.9,4.3-2.4,5.9l-1.3,1.3C59.9,92.1,57.9,93,55.6,93z"></path>
											<path
                          id="digestion-svg-8-road-2" class="st2" d="M77.9,89.4h-6c-2,0-3.9-0.8-5.3-2.2L61,81.6l3.5-3.5l5.6,5.6c0.5,0.5,1.1,0.7,1.7,0.7h6
 c0.6,0,1.3-0.3,1.7-0.7l0.5-0.5c0.5-0.5,0.7-1.1,0.7-1.7v-4.7h5v4.7c0,2-0.8,3.9-2.2,5.3l-0.5,0.5C81.8,88.6,79.9,89.4,77.9,89.4z"></path>
											<path
                          id="digestion-svg-8-road-3" class="st2" d="M64.6,72.2L61,68.7l11.2-11.2c1.5-1.5,3.5-2.3,5.6-2.3h16.8c0.8,0,1.5-0.3,2-0.8l2.7-2.7
 c0.5-0.5,0.8-1.3,0.8-2v-4h5v4c0,2.1-0.8,4.1-2.3,5.6l-2.7,2.7c-1.5,1.5-3.5,2.3-5.6,2.3H77.7c-0.8,0-1.5,0.3-2,0.8L64.6,72.2z"></path>
										</g>
										<g
                        class="factory-8-houses">
											<g id="digestion-svg-8-house-1">
												<rect id="digestion-svg-8-wall-1" x="16.7" y="44.2" class="st3" width="18.4" height="8"></rect>
												<rect
                            id="digestion-svg-8-bottom-1" x="16.7" y="51.2" class="st4" width="18.4"
                            height="0.9"></rect>
												<path
                            id="digestion-svg-8-roof-1" class="st5" d="M32.5,37.6H19.6c-0.6,0-1.1,0.3-1.5,0.8l-3.2,4.8c-0.8,1.2,0.1,2.7,1.5,2.7h19.3
 c1.4,0,2.2-1.6,1.5-2.7l-3.2-4.8C33.6,37.9,33,37.6,32.5,37.6z"></path>
												<rect id="digestion-svg-8-window-1" x="24.5"
                              y="46.6" class="digestion-svg-8-window st4"
                              width="2.8" height="3.6"
                              data-svg-origin="24.5 46.599998474121094"
                              transform="matrix(1,0,0,1,0,0)"
                              style="fill: rgb(169, 179, 110);"></rect>
											</g>
											<g
                          id="digestion-svg-8-house-2">
												<rect id="digestion-svg-8-wall-2" x="73.9" y="68.8" class="st3" width="18.4" height="8"></rect>
												<rect
                            id="digestion-svg-8-bottom-2" x="73.9" y="75.8" class="st4" width="18.4"
                            height="0.9"></rect>
												<path
                            id="digestion-svg-8-roof-2" class="st5" d="M89.6,62.2H76.7c-0.6,0-1.1,0.3-1.5,0.8l-3.2,4.8c-0.8,1.2,0.1,2.7,1.5,2.7h19.3
 c1.4,0,2.2-1.6,1.5-2.7L91.1,63C90.8,62.5,90.2,62.2,89.6,62.2z"></path>
												<rect id="digestion-svg-8-window-2" x="81.7"
                              y="71.2" class="digestion-svg-8-window st4"
                              width="2.8" height="3.6"
                              data-svg-origin="81.69999694824219 71.19999694824219"
                              transform="matrix(1,0,0,1,0,0)"
                              style="fill: rgb(190, 193, 103);"></rect>
											</g>
											<g
                          id="digestion-svg-8-house-3">
												<rect id="digestion-svg-8-wall-3" x="32" y="76.7" class="st3" width="18.4" height="8"></rect>
												<rect
                            id="digestion-svg-8-bottom-3" x="32" y="83.8" class="st4" width="18.4" height="0.9"></rect>
												<path
                            id="digestion-svg-8-roof-3" class="st5" d="M47.7,70.1H34.8c-0.6,0-1.1,0.3-1.5,0.8l-3.2,4.8c-0.8,1.2,0.1,2.7,1.5,2.7h19.3
 c1.4,0,2.2-1.6,1.5-2.7l-3.2-4.8C48.9,70.4,48.3,70.1,47.7,70.1z"></path>
												<rect id="digestion-svg-8-window-3" x="39.8"
                              y="79.2"
                              class="digestion-svg-8-window st4"
                              width="2.8" height="3.6"
                              data-svg-origin="39.79999923706055 79.19999694824219"
                              transform="matrix(1,0,0,1,0,0)"
                              style="fill: rgb(197, 198, 101);"></rect>
											</g>
											<g
                          id="digestion-svg-8-house-4" class="digestion-svg-8-fat-house">
												<path id="digestion-svg-8-wall-4" class="st6"
                              d="M137.4,45.5h-68c-8.2-7.8,1.5-27.4,1.5-27.4h63.2C134.1,18.1,143.9,38.2,137.4,45.5z"></path>
												<path
                            id="digestion-svg-8-bottom-4" class="st4"
                            d="M68,43.7c0.4,0.7,0.9,1.3,1.5,1.8h68c0.5-0.5,0.9-1.2,1.2-1.8H68z"></path>
												<path
                            id="digestion-svg-8-roof-4" class="st7" d="M127.2,0.4H78.3c-2.2,0-4.3,1.1-5.5,3L60.7,21.5c-2.9,4.4,0.2,10.3,5.5,10.3h73
 c5.3,0,8.4-5.9,5.5-10.3L132.7,3.4C131.5,1.5,129.4,0.4,127.2,0.4z"></path>
												<rect id="digestion-svg-8-window-4" x="100.7"
                              y="34.8"
                              class="digestion-svg-8-window st4"
                              width="4.2" height="5.3"
                              data-svg-origin="100.69999694824219 34.79999923706055"
                              transform="matrix(1,0,0,1,0,0)"
                              style="fill: rgb(196, 197, 101);"></rect>
											</g>
										</g>
										<g
                        id="digestion-svg-8-trees">
											<path id="digestion-svg-8-tree-1" class="digestion-svg-8-tree st8" d="M133.3,37.6c-0.1,0-0.2,0-0.3-0.1c-0.3-2-1.8-3.7-3.9-4.2c-2.6-0.5-5.2,1.2-5.7,3.8
 c-0.1,0.4-0.1,0.8-0.1,1.2c-1.5,0.8-2.6,2.2-3,3.9c-0.6,2.9,1.2,5.8,4.1,6.6c0.5,1.2,1.5,2.2,2.8,2.7v3.6h3.4v-3.6
 c1.5-0.6,2.7-1.9,3-3.6c0.2-0.9,0.1-1.8-0.2-2.6c1.5-0.4,2.7-1.5,3-3.1C136.9,40.1,135.5,38,133.3,37.6z"
                            data-svg-origin="128.34308624267578 55.099998474121094"
                            transform="matrix(0.99999,-0.00098,0.00098,0.99999,1.8222626911573574,-0.6681342661945777)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="digestion-svg-8-tree-2" class="digestion-svg-8-tree st8" d="M137.4,45.6c-0.1,0-0.1,0-0.2,0c-0.2-1.4-1.2-2.6-2.7-2.9c-1.8-0.4-3.6,0.8-3.9,2.6
 c-0.1,0.3-0.1,0.6-0.1,0.8c-1,0.5-1.8,1.5-2.1,2.7c-0.4,2,0.8,4,2.8,4.5c0.3,0.8,1,1.5,1.9,1.9v2.5h2.4v-2.5c1-0.4,1.9-1.3,2.1-2.5
 c0.1-0.6,0.1-1.2-0.1-1.8c1-0.2,1.8-1.1,2-2.1C139.9,47.4,138.9,45.9,137.4,45.6z"
                          data-svg-origin="133.95594024658203 57.69999694824219"
                          transform="matrix(0.9999,-0.01375,0.01375,0.9999,0.5114387748025697,1.2998866332951202)"
                          style="transform-origin: 0px 0px 0px;"></path>
											<path id="digestion-svg-8-tree-3"
                            class="digestion-svg-8-tree st8" d="M59.7,57.6c-0.1,0-0.1,0-0.2,0c-0.2-1.4-1.2-2.6-2.7-2.9c-1.8-0.4-3.6,0.8-3.9,2.6
 c-0.1,0.3-0.1,0.6-0.1,0.8c-1,0.5-1.8,1.5-2.1,2.7c-0.4,2,0.8,4,2.8,4.5c0.3,0.8,1,1.5,1.9,1.9v2.5h2.4v-2.5c1-0.4,1.9-1.3,2.1-2.5
 c0.1-0.6,0.1-1.2-0.1-1.8c1-0.2,1.8-1.1,2-2.1C62.2,59.4,61.2,57.9,59.7,57.6z"
                            data-svg-origin="56.25594902038574 69.69999694824219"
                            transform="matrix(0.99923,-0.03923,0.03923,0.99923,-1.3985587512438267,1.7122871094846381)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="digestion-svg-8-tree-4" class="digestion-svg-8-tree st9" d="M51.9,23.2c-0.1,0-0.2,0-0.3,0c-0.2-1.7-1.5-3.1-3.2-3.4c-2.2-0.4-4.3,1-4.7,3.1
 c-0.1,0.3-0.1,0.7-0.1,1c-1.2,0.6-2.2,1.8-2.5,3.2c-0.5,2.4,1,4.8,3.4,5.4c0.4,1,1.2,1.8,2.3,2.2v3h2.8v-3c1.2-0.5,2.2-1.6,2.5-3
 c0.1-0.7,0.1-1.5-0.2-2.1c1.2-0.3,2.2-1.3,2.4-2.6C54.9,25.3,53.7,23.5,51.9,23.2z"
                          data-svg-origin="47.73003387451172 37.70000457763672"
                          transform="matrix(0.99733,-0.07296,0.07296,0.99733,-1.083204799012785,2.9284122187180444)"
                          style="transform-origin: 0px 0px 0px;"></path>
											<path id="digestion-svg-8-tree-5"
                            class="digestion-svg-8-tree st9" d="M69.1,42.1c-0.1,0-0.2,0-0.3,0c-0.2-1.7-1.5-3.1-3.2-3.4c-2.2-0.4-4.3,1-4.7,3.1
 c-0.1,0.3-0.1,0.7-0.1,1c-1.2,0.6-2.2,1.8-2.5,3.2c-0.5,2.4,1,4.8,3.4,5.4c0.4,1,1.2,1.8,2.3,2.2v3h2.8v-3c1.2-0.5,2.2-1.6,2.5-3
 c0.1-0.7,0.1-1.5-0.2-2.1c1.2-0.3,2.2-1.3,2.4-2.6C72,44.2,70.9,42.4,69.1,42.1z"
                            data-svg-origin="64.91242790222168 56.599998474121094"
                            transform="matrix(0.99787,-0.0652,0.0652,0.99787,-2.012255942988496,3.700186942841654)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="digestion-svg-8-tree-6" class="digestion-svg-8-tree st8" d="M18.7,61.6c-0.1,0-0.2,0-0.3,0c-0.2-1.7-1.5-3.1-3.2-3.4c-2.2-0.4-4.3,1-4.7,3.1
 c-0.1,0.3-0.1,0.7-0.1,1c-1.2,0.6-2.2,1.8-2.5,3.2c-0.5,2.4,1,4.8,3.4,5.4c0.4,1,1.2,1.8,2.3,2.2v3h2.8v-3c1.2-0.5,2.2-1.6,2.5-3
 c0.1-0.7,0.1-1.5-0.2-2.1c1.2-0.3,2.2-1.3,2.4-2.6C21.6,63.7,20.5,62,18.7,61.6z"
                          data-svg-origin="14.512426853179932 76.09999084472656"
                          transform="matrix(0.99946,-0.0328,0.0328,0.99946,-0.9482680541919564,-0.13606202010311108)"
                          style="transform-origin: 0px 0px 0px;"></path>
											<path id="digestion-svg-8-tree-7"
                            class="digestion-svg-8-tree st9" d="M33.8,75.5c-0.1,0-0.2,0-0.3,0c-0.2-1.7-1.5-3.1-3.2-3.4c-2.2-0.4-4.3,1-4.7,3.1
 c-0.1,0.3-0.1,0.7-0.1,1c-1.2,0.6-2.2,1.8-2.5,3.2c-0.5,2.4,1,4.8,3.4,5.4c0.4,1,1.2,1.8,2.3,2.2v3h2.8v-3c1.2-0.5,2.2-1.6,2.5-3
 c0.1-0.7,0.1-1.5-0.2-2.1c1.2-0.3,2.2-1.3,2.4-2.6C36.7,77.6,35.6,75.8,33.8,75.5z"
                            data-svg-origin="29.612424850463867 89.99999237060547"
                            transform="matrix(0.99995,-0.00978,0.00978,0.99995,0.6613401424853151,-0.359101112282616)"
                            style="transform-origin: 0px 0px 0px;"></path>
										</g>
										<g
                        class="factory-8-hills">
											<path id="digestion-svg-8-hill-1" class="st9"
                            d="M111.9,53.8c-3.9,0-7.1,3.9-7.1,8.8H119C119,57.7,115.8,53.8,111.9,53.8z"></path>
											<path
                          id="digestion-svg-8-hill-2" class="st9"
                          d="M51,44.8c-3,0-5.3,3-5.3,6.7h10.7C56.3,47.8,53.9,44.8,51,44.8z"></path>
											<path
                          id="digestion-svg-8-hill-3" class="st9"
                          d="M73.4,77.5c-1.8,0-3.2,1.8-3.2,4h6.4C76.6,79.3,75.2,77.5,73.4,77.5z"></path>
											<path
                          id="digestion-svg-8-hill-4" class="st9"
                          d="M45.8,47.2c-1.9,0-3.4,1.9-3.4,4.2h6.8C49.1,49.1,47.6,47.2,45.8,47.2z"></path>
										</g>
									</svg>
								</div>


								<p
                    class="wider-paragraph">Паління —третій фактор ризику<sup>4</sup>.</p>

                <div
                    class="inline-svg-center-block inline-svg-bigger">
									<svg id="digestion-svg-7" class="inline-svg-illustration"
                       xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                       viewBox="0 0 133.6 124">
										<path id="digestion-svg-7-ground-shadow" class="st0" d="M13.6,68.6l-8.1,8.1c-1.8,1.8-2.8,4.2-2.8,6.7L0,103.5c0,5.3,1,6.5,2.8,8.3l9.4,9.5
 c1.8,1.8,4.2,2.8,6.7,2.8h48c2.5,0,4.9-1,6.7-2.8L98,96.7c1.8-1.8,4.2-2.8,6.7-2.8h5.2c2.5,0,4.9-1,6.7-2.8l7.3-7.3
 c2.8-2.8,2-5.9,2-12.8c0-4-105.5-5.3-105.5-5.3C17.8,65.8,15.4,66.8,13.6,68.6z"></path>
										<path id="digestion-svg-7-ground"
                          class="st1" d="M10.8,64.9L2.8,73C1,74.8,0,77.2,0,79.7v24.5c0,2.5,1,4.9,2.8,6.7l6.7,6.7
 c1.8,1.8,4.2,2.8,6.7,2.8h48c2.5,0,4.9-1,6.7-2.8L95.2,93c1.8-1.8,4.2-2.8,6.7-2.8h5.2c2.5,0,4.9-1,6.7-2.8l9.2-9.2
 c3.7-3.7,3.7-9.7,0-13.3l0,0c-1.8-1.8-4.2-2.8-6.7-2.8l-98.8,0C15,62.2,12.6,63.1,10.8,64.9z"></path>
										<g
                        id="digestion-svg-7-mountains">
											<path id="digestion-svg-7-mountain-1" class="st2" d="M80,72.9l-4-5.6c-0.8-1.2-2.5-1.2-3.4,0l-4,5.6c-1,1.4,0,3.3,1.7,3.3h8
 C80,76.1,80.9,74.2,80,72.9z"></path>
											<path id="digestion-svg-7-mountain-2" class="st3" d="M67.7,67.8l-8.4-11.9c-1.1-1.6-3.4-1.6-4.5,0l-8.4,11.9c-1.3,1.8,0,4.4,2.3,4.4h16.9
 C67.7,72.2,69,69.6,67.7,67.8z"></path>
											<path id="digestion-svg-7-mountain-3" class="st4" d="M73.8,71.7l-5.4-7.6c-1.1-1.6-3.4-1.6-4.6,0l-5.4,7.6c-1.3,1.9,0,4.4,2.3,4.4h10.8
 C73.8,76.1,75.1,73.6,73.8,71.7z"></path>
											<path id="digestion-svg-7-mountain-4" class="st2" d="M66.7,72.5l-7-9.9c-0.9-1.3-2.8-1.3-3.8,0l-7,9.9c-1.1,1.5,0,3.6,1.9,3.6h14
 C66.7,76.1,67.8,74,66.7,72.5z"></path>
											<path id="digestion-svg-7-mountain-5" class="st4" d="M52.9,73.6L48,66.7c-0.6-0.9-2-0.9-2.6,0l-4.9,6.9c-0.8,1.1,0,2.5,1.3,2.5h9.8
 C52.9,76.1,53.7,74.7,52.9,73.6z"></path>
										</g>
										<g class="factory-7-roads">
											<path id="digestion-svg-7-road-1" class="st5" d="M25.6,115.8h-3.5c-2,0-3.8-0.8-5.2-2.1l-4.6-4.6c-1.4-1.4-2.1-3.2-2.1-5.2V90.5
 c0-1.9,0.8-3.8,2.1-5.2l5.6-5.6c0.4-0.4,0.7-1,0.7-1.6v-4h5v4c0,1.9-0.8,3.8-2.1,5.2l-5.6,5.6c-0.4,0.4-0.7,1-0.7,1.6v13.4
 c0,0.6,0.2,1.2,0.7,1.6l4.6,4.6c0.4,0.4,1,0.7,1.6,0.7h3.5c0.6,0,1.2-0.2,1.6-0.7l1.5-1.5c0.4-0.4,0.7-1,0.7-1.6v-2.6h5v2.6
 c0,2-0.8,3.8-2.1,5.2l-1.5,1.5C29.4,115.1,27.6,115.8,25.6,115.8z"></path>
											<path id="digestion-svg-7-road-2" class="st5"
                            d="M79,97.2H55v-5h24c1.8,0,3.4-0.7,4.6-1.9l3-3c1.2-1.2,1.9-2.9,1.9-4.6v-0.9h5v0.9
 c0,3.1-1.2,6-3.4,8.2l-3,3C85,96,82.1,97.2,79,97.2z"></path>
										</g>
										<g class="factory-7-area-1">
											<g class="digestion-svg-7-smoke" data-svg-origin="32.400001525878906 50.29999923706055"
                         transform="matrix(0.85865,0,0,0.85865,4.5795492708204115,7.1096084561711805)"
                         style="transform-origin: 0px 0px 0px;">
												<path id="digestion-svg-7-smoke-1" class="st6" d="M52.5,40.5c-0.5-1.2-1.6-2-2.9-2c-1.7,0-3.2,1.4-3.2,3.2c0,0.9,0.4,1.7,0.9,2.2
 c0,0.2,0,0.4,0,0.6c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2C55.6,42.6,54.3,41,52.5,40.5z"></path>
												<circle
                            id="digestion-svg-7-smoke-2" class="st6" cx="50.5" cy="47.1" r="3.2"></circle>
												<circle
                            id="digestion-svg-7-smoke-3" class="st6" cx="47.3" cy="44.3" r="4.9"></circle>
												<path
                            id="digestion-svg-7-smoke-4" class="st6" d="M50.5,50.3c0.2-1.8-1-3.4-2.8-3.8c-0.1,0-0.2,0-0.3,0c-0.2-1.8-1.6-3.3-3.4-3.7
 c-2.3-0.5-4.6,1-5,3.4c-0.1,0.4-0.1,0.7-0.1,1.1c-3,0-6.1,1.7-6.5,3H50.5z"></path>
											</g>
											<g id="digestion-svg-7-pipe-1">
												<polygon class="st7" points="31.5,64.3 31.5,54.8 32.9,53.7 34,53.5 34,64.3"></polygon>
												<path
                            class="st8"
                            d="M33.8,53.7v10.5h-2.1v-9.2l1.2-1L33.8,53.7 M34.2,53.3l-1.4,0.3l-1.4,1.2v9.7h2.8V53.3L34.2,53.3z"></path>
												<rect
                            class="st9" x="31.5" y="64.7" width="2.5" height="7.5"></rect>
												<path class="st8"
                              d="M33.8,64.9V72h-2.1v-7.2H33.8 M34.2,64.5h-2.8v7.9h2.8V64.5L34.2,64.5z"></path>
												<rect
                            class="st8" x="31.3" y="64.1" width="2.8" height="1.2"></rect>
												<polygon class="st10"
                                 points="31.3,54.8 31.8,52.1 32.3,51.3 33,51.7 33.6,51.5 34.2,53.3 32.7,53.6 	"></polygon>
												<polygon
                            class="st8"
                            points="31.3,54.8 31.8,52.1 32.3,51.3 33,51.7 33.6,51.5 34.2,53.3 32.7,53.6 	"></polygon>
											</g>
											<g
                          id="digestion-svg-7-building-1">
												<polygon id="digestion-svg-7-sidewall-1" class="st11"
                                 points="28.5,69.1 28.5,66.8 22.9,66.8 22.9,74.2 35.9,74.2 35.9,69.1 	"></polygon>
												<polygon
                            id="digestion-svg-7-sidewall-2" class="st12"
                            points="24,69.1 24,66.8 18.4,66.8 18.4,74.2 31.4,74.2 31.4,69.1 	"></polygon>
												<rect
                            id="digestion-svg-7-roof-1" x="17.9" y="65.7" class="st8" width="11" height="1.1"></rect>
												<rect
                            id="digestion-svg-7-roof-2" x="23.4" y="68.6" class="st8" width="12.8" height="1.1"></rect>
												<rect
                            id="digestion-svg-7-window-1" x="19.7" y="71.5" class="st8" width="2.6" height="2.6"></rect>
												<rect
                            id="digestion-svg-7-window-2" x="20.3" y="67.6" class="digestion-svg-7-window st8"
                            width="1.6"
                            height="1.6" data-svg-origin="20.299999237060547 67.5999984741211"
                            transform="matrix(1,0,0,1,0,0)"
                            style="fill: rgb(169, 179, 110);"></rect>
												<rect id="digestion-svg-7-window-3" x="24.6" y="71.5"
                              class="st8" width="2.6" height="2.6"></rect>
												<rect
                            id="digestion-svg-7-window-4" x="27.6" y="71.5" class="st8" width="2.6" height="2.6"></rect>
											</g>
										</g>
										<g
                        class="factory-7-area-2">
											<g id="digestion-svg-7-smokes-2" class="digestion-svg-7-smoke"
                         data-svg-origin="65.0999984741211 34.59999752044678"
                         transform="matrix(0.99593,0,0,0.99593,0.2644358211474298,0.14054499186594427)"
                         style="transform-origin: 0px 0px 0px;">
												<path id="digestion-svg-7-smoke-5" class="st6" d="M124,6c-1.4-3.4-4.7-5.8-8.6-5.8c-5.1,0-9.2,4.1-9.2,9.2c0,2.6,1.1,4.9,2.8,6.6
 c-0.1,0.6-0.1,1.2-0.1,1.8c0,6.7,5.5,12.2,12.2,12.2c6.7,0,12.2-5.5,12.2-12.2C133.2,12.1,129.3,7.4,124,6z"></path>
												<circle
                            id="digestion-svg-7-smoke-6" class="st6" cx="118.1" cy="25.3" r="9.2"></circle>
												<circle
                            id="digestion-svg-7-smoke-7" class="st6" cx="108.9" cy="17.2" r="14.4"></circle>
												<path
                            id="digestion-svg-7-smoke-8" class="st6" d="M118,34.6c0.5-5.2-3-10-8.3-11c-0.3-0.1-0.6-0.1-0.8-0.1c-0.6-5.2-4.5-9.6-9.9-10.7
 c-6.8-1.3-13.3,3-14.7,9.8c-0.2,1-0.3,2.1-0.2,3.1c-8.8,0-17.8,4.9-19,8.9H118z"></path>
											</g>
											<g
                          id="digestion-svg-7-pipe-2">
												<polygon class="st7" points="62.4,75.8 62.4,47.9 66.4,44.6 69.9,43.8 69.9,75.8 		"></polygon>
												<path
                            class="st8"
                            d="M69.5,44.3v31.1h-6.7V48.1l3.7-3.1L69.5,44.3 M70.3,43.3l-4.1,1L62,47.7v28.5h8.3V43.3L70.3,43.3z"></path>
												<rect
                            class="st9" x="62.4" y="76.6" width="7.5" height="22.3"></rect>
												<path class="st8"
                              d="M69.5,77v21.5h-6.7V77H69.5 M70.3,76.2H62v23.1h8.3V76.2L70.3,76.2z"></path>
												<rect
                            class="st8" x="62" y="75.1" width="8.3" height="3.4"></rect>
												<polygon class="st13"
                                 points="62,47.7 63.4,39.9 64.8,37.4 66.9,38.6 68.6,38 70.3,43.3 66.2,44.3 	"></polygon>
												<polygon
                            class="st8"
                            points="62,47.7 63.4,39.9 64.8,37.4 66.9,38.6 68.6,38 70.3,43.3 66.2,44.3 	"></polygon>
											</g>
											<g
                          id="digestion-svg-7-building-2">
												<polygon id="digestion-svg-7-sidewall-2" class="st15"
                                 points="53.7,89.5 53.7,82.8 37.4,82.8 37.4,104.4 75.5,104.4 75.5,89.5 	"></polygon>
												<polygon
                            id="digestion-svg-7-frontwall-2" class="st16"
                            points="40.5,89.5 40.5,82.8 24.3,82.8 24.3,104.4 62.3,104.4 62.3,89.5 	"></polygon>
												<rect
                            id="digestion-svg-7-roof-3" x="22.8" y="79.7" class="st8" width="32.3" height="3.1"></rect>
												<rect
                            id="digestion-svg-7-roof-4" x="38.9" y="88.3" class="st8" width="37.4" height="3.1"></rect>
												<rect
                            id="digestion-svg-7-window-5" x="28.1" y="96.7" class="st8" width="7.7" height="7.7"></rect>
												<rect
                            id="digestion-svg-7-window-6" x="29.7" y="85.3" class="digestion-svg-7-window st8"
                            width="4.5"
                            height="4.5" data-svg-origin="29.700000762939453 85.30000305175781"
                            transform="matrix(1,0,0,1,0,0)"
                            style="fill: rgb(169, 179, 110);"></rect>
												<rect id="digestion-svg-7-window-7" x="42.3" y="96.7"
                              class="st8" width="7.7" height="7.7"></rect>
												<rect
                            id="digestion-svg-7-window-8" x="51.1" y="96.7" class="st8" width="7.7" height="7.7"></rect>
											</g>
										</g>
										<g
                        id="digestion-svg-7-trees-1">
											<path id="digestion-svg-7-tree-1" class="digestion-svg-7-tree st3" d="M115,63.9c-0.1,0-0.2,0-0.3,0c-0.2-1.6-1.4-3-3.1-3.3c-2.1-0.4-4.1,0.9-4.6,3
 c-0.1,0.3-0.1,0.6-0.1,1c-1.2,0.6-2.1,1.7-2.4,3.1c-0.5,2.3,1,4.6,3.3,5.2c0.4,1,1.2,1.8,2.2,2.2v2.9h2.7V75
 c1.2-0.5,2.2-1.5,2.4-2.9c0.1-0.7,0.1-1.4-0.1-2.1c1.2-0.3,2.1-1.2,2.4-2.5C117.9,65.9,116.8,64.2,115,63.9z"
                            data-svg-origin="110.96231842041016 78"
                            transform="matrix(0.99999,-0.00098,0.00098,0.99999,1.4210507209087968,-0.52898113690107)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="digestion-svg-7-tree-2" class="digestion-svg-7-tree st2" d="M122.9,71.2c0-0.1-0.1-0.1-0.1-0.2c0.2-0.6,0.1-1.3-0.1-1.9c-0.6-1.3-2-2-3.3-1.7
 c-0.6-0.8-1.7-1.2-2.7-0.7c-1.2,0.5-1.7,1.9-1.2,3c0,0,0,0,0,0.1c-0.8,0.6-1.1,1.7-0.7,2.6c0.2,0.4,0.5,0.7,0.8,1
 c0,0.4,0.1,0.9,0.2,1.3c0.3,0.8,1,1.4,1.8,1.6v1.6h1.6v-1.6c0.2,0,0.3-0.1,0.5-0.2c0.8-0.3,1.4-1,1.6-1.8c0.1,0,0.2-0.1,0.4-0.1
 C122.9,73.8,123.5,72.4,122.9,71.2z" data-svg-origin="118.8794937133789 77.9000015258789"
                          transform="matrix(0.9999,-0.01375,0.01375,0.9999,-0.08129061071735144,1.228311474232271)"
                          style="transform-origin: 0px 0px 0px;"></path>
											<path id="digestion-svg-7-tree-3"
                            class="digestion-svg-7-tree st4" d="M111.1,69.8c0-1.6-1.3-2.9-2.9-2.9c0,0,0,0-0.1,0c-0.3-1.3-1.4-2.3-2.8-2.3
 c-1.6,0-2.8,1.3-2.8,2.8c0,0.1,0,0.2,0,0.3c-1,0.5-1.6,1.5-1.6,2.7c0,1.2,0.7,2.2,1.7,2.7c-0.1,0.2-0.1,0.4-0.1,0.7
 c0,1,0.8,1.7,1.7,1.7c0.2,0,0.4,0,0.6-0.1v2.5h1.8v-2.7c0.2,0.1,0.5,0.1,0.7,0.1c1.4,0,2.5-1.1,2.5-2.5c0-0.2,0-0.4-0.1-0.5
 C110.6,71.7,111.1,70.8,111.1,69.8z" data-svg-origin="105.99999618530273 77.89999389648438"
                            transform="matrix(0.99923,-0.03923,0.03923,0.99923,-1.8347995302514182,3.7232741698859506)"
                            style="transform-origin: 0px 0px 0px;"></path>
										</g>
										<g
                        class="factory-7-area-3">
											<g class="digestion-svg-7-smoke" data-svg-origin="104.9000015258789 52.599998474121094"
                         transform="matrix(0.7528,0,0,0.7528,25.930687630288276,13.002422401772662)"
                         style="transform-origin: 0px 0px 0px;">
												<path id="digestion-svg-7-smoke-9" class="st6" d="M129.6,40.6c-0.6-1.4-2-2.4-3.6-2.4c-2.1,0-3.9,1.7-3.9,3.9c0,1.1,0.4,2.1,1.2,2.8
 c0,0.2-0.1,0.5-0.1,0.7c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1C133.4,43.2,131.8,41.2,129.6,40.6z"></path>
												<circle
                            id="digestion-svg-7-smoke-10" class="st6" cx="127.1" cy="48.7" r="3.9"></circle>
												<circle
                            id="digestion-svg-7-smoke-11" class="st6" cx="123.2" cy="45.3" r="6"></circle>
												<path
                            id="digestion-svg-7-smoke-12" class="st6" d="M127.1,52.6c0.2-2.2-1.3-4.2-3.5-4.6c-0.1,0-0.2,0-0.4-0.1c-0.3-2.2-1.9-4-4.2-4.5
 c-2.8-0.6-5.6,1.3-6.1,4.1c-0.1,0.4-0.1,0.9-0.1,1.3c-3.7,0-7.5,2.1-7.9,3.7H127.1z"></path>
											</g>
											<g
                          id="digestion-svg-7-pipe-3">
												<polygon class="st7"
                                 points="103.8,69.8 103.8,58.1 105.5,56.7 106.9,56.4 106.9,69.8 		"></polygon>
												<path
                            class="st8"
                            d="M106.7,56.6v13H104V58.2l1.5-1.3L106.7,56.6 M107.1,56.2l-1.7,0.4l-1.7,1.4V70h3.5V56.2L107.1,56.2z"></path>
												<rect
                            class="st9" x="103.8" y="70.1" width="3.1" height="9.3"></rect>
												<path class="st8"
                              d="M106.7,70.3v9H104v-9H106.7 M107.1,70h-3.5v9.7h3.5V70L107.1,70z"></path>
												<rect
                            class="st8" x="103.6" y="69.5" width="3.5" height="1.4"></rect>
												<polygon class="st14"
                                 points="103.6,58 104.2,54.8 104.8,53.8 105.7,54.2 106.4,54 107.1,56.2 105.4,56.6 	"></polygon>
												<polygon
                            class="st8"
                            points="103.6,58 104.2,54.8 104.8,53.8 105.7,54.2 106.4,54 107.1,56.2 105.4,56.6 	"></polygon>
											</g>
											<g
                          id="digestion-svg-7-building-3">
												<polygon id="digestion-svg-7-sidewall-3" class="st11"
                                 points="100.1,75.5 100.1,72.7 93.3,72.7 93.3,81.8 109.3,81.8 109.3,75.5 	"></polygon>
												<polygon
                            id="digestion-svg-7-frontwall-3" class="st12"
                            points="94.6,75.5 94.6,72.7 87.8,72.7 87.8,81.8 103.7,81.8 103.7,75.5 	"></polygon>
												<rect
                            id="digestion-svg-7-roof-5" x="87.2" y="71.4" class="st8" width="13.5" height="1.3"></rect>
												<rect
                            id="digestion-svg-7-roof-6" x="94" y="75" class="st8" width="15.6" height="1.3"></rect>
												<rect
                            id="digestion-svg-7-window-9" x="89.4" y="78.5" class="st8" width="3.2" height="3.2"></rect>
												<rect
                            id="digestion-svg-7-window-10" x="90.1" y="73.8" class="digestion-svg-7-window st8"
                            width="1.9"
                            height="1.9" data-svg-origin="90.0999984741211 73.80000305175781"
                            transform="matrix(1,0,0,1,0,0)"
                            style="fill: rgb(169, 179, 110);"></rect>
												<rect id="digestion-svg-7-window-11" x="95.4" y="78.5"
                              class="st8" width="3.2" height="3.2"></rect>
												<rect
                            id="digestion-svg-7-window-12" x="99.1" y="78.5" class="st8" width="3.2"
                            height="3.2"></rect>
											</g>
										</g>
										<g id="digestion-svg-7-trees-2">
											<path id="digestion-svg-7-tree-4" class="digestion-svg-7-tree st2" d="M67.4,100.2c-0.1,0-0.2,0-0.3,0c-0.2-1.6-1.4-3-3.1-3.3c-2.1-0.4-4.1,0.9-4.6,3
 c-0.1,0.3-0.1,0.6-0.1,1c-1.2,0.6-2.1,1.7-2.4,3.1c-0.5,2.3,1,4.6,3.3,5.2c0.4,1,1.2,1.8,2.2,2.2v2.9h2.7v-2.9
 c1.2-0.5,2.2-1.5,2.4-2.9c0.1-0.7,0.1-1.4-0.1-2.1c1.2-0.3,2.1-1.2,2.4-2.5C70.2,102.2,69.1,100.5,67.4,100.2z"
                            data-svg-origin="63.343017578125 114.29998779296875"
                            transform="matrix(0.99733,-0.07296,0.07296,0.99733,-6.673136112508967,4.2899328316396925)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="digestion-svg-7-tree-5" class="digestion-svg-7-tree st4" d="M75.3,107.6c0-0.1-0.1-0.1-0.1-0.2c0.2-0.6,0.1-1.3-0.1-1.9c-0.6-1.3-2-2-3.3-1.7
 c-0.6-0.8-1.7-1.2-2.7-0.7c-1.2,0.5-1.7,1.9-1.2,3c0,0,0,0,0,0.1c-0.8,0.6-1.1,1.7-0.7,2.6c0.2,0.4,0.5,0.7,0.8,1
 c0,0.4,0.1,0.9,0.2,1.3c0.3,0.8,1,1.4,1.8,1.6v1.6h1.6v-1.6c0.2,0,0.3-0.1,0.5-0.2c0.8-0.3,1.4-1,1.6-1.8c0.1,0,0.2-0.1,0.4-0.1
 C75.2,110.1,75.8,108.7,75.3,107.6z" data-svg-origin="71.25092697143555 114.30000305175781"
                          transform="matrix(0.99787,-0.0652,0.0652,0.99787,-6.322462495982181,4.477490072040355)"
                          style="transform-origin: 0px 0px 0px;"></path>
											<path id="digestion-svg-7-tree-6"
                            class="digestion-svg-7-tree st3" d="M63.4,106.1c0-1.6-1.3-2.9-2.9-2.9c0,0,0,0-0.1,0c-0.3-1.3-1.4-2.3-2.8-2.3
 c-1.6,0-2.8,1.3-2.8,2.8c0,0.1,0,0.2,0,0.3c-1,0.5-1.6,1.5-1.6,2.7c0,1.2,0.7,2.2,1.7,2.7c-0.1,0.2-0.1,0.4-0.1,0.7
 c0,1,0.8,1.7,1.7,1.7c0.2,0,0.4,0,0.6-0.1v2.5H59v-2.7c0.2,0.1,0.5,0.1,0.7,0.1c1.4,0,2.5-1.1,2.5-2.5c0-0.2,0-0.4-0.1-0.5
 C62.9,108,63.4,107.1,63.4,106.1z" data-svg-origin="58.30000305175781 114.19998931884766"
                            transform="matrix(0.99946,-0.0328,0.0328,0.99946,-2.5752779625165534,1.4788537686648162)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="digestion-svg-7-tree-7" class="digestion-svg-7-tree st3" d="M10.8,73c-0.1,0-0.1,0-0.2,0c-0.1-1-0.9-1.8-1.9-2c-1.3-0.3-2.5,0.6-2.8,1.8
 C6,73,6,73.2,6,73.4c-0.7,0.4-1.3,1-1.4,1.9c-0.3,1.4,0.6,2.8,2,3.2c0.2,0.6,0.7,1.1,1.3,1.3v1.7h1.6v-1.7c0.7-0.3,1.3-0.9,1.5-1.7
 c0.1-0.4,0-0.9-0.1-1.2c0.7-0.2,1.3-0.7,1.4-1.5C12.5,74.2,11.9,73.2,10.8,73z" data-svg-origin="8.440204620361328 81.5"
                          transform="matrix(0.99995,-0.00978,0.00978,0.99995,0.10839767717696525,-0.2934599863819489)"
                          style="transform-origin: 0px 0px 0px;"></path>
											<path id="digestion-svg-7-tree-8"
                            class="digestion-svg-7-tree st4" d="M15.6,77.4c0,0,0-0.1-0.1-0.1c0.1-0.4,0.1-0.8-0.1-1.2c-0.3-0.8-1.2-1.2-2-1
 c-0.4-0.5-1.1-0.7-1.7-0.4c-0.7,0.3-1,1.1-0.7,1.8c0,0,0,0,0,0c-0.5,0.4-0.6,1-0.4,1.6c0.1,0.3,0.3,0.5,0.5,0.6
 c0,0.3,0,0.5,0.1,0.8c0.2,0.5,0.6,0.8,1.1,1v1h1v-1c0.1,0,0.2-0.1,0.3-0.1c0.5-0.2,0.8-0.6,1-1.1c0.1,0,0.2,0,0.2-0.1
 C15.6,79,15.9,78.2,15.6,77.4z" data-svg-origin="13.108769416809082 81.50000762939453"
                            transform="matrix(0.99999,-0.00015,0.00015,0.99999,0.5825329018298371,-0.25149807070298014)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="digestion-svg-7-tree-9" class="digestion-svg-7-tree st2" d="M8.4,76.6c0-1-0.8-1.8-1.8-1.8c0,0,0,0,0,0c-0.2-0.8-0.9-1.4-1.7-1.4c-0.9,0-1.7,0.8-1.7,1.7
 c0,0.1,0,0.1,0,0.2c-0.6,0.3-1,0.9-1,1.6c0,0.7,0.4,1.3,1,1.6c0,0.1-0.1,0.3-0.1,0.4c0,0.6,0.5,1.1,1.1,1.1c0.1,0,0.2,0,0.4-0.1
 v1.5h1.1v-1.6c0.1,0,0.3,0.1,0.4,0.1c0.8,0,1.5-0.7,1.5-1.5c0-0.1,0-0.2,0-0.3C8.1,77.7,8.4,77.2,8.4,76.6z"
                          data-svg-origin="5.299999475479126 81.39998626708984"
                          transform="matrix(0.99998,-0.00558,0.00558,0.99998,0.23083667741970082,-0.26977384356592715)"
                          style="transform-origin: 0px 0px 0px;"></path>
										</g>
									</svg>
								</div>






								<p>Ще один важливий фактор ризику розвитку хронічного панкреатиту — дисліпідемія. Такий стан може розвинутися  у тих, хто харчується на ходу, вживаючи багато продуктів на кшталт «фастфуду» з великою кількістю насичених жирів та тригліцеридів (збільшення їх рівня в крові підвищує ризик захворювань серця та судин, а також ризик розвитку гострого панкреатиту). Таке харчування може призвести до здуття живота та важкості в животі. Однак на гострий панкреатит можуть захворіти і прибічники здорового способу життя, якщо в їх їжі значно перевищена норма насичених жирних кислот<sup>6</sup>.
								</p>
                <p>Фоновою причиною розвитку запального процесу в тканині підшлункової залози можуть стати супутні гострі і хронічні захворювання: ускладнене проходження вивідними протоками підшлункової залози пухлиною або паразитами, інфекційні та бактеріальні захворювання, травма та хірургічні операції на органах поруч з підшлунковою залозою. Гострий панкреатит може з’явитися і після довготривалого прийому деяких ліків (наприклад, кортикостероїдів)<sup>4</sup>. Рецидив цього захворювання може трапитися у людей, які страждають на жовчнокам’яну хворобу, дисфункцію сфінктера Одді, анатомічні дефекти протоків, які перешкоджають відтоку панкреатичного соку; ускладнення проходження  Вірсунговою протокою; генетичні мутації гена, який регулює трансмембранну провідність<sup>4</sup>.
								</p>
								<div
                    class="inline-svg-center-block">
									<svg id="digestion-svg-10" class="inline-svg-illustration"
                       xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                       viewBox="0 0 171 120.5">
										<defs>
											<linearGradient id="blue-gradient" gradientUnits="userSpaceOnUse" x1="83.7743" y1="109.0557"
                                      x2="93.2433" y2="109.0557">
												<stop offset="0" stop-color="#0069B4"></stop>
												<stop
                            offset="0.4665" stop-color="#0D54A0"></stop>
												<stop offset="1" stop-color="#1F3685"></stop>
											</linearGradient>
										</defs>
										<g
                        id="digestion-svg-10-burger">
											<path id="digestion-svg-10-bun-top" class="st1"
                            d="M88.5,51.8c-14.9,0-27,3.3-27,7.4v7.4h54.1v-7.4C115.5,55.1,103.4,51.8,88.5,51.8z"></path>
											<path
                          id="digestion-svg-10-meat-top" class="st0" d="M112,73.2H65.2c-2,0-3.7-1.6-3.7-3.7v0c0-2,1.6-3.7,3.7-3.7H112c2,0,3.7,1.6,3.7,3.7v0
 C115.6,71.5,114,73.2,112,73.2z"></path>
											<path id="digestion-svg-10-meat-bottom" class="st0" d="M112,81.7H65.2c-2,0-3.7-1.6-3.7-3.7v0c0-2,1.6-3.7,3.7-3.7H112c2,0,3.7,1.6,3.7,3.7v0
 C115.6,80.1,114,81.7,112,81.7z"></path>
											<path id="digestion-svg-10-cheese" class="st1" d="M115.6,72.9H61.5v1.3v0.3v4.6c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5v-1.6
 c0-0.3,0.2-0.5,0.5-0.5h0.7c0.3,0,0.5,0.2,0.5,0.5v0c0,0.3,0.2,0.5,0.5,0.5h0.6c0.3,0,0.5-0.2,0.5-0.5v-1.1c0-0.3,0.2-0.5,0.5-0.5
 h0.6c0.3,0,0.5,0.2,0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5v-2h21.1c0,0,0,0.1,0,0.1v1.1c0,0.3,0.2,0.5,0.5,0.5
 h1.8c0.3,0,0.5-0.2,0.5-0.5s0.2-0.5,0.5-0.5h1.5c0.3,0,0.5-0.2,0.5-0.5c0,0,0-0.1,0-0.1h17.8V72.9z"></path>
											<rect
                          id="digestion-svg-10-bun-bottom" x="61.5" y="81.5" class="st1" width="54.1"
                          height="7.4"></rect>
											<g
                          id="digestion-svg-10-burger-seeds">
												<circle id="digestion-svg-10-burger-seed-13" class="st2" cx="81" cy="54" r="0.9"></circle>
												<circle
                            id="digestion-svg-10-burger-seed-12" class="st2" cx="106.3" cy="56.4" r="0.9"></circle>
												<circle
                            id="digestion-svg-10-burger-seed-11" class="st2" cx="68.7" cy="56.7" r="1.1"></circle>
												<circle
                            id="digestion-svg-10-burger-seed-10" class="st2" cx="74.1" cy="57.4" r="0.7"></circle>
												<circle
                            id="digestion-svg-10-burger-seed-9" class="st2" cx="64.2" cy="59.2" r="0.7"></circle>
												<circle
                            id="digestion-svg-10-burger-seed-8" class="st2" cx="79.4" cy="57.7" r="0.7"></circle>
												<circle
                            id="digestion-svg-10-burger-seed-7" class="st2" cx="73.9" cy="54.3" r="0.5"></circle>
												<circle
                            id="digestion-svg-10-burger-seed-6" class="st2" cx="97.5" cy="54.1" r="0.5"></circle>
												<circle
                            id="digestion-svg-10-burger-seed-5" class="st2" cx="102" cy="54.3" r="0.5"></circle>
												<circle
                            id="digestion-svg-10-burger-seed-4" class="st2" cx="87.5" cy="57.2" r="0.5"></circle>
												<circle
                            id="digestion-svg-10-burger-seed-3" class="st2" cx="88.1" cy="53.4" r="1.1"></circle>
												<circle
                            id="digestion-svg-10-burger-seed-2" class="st2" cx="111.7" cy="58.5" r="1.1"></circle>
												<circle
                            id="digestion-svg-10-burger-seed-1" class="st2" cx="96" cy="56.4" r="0.6"></circle>
											</g>
										</g>
										<g
                        id="digestion-svg-10-stars">
											<polygon id="digestion-svg-10-star-6" class="digestion-svg-10-star st7" points="53.4,33.3 54.7,36 57.7,36.4 55.5,38.5 56,41.4 53.4,40 50.8,41.4 51.3,38.5
 49.2,36.4 52.1,36 	" data-svg-origin="49.20000076293945 33.29999923706055"
                               transform="matrix(1.29436,0,0,1.29436,-14.482764796389425,-9.802358723408311)"></polygon>
											<polygon
                          id="digestion-svg-10-star-5" class="digestion-svg-10-star st7" points="132.5,52.4 133.5,54.5 135.8,54.9 134.1,56.5 134.5,58.8 132.5,57.8 130.4,58.8
 130.8,56.5 129.1,54.9 131.4,54.5 	" data-svg-origin="129.10000610351562 52.400001525878906"
                          transform="matrix(1.1907,0,0,1.1907,-24.62011820016872,-9.992983503205494)"></polygon>
											<polygon
                          id="digestion-svg-10-star-4" class="digestion-svg-10-star st7" points="52.5,64.9 53.6,67 55.9,67.4 54.2,69 54.6,71.3 52.5,70.2 50.5,71.3 50.9,69
 49.2,67.4 51.5,67 	" data-svg-origin="49.20000076293945 64.9000015258789"
                          transform="matrix(0.98235,0,0,0.98235,0.8680287528642054,1.1450216770693373)"></polygon>
											<polygon
                          id="digestion-svg-10-star-3" class="digestion-svg-10-star st7" points="127,76.4 128.1,78.5 130.4,78.8 128.7,80.5 129.1,82.8 127,81.7 124.9,82.8
 125.3,80.5 123.6,78.8 126,78.5 	" data-svg-origin="123.5999984741211 76.4000015258789"
                          transform="matrix(0.87485,0,0,0.87485,15.468050865442606,9.56115796368411)"></polygon>
											<polygon
                          id="digestion-svg-10-star-2" class="digestion-svg-10-star st7" points="93.6,33.3 94.4,35 96.3,35.3 94.9,36.6 95.2,38.5 93.6,37.6 91.9,38.5 92.2,36.6
 90.8,35.3 92.7,35 	" data-svg-origin="90.80000305175781 33.29999923706055"
                          transform="matrix(1.112,0,0,1.112,-10.17032620885189,-3.7298661190834466)"></polygon>
											<polygon
                          id="digestion-svg-10-star-1" class="digestion-svg-10-star st7" points="69.8,39.5 70.6,41.2 72.5,41.5 71.1,42.8 71.5,44.7 69.8,43.8 68.1,44.7 68.4,42.8
 67.1,41.5 68.9,41.2 	" data-svg-origin="67.0999984741211 39.5"
                          transform="matrix(1.26555,0,0,1.26555,-17.81905830626856,-10.489609822704658)"></polygon>
										</g>
										<g
                        id="digestion-svg-10-hunters-trap">
											<rect x="82.4" y="88.9" class="st3" width="12.2" height="14.1"></rect>
											<circle
                          class="st4" cx="88.5" cy="109.1" r="11"></circle>
											<rect x="55.8" y="88.9" class="st4"
                            width="65.5"
                            height="3.1"></rect>
											<g
                          id="digestion-svg-10-hunters-trap-right"
                          data-svg-origin="88.89998016357421 110.81000747680665"
                          transform="matrix(0.88357,-0.46829,0.46829,0.88357,-41.54127916311748,54.532639953435414)"
                          style="transform-origin: 0px 0px 0px;">
												<path class="st5" d="M167.6,104.9l-2.5-10.4l-2.5,10.4h-0.1l-3.1-10.4l-3.1,10.4h0l-4.1-10.4l-4.1,10.4h0l-4.6-10.4
 l-4.6,10.4h0l-5.2-10.4l-5.2,10.4h0l-6.2-10.4l-6.2,10.4h0l-6.2-10.4l-6.2,10.4h-7.2c-1.5-2.8-4.4-4.7-7.8-4.7
 c-4.9,0-8.8,4-8.8,8.8c0,4.9,4,8.8,8.8,8.8c3.4,0,6.3-1.9,7.8-4.7h71.3L167.6,104.9L167.6,104.9z M88.5,113.8
 c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7C93.3,111.7,91.1,113.8,88.5,113.8z"></path>
												<path
                            class="st4" d="M97.2,110.7c-0.3,1.6-1,3.1-2.1,4.3h74.8v-4.3H97.2z"></path>
											</g>
											<circle
                          class="st6"
                          cx="88.5"
                          cy="109.1"
                          r="4.7"></circle>
											<g
                          id="digestion-svg-10-hunters-trap-left" data-svg-origin="87.47801067352295 109.94400100708008"
                          transform="matrix(0.88357,0.46829,-0.46829,0.88357,61.670947641180696,-28.16494586634495)"
                          style="transform-origin: 0px 0px 0px;">
												<path class="st5" d="M9.5,104.9v8.3h71.3c1.5,2.8,4.4,4.7,7.8,4.7c4.9,0,8.8-4,8.8-8.8c0-4.9-4-8.8-8.8-8.8
 c-3.4,0-6.3,1.9-7.8,4.7h-7.2l-6.2-10.4l-6.2,10.4h0l-6.2-10.4l-6.2,10.4h0l-5.2-10.4l-5.2,10.4h0l-4.6-10.4L29,104.9h0l-4.1-10.4
 l-4.1,10.4h0l-3.1-10.4l-3.1,10.4h-0.1L12,94.5L9.5,104.9L9.5,104.9z M83.8,109.1c0-2.6,2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7
 c0,2.6-2.1,4.7-4.7,4.7C85.9,113.8,83.8,111.7,83.8,109.1z"></path>
												<path class="st4"
                              d="M79.9,110.7c0.3,1.6,1,3.1,2.1,4.3H7.2v-4.3H79.9z"></path>
											</g>
										</g>
										<g
                        id="digestion-svg-10-clouds">
											<path id="digestion-svg-10-cloud-3" class="digestion-svg-10-cloud st8" d="M25.9,63.1c0.3-3.2-1.9-6.1-5.1-6.7c-0.2,0-0.3-0.1-0.5-0.1c-0.4-3.2-2.8-5.9-6.1-6.6
 c-4.1-0.8-8.2,1.9-9,6C5.1,56.3,5,57,5,57.6c-2.1,1.1-3.8,3-4.5,5.5H25.9z" data-svg-origin="0.5 49.55955123901367"
                            transform="matrix(0.99774,0,0,0.99774,1.9785875075785624,0.11170458055949695)"></path>
											<path
                          id="digestion-svg-10-cloud-2" class="digestion-svg-10-cloud st8" d="M170.6,38.6c0.5-5.9-3.5-11.3-9.4-12.5c-0.3-0.1-0.6-0.1-1-0.1c-0.7-5.9-5.2-10.9-11.3-12.2
 c-7.7-1.5-15.1,3.5-16.7,11.1c-0.2,1.2-0.3,2.4-0.3,3.5c-4,2-7.1,5.6-8.4,10.1H170.6z"
                          data-svg-origin="123.50000762939453 13.5340576171875"
                          transform="matrix(0.95628,0,0,0.95628,6.9619576267760195,0.5916776731338071)"></path>
											<path
                          id="digestion-svg-10-cloud-1" class="digestion-svg-10-cloud st8" d="M89.1,17.3c0.3-4-2.3-7.6-6.3-8.4c-0.2,0-0.4-0.1-0.6-0.1c-0.5-4-3.5-7.4-7.6-8.2
 c-5.2-1-10.2,2.3-11.2,7.5c-0.2,0.8-0.2,1.6-0.2,2.4c-2.7,1.4-4.8,3.8-5.6,6.8H89.1z"
                          data-svg-origin="57.599998474121094 0.4216110110282898"
                          transform="matrix(0.87294,0,0,0.87294,8.047919871131219,0.053568691034139804)"></path>
										</g>
									</svg>
								</div>
								<p>Симптоми гострого порушення роботи підшлункової залози проявляються раптово. Людина страждає від сильних болів в епігастральній (підложечній) ділянці, у неї частий пронос, блювота, після якої не стає краще. Неможливо ні повноцінно відпочити, ні поспати, ні зайнятися повсякденними справами<sup>4</sup>.
								</p>
								<p
                    class="connected-paragraph">Гострий панкреатит – це важкий стан, що потребує госпіталізації.</p>
								<p
                    class="attention-paragraph">А якщо ж ви переїли, то симптоми здуття живота та дискомфортні відчуття важкості в шлунку допоможе зняти ферментний препарат
									<span>ФЕСТАЛ<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000<sup>7</sup>
									</span>.
								</p>
								<p
                    class="note-paragraph">Виникнення вказаних вище симптомів є приводом для звернення за консультацією до лікаря, який може порадити приймання препаратів, призначених для нормалізації процесів травлення.</p>
							</section>
							<div
                  id="triple-help-block">
								<ul class="custom-unordered-list">
									<li>
										<div
                        class="custom-list-dot"></div>
										<span
                        class="custom-list-item">Ферментний препарат
											<span>Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
											</span> містить панкреатин в дозуванні 10 000 одиниць по ліпазі.
										</span>
									</li>
									<li>
										<div
                        class="custom-list-dot"></div>
                    <span class="custom-list-item">Його формула сприяє травленню, зменшенню відчуття важкості і здуття живота.
										</span>
									</li>
									<li>
										<div
                        class="custom-list-dot"></div>
										<span
                        class="custom-list-item">
											<span>Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
											</span> допомагає перетравлювати білки, жири і вуглеводи, і таким чином нормалізує процеси травлення<sup>7</sup>.
										</span>
									</li>
								</ul>
								<div
                    class="slogan-block">
									<img src="../assets/imgs/tripleHelpBlock/pack.png" alt="">
										<p
                        class="slogan">Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
											<br>
												<span>потрійна</span>
												<br>
													<span>допомога травленню!<sup>*</sup>
													</span>
												</p>
											</div>
										</div>
										<div
                        class="bottom-note-block">
											<p class="footnote">
                  Адаптовано з:

												<br>
													<sup>1</sup> “Overview of Gastrointestinal Function & Regulation.” Ganong's Review of Medical Physiology, by Kim E. Barrett and William F. Ganong, McGraw-Hill Education, 2019, pp. 1038–1172.

													<br>
														<sup>2</sup> “Endocrine Functions of the Pancreas &amp; Regulation of Carbohydrate Metabolism.” Ganong's Review of Medical Physiology, by Kim E. Barrett and William F. Ganong, McGraw-Hill Education, 2019, pp. 986–1037.

														<br>
															<sup>3</sup> Матвеева Л. Ферментная недостаточность поджелудочной железы: проблема и ее коррекция. Здоров’я України, 2015; 20(369): 18-19.

															<br>
																<sup>4</sup> O'Brien SJ, Omer E. Chronic Pancreatitis and Nutrition Therapy. Nutr Clin Pract. 2019 Oct;34 Suppl 1:S13-S26. doi: 10.1002/ncp.10379. PMID: 31535736

																<br>
																	<sup>5</sup> Edderkaoui, Mouad. Thrower, Edwin. (2015). Smoking induced pancreatitis and pancreatic cancer. Pancreapedia: Exocrine Pancreas Knowledge Base, DOI: 10.3998/panc.2015.25

																	<br>
																		<sup>6</sup> Khatua B, El-Kurdi B, Singh VP. Obesity and pancreatitis. Curr Opin Gastroenterol. 2017 Sep;33(5):374-382. doi: 10.1097/MOG.0000000000000386. PMID: 28719397; PMCID: PMC6640854.

																		<br>
																			<sup>7</sup> Інструкція для медичного застосування препарату Фестал<sup>®</sup> НЕО 10000, таблетки, вкриті оболонкою, кишковорозчинні. РП №UA/14533/01/01. Наказ МОЗ України №1994 від 21.09.2021.


                        <!--																			<br>-->
                        <!--																				<sup>*</sup> Формула оновлена за рахунок збільшення вмісту ліпази (на 3 000 МО) та α-амілази (на 2 500 МО) порівняно із препаратом Фестал<sup>®</sup> РП №UA2531/01/01. Наказ МОЗ України №341 від 29.03.2017.-->

																				<br>
																					<sup>*</sup> До складу препарату входять три основні панкреатичні травні ферменти: ліпаза, α-амілаза, протеази (трипсин і хімотрипсин), які чинять ліполітичну, амілолітичну і протеолітичну дію.

																				</p>
																				<p
                                            class="adversting-text">Реклама лікарського засобу. Перед застосуванням лікарського засобу необхідно проконсультуватися з лікарем та обов’язково ознайомитися з інструкцією для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000. Зберігати у недоступному для дітей місці.
																				</p>
																				<table
                                            class="adversting-text-table">
																					<tbody>
																						<tr class="top-line">
																							<td>

																							</td>
																							<td>
<!--																								<router-link to="/feedback">Зворотний зв'язок</router-link>.-->
																							</td>
																						</tr>
																						<tr
                                                class="bottom-line">
																							<td><router-link to="/official-notice">Офіційне повідомлення</router-link>.<br>
                                                Всі права захищені<sup>®</sup> 2021. Дата останнього перегляду 08.07.2022. Сайт призначений виключно для відвідувачів з України.
																							</td>
																							<td>

																									<a
                                                      href="http://www.sanofi.ua"
                                                      style="padding-right: 5px; display: inline-block;"
                                                      target="_blank">www.sanofi.ua</a>



																									<a
                                                      href="http://www.festal.ua" target="_blank">www.festal.ua</a>
																								<br>MAT-UA-2200466-1.0-07/2022
																							</td>
																							<td>
																								<router-link class="non-line"
                                                             to="/instruction">Інструкція для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000, таблетки, вкриті оболонкою, кишковорозчинні.
																																												</router-link><br>РП №UA/14533/01/01. Наказ МОЗ України №1994 від 21.09.2021.
																							</td>
																						</tr>
																					</tbody>
																				</table>
																			</div>
																			<footer
                                          id="self-medication-block">
																				<div class="word">
																					<div class="letter s"></div>
																					<div
                                              class="letter a"></div>
																					<div class="letter m bigger-letter"></div>
																					<div class="letter o"></div>
																					<div
                                              class="letter l"></div>
																					<div class="letter i smaller-letter"></div>
																					<div class="letter k"></div>
																					<div
                                              class="letter u"></div>
																					<div class="letter v"></div>
																					<div class="letter a"></div>
																					<div
                                              class="letter n"></div>
																					<div class="letter n"></div>
																					<div class="letter ia"></div>
																				</div>
																				<div
                                            class="word">
																					<div class="letter m bigger-letter"></div>
																					<div class="letter o"></div>
																					<div
                                              class="letter ge bigger-letter"></div>
																					<div class="letter e"></div>
																				</div>
																				<div class="word">
																					<div
                                              class="letter b"></div>
																					<div class="letter u"></div>
																					<div class="letter t"></div>
																					<div
                                              class="letter ii"></div>
																				</div>
																				<div class="word">
																					<div class="letter sh bigger-letter"></div>
																					<div
                                              class="letter k"></div>
																					<div class="letter i smaller-letter"></div>
																					<div class="letter d"></div>
																					<div
                                              class="letter l"></div>
																					<div class="letter ii"></div>
																					<div class="letter v"></div>
																					<div
                                              class="letter ii"></div>
																					<div class="letter m bigger-letter"></div>
																				</div>
																				<div class="word">
																					<div
                                              class="letter d"></div>
																					<div class="letter l"></div>
																					<div class="letter ia"></div>
																				</div>
																				<div
                                            class="word">
																					<div class="letter v"></div>
																					<div class="letter a"></div>
																					<div
                                              class="letter sh bigger-letter"></div>
																					<div class="letter o"></div>
																					<div class="letter g"></div>
																					<div
                                              class="letter o"></div>
																				</div>
																				<div class="word">
																					<div class="letter z"></div>
																					<div
                                              class="letter d"></div>
																					<div class="letter o"></div>
																					<div class="letter r"></div>
																					<div
                                              class="letter o"></div>
																					<div class="letter v"></div>
																					<div
                                              class="letter znak smaller-letter"></div>
																					<div
                                              class="letter ia"></div>
																				</div>
																				<svg id="self-medication-svg"
                                             xmlns="http://www.w3.org/2000/svg"
                                             xmlns:xlink="http://www.w3.org/1999/xlink"
                                             xml:space="preserve"
                                             x="0px" y="0px" viewBox="0 0 568.5 58.4">
																					<g id="self-medication-svg-letters">
																						<path class="self-medication-svg-letter" d="M8.8,17.2c0-5,1.3-7.9,4.8-7.9c3.5,0,4.8,2.9,4.8,7.9V18c0,0-0.1,0.2-0.2,0.2S18,18,18,18v-0.8
 c0-4.4-1.1-6.8-4.4-6.8c-3.3,0-4.3,2.4-4.3,6.8v23.2c0,4.4,1.1,6.8,4.3,6.8c3,0,4.6-2.5,4.6-2.5s0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6s-1.3,2.5-4.6,2.5c-3.5,0-4.8-2.9-4.8-7.9V17.2z"></path>
																						<path class="self-medication-svg-letter"
                                                  d="M24.1,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2L30,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9l-1.7,12c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L24.1,9.5z M21.1,34.8h6.6l-3.3-24.2L21.1,34.8z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M43.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																						<path class="self-medication-svg-letter" d="M45.4,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M45.9,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M60.1,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L60.1,9.7z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M70,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6
 h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6H70z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M76.2,28.5L74,30.1V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L76.2,28.5z"></path>
																						<path class="self-medication-svg-letter" d="M87,37c0,0-0.1-0.1-0.1-0.1L82,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L86.6,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L87,37z"></path>
																						<path class="self-medication-svg-letter" d="M93.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M93.7,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M93.7,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																						<path class="self-medication-svg-letter"
                                                  d="M107.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L103,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L107.8,9.5z M104.8,34.8h6.6l-3.3-24.2L104.8,34.8z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M124.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M135.9,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M146.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L137.7,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M146,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																						<path class="self-medication-svg-letter"
                                                  d="M166.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2
 c-0.2,0-0.3-0.2-0.3-0.2l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 l5.7,30.5l5.8-30.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																						<path class="self-medication-svg-letter" d="M168.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M168.7,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M179.1,48c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.5-20.2l-4.2-18.2c0,0,0.1-0.2,0.3-0.2
 c0.2,0,0.3,0.2,0.3,0.2l4.1,17.7l2.1,1.7V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2v19.3l2.1-1.7l4.1-17.7
 c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-4.2,18.2l4.5,20.2c0,0-0.1,0.2-0.3,0.2c-0.1,0-0.3-0.2-0.3-0.2L188,28.3l-2.1,1.7v18
 c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V30l-2.1-1.7L179.1,48z"></path>
																						<path class="self-medication-svg-letter" d="M194.2,47h8.1c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.5c-2.4,0-4-0.3-4-0.3V9.7c0,0,1.6-0.3,4-0.3h4.4
 c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-8v17.1h7.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.2V47z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M209.5,26.8c0,0,1.7-0.2,4-0.2c3.2,0,4.5,3.1,4.5,8.4v4.8c0,5.3-1.3,8.4-4.5,8.4c-2.6,0-4.4-0.3-4.4-0.3V9.7
 c0,0,1.6-0.3,4-0.3h4.3c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.9V26.8z M209.5,47.1h4c3,0,4.1-2.5,4.1-7.3V35
 c0-4.8-1.1-7.3-4.1-7.3h-4V47.1z"></path>
																						<path class="self-medication-svg-letter" d="M223.5,37c0,0-0.1-0.1-0.1-0.1l-4.9-27.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L223.1,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L223.5,37z"></path>
																						<path class="self-medication-svg-letter" d="M234.1,10.5V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5h-4.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h9.8c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6H234.1z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M249.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L240.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																						<path class="self-medication-svg-letter" d="M272.1,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C272.1,9.4,272.1,9.5,272.1,9.5"></path>
																						<path
                                                class="self-medication-svg-letter" d="M276.7,28.5l-2.2,1.7V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L276.7,28.5z"></path>
																						<path class="self-medication-svg-letter" d="M284.4,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6
 H284.4z"></path>
																						<path class="self-medication-svg-letter" d="M287.1,47.1c1.8,0,2.1-2.3,2.3-6.8L291,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M297.4,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H297.4z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M303.9,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L303.9,9.7z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M322.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L313.1,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46L322,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z
 "></path>
																						<path class="self-medication-svg-letter" d="M324.4,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M324.8,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M324.8,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																						<path class="self-medication-svg-letter"
                                                  d="M344.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L335.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																						<path class="self-medication-svg-letter" d="M359.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2V48z"></path>
																						<path class="self-medication-svg-letter" d="M366.5,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2H367v8.2c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 V47.1z M376.9,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H376.9z"></path>
																						<path class="self-medication-svg-letter"
                                                  d="M383.3,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L383.3,9.7z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M400.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L392,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M400.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M405.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M405.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M405.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																						<path class="self-medication-svg-letter"
                                                  d="M419.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L415,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L419.8,9.5z M416.8,34.8h6.6l-3.3-24.2L416.8,34.8z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M441.6,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C441.6,9.4,441.6,9.5,441.6,9.5"></path>
																						<path
                                                class="self-medication-svg-letter" d="M443.6,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M444,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M455.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.7c0,0,1.6-0.3,4-0.3h3.7c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-7.2V48z"></path>
																						<path class="self-medication-svg-letter" d="M463.9,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M464.3,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M489.5,17.8v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3c0,5.4-1.3,8.7-4.5,8.7c-3.2,0-4.5-2.5-4.5-2.5
 s-0.1-0.2-0.1-0.6c0-0.4,0.1-0.6,0.1-0.6s1.5,2.5,4.5,2.5s4.1-2.6,4.1-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-1.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h1.8c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.8-7.4c-3.2,0-4.2,2.4-4.2,6.8V18c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2v-0.8c0-5,1.3-7.9,4.7-7.9C488.3,9.3,489.5,12.4,489.5,17.8"></path>
																						<path
                                                class="self-medication-svg-letter" d="M490.3,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M500.7,47V10.5h-6.1L493,40.4c-0.2,3.1-0.4,5.4-1.1,6.6H500.7z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M504,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M504.4,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M520,9.3c3.2,0,4.5,2.9,4.5,7.9V22c0,5-1.3,7.9-4.5,7.9c-2.3,0-3.8-0.2-3.8-0.2V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5C515.8,9.5,517.5,9.3,520,9.3 M516.2,28.8h3.8c3,0,4.1-2.4,4.1-6.8v-4.8c0-4.4-1.1-6.8-4.1-6.8h-3.8
 V28.8z"></path>
																						<path class="self-medication-svg-letter" d="M526.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M526.8,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																						<path
                                                class="self-medication-svg-letter" d="M538.1,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M538.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M538.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																						<path class="self-medication-svg-letter"
                                                  d="M548.1,18.7c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2s1.1-2.7,1.1-9.2c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2C549.2,16.3,548.1,18.7,548.1,18.7"></path>
																						<path class="self-medication-svg-letter" d="M559.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L551,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M559.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																					</g>
																				</svg>
																			</footer>
																		</div>
																	</span>
    </div>
  </div>
</template>

<script>

import actualPaddingSetter from '../modules/actualPaddingSetter';
import scrollToDestination from '../modules/scrollToDestination';
import digestionSvgAnimate from '../modules/svg_animation/digestionSvgAnimate';


export default {
  name: "digestion",
  mounted() {

    let trigger25 = true;
    let trigger50 = true;
    let trigger75 = true;
    let trigger90 = true;

    window.addEventListener("scroll", () => {

      var h = document.documentElement,
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';

      var percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);
      if (percent >= 25 && percent < 50 && trigger25) {
        trigger25 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол сторінки 25%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 50 && percent < 75 && trigger50) {
        trigger50 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 50%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 75 && percent < 90 && trigger75) {
        trigger75 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 75%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 90 && trigger90) {
        trigger90 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 90%',
          'event_label': 'UA: ' + this.$route.path
        })
      }
    });


    digestionSvgAnimate();
    let headerHeight = actualPaddingSetter();

    actualPaddingSetter();
    scrollToDestination(headerHeight);
  }
}
</script>

<style lang="less">


</style>
