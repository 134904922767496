<template>
  <div class="nutritionAdvices" data-view>
    <div data-reactroot="" id="content">
      <div id="router-container">
        <top-menu></top-menu>
        <message-alert></message-alert>
        <span>
				<div id="nutrition-advices-page-container" class="content-container" style="padding-top: 60px;">
					<div
              id="part_1" class="title-block">
						<h1>Поради та рекомендації
							<br>з харчування
							</h1>
						</div>
						<section
                class="main-column">
							<p>Профілактика захворювань підшлункової залози має велике значення для недопущення проблем з травленням<sup>1</sup>. Але що робити, якщо підшлункова залоза раптово почала вас турбувати?
							</p>
              <!--							<p class="connected-paragraph attention-paragraph">Перша рекомендація – звернутись до лікаря.</p>-->
							<p
                  class="connected-paragraph">Потрібно звернутися за консультацією до лікаря і, після встановлення лікарем відповідного діагнозу, почати лікування згідно з рекомендаціями лікаря і дотримуватися особливого харчування при захворюванні підшлункової залози<sup>2</sup>.
							</p>
							<div
                  class="inline-svg-center-block">
								<svg id="advices-svg-1" class="inline-svg-illustration"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                     viewBox="0 0 143.9 93.3">
									<g id="advices-svg-1-drugs">
										<path id="advices-svg-1-drugs-pack" class="st0" d="M110.1,90.8V78.3c0-1.2,1-2.1,2.1-2.1h29.2c1.2,0,2.1,1,2.1,2.1v12.6c0,1.2-1,2.1-2.1,2.1h-29.2
 C111.1,92.9,110.1,92,110.1,90.8z"></path>
										<g id="advices-svg-1-tablet-8" class="advices-svg-1-tablet"
                       data-svg-origin="138.6999969482422 80.25"
                       transform="matrix(0.82628,0,0,0.82628,23.654588049097296,13.45081304368487)"
                       style="transform-origin: 0px 0px 0px;">
											<path class="st1" d="M140.9,80.6V80h-0.3c-0.4-0.6-1.1-1.1-1.9-1.1s-1.5,0.4-1.9,1.1h-0.3v0.5c0,1.2,1,2.2,2.2,2.2
 S140.9,81.8,140.9,80.6z"></path>
											<circle class="advices-svg-1-tablet-face st2" cx="138.7" cy="80" r="2.2"
                              data-svg-origin="138.70000004768372 80.00000309944153"
                              transform="matrix(1,0,0,1,0,0)"
                              style="transform-origin: 0px 0px 0px; fill: rgb(60, 165, 229);"></circle>
										</g>
										<g
                        id="advices-svg-1-tablet-7" class="advices-svg-1-tablet"
                        data-svg-origin="138.6999969482422 87.6500015258789"
                        transform="matrix(0.93265,0,0,0.93265,8.901359102595261,5.413173675340724)"
                        style="transform-origin: 0px 0px 0px;">
											<path class="st1" d="M140.9,87.9v-0.5h-0.3c-0.4-0.6-1.1-1.1-1.9-1.1s-1.5,0.4-1.9,1.1h-0.3v0.5
 c0,1.2,1,2.2,2.2,2.2S140.9,89.2,140.9,87.9z"></path>
											<circle class="advices-svg-1-tablet-face st2" cx="138.7" cy="87.4"
                              r="2.2"
                              data-svg-origin="138.70000004768372 87.40000462532043"
                              transform="matrix(1,0,0,1,0,0)"
                              style="transform-origin: 0px 0px 0px; fill: rgb(85, 150, 245);"></circle>
										</g>
										<g
                        id="advices-svg-1-tablet-6" class="advices-svg-1-tablet"
                        data-svg-origin="131.1999969482422 80.25"
                        transform="matrix(0.99762,0,0,0.99762,-0.1288454768859877,-0.29967848964844834)"
                        style="transform-origin: 0px 0px 0px;">
											<path class="st1" d="M133.4,80.6V80h-0.3c-0.4-0.6-1.1-1.1-1.9-1.1s-1.5,0.4-1.9,1.1H129v0.5
 c0,1.2,1,2.2,2.2,2.2S133.4,81.8,133.4,80.6z"></path>
											<circle class="advices-svg-1-tablet-face st2" cx="131.2" cy="80"
                              r="2.2"
                              data-svg-origin="131.20000004768372 80.00000309944153"
                              transform="matrix(1,0,0,1,0,0)"
                              style="transform-origin: 0px 0px 0px; fill: rgb(99, 141, 255);"></circle>
										</g>
										<g
                        id="advices-svg-1-tablet-5" class="advices-svg-1-tablet"
                        data-svg-origin="131.1999969482422 87.6500015258789"
                        transform="matrix(0.97371,0,0,0.97371,3.008292227920916,1.8136802793447175)"
                        style="transform-origin: 0px 0px 0px;">
											<path class="st1" d="M133.4,87.9v-0.5h-0.3c-0.4-0.6-1.1-1.1-1.9-1.1s-1.5,0.4-1.9,1.1H129v0.5
 c0,1.2,1,2.2,2.2,2.2S133.4,89.2,133.4,87.9z"></path>
											<circle class="advices-svg-1-tablet-face st2" cx="131.2" cy="87.4"
                              r="2.2"
                              data-svg-origin="131.20000004768372 87.40000462532043"
                              transform="matrix(1,0,0,1,0,0)"
                              style="transform-origin: 0px 0px 0px; fill: rgb(94, 145, 251);"></circle>
										</g>
										<g
                        id="advices-svg-1-tablet-4" class="advices-svg-1-tablet" data-svg-origin="123.75 80.25"
                        transform="matrix(0.86734,0,0,0.86734,15.965514500000072,10.155213043684947)"
                        style="transform-origin: 0px 0px 0px;">
											<path class="st1" d="M126,80.6V80h-0.3c-0.4-0.6-1.1-1.1-1.9-1.1c-0.8,0-1.5,0.4-1.9,1.1h-0.3v0.5
 c0,1.2,1,2.2,2.2,2.2S126,81.8,126,80.6z"></path>
											<circle class="advices-svg-1-tablet-face st2" cx="123.7" cy="80"
                              r="2.2" data-svg-origin="123.70000004768372 80.00000309944153"
                              transform="matrix(1,0,0,1,0,0)"
                              style="transform-origin: 0px 0px 0px; fill: rgb(69, 160, 235);"></circle>
										</g>
										<g
                        id="advices-svg-1-tablet-3" class="advices-svg-1-tablet"
                        data-svg-origin="123.75 87.6500015258789"
                        transform="matrix(0.80237,0,0,0.80237,24.006514500000012,16.832130171908567)"
                        style="transform-origin: 0px 0px 0px;">
											<path class="st1" d="M126,87.9v-0.5h-0.3c-0.4-0.6-1.1-1.1-1.9-1.1c-0.8,0-1.5,0.4-1.9,1.1h-0.3v0.5
 c0,1.2,1,2.2,2.2,2.2S126,89.2,126,87.9z"></path>
											<circle class="advices-svg-1-tablet-face st2" cx="123.7" cy="87.4"
                              r="2.2" data-svg-origin="123.70000004768372 87.40000462532043"
                              transform="matrix(1,0,0,1,0,0)"
                              style="transform-origin: 0px 0px 0px; fill: rgb(55, 169, 225);"></circle>
										</g>
										<g
                        id="advices-svg-1-tablet-2" class="advices-svg-1-tablet"
                        data-svg-origin="116.29999923706055 80.25"
                        transform="matrix(0.82628,0,0,0.82628,19.763318886775156,13.45081304368487)"
                        style="transform-origin: 0px 0px 0px;">
											<path class="st1" d="M118.5,80.6V80h-0.3c-0.4-0.6-1.1-1.1-1.9-1.1s-1.5,0.4-1.9,1.1h-0.3v0.5
 c0,1.2,1,2.2,2.2,2.2S118.5,81.8,118.5,80.6z"></path>
											<circle class="advices-svg-1-tablet-face st2" cx="116.3" cy="80"
                              r="2.2"
                              data-svg-origin="116.30000615119934 80.00000309944153"
                              transform="matrix(1,0,0,1,0,0)"
                              style="transform-origin: 0px 0px 0px; fill: rgb(60, 165, 229);"></circle>
										</g>
										<g
                        id="advices-svg-1-tablet-1" class="advices-svg-1-tablet"
                        data-svg-origin="116.29999923706055 87.6500015258789"
                        transform="matrix(0.93265,0,0,0.93265,7.3927309057051955,5.413173675340724)"
                        style="transform-origin: 0px 0px 0px;">
											<path class="st1" d="M118.5,87.9v-0.5h-0.3c-0.4-0.6-1.1-1.1-1.9-1.1s-1.5,0.4-1.9,1.1h-0.3v0.5
 c0,1.2,1,2.2,2.2,2.2S118.5,89.2,118.5,87.9z"></path>
											<circle class="advices-svg-1-tablet-face st2" cx="116.3" cy="87.4"
                              r="2.2"
                              data-svg-origin="116.30000615119934 87.40000462532043"
                              transform="matrix(1,0,0,1,0,0)"
                              style="transform-origin: 0px 0px 0px; fill: rgb(85, 150, 245);"></circle>
										</g>
									</g>
									<g
                      id="advices-svg-1-mountains">
										<path id="advices-svg-1-mountain-5" class="st3" d="M53.1,89.2l-4.6-6.4c-0.9-1.3-2.9-1.3-3.8,0l-4.6,6.4c-1.1,1.6,0,3.7,1.9,3.7h9.1
 C53.1,92.9,54.2,90.7,53.1,89.2z"></path>
										<path id="advices-svg-1-mountain-4" class="st4" d="M41.5,83.4l-9.6-13.5c-1.3-1.8-3.9-1.8-5.2,0l-9.6,13.5c-1.5,2.1,0,5,2.6,5H39
 C41.5,88.4,43,85.5,41.5,83.4z"></path>
										<path id="advices-svg-1-mountain-3" class="st5" d="M48.4,87.9l-6.2-8.7c-1.3-1.8-3.9-1.8-5.2,0l-6.2,8.7c-1.5,2.1,0,5,2.6,5h12.4
 C48.4,92.9,49.9,90,48.4,87.9z"></path>
										<path id="advices-svg-1-mountain-2" class="st6" d="M37.8,87.2L26.9,71.9c-1.4-2-4.4-2-5.9,0L10.1,87.2c-1.7,2.4,0,5.7,2.9,5.7h21.8
 C37.8,92.9,39.5,89.6,37.8,87.2z"></path>
										<path id="advices-svg-1-mountain-1" class="st7" d="M14.8,90l-5.6-7.8c-0.7-1-2.3-1-3,0L0.7,90c-0.9,1.2,0,2.9,1.5,2.9h11.1
 C14.8,92.9,15.7,91.2,14.8,90z"></path>
									</g>
									<g id="advices-svg-1-trees">
										<path id="advices-svg-1-tree-2" class="advices-svg-1-tree st7" d="M120.5,69.1c-0.1,0-0.3-0.1-0.4-0.1c-0.3-2.7-2.4-5.1-5.2-5.6c-3.6-0.7-7,1.6-7.7,5.2
 c-0.1,0.5-0.1,1.1-0.1,1.6c-2,1-3.6,2.9-4,5.3c-0.8,4,1.7,7.8,5.5,8.8c0.7,1.7,2,3,3.8,3.7v4.8h4.6V88c2-0.8,3.7-2.6,4.1-4.9
 c0.2-1.2,0.1-2.4-0.3-3.5c2-0.5,3.6-2.1,4-4.2C125.3,72.6,123.4,69.7,120.5,69.1z"
                          data-svg-origin="113.91527938842773 92.80000305175781"
                          transform="matrix(0.99762,-0.06886,0.06886,0.99762,-3.588717747210282,6.996810453075199)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="advices-svg-1-tree-1" class="advices-svg-1-tree st6" d="M127.7,78.5c-0.1,0-0.2,0-0.3,0c-0.2-1.7-1.5-3.1-3.3-3.5c-2.2-0.4-4.4,1-4.8,3.2
 c-0.1,0.3-0.1,0.7-0.1,1c-1.3,0.6-2.2,1.8-2.5,3.3c-0.5,2.5,1,4.9,3.4,5.5c0.4,1,1.3,1.9,2.3,2.3v2.6h2.9v-2.6
 c1.3-0.5,2.3-1.6,2.6-3c0.1-0.7,0.1-1.5-0.2-2.2c1.2-0.3,2.2-1.3,2.5-2.6C130.7,80.6,129.5,78.8,127.7,78.5z"
                        data-svg-origin="123.45902252197266 92.9000015258789"
                        transform="matrix(0.99998,0.00534,-0.00534,0.99998,2.037753708165056,-1.3239599381016447)"
                        style="transform-origin: 0px 0px 0px;"></path>
									</g>
									<g id="advices-svg-1-clouds">
										<path id="advices-svg-1-cloud-3" class="advices-svg-1-cloud st8" d="M41.1,30.1c0.3-3.5-2.1-6.7-5.6-7.4c-0.2,0-0.4-0.1-0.6-0.1c-0.4-3.5-3.1-6.5-6.7-7.2
 c-4.6-0.9-9,2.1-9.9,6.6c-0.1,0.7-0.2,1.4-0.2,2.1c-2.4,1.2-4.2,3.3-5,6H41.1z"
                          data-svg-origin="13.100000381469727 15.24033260345459"
                          transform="matrix(0.82628,0,0,0.82628,-2.067235671509888,4.121855039414838)"></path>
										<path
                        id="advices-svg-1-cloud-2" class="advices-svg-1-cloud st8" d="M14,68.2c0.1-1.1-0.6-2.1-1.7-2.3c-0.1,0-0.1,0-0.2,0c-0.1-1.1-0.9-2-2.1-2.2
 c-1.4-0.3-2.8,0.6-3,2c0,0.2-0.1,0.4,0,0.6c-0.7,0.4-1.3,1-1.5,1.8H14z" data-svg-origin="5.5 63.642295837402344"
                        transform="matrix(0.93265,0,0,0.93265,-1.3133128666666556,3.633256580553806)"></path>
										<path
                        id="advices-svg-1-cloud-1" class="advices-svg-1-cloud st8" d="M69.1,11.2c0.2-2.3-1.4-4.5-3.7-4.9c-0.1,0-0.3,0-0.4-0.1c-0.3-2.3-2-4.3-4.5-4.8
 c-3-0.6-6,1.4-6.6,4.4c-0.1,0.5-0.1,0.9-0.1,1.4c-1.6,0.8-2.8,2.2-3.3,4H69.1z"
                        data-svg-origin="50.500003814697266 1.2935545444488525"
                        transform="matrix(0.99762,0,0,0.99762,0.060475809046934614,-1.9495002060423874)"></path>
									</g>
									<g
                      id="advices-svg-1-bottle">
										<rect id="advices-svg-1-bottle-cap" x="93.6" y="55.9" class="st9" width="17.7" height="5.4"></rect>
										<path
                        id="advices-svg-1-bottle-body" class="st7" d="M109.3,61.3H95.6l-2.9,4.3c-0.3,0.5-0.5,1-0.5,1.6v25.7h20.4V67.2c0-0.6-0.2-1.1-0.5-1.6
 L109.3,61.3z" data-svg-origin="92.19999694824219 61.29999923706055" transform="matrix(1,0,0,1,0,0)"
                        style="fill: rgb(87, 158, 248);"></path>
										<rect id="advices-svg-1-bottle-label" x="92.3" y="71" class="st9"
                          width="20.4" height="17.3"></rect>
										<rect
                        id="advices-svg-1-bottle-label-stripe" x="92.3" y="73.3" class="st10" width="20.4"
                        height="3.9"></rect>
									</g>
									<g
                      id="advices-svg-1-case">
										<path id="advices-svg-1-handle" class="st10" d="M50.9,38.3v7.1c0,1.6,1.3,3,3,3h0c1.6,0,3-1.3,3-3v-5.3c0-1.1,0.9-2,2-2H69c1.1,0,2,0.9,2,2
 v5.3c0,1.6,1.3,3,3,3h0c1.6,0,3-1.3,3-3v-7.1c0-2.8-2.3-5.1-5.1-5.1H56C53.2,33.2,50.9,35.5,50.9,38.3z"></path>
										<path
                        id="advices-svg-1-case-body" class="st11" d="M92.2,45.5H35.6c-3.4,0-6.2,2.8-6.2,6.2v35.1c0,3.4,2.8,6.2,6.2,6.2h56.6
 c3.4,0,6.2-2.8,6.2-6.2V51.6C98.4,48.2,95.6,45.5,92.2,45.5z"></path>
										<g id="advices-svg-1-case-cross-mark"
                       data-svg-origin="63.900001525878906 69.19999694824219"
                       transform="matrix(0.99489,0.10092,-0.10092,0.99489,7.309950335706482,-6.0955266832308865)"
                       style="transform-origin: 0px 0px 0px;">
											<circle id="advices-svg-1-case-circle" class="st12" cx="63.9" cy="69.2" r="16.5"></circle>
											<polygon
                          id="advices-svg-1-case-cross" class="st10" points="75,65.5 67.6,65.5 67.6,58.1 60.2,58.1 60.2,65.5 52.8,65.5 52.8,72.9 60.2,72.9
 60.2,80.3 67.6,80.3 67.6,72.9 75,72.9 	"></polygon>
										</g>
										<rect id="advices-svg-1-case-part-2" x="37.9" y="43.4"
                          class="st10" width="4.1" height="49.5"></rect>
										<rect
                        id="advices-svg-1-case-part-1" x="85.8" y="43.4" class="st10" width="4.1" height="49.5"></rect>
									</g>
									<g
                      id="advices-svg-1-exclamation-mark-quote" data-svg-origin="107.60000228881836 21.10000115633011"
                      transform="matrix(0.89938,0,0,0.89938,8.066550833440767,5.478512673438523)"
                      style="transform-origin: 0px 0px 0px;">
										<path id="advices-svg-1-exclamation-mark-buble" class="st13" d="M100.4,0.3h14.5c3.6,0,6.5,2.9,6.5,6.5v22.9c0,3.6-2.9,6.5-6.5,6.5h-4.8
 c-1.2,2.1-4,5.6-9.7,5.7c2.2-1.7,3.1-3.9,3.4-5.7h-3.5c-3.6,0-6.5-2.9-6.5-6.5V6.8C93.9,3.2,96.8,0.3,100.4,0.3z"></path>
										<path
                        id="advices-svg-1-exclamation-mark" class="st10" d="M107.5,28.9c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3
 S109.3,28.9,107.5,28.9z M109.8,19.8c0,0.5-0.1,0.6-0.6,0.6h-3.5c-0.5,0-0.5-0.1-0.6-0.6l-0.7-11.5c0-0.3,0.1-0.4,0.6-0.4h4.9
 c0.5,0,0.6,0.1,0.6,0.4v0.2L109.8,19.8z"></path>
									</g>
								</svg>
							</div>
							<h2 class="subtitle">Найперші рекомендації
								<br>в період загострення:
								</h2>
								<ul
                    class="custom-unordered-list">
									<li>
										<div class="custom-list-dot"></div>
										<span class="custom-list-item">Нічого не їсти.</span>
									</li>
									<li>
										<div
                        class="custom-list-dot"></div>
										<span class="custom-list-item">Прикласти до місця, де ви відчуваєте біль, щось холодне (наприклад, грілку з холодною водою або льодом).</span>
									</li>
									<li>
										<div
                        class="custom-list-dot"></div>
										<span class="custom-list-item">Забезпечити організму відпочинок та спокій<sup>2</sup>.
										</span>
									</li>
									<li>
										<div
                        class="custom-list-dot"></div>
										<span class="custom-list-item attention-custom-list-item">Категорично забороняється палити і вживати алкоголь<sup>1</sup>.
										</span>
									</li>
								</ul>
								<h2
                    class="subtitle">Харчування при загостренні панкреатиту:</h2>
								<div class="inline-svg-center-block">
									<svg id="advices-svg-2" class="inline-svg-illustration"
                       xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                       viewBox="0 0 130.8 96.1">
										<g id="advices-svg-2-mountains">
											<path id="advices-svg-2-mountain-4" class="st0" d="M39,77.2L27.1,60.5c-1.6-2.2-4.8-2.2-6.4,0L8.9,77.2c-1.8,2.6,0,6.2,3.2,6.2h23.7
 C39,83.4,40.8,79.8,39,77.2z"></path>
											<path id="advices-svg-2-mountain-3" class="st0" d="M47.6,82.8L39.9,72c-1.6-2.2-4.9-2.2-6.4,0l-7.6,10.7c-1.9,2.6,0,6.2,3.2,6.2h15.3
 C47.6,89,49.4,85.4,47.6,82.8z"></path>
											<path id="advices-svg-2-mountain-2" class="st1" d="M37.7,83.8L27.8,70c-1.3-1.8-4-1.8-5.3,0l-9.9,13.9c-1.5,2.2,0,5.1,2.7,5.1H35
 C37.6,89,39.2,86,37.7,83.8z"></path>
											<path id="advices-svg-2-mountain-1" class="st2" d="M18.2,85.4l-6.9-9.7c-0.9-1.3-2.8-1.3-3.7,0l-6.9,9.7c-1.1,1.5,0,3.6,1.8,3.6h13.7
 C18.2,89,19.3,86.9,18.2,85.4z"></path>
										</g>
										<g id="advices-svg-2-trees">
											<path id="advices-svg-2-tree-2" class="advices-svg-2-tree st0" d="M119.2,59.8c-0.2,0-0.4-0.1-0.5-0.1c-0.4-3.4-2.9-6.2-6.4-6.9c-4.4-0.9-8.6,2-9.5,6.3
 c-0.1,0.7-0.2,1.3-0.1,2c-2.5,1.3-4.4,3.6-5,6.5c-1,4.9,2,9.6,6.8,10.9c0.8,2,2.5,3.7,4.6,4.5V89h5.7V83c2.5-1,4.5-3.1,5.1-6
 c0.3-1.5,0.2-2.9-0.3-4.3c2.4-0.6,4.4-2.5,4.9-5.2C125.1,64,122.8,60.5,119.2,59.8z"
                            data-svg-origin="111.05145645141602 89"
                            transform="matrix(0.99762,-0.06886,0.06886,0.99762,-2.747664677109647,6.539763516126868)"
                            style="transform-origin: 0px 0px 0px;"></path>
											<path
                          id="advices-svg-2-tree-1" class="advices-svg-2-tree st3" d="M127.1,70.6c-0.1,0-0.2,0-0.3-0.1c-0.3-2.1-1.8-3.9-4.1-4.4c-2.8-0.5-5.4,1.2-6,4
 c-0.1,0.4-0.1,0.8-0.1,1.3c-1.6,0.8-2.8,2.3-3.1,4.1c-0.6,3.1,1.3,6.1,4.3,6.9c0.5,1.3,1.6,2.3,2.9,2.8V89h3.6v-3.8
 c1.6-0.6,2.8-2,3.2-3.8c0.2-0.9,0.1-1.9-0.2-2.7c1.5-0.4,2.8-1.6,3.1-3.3C130.9,73.2,129.4,71,127.1,70.6z"
                          data-svg-origin="121.9428482055664 89"
                          transform="matrix(0.99998,0.00534,-0.00534,0.99998,2.4478199922114783,-1.483050271391118)"
                          style="transform-origin: 0px 0px 0px;"></path>
										</g>
										<g id="advices-svg-2-bottle">
											<path id="advices-svg-2-bottle-body" class="st2" d="M111.2,68.2c0.5,0,0.9-0.4,0.9-1V46.8c0-3.9-1.9-7.4-4.8-9.5c-2-1.5-3.2-3.7-3.2-6.2v-1.4h-8.1
 v1.4c0,2.5-1.2,4.7-3.2,6.2c-2.9,2.1-4.8,5.6-4.8,9.5v20.5c0,0.5,0.4,1,0.9,1c0.5,0,1,0.4,1,1v7.7c0,0.5-0.4,1-0.9,1
 c-0.5,0-1,0.4-1,1v7.1c0,1.7,1.4,3.1,3.1,3.1h8.4h1.3h8.4c1.7,0,3.1-1.4,3.1-3.1v-7.1c0-0.5-0.4-1-0.9-1c-0.5,0-1-0.4-1-1v-7.7
 C110.2,68.7,110.6,68.2,111.2,68.2z" data-svg-origin="88 29.69999885559082" transform="matrix(1,0,0,1,0,0)"
                            style="fill: rgb(87, 158, 248);"></path>
											<rect
                          id="advices-svg-2-bottle-cap" x="94.8" y="22.7" class="st4" width="10.4" height="7.1"></rect>
											<rect
                          id="advices-svg-2-bottle-label" x="87.9" y="46.1" class="st4" width="24.2"
                          height="14.4"></rect>
										</g>
										<g
                        id="advices-svg-2-cake">
											<rect id="advices-svg-2-cake-part-5" x="50.1" y="80.8" class="st5" width="48.2"
                            height="8.2"></rect>
											<rect
                          id="advices-svg-2-cake-part-3" x="50.1" y="70.2" class="st5" width="48.2" height="8.2"></rect>
											<path
                          id="advices-svg-2-cake-part-4" class="st6" d="M98.6,78.4H52.8c-0.7,0-1.2,0.5-1.2,1.2v0c0,0.7,0.5,1.2,1.2,1.2h45.8
 c0.7,0,1.2-0.5,1.2-1.2v0C99.8,78.9,99.3,78.4,98.6,78.4z"></path>
											<rect id="advices-svg-2-cake-part-1" x="50.1" y="60.7"
                            class="st5" width="48.2" height="8.2"></rect>
											<path
                          id="advices-svg-2-cake-part-2" class="st6" d="M98.6,68.2H52.8c-0.7,0-1.2,0.5-1.2,1.2l0,0c0,0.7,0.5,1.2,1.2,1.2h45.8
 c0.7,0,1.2-0.5,1.2-1.2l0,0C99.8,68.8,99.3,68.2,98.6,68.2z"></path>
											<path id="advices-svg-2-cake-top" class="st6" d="M101.5,55.8c0-1.7-1.4-3.1-3.1-3.1h-1.7c-0.3-0.8-1.1-1.3-1.9-1.3c-0.9,0-1.6,0.6-1.9,1.3h-5
 c0,0,0,0,0,0c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.7,1.2-2.7,2.7c0,0,0,0,0,0h-4.2c0,0,0,0,0,0c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5
 c0,0,0,0,0,0h-3.1c0,0,0,0,0,0c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6c0,0,0,0,0,0H56.4c-1.7,0-3.1,1.4-3.1,3.1v2
 c0,1.7,1.4,3.1,3.1,3.1h11c0,0,0,0.1,0,0.1c0,0.7,0.6,1.3,1.3,1.3h3.5c0.7,0,1.3-0.6,1.3-1.3v-0.1h6.7v0.4c0,0.7,0.6,1.3,1.3,1.3h1
 c0.7,0,1.3-0.6,1.3-1.3v-0.2c0-0.1,0-0.1,0-0.2h3c0,0.1,0,0.1,0,0.2v1.2c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3,0.6,1.3,1.3v0.4
 c0,0.7,0.6,1.3,1.3,1.3h0.2c0.7,0,1.3-0.6,1.3-1.3v-4.4h4v2.6c0,0.7,0.6,1.3,1.3,1.3h3c0.7,0,1.3-0.6,1.3-1.3v-5.6c0,0,0,0,0,0
 c0,0,0,0,0,0V55.8z"></path>
										</g>
										<g id="advices-svg-2-sausage">
											<path id="advices-svg-2-sausage-tail" class="st7"
                            d="M50.1,85.7l-5.4,7.8c0,0,3.8-1.8,5.4-1.8c1.5,0,1.7,4.8,2.6,3c1.2-2.5,5.8,0,5.8,0L50.1,85.7z"></path>
											<path
                          id="advices-svg-2-sausage-body" class="st8"
                          d="M34.1,25.6v52.1c0,6.3,7.1,11.3,15.9,11.3S66,83.9,66,77.7V56.3L34.1,25.6z"></path>
											<ellipse
                          id="advices-svg-2-sausage-cut"
                          transform="matrix(0.7071 0.7071 -0.7071 0.7071 43.5899 -23.4221)" class="st7"
                          cx="50.1" cy="40.9" rx="22.3" ry="3.1"></ellipse>
											<g id="advices-svg-2-sausage-dots">
												<ellipse id="advices-svg-2-sausage-dot-7" class="st2" cx="37.6" cy="28.2" rx="0.7"
                                 ry="0.6"></ellipse>
												<ellipse
                            id="advices-svg-2-sausage-dot-6" class="st2" cx="49.3" cy="38.4" rx="0.7"
                            ry="0.6"></ellipse>
												<ellipse
                            id="advices-svg-2-sausage-dot-5" class="st2" cx="61.8" cy="52.7" rx="0.7"
                            ry="0.6"></ellipse>
												<ellipse
                            id="advices-svg-2-sausage-dot-4" class="st2" cx="41" cy="32.3" rx="1" ry="0.9"></ellipse>
												<ellipse
                            id="advices-svg-2-sausage-dot-3" class="st2" cx="51.6" cy="43.8" rx="1" ry="0.9"></ellipse>
												<ellipse
                            id="advices-svg-2-sausage-dot-2" class="st2" cx="44.4" cy="34.8" rx="1.5"
                            ry="1.3"></ellipse>
												<ellipse
                            id="advices-svg-2-sausage-dot-1" class="st2" cx="56.3" cy="46.6" rx="1.5"
                            ry="1.3"></ellipse>
											</g>
										</g>
										<g
                        id="advices-svg-2-kebab">
											<path id="advices-svg-2-kebab-skewer" class="st2" d="M38.1,90.4c0-1.8-1-3.3-2.5-4V8.8l-1.9-3.3l-1.9,3.3v77.6c-1.5,0.7-2.5,2.2-2.5,4
 c0,2.4,2,4.4,4.4,4.4C36.2,94.8,38.1,92.9,38.1,90.4z M31.6,90.4c0-1.2,1-2.1,2.1-2.1c1.2,0,2.1,1,2.1,2.1s-1,2.1-2.1,2.1
 C32.5,92.6,31.6,91.6,31.6,90.4z"></path>
											<g id="advices-svg-2-kebab-meat-3" class="advices-svg-2-kebab-meat"
                         data-svg-origin="34.15000057220459 28.25"
                         transform="matrix(1,0,0,1,0,-0.4733009910842716)"
                         style="transform-origin: 0px 0px 0px;">
												<path class="st0" d="M26.6,30.5V26c0-2.9,2.4-5.3,5.3-5.3h4.5c2.9,0,5.3,2.4,5.3,5.3v4.5c0,2.9-2.4,5.3-5.3,5.3
 h-4.5C29,35.8,26.6,33.5,26.6,30.5z"></path>
												<path class="st4" d="M37.8,33.2l-8.5-8.5c-0.4-0.4-0.4-0.9,0-1.3h0c0.4-0.4,0.9-0.4,1.3,0l8.5,8.5
 c0.4,0.4,0.4,0.9,0,1.3h0C38.7,33.6,38.1,33.6,37.8,33.2z"></path>
												<path class="st4" d="M38.9,28.5L34,23.5c-0.4-0.4-0.4-0.9,0-1.3l0,0c0.4-0.4,0.9-0.4,1.3,0l4.9,4.9
 c0.4,0.4,0.4,0.9,0,1.3l0,0C39.8,28.8,39.3,28.8,38.9,28.5z"></path>
												<path class="st4" d="M33,34.3l-4.9-4.9c-0.4-0.4-0.4-0.9,0-1.3v0c0.4-0.4,0.9-0.4,1.3,0l4.9,4.9
 c0.4,0.4,0.4,0.9,0,1.3v0C34,34.7,33.4,34.7,33,34.3z"></path>
											</g>
											<g id="advices-svg-2-kebab-meat-2"
                         class="advices-svg-2-kebab-meat"
                         data-svg-origin="34.15000057220459 45.95000076293945"
                         transform="matrix(1,0,0,1,0,3.194331526288307)"
                         style="transform-origin: 0px 0px 0px;">
												<path class="st0" d="M26.6,48.2v-4.5c0-2.9,2.4-5.3,5.3-5.3h4.5c2.9,0,5.3,2.4,5.3,5.3v4.5c0,2.9-2.4,5.3-5.3,5.3
 h-4.5C29,53.5,26.6,51.1,26.6,48.2z"></path>
												<path class="st4" d="M37.8,50.9l-8.5-8.5c-0.4-0.4-0.4-0.9,0-1.3h0c0.4-0.4,0.9-0.4,1.3,0l8.5,8.5
 c0.4,0.4,0.4,0.9,0,1.3l0,0C38.7,51.2,38.1,51.2,37.8,50.9z"></path>
												<path class="st4" d="M38.9,46.1L34,41.2c-0.4-0.4-0.4-0.9,0-1.3h0c0.4-0.4,0.9-0.4,1.3,0l4.9,4.9
 c0.4,0.4,0.4,0.9,0,1.3h0C39.8,46.5,39.3,46.5,38.9,46.1z"></path>
												<path class="st4" d="M33,52l-4.9-4.9c-0.4-0.4-0.4-0.9,0-1.3v0c0.4-0.4,0.9-0.4,1.3,0l4.9,4.9
 c0.4,0.4,0.4,0.9,0,1.3v0C34,52.3,33.4,52.3,33,52z"></path>
											</g>
											<g id="advices-svg-2-kebab-meat-1"
                         class="advices-svg-2-kebab-meat"
                         data-svg-origin="34.15000057220459 63.65000343322754"
                         transform="matrix(1,0,0,1,0,4.934248721110167)"
                         style="transform-origin: 0px 0px 0px;">
												<path class="st0" d="M26.6,65.9v-4.5c0-2.9,2.4-5.3,5.3-5.3h4.5c2.9,0,5.3,2.4,5.3,5.3v4.5c0,2.9-2.4,5.3-5.3,5.3
 h-4.5C29,71.2,26.6,68.8,26.6,65.9z"></path>
												<path class="st4" d="M37.8,68.5L29.2,60c-0.4-0.4-0.4-0.9,0-1.3h0c0.4-0.4,0.9-0.4,1.3,0l8.5,8.5
 c0.4,0.4,0.4,0.9,0,1.3l0,0C38.7,68.9,38.1,68.9,37.8,68.5z"></path>
												<path class="st4" d="M38.9,63.8L34,58.9c-0.4-0.4-0.4-0.9,0-1.3l0,0c0.4-0.4,0.9-0.4,1.3,0l4.9,4.9
 c0.4,0.4,0.4,0.9,0,1.3l0,0C39.8,64.1,39.3,64.1,38.9,63.8z"></path>
												<path class="st4" d="M33,69.7l-4.9-4.9c-0.4-0.4-0.4-0.9,0-1.3l0,0c0.4-0.4,0.9-0.4,1.3,0l4.9,4.9
 c0.4,0.4,0.4,0.9,0,1.3l0,0C34,70,33.4,70,33,69.7z"></path>
											</g>
										</g>
										<g id="advices-svg-2-cross-quote"
                       data-svg-origin="72.24999618530273 19.80000001192093"
                       transform="matrix(0.89938,0,0,0.89938,4.679685988235676,5.607708738977184)"
                       style="transform-origin: 0px 0px 0px;">
											<path id="advices-svg-2-cross-buble" class="st9" d="M65.4,0.3h13.7c3.4,0,6.1,2.7,6.1,6.1v21.5c0,3.4-2.7,6.1-6.1,6.1h-4.5c-1.1,2-3.8,5.3-9.1,5.3
 c2.1-1.6,2.9-3.7,3.2-5.3h-3.3c-3.4,0-6.1-2.7-6.1-6.1V6.4C59.3,3,62,0.3,65.4,0.3z"></path>
											<polygon
                          id="advices-svg-2-cross" class="st4" points="81.7,10.7 78.1,7 72.3,12.8 66.6,7 62.9,10.7 68.7,16.4 62.9,22.2 66.6,25.9 72.3,20.1
 78.1,25.9 81.7,22.2 76,16.4 	"></polygon>
										</g>
									</svg>
								</div>
								<ul class="custom-unordered-list">
									<li>
										<div
                        class="custom-list-dot"></div>
										<span class="custom-list-item">Виключіть з меню жирне, смажене, копчене (шашлик, майонез, жирну сметану, сало, копчену ковбасу тощо), гостре (гірчицю, хрін тощо) та солодке<sup>2</sup>.
										</span>
									</li>
									<li>
										<div
                        class="custom-list-dot"></div>
                    <span class="custom-list-item">Із напоїв пийте тільки мінеральну воду без газу. Не рекомендується споживати чорну каву та міцний чай, солодкі газовані напої<sup>2</sup>.  Якщо ви зовсім не можете відмовитися від чорного чаю, зробіть дуже слабку заварку та додайте в неї невеликий шматочок імбиру та ложечку меду<sup>3</sup>.
										</span>
									</li>
									<li>
										<div
                        class="custom-list-dot"></div>
										<span
                        class="custom-list-item">Не варто провокувати підшлункову на нові приступи й фастфудом<sup>2</sup>. Необхідно розуміти, що харчування при хворобі підшлункової залози — це вкрай важливо<sup>2</sup>. Тому якийсь час вам доведеться дотримуватись дієти та обходитися без свіжої випічки, хліба, здоби<sup>2</sup>.
										</span>
									</li>
									<li>
										<div
                        class="custom-list-dot"></div>
                    <span class="custom-list-item">Рекомендоване меню складається з овочевих супів, варених або тушкованих овочів, макаронних запіканок<sup>3</sup>. З м’ясного рекомендовано відварену курку, тушковану індичку або кролика, парові котлети, запечену нежирну рибу<sup>3</sup>. </span>
									</li>
									<li>
										<div
                        class="custom-list-dot"></div>
										<span class="custom-list-item">Доведеться зачекати і з холодними стравами (морозивом, фруктовим льодом). Їжте все тільки тепле або ледь тепле<sup>2</sup>.
										</span>
									</li>
									<li>
										<div
                        class="custom-list-dot"></div>
										<span class="custom-list-item">Не поспішайте відправлятися в поїздку або відвідувати тренажерний зал.</span>
									</li>
								</ul>

              <p>
                А для того, щоб повернутися до звичайного життя і знову отримувати задоволення від улюбленої їжі, важливо проконсультуватися з лікарем, який підбере необхідне лікування (у тому числі ферментну терапію) і скоригує харчування на основі встановленого діагнозу.
              </p>
								<p
                    class="download-paragraph">Завантажити інструкцію для медичного застосування лікарського засобу Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000.
									<br>
										<a
                        href="/instruction.pdf" download="instruction.pdf">Інструкція Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000.</a>
									</p>
								</section>
								<div id="triple-help-block">
									<ul
                      class="custom-unordered-list">
										<li>
											<div class="custom-list-dot"></div>
											<span class="custom-list-item">Ферментний препарат
												<span>Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
												</span> містить панкреатин в дозуванні 10 000 одиниць по ліпазі.
											</span>
										</li>
										<li>
											<div
                          class="custom-list-dot"></div>
                      <span class="custom-list-item">Його формула сприяє травленню, зменшенню відчуття важкості і здуття живота.
											</span>
										</li>
										<li>
											<div
                          class="custom-list-dot"></div>
											<span class="custom-list-item">
												<span>Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
												</span> допомагає перетравлювати білки, жири і вуглеводи, і таким чином нормалізує процеси травлення<sup>5</sup>.
											</span>
										</li>
									</ul>
									<div
                      class="slogan-block">
													<img src="../assets/imgs/tripleHelpBlock/pack.png" alt="">
														<p
                                class="slogan">Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
															<br>
																<span>потрійна</span>
																<br>
																	<span>допомога травленню!<sup>5*</sup>
																	</span>
																</p>
															</div>
											</div>
											<div
                          class="bottom-note-block">
												<p class="footnote">
            Адаптовано з:

													<br>
														<sup>1</sup> Barry K. Chronic Pancreatitis: Diagnosis and Treatment. Am Fam Physician. 2018 Mar 15;97(6):385-393. PMID: 29671537.

														<br>
															<sup>2</sup> O'Brien SJ, Omer E. Chronic Pancreatitis and Nutrition Therapy. Nutr Clin Pract. 2019 Oct;34 Suppl 1:S13-S26. doi: 10.1002/ncp.10379. PMID: 31535736.

															<br>
																<sup>3</sup> Матвеева Л. Ферментная недостаточность поджелудочной железы: проблема и ее коррекция. Здоров’я України, 2015; 20(369): 18-19.

																<br>
																	<sup>4</sup> Dominguez-Muñoz JE. Diagnosis and treatment of pancreatic exocrine insufficiency. Curr Opin Gastroenterol. 2018 Sep;34(5):349-354. doi: 10.1097/MOG.0000000000000459. PMID: 29889111.

																	<br>
																		<sup>5</sup> Інструкція для медичного застосування препарату Фестал<sup>®</sup> НЕО 10000, таблетки, вкриті оболонкою, кишковорозчинні. РП №UA/14533/01/01. Наказ МОЗ України №1994 від 21.09.2021

                          <!--																		<br>-->
                          <!--																			<sup>*</sup> Формула оновлена за рахунок збільшення вмісту ліпази (на 3 000 МО) та α-амілази (на 2 500 МО) порівняно із препаратом Фестал<sup>®</sup> РП №UA2531/01/01. Наказ МОЗ України №341 від 29.03.2017.-->

																			<br>
																				<sup>*</sup> До складу препарату входять три основні панкреатичні травні ферменти: ліпаза, α-амілаза, протеази (трипсин і хімотрипсин), які чинять ліполітичну, амілолітичну і протеолітичну дію.

																			</p>
																			<p
                                          class="adversting-text">Реклама лікарського засобу. Перед застосуванням лікарського засобу необхідно проконсультуватися з лікарем та обов’язково ознайомитися з інструкцією для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000. Зберігати у недоступному для дітей місці.
																			</p>
																			<table
                                          class="adversting-text-table">
																				<tbody>
																					<tr class="top-line">
																						<td>

																						</td>
																						<td>
<!--																							<a href="/feedback">Зворотний зв’язок</a>.-->
																						</td>
																					</tr>
																					<tr class="bottom-line">
																						<td><router-link
                                                to="/official-notice">Офіційне повідомлення</router-link>.<br>Всі права захищені<sup>®</sup> 2021. Дата останнього перегляду 08.07.2022. Сайт призначений виключно для відвідувачів з України.
																						</td>
																						<td>

																								<a
                                                    href="http://www.sanofi.ua"
                                                    style="padding-right: 5px; display: inline-block;" target="_blank">www.sanofi.ua</a>



																								<a href="http://www.festal.ua"
                                                   target="_blank">www.festal.ua</a>
																							<br>MAT-UA-2200466-1.0-07/2022
																						</td>
																						<td>
																							<router-link
                                                  to="/instruction" class="non-line">Інструкція для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000, таблетки, вкриті оболонкою, кишковорозчинні.
                                                </router-link><br>РП №UA/14533/01/01. Наказ МОЗ України №1994 від 21.09.2021.

																						</td>
																					</tr>
																				</tbody>
																			</table>
																		</div>
																		<footer
                                        id="self-medication-block">
																			<div class="word">
																				<div class="letter s"></div>
																				<div class="letter a"></div>
																				<div
                                            class="letter m bigger-letter"></div>
																				<div class="letter o"></div>
																				<div class="letter l"></div>
																				<div
                                            class="letter i smaller-letter"></div>
																				<div class="letter k"></div>
																				<div class="letter u"></div>
																				<div
                                            class="letter v"></div>
																				<div class="letter a"></div>
																				<div class="letter n"></div>
																				<div class="letter n"></div>
																				<div
                                            class="letter ia"></div>
																			</div>
																			<div class="word">
																				<div class="letter m bigger-letter"></div>
																				<div
                                            class="letter o"></div>
																				<div class="letter ge bigger-letter"></div>
																				<div class="letter e"></div>
																			</div>
																			<div
                                          class="word">
																				<div class="letter b"></div>
																				<div class="letter u"></div>
																				<div class="letter t"></div>
																				<div
                                            class="letter ii"></div>
																			</div>
																			<div class="word">
																				<div class="letter sh bigger-letter"></div>
																				<div
                                            class="letter k"></div>
																				<div class="letter i smaller-letter"></div>
																				<div class="letter d"></div>
																				<div
                                            class="letter l"></div>
																				<div class="letter ii"></div>
																				<div class="letter v"></div>
																				<div
                                            class="letter ii"></div>
																				<div class="letter m bigger-letter"></div>
																			</div>
																			<div class="word">
																				<div
                                            class="letter d"></div>
																				<div class="letter l"></div>
																				<div class="letter ia"></div>
																			</div>
																			<div class="word">
																				<div
                                            class="letter v"></div>
																				<div class="letter a"></div>
																				<div class="letter sh bigger-letter"></div>
																				<div
                                            class="letter o"></div>
																				<div class="letter g"></div>
																				<div class="letter o"></div>
																			</div>
																			<div class="word">
																				<div
                                            class="letter z"></div>
																				<div class="letter d"></div>
																				<div class="letter o"></div>
																				<div class="letter r"></div>
																				<div
                                            class="letter o"></div>
																				<div class="letter v"></div>
																				<div class="letter znak smaller-letter"></div>
																				<div
                                            class="letter ia"></div>
																			</div>
																			<svg id="self-medication-svg"
                                           xmlns="http://www.w3.org/2000/svg"
                                           xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px"
                                           y="0px" viewBox="0 0 568.5 58.4">
																				<g id="self-medication-svg-letters">
																					<path class="self-medication-svg-letter" d="M8.8,17.2c0-5,1.3-7.9,4.8-7.9c3.5,0,4.8,2.9,4.8,7.9V18c0,0-0.1,0.2-0.2,0.2S18,18,18,18v-0.8
 c0-4.4-1.1-6.8-4.4-6.8c-3.3,0-4.3,2.4-4.3,6.8v23.2c0,4.4,1.1,6.8,4.3,6.8c3,0,4.6-2.5,4.6-2.5s0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6s-1.3,2.5-4.6,2.5c-3.5,0-4.8-2.9-4.8-7.9V17.2z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M24.1,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2L30,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9l-1.7,12c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L24.1,9.5z M21.1,34.8h6.6l-3.3-24.2L21.1,34.8z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M43.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																					<path class="self-medication-svg-letter" d="M45.4,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M45.9,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M60.1,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L60.1,9.7z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M70,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6
 h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6H70z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M76.2,28.5L74,30.1V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L76.2,28.5z"></path>
																					<path class="self-medication-svg-letter" d="M87,37c0,0-0.1-0.1-0.1-0.1L82,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L86.6,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L87,37z"></path>
																					<path class="self-medication-svg-letter" d="M93.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M93.7,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M93.7,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M107.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L103,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L107.8,9.5z M104.8,34.8h6.6l-3.3-24.2L104.8,34.8z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M124.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M135.9,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M146.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L137.7,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M146,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M166.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2
 c-0.2,0-0.3-0.2-0.3-0.2l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 l5.7,30.5l5.8-30.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																					<path class="self-medication-svg-letter" d="M168.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M168.7,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M179.1,48c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.5-20.2l-4.2-18.2c0,0,0.1-0.2,0.3-0.2
 c0.2,0,0.3,0.2,0.3,0.2l4.1,17.7l2.1,1.7V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2v19.3l2.1-1.7l4.1-17.7
 c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-4.2,18.2l4.5,20.2c0,0-0.1,0.2-0.3,0.2c-0.1,0-0.3-0.2-0.3-0.2L188,28.3l-2.1,1.7v18
 c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V30l-2.1-1.7L179.1,48z"></path>
																					<path class="self-medication-svg-letter" d="M194.2,47h8.1c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.5c-2.4,0-4-0.3-4-0.3V9.7c0,0,1.6-0.3,4-0.3h4.4
 c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-8v17.1h7.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.2V47z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M209.5,26.8c0,0,1.7-0.2,4-0.2c3.2,0,4.5,3.1,4.5,8.4v4.8c0,5.3-1.3,8.4-4.5,8.4c-2.6,0-4.4-0.3-4.4-0.3V9.7
 c0,0,1.6-0.3,4-0.3h4.3c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.9V26.8z M209.5,47.1h4c3,0,4.1-2.5,4.1-7.3V35
 c0-4.8-1.1-7.3-4.1-7.3h-4V47.1z"></path>
																					<path class="self-medication-svg-letter" d="M223.5,37c0,0-0.1-0.1-0.1-0.1l-4.9-27.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L223.1,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L223.5,37z"></path>
																					<path class="self-medication-svg-letter" d="M234.1,10.5V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5h-4.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h9.8c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6H234.1z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M249.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L240.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																					<path class="self-medication-svg-letter" d="M272.1,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C272.1,9.4,272.1,9.5,272.1,9.5"></path>
																					<path
                                              class="self-medication-svg-letter" d="M276.7,28.5l-2.2,1.7V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L276.7,28.5z"></path>
																					<path class="self-medication-svg-letter" d="M284.4,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6
 H284.4z"></path>
																					<path class="self-medication-svg-letter" d="M287.1,47.1c1.8,0,2.1-2.3,2.3-6.8L291,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M297.4,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H297.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M303.9,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L303.9,9.7z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M322.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L313.1,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46L322,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z
 "></path>
																					<path class="self-medication-svg-letter" d="M324.4,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M324.8,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M324.8,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M344.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L335.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																					<path class="self-medication-svg-letter" d="M359.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2V48z"></path>
																					<path class="self-medication-svg-letter" d="M366.5,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2H367v8.2c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 V47.1z M376.9,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H376.9z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M383.3,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L383.3,9.7z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M400.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L392,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M400.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M405.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M405.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M405.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M419.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L415,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L419.8,9.5z M416.8,34.8h6.6l-3.3-24.2L416.8,34.8z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M441.6,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C441.6,9.4,441.6,9.5,441.6,9.5"></path>
																					<path
                                              class="self-medication-svg-letter" d="M443.6,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M444,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M455.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.7c0,0,1.6-0.3,4-0.3h3.7c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-7.2V48z"></path>
																					<path class="self-medication-svg-letter" d="M463.9,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M464.3,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M489.5,17.8v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3c0,5.4-1.3,8.7-4.5,8.7c-3.2,0-4.5-2.5-4.5-2.5
 s-0.1-0.2-0.1-0.6c0-0.4,0.1-0.6,0.1-0.6s1.5,2.5,4.5,2.5s4.1-2.6,4.1-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-1.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h1.8c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.8-7.4c-3.2,0-4.2,2.4-4.2,6.8V18c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2v-0.8c0-5,1.3-7.9,4.7-7.9C488.3,9.3,489.5,12.4,489.5,17.8"></path>
																					<path
                                              class="self-medication-svg-letter" d="M490.3,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M500.7,47V10.5h-6.1L493,40.4c-0.2,3.1-0.4,5.4-1.1,6.6H500.7z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M504,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M504.4,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M520,9.3c3.2,0,4.5,2.9,4.5,7.9V22c0,5-1.3,7.9-4.5,7.9c-2.3,0-3.8-0.2-3.8-0.2V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5C515.8,9.5,517.5,9.3,520,9.3 M516.2,28.8h3.8c3,0,4.1-2.4,4.1-6.8v-4.8c0-4.4-1.1-6.8-4.1-6.8h-3.8
 V28.8z"></path>
																					<path class="self-medication-svg-letter" d="M526.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M526.8,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M538.1,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M538.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M538.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M548.1,18.7c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2s1.1-2.7,1.1-9.2c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2C549.2,16.3,548.1,18.7,548.1,18.7"></path>
																					<path class="self-medication-svg-letter" d="M559.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L551,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M559.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																				</g>
																			</svg>
																		</footer>
																	</div>
																</span>
      </div>
    </div>
  </div>
</template>

<script>
import actualPaddingSetter from '../modules/actualPaddingSetter';
import nutritionAdvicesSvgAnimate from '../modules/svg_animation/nutritionAdvicesSvgAnimate';

export default {
  name: "nutritionAdvices",
  mounted() {

    let trigger25 = true;
    let trigger50 = true;
    let trigger75 = true;
    let trigger90 = true;

    window.addEventListener("scroll", () => {

      var h = document.documentElement,
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';

      var percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);
      if (percent >= 25 && percent < 50 && trigger25) {
        trigger25 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол сторінки 25%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 50 && percent < 75 && trigger50) {
        trigger50 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 50%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 75 && percent < 90 && trigger75) {
        trigger75 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 75%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 90 && trigger90) {
        trigger90 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 90%',
          'event_label': 'UA: ' + this.$route.path
        })
      }
    })
    window.scrollTo(0, 0);
    actualPaddingSetter();
    nutritionAdvicesSvgAnimate();
  }
}
</script>

<style lang="less">

</style>
