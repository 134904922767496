<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: "app",
  data: () => ({
        metaData: {
          "home": {
            title: "Офіційний сайт Фестал в Україні | Festal.ua",
            description: "Festal.ua •️Дізнайтеся більше на офіційному сайті Фестал Україна!",
          },
          "digestion": {
            title: "Процес травлення: що, як, чому | Festal.ua",
            description: "Festal.ua •️Процес травлення: що, як, чому | Дізнайтеся більше на офіційному сайті Фестал в Україні!",
          },

          "enzymes": {
            title: "Травні ферменти | Festal.ua",
            description: "Festal.ua •️Травні ферменти | Дізнайтеся більше на офіційному сайті Фестал в Україні",
          },
          "festalNeo": {
            title: "ФЕСТАЛ НЕО 10000 | Festal.ua",
            description: "Festal.ua •️ФЕСТАЛ НЕО 10 000 (Festal Neo 10 000) | Дізнайтеся більше на офіційному сайті Фестал в Україні",
          },
          "instruction": {
            title: "Інструкція ФЕСТАЛ НЕО 10000 | Festal.ua",
            description: "Festal.ua •️Інструкція ФЕСТАЛ НЕО 10 000 | Дізнайтеся більше на офіційному сайті Фестал в Україні",
          },
          "nutritionAdvices": {
            title: "Поради та рекомендації з харчування | Festal.ua",
            description: "Festal.ua •️Поради та рекомендації з харчування | Дізнайтеся більше на офіційному сайті Фестал в Україні",
          },
          "officialNotice": {
            title: "Офіційне повідомлення для користувачів Сайту | Festal.ua",
            description: "Festal.ua • Офіційне повідомлення для користувачів Сайту | Дізнайтеся більше на офіційному сайті Фестал в Україні",
          },
          "feedback": {
            title: "Зворотній зв'язок | Festal.ua",
            description: "Festal.ua • Зворотній зв'язок | Дізнайтеся більше на офіційному сайті Фестал в Україні",
          },

          "HomeRu": {
            title: "Фестал® НЕО 10 000. Тройная помощь пищеварению!",
            description: "",
          },
          "digestionRu": {
            title: "Процесс пищеварения",
            description: "",
          },
          "enzymesRu": {
            title: "Пищеварительные ферменты",
            description: "",
          },
          "festalNeoRu": {
            title: "Фестал НЕО 10 000",
            description: "",
          },
          "instructionRu": {
            title: "Инструкция Фестал НЕО 10 000",
            description: "",
          },
          "nutritionAdvicesRu": {
            title: "Советы и рекомендации по питанию",
            description: "",
          },
          "officialNoticeRu": {
            title: "Официальное уведомление",
            description: "",
          },
          "feedbackRu": {
            title: "Обратная связь",
            description: "",
          }
        }
      }
  ),
  watch: {
    $route(to, from) {
      document.querySelector("link[rel='canonical']").setAttribute('href', 'https://festal.ua' + to.path)

      let keys = to.name;

      document.title = this.metaData[keys].title + ' | Festal.ua';
      document.querySelector("meta[name='description']").setAttribute('content', this.metaData[keys].description)

    }
  },
  mounted() {
    document.querySelector("link[rel='canonical']").setAttribute('href', 'https://festal.ua' + this.$route.path)
  }
}
</script>

<style lang="less">
@import "styles/global";
@import "styles/fonts.css";
@import "styles/normalize.css";



  .mobile-bottom-text-block {
    padding-bottom: 37px;

    .flex-container {
      position: relative;
    }

    .slogan-triple-help {
      position: absolute;



      @media screen and (max-width: 1024px){
        left: 1px;
        bottom: -37px;
      }
      @media screen and (max-width: 500px){
        left: -6px;
        bottom: -30px;
      }
    }
  }


.bottom-line {

  td {
    &:nth-child(1) {
      width: 32%;
    }

    &:nth-child(2) {
      width: 14.5%;
      padding: 5px 20px;
    }

  }

  .non-line {
    sup {
      vertical-align: initial;
    }
  }
}

</style>
