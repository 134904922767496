<template>
    <div class="not-found" data-view>
        <div data-reactroot="" id="content">
            <div id="router-container">
                <top-menu></top-menu>
                <message-alert></message-alert>

                <div id="not-found-container" class="content-container" style="padding-top: 60px; height: 821px;">
                    <section class="main-column">
                        <div class="inline-svg-center-block">
                            <svg id="not-found-svg-1" class="inline-svg-illustration" xmlns="http://www.w3.org/2000/svg"
                                 xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                                 viewBox="0 0 831 315.9"><path class="st0" d="M660.9,259.1l-56-78.9c-7.4-10.4-22.8-10.4-30.1,0l-56,78.9c-8.7,12.2,0.1,29.2,15.1,29.2h112
 C660.8,288.3,669.6,271.4,660.9,259.1"></path>
                                <path class="st1" d="M660.9,259.1l-56-78.9c-7.4-10.4-22.8-10.4-30.1,0l-56,78.9c-8.7,12.2,0.1,29.2,15.1,29.2h112
 C660.8,288.3,669.6,271.4,660.9,259.1z"></path>
                                <path class="st2" d="M701.4,285.1l-36-50.7c-7.4-10.4-22.9-10.4-30.3,0l-36,50.7c-8.7,12.3,0.1,29.4,15.2,29.4h72
 C701.4,314.5,710.2,297.4,701.4,285.1"></path>
                                <path class="st1" d="M701.4,285.1l-36-50.7c-7.4-10.4-22.9-10.4-30.3,0l-36,50.7c-8.7,12.3,0.1,29.4,15.2,29.4h72
 C701.4,314.5,710.2,297.4,701.4,285.1z"></path>
                                <path class="st3" d="M654.7,290.3l-46.5-65.5c-6.1-8.6-18.9-8.6-25,0l-46.5,65.5c-7.2,10.2,0.1,24.2,12.5,24.2h93
 C654.6,314.5,661.9,300.4,654.7,290.3"></path>
                                <path class="st1" d="M654.7,290.3l-46.5-65.5c-6.1-8.6-18.9-8.6-25,0l-46.5,65.5c-7.2,10.2,0.1,24.2,12.5,24.2h93
 C654.6,314.5,661.9,300.4,654.7,290.3z"></path>
                                <path class="st2" d="M562.8,297.6l-32.4-45.7c-4.3-6-13.2-6-17.5,0l-32.4,45.7c-5,7.1,0,16.9,8.7,16.9H554
 C562.7,314.5,567.8,304.7,562.8,297.6"></path>
                                <path class="st1" d="M562.8,297.6l-32.4-45.7c-4.3-6-13.2-6-17.5,0l-32.4,45.7c-5,7.1,0,16.9,8.7,16.9H554
 C562.7,314.5,567.8,304.7,562.8,297.6z"></path>
                                <g class="not-found-svg-cloud"
                                   data-svg-origin="329.29998779296875 174.15774536132812"
                                   transform="matrix(0.80721,0,0,0.80721,63.59132437115068,35.43008394931503)"><path class="st4" d="M387.9,205.5c0.6-7.3-4.3-14.1-11.7-15.6c-0.4-0.1-0.8-0.1-1.2-0.2c-0.9-7.4-6.4-13.7-14.1-15.2
 c-9.6-1.9-18.9,4.3-20.8,13.9c-0.3,1.5-0.4,3-0.3,4.4c-5,2.5-8.8,7-10.5,12.7H387.9z"></path>
                                    <path class="st5" d="M387.9,205.5c0.6-7.3-4.3-14.1-11.7-15.6c-0.4-0.1-0.8-0.1-1.2-0.2c-0.9-7.4-6.4-13.7-14.1-15.2
 c-9.6-1.9-18.9,4.3-20.8,13.9c-0.3,1.5-0.4,3-0.3,4.4c-5,2.5-8.8,7-10.5,12.7H387.9z"></path></g>
                                <path class="st6" d="M371.6,313.6c-79,0-117.7-60.4-117.7-153.2c0-81.3,29.3-159,123-159c87.9,0,115.4,74.6,115.4,150.5
 C492.3,236.4,471.5,313.6,371.6,313.6 M375.1,65.4c-23.5,0-40.8,20-40.8,94.1c0,71.9,16.9,90.6,39.5,90.6
 c24.9,0,39.1-17.3,39.1-85.3C412.9,83.2,400.4,65.4,375.1,65.4"></path>
                                <path class="st7" d="M371.6,313.6c-79,0-117.7-60.4-117.7-153.2c0-81.3,29.3-159,123-159c87.9,0,115.4,74.6,115.4,150.5
 C492.3,236.4,471.5,313.6,371.6,313.6z M375.1,65.4c-23.5,0-40.8,20-40.8,94.1c0,71.9,16.9,90.6,39.5,90.6
 c24.9,0,39.1-17.3,39.1-85.3C412.9,83.2,400.4,65.4,375.1,65.4z"></path>
                                <path class="st0" d="M156.1,279.8L121,230.3c-4.6-6.5-14.3-6.5-18.9,0L67,279.8c-5.4,7.7,0,18.3,9.5,18.3h70.2
 C156.1,298.1,161.6,287.4,156.1,279.8"></path>
                                <path class="st8" d="M156.1,279.8L121,230.3c-4.6-6.5-14.3-6.5-18.9,0L67,279.8c-5.4,7.7,0,18.3,9.5,18.3h70.2
 C156.1,298.1,161.6,287.4,156.1,279.8z"></path>
                                <path class="st2" d="M181.6,296.1L159,264.3c-4.7-6.6-14.4-6.6-19,0l-22.6,31.8c-5.5,7.7,0,18.4,9.5,18.4H172
 C181.5,314.5,187,303.8,181.6,296.1"></path>
                                <path class="st8" d="M181.6,296.1L159,264.3c-4.7-6.6-14.4-6.6-19,0l-22.6,31.8c-5.5,7.7,0,18.4,9.5,18.4H172
 C181.5,314.5,187,303.8,181.6,296.1z"></path>
                                <path class="st3" d="M152.2,299.3L123,258.2c-3.8-5.4-11.9-5.4-15.7,0l-29.2,41.1c-4.5,6.4,0,15.2,7.8,15.2h58.3
 C152.2,314.5,156.7,305.7,152.2,299.3"></path>
                                <path class="st8" d="M152.2,299.3L123,258.2c-3.8-5.4-11.9-5.4-15.7,0l-29.2,41.1c-4.5,6.4,0,15.2,7.8,15.2h58.3
 C152.2,314.5,156.7,305.7,152.2,299.3z"></path>
                                <path class="st2" d="M94.6,303.9l-20.3-28.7c-2.7-3.8-8.3-3.8-11,0l-20.3,28.7c-3.2,4.4,0,10.6,5.5,10.6h40.7
 C94.6,314.5,97.7,308.3,94.6,303.9"></path>
                                <path class="st8" d="M94.6,303.9l-20.3-28.7c-2.7-3.8-8.3-3.8-11,0l-20.3,28.7c-3.2,4.4,0,10.6,5.5,10.6h40.7
 C94.6,314.5,97.7,308.3,94.6,303.9z"></path>
                                <g class="not-found-svg-cloud"
                                   data-svg-origin="191.29998779296875 139.15774536132812"
                                   transform="matrix(0.88748,0,0,0.88748,22.836420550968448,15.907658477201357)"><path class="st4" d="M249.9,170.5c0.6-7.3-4.3-14.1-11.7-15.6c-0.4-0.1-0.8-0.1-1.2-0.2c-0.9-7.4-6.4-13.7-14.1-15.2
 c-9.6-1.9-18.9,4.3-20.8,13.9c-0.3,1.5-0.4,3-0.3,4.4c-5,2.5-8.8,7-10.5,12.7H249.9z"></path>
                                    <path class="st5" d="M249.9,170.5c0.6-7.3-4.3-14.1-11.7-15.6c-0.4-0.1-0.8-0.1-1.2-0.2c-0.9-7.4-6.4-13.7-14.1-15.2
 c-9.6-1.9-18.9,4.3-20.8,13.9c-0.3,1.5-0.4,3-0.3,4.4c-5,2.5-8.8,7-10.5,12.7H249.9z"></path></g>
                                <g
                                        class="not-found-svg-tree" data-svg-origin="249.1968231201172 314.6000061035156"
                                        transform="matrix(0.99762,-0.06893,0.06893,0.99762,-11.888923317954628,14.027541341658463)"
                                        style="transform-origin: 0px 0px 0px;"><path class="st3" d="M273.3,228.3c-0.5-0.1-1.1-0.2-1.6-0.2c-1.2-9.9-8.7-18.4-19-20.4c-12.9-2.6-25.5,5.8-28,18.7
 c-0.4,2-0.5,4-0.4,5.9c-7.3,3.7-12.9,10.6-14.6,19.3c-2.9,14.4,6,28.4,20.1,32.1c2.5,6,7.4,10.9,13.7,13.3v17.6h16.8v-17.6
 c7.4-2.8,13.3-9.3,14.9-17.6c0.9-4.4,0.5-8.7-0.9-12.6c7.2-1.8,13-7.5,14.6-15.3C290.9,240.7,283.9,230.4,273.3,228.3"></path>
                                    <path class="st9" d="M273.3,228.3c-0.5-0.1-1.1-0.2-1.6-0.2c-1.2-9.9-8.7-18.4-19-20.4c-12.9-2.6-25.5,5.8-28,18.7
 c-0.4,2-0.5,4-0.4,5.9c-7.3,3.7-12.9,10.6-14.6,19.3c-2.9,14.4,6,28.4,20.1,32.1c2.5,6,7.4,10.9,13.7,13.3v17.6h16.8v-17.6
 c7.4-2.8,13.3-9.3,14.9-17.6c0.9-4.4,0.5-8.7-0.9-12.6c7.2-1.8,13-7.5,14.6-15.3C290.9,240.7,283.9,230.4,273.3,228.3z"></path></g>
                                <g
                                        class="not-found-svg-tree" data-svg-origin="296.9296569824219 314.7999572753906"
                                        transform="matrix(0.99935,-0.03585,0.03585,0.99935,-5.102958403010639,8.313250718462484)"
                                        style="transform-origin: 0px 0px 0px;"><path class="st3" d="M321.8,273.6c-0.2-0.4-0.4-0.8-0.6-1.1c1-3.8,0.8-7.9-0.9-11.7c-3.5-8-12.1-12.2-20.3-10.3
 c-3.8-5.1-10.7-7.1-16.8-4.4c-7.1,3.1-10.4,11.4-7.3,18.5c0.1,0.1,0.1,0.2,0.2,0.4c-4.7,3.6-6.6,10.1-4.1,15.9
 c1.1,2.6,2.9,4.6,5.1,6c-0.1,2.6,0.3,5.3,1.5,7.9c2.1,4.9,6.2,8.3,10.8,9.9v10.1h9.9v-9.7c1-0.2,2-0.5,2.9-1
 c4.9-2.1,8.4-6.2,9.9-11c0.8-0.2,1.5-0.4,2.3-0.8C321.6,289,324.9,280.7,321.8,273.6"></path>
                                    <path class="st9" d="M321.8,273.6c-0.2-0.4-0.4-0.8-0.6-1.1c1-3.8,0.8-7.9-0.9-11.7c-3.5-8-12.1-12.2-20.3-10.3
 c-3.8-5.1-10.7-7.1-16.8-4.4c-7.1,3.1-10.4,11.4-7.3,18.5c0.1,0.1,0.1,0.2,0.2,0.4c-4.7,3.6-6.6,10.1-4.1,15.9
 c1.1,2.6,2.9,4.6,5.1,6c-0.1,2.6,0.3,5.3,1.5,7.9c2.1,4.9,6.2,8.3,10.8,9.9v10.1h9.9v-9.7c1-0.2,2-0.5,2.9-1
 c4.9-2.1,8.4-6.2,9.9-11c0.8-0.2,1.5-0.4,2.3-0.8C321.6,289,324.9,280.7,321.8,273.6z"></path></g>
                                <g
                                        class="not-found-svg-tree" data-svg-origin="225.9499969482422 314.4000244140625"
                                        transform="matrix(0.99993,-0.01162,0.01162,0.99993,3.378821979478552,-0.40550211123516533)"
                                        style="transform-origin: 0px 0px 0px;"><path class="st3" d="M257.4,264.6c0-10-8.1-18.1-18.1-18.1c-0.1,0-0.2,0-0.4,0c-1.6-8-8.7-14-17.1-14c-9.7,0-17.5,7.8-17.5,17.5
 c0,0.7,0.1,1.4,0.1,2.1c-5.9,3.1-9.9,9.3-9.9,16.4c0,7.4,4.3,13.7,10.6,16.7c-0.5,1.2-0.8,2.6-0.8,4c0,5.9,4.8,10.7,10.7,10.7
 c1.3,0,2.5-0.3,3.7-0.7v15.2h11.2v-16.8c1.4,0.4,2.8,0.6,4.3,0.6c8.6,0,15.6-7,15.6-15.6c0-1.1-0.1-2.2-0.3-3.2
 C254.3,276.3,257.4,270.8,257.4,264.6"></path>
                                    <path class="st9" d="M257.4,264.6c0-10-8.1-18.1-18.1-18.1c-0.1,0-0.2,0-0.4,0c-1.6-8-8.7-14-17.1-14c-9.7,0-17.5,7.8-17.5,17.5
 c0,0.7,0.1,1.4,0.1,2.1c-5.9,3.1-9.9,9.3-9.9,16.4c0,7.4,4.3,13.7,10.6,16.7c-0.5,1.2-0.8,2.6-0.8,4c0,5.9,4.8,10.7,10.7,10.7
 c1.3,0,2.5-0.3,3.7-0.7v15.2h11.2v-16.8c1.4,0.4,2.8,0.6,4.3,0.6c8.6,0,15.6-7,15.6-15.6c0-1.1-0.1-2.2-0.3-3.2
 C254.3,276.3,257.4,270.8,257.4,264.6z"></path></g>
                                <g class="not-found-svg-tree"
                                   data-svg-origin="745.96435546875 314.6000061035156"
                                   transform="matrix(0.99999,-0.00046,0.00046,0.99999,9.059203715213613,-3.550083176664714)"
                                   style="transform-origin: 0px 0px 0px;"><path class="st3" d="M770.1,228.3c-0.5-0.1-1.1-0.2-1.6-0.2c-1.2-9.9-8.7-18.4-19-20.4c-12.9-2.6-25.5,5.8-28,18.7
 c-0.4,2-0.5,4-0.4,5.9c-7.3,3.7-12.9,10.6-14.6,19.3c-2.9,14.4,6,28.4,20.1,32.1c2.5,6,7.4,10.9,13.7,13.3v17.6H757v-17.6
 c7.4-2.8,13.3-9.3,14.9-17.6c0.9-4.4,0.5-8.7-0.9-12.6c7.2-1.8,13-7.5,14.6-15.3C787.7,240.7,780.8,230.4,770.1,228.3"></path>
                                    <path class="st9" d="M770.1,228.3c-0.5-0.1-1.1-0.2-1.6-0.2c-1.2-9.9-8.7-18.4-19-20.4c-12.9-2.6-25.5,5.8-28,18.7
 c-0.4,2-0.5,4-0.4,5.9c-7.3,3.7-12.9,10.6-14.6,19.3c-2.9,14.4,6,28.4,20.1,32.1c2.5,6,7.4,10.9,13.7,13.3v17.6H757v-17.6
 c7.4-2.8,13.3-9.3,14.9-17.6c0.9-4.4,0.5-8.7-0.9-12.6c7.2-1.8,13-7.5,14.6-15.3C787.7,240.7,780.8,230.4,770.1,228.3z"></path></g>
                                <g
                                        class="not-found-svg-tree" data-svg-origin="793.8296813964844 314.7999572753906"
                                        transform="matrix(0.99999,-0.00431,0.00431,0.99999,4.642438252883657,0.8919011516877049)"
                                        style="transform-origin: 0px 0px 0px;"><path class="st3" d="M818.7,273.6c-0.2-0.4-0.4-0.8-0.6-1.1c1-3.8,0.8-7.9-0.9-11.7c-3.5-8-12.1-12.2-20.3-10.3
 c-3.8-5.1-10.7-7.1-16.8-4.4c-7.1,3.1-10.4,11.4-7.3,18.5c0.1,0.1,0.1,0.2,0.2,0.4c-4.7,3.6-6.6,10.1-4.1,15.9
 c1.1,2.6,2.9,4.6,5.1,6c-0.1,2.6,0.3,5.3,1.5,7.9c2.1,4.9,6.2,8.3,10.8,9.9v10.1h9.9v-9.7c1-0.2,2-0.5,2.9-1
 c4.9-2.1,8.4-6.2,9.9-11c0.8-0.2,1.5-0.4,2.3-0.8C818.5,289,821.8,280.7,818.7,273.6"></path>
                                    <path class="st9" d="M818.7,273.6c-0.2-0.4-0.4-0.8-0.6-1.1c1-3.8,0.8-7.9-0.9-11.7c-3.5-8-12.1-12.2-20.3-10.3
 c-3.8-5.1-10.7-7.1-16.8-4.4c-7.1,3.1-10.4,11.4-7.3,18.5c0.1,0.1,0.1,0.2,0.2,0.4c-4.7,3.6-6.6,10.1-4.1,15.9
 c1.1,2.6,2.9,4.6,5.1,6c-0.1,2.6,0.3,5.3,1.5,7.9c2.1,4.9,6.2,8.3,10.8,9.9v10.1h9.9v-9.7c1-0.2,2-0.5,2.9-1
 c4.9-2.1,8.4-6.2,9.9-11c0.8-0.2,1.5-0.4,2.3-0.8C818.5,289,821.8,280.7,818.7,273.6z"></path></g>
                                <g
                                        class="not-found-svg-tree" data-svg-origin="722.75 314.4000244140625"
                                        transform="matrix(0.99974,-0.0225,0.0225,0.99974,0.1253165415471713,13.292023210942816)"
                                        style="transform-origin: 0px 0px 0px;"><path class="st3" d="M754.2,264.6c0-10-8.1-18.1-18.1-18.1c-0.1,0-0.2,0-0.4,0c-1.6-8-8.7-14-17.1-14c-9.7,0-17.5,7.8-17.5,17.5
 c0,0.7,0.1,1.4,0.1,2.1c-5.9,3.1-9.9,9.3-9.9,16.4c0,7.4,4.3,13.7,10.6,16.7c-0.5,1.2-0.8,2.6-0.8,4c0,5.9,4.8,10.7,10.7,10.7
 c1.3,0,2.5-0.3,3.7-0.7v15.2h11.2v-16.8c1.4,0.4,2.8,0.6,4.3,0.6c8.6,0,15.6-7,15.6-15.6c0-1.1-0.1-2.2-0.3-3.2
 C751.1,276.3,754.2,270.8,754.2,264.6"></path>
                                    <path class="st9" d="M754.2,264.6c0-10-8.1-18.1-18.1-18.1c-0.1,0-0.2,0-0.4,0c-1.6-8-8.7-14-17.1-14c-9.7,0-17.5,7.8-17.5,17.5
 c0,0.7,0.1,1.4,0.1,2.1c-5.9,3.1-9.9,9.3-9.9,16.4c0,7.4,4.3,13.7,10.6,16.7c-0.5,1.2-0.8,2.6-0.8,4c0,5.9,4.8,10.7,10.7,10.7
 c1.3,0,2.5-0.3,3.7-0.7v15.2h11.2v-16.8c1.4,0.4,2.8,0.6,4.3,0.6c8.6,0,15.6-7,15.6-15.6c0-1.1-0.1-2.2-0.3-3.2
 C751.1,276.3,754.2,270.8,754.2,264.6z"></path></g>
                                <g class="not-found-svg-cloud"
                                   data-svg-origin="0.8999972343444824 195.5019989013672"
                                   transform="matrix(0.98415,0,0,0.98415,2.7766503161909095,1.4136378633972337)"><path class="st4" d="M34.1,213.3c0.4-4.2-2.5-8-6.6-8.9c-0.2,0-0.5-0.1-0.7-0.1c-0.5-4.2-3.7-7.7-8-8.6c-5.4-1.1-10.7,2.5-11.8,7.9
 c-0.2,0.8-0.2,1.7-0.2,2.5c-2.8,1.4-5,4-5.9,7.2H34.1z"></path>
                                    <path class="st10" d="M34.1,213.3c0.4-4.2-2.5-8-6.6-8.9c-0.2,0-0.5-0.1-0.7-0.1c-0.5-4.2-3.7-7.7-8-8.6
 c-5.4-1.1-10.7,2.5-11.8,7.9c-0.2,0.8-0.2,1.7-0.2,2.5c-2.8,1.4-5,4-5.9,7.2H34.1z"></path></g>
                                <rect x="394.5" y="292.8"
                                      class="st0"
                                      width="78.8"
                                      height="20.3"></rect>
                                <rect
                                        x="394.5" y="292.8" class="st11" width="78.8" height="20.3"></rect>
                                <polygon class="st6"
                                         points="481.6,289.7 447.6,289.7 416.9,252.9 451,252.9 	"></polygon>
                                <polygon
                                        class="st11"
                                        points="481.6,289.7 447.6,289.7 416.9,252.9 451,252.9 	"></polygon>
                                <polygon
                                        class="st0" points="447.6,289.7 386.3,289.7 416.9,252.9 	"></polygon>
                                <polygon
                                        class="st11" points="447.6,289.7 386.3,289.7 416.9,252.9 	"></polygon>
                                <rect x="445.2"
                                      y="292.8"
                                      class="st6"
                                      width="28.2"
                                      height="20.3"></rect>
                                <rect
                                        x="445.2" y="292.8" class="st11" width="28.2" height="20.3"></rect>
                                <rect x="383.5"
                                      y="289.2"
                                      class="st12"
                                      width="101.2"
                                      height="4"></rect>
                                <rect
                                        x="383.5" y="311.4" class="st12" width="101.2" height="4"></rect>
                                <rect x="413.4"
                                      y="250.9"
                                      class="st12"
                                      width="41.3"
                                      height="4"></rect>
                                <rect
                                        x="412.2" y="297.5" class="st12" width="11.3" height="14.2"></rect>
                                <path
                                        class="not-found-svg-window st12"
                                        d="M421.1,278.1c0,1.8-1.4,3.2-3.2,3.2s-3.2-1.4-3.2-3.2s1.4-3.2,3.2-3.2S421.1,276.3,421.1,278.1"
                                        data-svg-origin="414.6999816894531 274.8999938964844"
                                        transform="matrix(1,0,0,1,0,0)"
                                        style="fill: rgb(154, 170, 115);"></path>
                                <path class="st6" d="M237.2,255.3h-21.2v50.1c0,8.2-1.7,8.6-9.1,8.6h-57c-8.2,0-9.1-1.3-9.1-8.6v-50.1H26.5c-7.3,0-9.1-1.7-9.1-9.1
 v-56.6L131,13.1c2.6-3.9,3.5-5.6,5.6-5.6c1.3,0,3,0.4,6,1.7l54.8,21.6c4.8,1.7,7.3,3.5,7.3,6c0,1.7-1.3,3.5-3,6L93.5,191h49.7
 l9.5-54.4c1.3-7.3,1.7-8.6,9.1-8.6H207c7.3,0,9.1,1.7,9.1,9.1v54.4h21.2c8.2,0,9.1,0.9,9.1,10.8v44
 C246.3,253.6,245.4,255.3,237.2,255.3"></path>
                                <path class="st7" d="M237.2,255.3h-21.2v50.1c0,8.2-1.7,8.6-9.1,8.6h-57c-8.2,0-9.1-1.3-9.1-8.6v-50.1H26.5c-7.3,0-9.1-1.7-9.1-9.1
 v-56.6L131,13.1c2.6-3.9,3.5-5.6,5.6-5.6c1.3,0,3,0.4,6,1.7l54.8,21.6c4.8,1.7,7.3,3.5,7.3,6c0,1.7-1.3,3.5-3,6L93.5,191h49.7
 l9.5-54.4c1.3-7.3,1.7-8.6,9.1-8.6H207c7.3,0,9.1,1.7,9.1,9.1v54.4h21.2c8.2,0,9.1,0.9,9.1,10.8v44
 C246.3,253.6,245.4,255.3,237.2,255.3z"></path>
                                <path class="st6" d="M738.6,255.3h-21.2v50.1c0,8.2-1.7,8.6-9.1,8.6h-57c-8.2,0-9.1-1.3-9.1-8.6v-50.1H527.9
 c-7.3,0-9.1-1.7-9.1-9.1v-56.6L632.3,13.1c2.6-3.9,3.5-5.6,5.6-5.6c1.3,0,3,0.4,6,1.7l54.8,21.6c4.8,1.7,7.3,3.5,7.3,6
 c0,1.7-1.3,3.5-3,6L594.8,191h49.7l9.5-54.4c1.3-7.3,1.7-8.6,9.1-8.6h45.3c7.3,0,9.1,1.7,9.1,9.1v54.4h21.2c8.2,0,9.1,0.9,9.1,10.8
 v44C747.6,253.6,746.8,255.3,738.6,255.3"></path>
                                <path class="st7" d="M738.6,255.3h-21.2v50.1c0,8.2-1.7,8.6-9.1,8.6h-57c-8.2,0-9.1-1.3-9.1-8.6v-50.1H527.9
 c-7.3,0-9.1-1.7-9.1-9.1v-56.6L632.3,13.1c2.6-3.9,3.5-5.6,5.6-5.6c1.3,0,3,0.4,6,1.7l54.8,21.6c4.8,1.7,7.3,3.5,7.3,6
 c0,1.7-1.3,3.5-3,6L594.8,191h49.7l9.5-54.4c1.3-7.3,1.7-8.6,9.1-8.6h45.3c7.3,0,9.1,1.7,9.1,9.1v54.4h21.2c8.2,0,9.1,0.9,9.1,10.8
 v44C747.6,253.6,746.8,255.3,738.6,255.3z"></path>
                                <g class="not-found-svg-cloud"
                                   data-svg-origin="4.799995422363281 84.65544891357422"
                                   transform="matrix(0.99278,0,0,0.99278,2.9263745536244716,-1.2445783643286772)"><path class="st4" d="M95.7,133.2c1-11.4-6.7-21.9-18.2-24.2c-0.6-0.1-1.2-0.2-1.8-0.3c-1.4-11.4-10-21.2-21.9-23.5
 c-14.9-3-29.3,6.7-32.3,21.6c-0.5,2.3-0.6,4.6-0.5,6.8c-7.7,3.9-13.7,10.9-16.2,19.6H95.7z"></path>
                                    <path class="st5" d="M95.7,133.2c1-11.4-6.7-21.9-18.2-24.2c-0.6-0.1-1.2-0.2-1.8-0.3c-1.4-11.4-10-21.2-21.9-23.5
 c-14.9-3-29.3,6.7-32.3,21.6c-0.5,2.3-0.6,4.6-0.5,6.8c-7.7,3.9-13.7,10.9-16.2,19.6H95.7z"></path></g>
                                <g
                                        class="not-found-svg-cloud"
                                        data-svg-origin="412.8000183105469 1.4813613891601562"
                                        transform="matrix(0.91251,0,0,0.91251,37.80147272189527,-0.12070644041443246)"><path class="st4" d="M536.4,67.5c1.3-15.5-9.1-29.8-24.7-32.9c-0.8-0.2-1.7-0.3-2.5-0.4c-1.9-15.5-13.6-28.8-29.8-32
 c-20.2-4-39.9,9.1-43.9,29.3c-0.6,3.1-0.8,6.2-0.7,9.3c-10.4,5.3-18.6,14.8-22,26.7H536.4z"></path>
                                    <path class="st5" d="M536.4,67.5c1.3-15.5-9.1-29.8-24.7-32.9c-0.8-0.2-1.7-0.3-2.5-0.4c-1.9-15.5-13.6-28.8-29.8-32
 c-20.2-4-39.9,9.1-43.9,29.3c-0.6,3.1-0.8,6.2-0.7,9.3c-10.4,5.3-18.6,14.8-22,26.7H536.4z"></path></g>
                                <g
                                        class="not-found-svg-cloud"
                                        data-svg-origin="685.7999877929688 124.84271240234375"
                                        transform="matrix(0.81584,0,0,0.81584,126.53425743195791,24.67417597893064)"><path class="st4" d="M747.6,157.8c0.7-7.7-4.6-14.9-12.3-16.4c-0.4-0.1-0.8-0.1-1.3-0.2c-1-7.8-6.8-14.4-14.9-16
 c-10.1-2-19.9,4.6-22,14.7c-0.3,1.6-0.4,3.1-0.3,4.6c-5.2,2.6-9.3,7.4-11,13.3H747.6z"></path>
                                    <path class="st5" d="M747.6,157.8c0.7-7.7-4.6-14.9-12.3-16.4c-0.4-0.1-0.8-0.1-1.3-0.2c-1-7.8-6.8-14.4-14.9-16
 c-10.1-2-19.9,4.6-22,14.7c-0.3,1.6-0.4,3.1-0.3,4.6c-5.2,2.6-9.3,7.4-11,13.3H747.6z"></path></g>
                                <path class="st0" d="M506.5,273.6c-0.2-0.4-0.4-0.8-0.6-1.1c1-3.8,0.8-7.9-0.9-11.7c-3.5-8-12.1-12.2-20.3-10.3
 c-3.8-5.1-10.7-7.1-16.8-4.4c-7.1,3.1-10.4,11.4-7.3,18.5c0.1,0.1,0.1,0.2,0.2,0.4c-4.7,3.6-6.6,10.1-4.1,15.9
 c1.1,2.6,2.9,4.6,5.1,6c-0.1,2.6,0.3,5.3,1.5,7.9c2.1,4.9,6.2,8.3,10.8,9.9v10.1h9.9v-9.7c1-0.2,2-0.5,2.9-1
 c4.9-2.1,8.4-6.2,9.9-11c0.8-0.2,1.5-0.4,2.3-0.8C506.3,289,509.6,280.7,506.5,273.6"></path>
                                <path class="st8" d="M506.5,273.6c-0.2-0.4-0.4-0.8-0.6-1.1c1-3.8,0.8-7.9-0.9-11.7c-3.5-8-12.1-12.2-20.3-10.3
 c-3.8-5.1-10.7-7.1-16.8-4.4c-7.1,3.1-10.4,11.4-7.3,18.5c0.1,0.1,0.1,0.2,0.2,0.4c-4.7,3.6-6.6,10.1-4.1,15.9
 c1.1,2.6,2.9,4.6,5.1,6c-0.1,2.6,0.3,5.3,1.5,7.9c2.1,4.9,6.2,8.3,10.8,9.9v10.1h9.9v-9.7c1-0.2,2-0.5,2.9-1
 c4.9-2.1,8.4-6.2,9.9-11c0.8-0.2,1.5-0.4,2.3-0.8C506.3,289,509.6,280.7,506.5,273.6z"></path>
                                <path class="st0" d="M482.4,288.2c0.1-0.3,0.2-0.5,0.4-0.7c-0.6-2.4-0.5-5.1,0.6-7.5c2.2-5.1,7.7-7.8,13-6.6
 c2.5-3.3,6.9-4.5,10.8-2.9c4.6,2,6.7,7.3,4.7,11.9c0,0.1-0.1,0.2-0.1,0.2c3,2.3,4.2,6.5,2.6,10.2c-0.7,1.6-1.9,2.9-3.3,3.8
 c0.1,1.7-0.2,3.4-0.9,5.1c-1.4,3.1-3.9,5.3-6.9,6.3v6.5h-6.3v-6.2c-0.6-0.2-1.3-0.4-1.9-0.6c-3.2-1.4-5.4-4-6.4-7.1
 c-0.5-0.1-1-0.3-1.5-0.5C482.5,298.1,480.4,292.8,482.4,288.2"></path>
                                <path class="st13" d="M482.4,288.2c0.1-0.3,0.2-0.5,0.4-0.7c-0.6-2.4-0.5-5.1,0.6-7.5c2.2-5.1,7.7-7.8,13-6.6
 c2.5-3.3,6.9-4.5,10.8-2.9c4.6,2,6.7,7.3,4.7,11.9c0,0.1-0.1,0.2-0.1,0.2c3,2.3,4.2,6.5,2.6,10.2c-0.7,1.6-1.9,2.9-3.3,3.8
 c0.1,1.7-0.2,3.4-0.9,5.1c-1.4,3.1-3.9,5.3-6.9,6.3v6.5h-6.3v-6.2c-0.6-0.2-1.3-0.4-1.9-0.6c-3.2-1.4-5.4-4-6.4-7.1
 c-0.5-0.1-1-0.3-1.5-0.5C482.5,298.1,480.4,292.8,482.4,288.2z"></path></svg>
                        </div>
                        <h2>СТОРІНКА НЕ ЗНАЙДЕНА</h2>
                        <p> Перевірте вірність URL-адреси</p><span>або</span>
                        <p>поверніться на
                            <router-link to="/">головну сторінку</router-link>
                        </p>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import actualPaddingSetter from '../modules/actualPaddingSetter';
    import notFoundSvgAnimate from '../modules/svg_animation/notFoundSvgAnimate';

    export default {
        name: "notFound",
        mounted() {
            this.pageHeightSetter();
            notFoundSvgAnimate();
        },
        methods: {
            pageHeightSetter() {
                let notFoundPage = document.querySelector('#not-found-container'),
                    headerHeight = actualPaddingSetter(),
                    windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                notFoundPage.style.height = windowHeight - headerHeight + 'px';

                window.addEventListener('resize', () => {
                    notFoundPage = document.querySelector('#not-found-container');
                    headerHeight = actualPaddingSetter();
                    windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                    notFoundPage.style.height = windowHeight - headerHeight + 'px';
                });
            }
        }
    }
</script>

<style scoped>

</style>
