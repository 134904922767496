/*** IMPORTS FROM imports-loader ***/
var define = false;

// Animates The Given Path Element With Given Dash Length, Animation Duration and Direction
export default function animateDashedPath(path, dashLength, drawAnimationDuration) {

    var pathLength = path.getTotalLength(),
        doublePath = path.getTotalLength() * 2,
        numberOfSteps = Math.round(pathLength / (dashLength * 2) + 1),
        stepDuration = drawAnimationDuration / numberOfSteps;

    // Build The Dash Array So We Don't Have To Do It Manually
    var dashArray = [];
    for (var i = numberOfSteps; i > 0; i--) {
        dashArray.push(dashLength);
        dashArray.push(dashLength);
    }

    dashArray.push(pathLength);

    // Animation Start Conditions
    path.setAttribute("stroke-dasharray", dashArray.join(" "));
    path.setAttribute("stroke-dashoffset", -pathLength);

    var dashDrawInterval = setInterval(dashAnimate, stepDuration);

    // Animating Dash Until It Is Full

    // From Right To Left
    function dashAnimate() {
        path.style.strokeOpacity = 1;
        pathLength += dashLength * 2;
        path.setAttribute("stroke-dashoffset", -pathLength);
        if (pathLength > doublePath) {
            clearInterval(dashDrawInterval);
        }
    }
}

