/*** IMPORTS FROM imports-loader ***/
var define = false;

import {TweenMax, TimelineMax} from "gsap";

export default function nutritionAdvicesSvgAnimate() {

    // SVG №0
    TweenMax.staggerFromTo(
        '.advices-svg-1-tree', 3,
        {rotation: -5},
        {rotation: 3, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Power1.easeInOut},
        1
    );
    TweenMax.staggerFromTo(
        '.advices-svg-1-cloud', 3,
        {x: -5, y: 2, scale: .8},
        {x: 0, y: -2, scale: 1, repeat: -1, yoyo: true, force3D: false, ease: Power1.easeInOut},
        1
    );
    TweenMax.staggerFromTo(
        '.advices-svg-1-tablet', 3,
        {scale: .8},
        {transformOrigin: "50% 50%", scale: 1, repeat: -1, yoyo: true, force3D: false, ease: Power1.easeInOut},
        1
    );
    TweenMax.staggerTo(
        '.advices-svg-1-tablet-face', 3,
        {
            fill: 'rgb(100,141,255)',
            transformOrigin: "50% 50%",
            scale: 1,
            repeat: -1,
            yoyo: true,
            force3D: false,
            ease: Power1.easeInOut
        },
        1
    );
    TweenMax.to(
        '#advices-svg-1-bottle-body', 3,
        {fill: 'rgb(100,141,255)', repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}
    );
    TweenMax.fromTo(
        '#advices-svg-1-exclamation-mark-quote', 3,
        {scale: .8, y: 10},
        {scale: 1, y: 5, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeOut}
    );
    TweenMax.to(
        '#advices-svg-1-case-cross-mark', 5,
        {
            rotation: 720,
            transformOrigin: "50% 50%",
            repeat: -1,
            yoyo: true,
            force3D: false,
            ease: Back.easeOut.config(4)
        }
    );


    // SVG №2
    TweenMax.staggerFromTo(
        '.advices-svg-2-tree', 3,
        {rotation: -5},
        {rotation: 3, transformOrigin: "50% 100%", repeat: -1, yoyo: true, force3D: false, ease: Power1.easeInOut},
        1
    );
    TweenMax.fromTo(
        '#advices-svg-2-cross-quote', 3,
        {scale: .8, y: 10},
        {scale: 1, y: 5, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Bounce.easeOut}
    );
    TweenMax.to(
        '#advices-svg-1-case-cross-mark', 5,
        {
            rotation: 720,
            transformOrigin: "50% 50%",
            repeat: -1,
            yoyo: true,
            force3D: false,
            ease: Back.easeOut.config(4)
        }
    );
    TweenMax.staggerFromTo(
        '.advices-svg-2-kebab-meat', 3,
        {y: -4},
        {y: 5, transformOrigin: "50% 50%", repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut},
        1
    );
    TweenMax.to(
        '#advices-svg-2-bottle-body', 3,
        {fill: 'rgb(100,141,255)', repeat: -1, yoyo: true, force3D: false, ease: Sine.easeOut}
    );
}	
