/*** IMPORTS FROM imports-loader ***/
var define = false;

import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import digestion from './views/digestion.vue'
import enzymes from './views/enzymes.vue'
import festalNeo from './views/festalNeo.vue'
import instruction from './views/instruction.vue'
import nutritionAdvices from './views/nutritionAdvices.vue'
import officialNotice from './views/officialNotice.vue'
import feedback from './views/feedback.vue'
import notFound from './views/notFound'




Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [

    {
      path: "*",
      redirect: "/404"
    },

    {
      path: "/404",
      name: "404",
      component: notFound
    },


    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/digestion',
      name: 'digestion',
      component: digestion
    },
    {
      path: '/enzymes',
      name: 'enzymes',
      component: enzymes
    },
    {
      path: '/festal-neo',
      name: 'festalNeo',
      component: festalNeo
    },
    {
      path: '/instruction',
      name: 'instruction',
      component: instruction
    },
    {
      path: '/nutrition-advices',
      name: 'nutritionAdvices',
      component: nutritionAdvices
    },
    {
      path: '/official-notice',
      name: 'officialNotice',
      component: officialNotice
    },
    {
      path: '/feedback',
      name: 'feedback',
      redirect: '/',
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash}

    } else if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0}
    }
  }
})

