<template>
    <header id="header-container">
        <button @click="openMenu()" class="menu-btn">Меню</button>
        <a class="sanofi-logo" style="cursor: default"></a>
        <div v-bind:class="[{ active: isActive }]" id="menu-container" class="menu-container">

            <ul class="main-menu-list" @click="closeMenu()">
                <router-link to="/" :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Головна сторінка', 'event_label':'UA: ${$route.path}'})`">Головна сторінка</router-link>

                <div><span><router-link :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Травлення', 'event_label':'UA: ${$route.path}'})`" to="/digestion">Травлення</router-link></span>
                    <ul class="submenu-list">
                        <li>
                            <router-link :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Процес травлення: що, як, чому', 'event_label':'UA: ${$route.path}'})`" to="/digestion#part_1">Процес травлення: що, як, чому</router-link>
                        </li>
                        <li>
                            <router-link to="/digestion#part_2" :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Підшлункова залоза: анатомія та функції', 'event_label':'UA: ${$route.path}'})`">Підшлункова залоза: анатомія та функції</router-link>
                        </li>
                        <li>
                            <router-link to="/digestion#part_3" :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Порушення роботи підшлункової залози', 'event_label':'UA: ${$route.path}'})`">Порушення роботи підшлункової залози</router-link>
                        </li>
                    </ul>
                </div>

                <div><span><router-link :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Ферменти', 'event_label':'UA: ${$route.path}'})`" to="/enzymes">Ферменти</router-link></span>
                    <ul class="submenu-list">
                        <li>
                            <router-link to="/enzymes#part_1" :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Травні ферменти', 'event_label':'UA: ${$route.path}'})`">Травні ферменти</router-link>
                        </li>
                        <li>
                            <router-link to="/enzymes#part_2" :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Дефіцит ферментів', 'event_label':'UA: ${$route.path}'})`">Дефіцит ферментів</router-link>
                        </li>
                        <li>
                            <router-link to="/enzymes#part_3" :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Ферментні препарати', 'event_label':'UA: ${$route.path}'})`">Ферментні препарати</router-link>
                        </li>
                    </ul>
                </div>

                <router-link to="/festal-neo" :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Фестал® НЕО 10 000', 'event_label':'UA: ${$route.path}'})`">Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000</router-link>
                <router-link to="/instruction" :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Інструкція Фестал® НЕО 10 000', 'event_label':'UA: ${$route.path}'})`">Інструкція Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000</router-link>
                <router-link to="/nutrition-advices" :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Поради та рекомендації з харчування', 'event_label':'UA: ${$route.path}'})`">Поради та рекомендації з харчування</router-link>
                <router-link to="/official-notice" :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Офіційне повідомлення', 'event_label':'UA: ${$route.path}'})`">Офіційне повідомлення</router-link>
<!--                <router-link to="/feedback">Зворотній зв'язок</router-link>-->
                <a href="https://tabletki.ua/%D0%A4%D0%B5%D1%81%D1%82%D0%B0%D0%BB-%D0%BD%D0%B5%D0%BE-10-000/pharmacy/kiev/"
                   target="_blank" :onclick="`dataLayer.push({'event': 'click', 'event_category': 'menu: Купити Фестал® НЕО 10 000', 'event_label':'UA: ${$route.path}'})`">Купити Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000</a>
            </ul>
            <button @click="closeMenu()" class="close-menu"></button>
        </div>
    </header>
</template>

<script>
    import actualPaddingSetter from '../modules/actualPaddingSetter';

    export default {
        name: "topMenu",
        data: () => ({
            isActive: false,
        }),
        mounted() {
            this.menuHeightSetter();
        },

        methods: {

            switchLang() {
                if (this.$route.path === '/') {
                    this.$router.push("/ru")
                } else {
                    this.$router.push("/ru" + this.$route.path)
                }


            },
            menuHeightSetter() {
                let menu = document.querySelector('#menu-container'),
                    headerHeight = actualPaddingSetter(),
                    windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                menu.style.minHeight = windowHeight - headerHeight + 'px';
                menu.style.top = headerHeight + 'px';

                window.addEventListener('resize', () => {
                    menu = document.querySelector('#menu-container');
                    headerHeight = actualPaddingSetter();
                    windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                    menu.style.minHeight = windowHeight - headerHeight + 'px';
                    menu.style.top = headerHeight + 'px';
                });
            },

            openMenu() {
                if (this.isActive === true) {
                    this.isActive = false;
                } else {

                    this.isActive = true;
                }

            },
            closeMenu() {
                this.isActive = false;
            }
        }
    }
</script>

<style lang="less" scoped>

    .menu-container {
        transition: all 1s ease;

        &.active {
            transform: translateX(0);
        }

    }
</style>
