<template>
  <div class="enzymes" data-view>
    <div data-reactroot="" id="content">
      <div id="router-container">
        <top-menu></top-menu>
        <message-alert></message-alert>
        <span>
				<div id="enzymes-page-container" class="content-container" style="padding-top: 60px;">
					<div
              id="part_1" class="title-block">
						<h1>Травні
							<br>ферменти
							</h1>
						</div>
						<section
                class="main-column">
              <p>Підшлункова залоза виділяє травні ферменти, які перетравлюють компоненти їжі в тонкому кишечнику. Ферменти розщеплюють складні поживні речовини, амілаза – вуглеводи, ліпаза - жири, а протеаза - білки<sup>1</sup>.</p>
							<div
                  class="inline-svg-center-block inline-svg-bigger">
								<svg id="ferment-svg-1" class="inline-svg-illustration"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
                     viewBox="0 0 121.4 97.8">
									<g id="ferment-svg-1-trees">
										<path id="ferment-svg-1-tree-1" class="ferment-svg-1-tree st1" d="M99.7,69.1c-0.2,0-0.4-0.1-0.5-0.1c-0.4-3.3-2.8-6-6.2-6.7c-4.2-0.8-8.4,1.9-9.2,6.2
 c-0.1,0.7-0.2,1.3-0.1,1.9c-2.4,1.2-4.2,3.5-4.8,6.3c-0.9,4.7,2,9.3,6.6,10.6c0.8,2,2.4,3.6,4.5,4.4v5.8h5.5v-5.8
 c2.4-0.9,4.4-3,4.9-5.8c0.3-1.4,0.2-2.9-0.3-4.1c2.4-0.6,4.3-2.5,4.8-5C105.5,73.2,103.2,69.8,99.7,69.1z"
                          data-svg-origin="91.86577224731445 97.50000762939453"
                          transform="matrix(0.99878,-0.04922,0.04922,0.99878,-1.6580370116918068,3.361255258249109)"
                          style="transform-origin: 0px 0px 0px;"></path>
										<path
                        id="ferment-svg-1-tree-2" class="ferment-svg-1-tree st1" d="M100.2,76.5c0.1,0,0.3,0,0.4-0.1c0.3-2.4,2.1-4.5,4.6-5c3.1-0.6,6.2,1.4,6.8,4.6
 c0.1,0.5,0.1,1,0.1,1.4c1.8,0.9,3.1,2.6,3.6,4.7c0.7,3.5-1.5,6.9-4.9,7.8c-0.6,1.5-1.8,2.7-3.3,3.2v4.3h-4.1v-4.3
 c-1.8-0.7-3.2-2.3-3.6-4.3c-0.2-1.1-0.1-2.1,0.2-3.1c-1.7-0.4-3.2-1.8-3.5-3.7C95.9,79.5,97.6,77,100.2,76.5z"
                        data-svg-origin="106.10458755493164 97.4000015258789"
                        transform="matrix(0.99622,-0.0868,0.0868,0.99622,-5.815925233468818,8.63067779087979)"
                        style="transform-origin: 0px 0px 0px;"></path>
									</g>
									<g id="ferment-svg-1-mountains">
										<path id="ferment-svg-1-mountain-1" class="st2" d="M89.9,90.3l-8.8-12.4c-1.8-2.6-5.6-2.6-7.4,0l-8.8,12.4c-2.1,3,0,7.2,3.7,7.2h17.6
 C89.9,97.5,92.1,93.3,89.9,90.3z"></path>
										<path id="ferment-svg-1-mountain-2" class="st3" d="M47.7,87.3L32.6,66.1c-2-2.8-6.1-2.8-8.1,0L9.4,87.3c-2.3,3.3,0,7.8,4.1,7.8h30.1
 C47.6,95.2,50,90.6,47.7,87.3z"></path>
										<path id="ferment-svg-1-mountain-3" class="st4" d="M72,87.8L60.1,71c-2.5-3.5-7.6-3.5-10,0L38.1,87.8c-2.9,4.1,0,9.7,5,9.7H67
 C72,97.5,74.9,91.9,72,87.8z"></path>
										<path id="ferment-svg-1-mountain-4" class="st1" d="M56.9,90.7L43.8,72.2c-1.7-2.4-5.3-2.4-7.1,0L23.7,90.7c-2,2.9,0,6.8,3.5,6.8h26.2
 C56.9,97.5,59,93.5,56.9,90.7z"></path>
										<path id="ferment-svg-1-mountain-5" class="st5" d="M28.2,91.9L17.5,76.8c-1.4-2-4.4-2-5.8,0L1,91.9c-1.7,2.3,0,5.6,2.9,5.6h21.5
 C28.2,97.5,29.9,94.3,28.2,91.9z"></path>
									</g>
									<g id="ferment-1-vials">
										<g id="ferment-1-vial-1" class="ferment-1-vial" data-svg-origin="40 65.4000015258789"
                       transform="matrix(1,0,0,1,0,0.9293833226773556)" style="transform-origin: 0px 0px 0px;">
											<path class="st6" d="M46,40.9H34.1v44.9h0c0,0,0,0,0,0.1c0,3.3,2.7,5.9,5.9,5.9c3.3,0,5.9-2.7,5.9-5.9
 c0,0,0,0,0-0.1h0V40.9z"></path>
											<rect class="st7" x="32" y="39" width="16" height="4.8"></rect>
										</g>
										<g
                        id="ferment-1-vial-2" class="ferment-1-vial"
                        data-svg-origin="63.80000114440918 65.4000015258789"
                        transform="matrix(1,0,0,1,0,-3.496038115636617)" style="transform-origin: 0px 0px 0px;">
											<path class="st6" d="M69.8,40.9H57.9v44.9h0c0,0,0,0,0,0.1c0,3.3,2.7,5.9,5.9,5.9c3.3,0,5.9-2.7,5.9-5.9
 c0,0,0,0,0-0.1h0V40.9z"></path>
											<rect class="st7" x="55.8" y="39" width="16" height="4.8"></rect>
										</g>
										<g
                        id="ferment-1-vial-3" class="ferment-1-vial"
                        data-svg-origin="87.69999694824219 65.4000015258789"
                        transform="matrix(1,0,0,1,0,0.056495733881824606)" style="transform-origin: 0px 0px 0px;">
											<path class="st6" d="M93.6,40.9H81.7v44.9h0c0,0,0,0,0,0.1c0,3.3,2.7,5.9,5.9,5.9c3.3,0,5.9-2.7,5.9-5.9
 c0,0,0,0,0-0.1h0V40.9z"></path>
											<rect class="st7" x="79.7" y="39" width="16" height="4.8"></rect>
										</g>
										<path
                        id="ferment-1-vial-holder" class="st8"
                        d="M25.7,73.3v24.1h76.1V73.3H25.7z M98.5,94.3H28.9V76.5h69.7V94.3z"></path>
									</g>
									<g
                      id="ferment-1-bulbs">
										<circle id="ferment-1-bulb-1" class="ferment-1-bulb st9" cx="46.5" cy="25.5" r="5.6"
                            data-svg-origin="46.500001430511475 25.499999523162842"
                            transform="matrix(0.99708,0,0,0.99708,0.1354689552412225,0.07428942339317501)"
                            style="transform-origin: 0px 0px 0px;"></circle>
										<circle
                        id="ferment-1-bulb-2" class="ferment-1-bulb st9" cx="36.6" cy="12.1" r="4.1"
                        data-svg-origin="36.59999990463257 12.099999904632568"
                        transform="matrix(0.82809,0,0,0.82809,6.291745831990248,2.080058037306671)"
                        style="transform-origin: 0px 0px 0px;"></circle>
										<circle id="ferment-1-bulb-3"
                            class="ferment-1-bulb st9" cx="67.4"
                            cy="28.1" r="3.4"
                            data-svg-origin="67.40000009536743 28.100000858306885"
                            transform="matrix(0.96193,0,0,0.96193,2.5656440318806517,1.0696528100288738)"
                            style="transform-origin: 0px 0px 0px;"></circle>
										<circle
                        id="ferment-1-bulb-4" class="ferment-1-bulb st9" cx="48.5" cy="5.8" r="2.1"
                        data-svg-origin="48.500001430511475 5.800000190734863"
                        transform="matrix(0.99698,0,0,0.99698,0.14607161341405828,0.017468357952037827)"
                        style="transform-origin: 0px 0px 0px;"></circle>
										<circle id="ferment-1-bulb-5"
                            class="ferment-1-bulb st9" cx="83.1"
                            cy="24.3" r="3.4"
                            data-svg-origin="83.09999704360962 24.299999713897705"
                            transform="matrix(0.99991,0,0,0.99991,0.006906507389942362,0.002019592461738995)"
                            style="transform-origin: 0px 0px 0px;"></circle>
										<circle
                        id="ferment-1-bulb-6" class="ferment-1-bulb st9" cx="84.8" cy="34.5" r="1.7"
                        data-svg-origin="84.80000615119934 34.49999928474426"
                        transform="matrix(1.00124,0,0,1.00124,-0.10593721549074075,-0.043099452754070455)"
                        style="transform-origin: 0px 0px 0px;"></circle>
									</g>
									<g id="ferment-1-clouds">
										<path id="ferment-1-cloud-1" class="ferment-1-cloud st10" d="M92,12.3c0.2-2.8-1.6-5.3-4.4-5.9c-0.2,0-0.3-0.1-0.5-0.1c-0.3-2.8-2.4-5.2-5.3-5.7
 c-3.6-0.7-7.2,1.6-7.9,5.3c-0.1,0.6-0.1,1.1-0.1,1.7c-1.9,0.9-3.3,2.7-4,4.8H92z"
                          data-svg-origin="69.79999542236328 0.4748331308364868"
                          transform="matrix(0.85446,0,0,0.85446,6.519994213791023,0.9798171245962255)"></path>
										<path
                        id="ferment-1-cloud-2" class="ferment-1-cloud st10" d="M27.3,44.4c0.2-2.6-1.5-5-4.2-5.6c-0.1,0-0.3,0-0.4-0.1c-0.3-2.6-2.3-4.9-5-5.4
 c-3.4-0.7-6.7,1.5-7.4,5c-0.1,0.5-0.1,1.1-0.1,1.6c-1.8,0.9-3.1,2.5-3.7,4.5H27.3z"
                        data-svg-origin="6.499999046325684 33.17031478881836"
                        transform="matrix(0.8005,0,0,0.8005,-3.690586568027375,8.607007538474031)"></path>
										<path
                        id="ferment-1-cloud-3" class="ferment-1-cloud st10" d="M121,45c0.2-2.2-1.3-4.3-3.6-4.8c-0.1,0-0.2,0-0.4-0.1c-0.3-2.3-2-4.2-4.3-4.6
 c-2.9-0.6-5.8,1.3-6.4,4.3c-0.1,0.5-0.1,0.9-0.1,1.3c-1.5,0.8-2.7,2.1-3.2,3.9H121z"
                        data-svg-origin="103 35.38945388793945"
                        transform="matrix(0.83544,0,0,0.83544,12.83551013333315,7.114767905877388)"></path>
									</g>
								</svg>
							</div>
							<div
                  class="ferment-types-block">
								<div class="ferment-type-block ferment-type-block-1">
									<img
                      class="ferment-line" src="../assets/imgs/enzymesPage/path_1.png" width="11px"
                      height="188px">
										<span
                        class="ferment-type">амілаза
											<br>розщеплює
												<br>вуглеводи
												</span>
											</div>
											<div
                          class="ferment-type-block ferment-type-block-2">
												<img class="ferment-line"
                             src="../assets/imgs/enzymesPage/path_2.png"
                             width="11px" height="75px">
													<span
                              class="ferment-type">ліпаза
														<br>розщеплює
															<br>жири
															</span>
														</div>
														<div
                                class="ferment-type-block ferment-type-block-3">
															<img class="ferment-line"
                                   src="../assets/imgs/enzymesPage/path_1.png"
                                   width="11px" height="188px">
																<span
                                    class="ferment-type">протеаза
																	<br>розщеплює
																		<br>білки
																		</span>
																	</div>
																</div>
																<div id="part_2"
                                     class="title-block-2">
																	<h1>Дефіцит
																		<br>ферментів
																		</h1>
																	</div>
																	<p>Коли організму не вистачає поживних речовин, у людини знижується маса тіла, вона відчуває слабкість, млявість<sup>2</sup>. Виникають гіповітамінози: шкіра стає сухою, волосся - ламким, псуються і ламаються нігті, кровоточать ясна, в куточках рота з'являються заїди, відзначається почервоніння язика і відчуття печіння в роті<sup>3</sup>.
																	</p>
																	<p
                                      class="connected-paragraph">Через порушення в роботі підшлункової залози порушується баланс між обсягом і складом спожитої людиною їжі і тим, як травна система здатна цю їжу перетравити і поглинути<sup>3</sup>.
																	</p>
																	<p
                                      class="connected-paragraph">Цей феномен називається «дефіцит ферментів». Ймовірними причинами його появи є порушення функції підшлункової залози і недостатня кількість ферментів<sup>3</sup>.</p>
																	<h2
                                      class="subtitle">Причини, що викликають знижене виділення ферментів, можна умовно розділити на дві групи: гастрогенні та панкреатичні<sup>3</sup>.</h2>
																	<div
                                      class="rounded-dashed-border-block dotted-path-connection dotted-path-connection-1">
																		<h3
                                        class="group-title">Гастрогенна ферментна
																			<br>недостатність
																			</h3>
																			<p class="connected-paragraph">Гастрогенна ферментна недостатність може виникнути, якщо людина перенесла захворювання, яке призвело до зниженої секреції шлункового соку (видалення частини шлунка, стани зі зниженою кислотністю). Такі захворювання супроводжуються зниженням виділення ферментів. Також, причиною появи ферментної недостатності може бути інтенсивний розвиток патогенної мікрофлори в кишечнику, внаслідок чого ферменти в тонкому кишечнику не будуть здатні функціонувати в активній формі<sup>3</sup>.
																			</p>
																		</div>
																		<div
                                        class="rounded-dashed-border-block">
																			<h3 class="group-title">панкреатична ферментна
																				<br>недостатність
																				</h3>
																				<p
                                            class="connected-paragraph">Другий тип недостатності (панкреатична) можуть викликати або захворювання самої підшлункової залози (первинна), або причини, які обмежують і ускладнюють дію травних ферментів (вторинна)<sup>2</sup>.
																				</p>
																				<p
                                            class="connected-paragraph">Первинна панкреатична недостатність означає, що кількість ферментів, що виробляються, нижча, ніж необхідно, але вони виконують свою функцію. А в разі вторинної недостатності навпаки - активність ферментів нижча, хоча їх стільки, скільки потрібно для травлення<sup>2</sup>.
																				</p>
																			</div>
																		</section>
																		<div
                                        id="part_3" class="title-block">
																			<h1>Ферментні
																				<br>препарати
																				</h1>
																			</div>
																			<section
                                          class="main-column">
																				<div class="inline-svg-center-block">
																					<svg id="ferment-svg-2" class="inline-svg-illustration"
                                               xmlns="http://www.w3.org/2000/svg"
                                               xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px"
                                               y="0px" viewBox="0 0 140.8 88.2">
																						<g id="ferment-svg-2-mountains">
																							<path id="ferment-svg-2-mountain-1" class="st0" d="M139.9,82.8l-6.1-8.6c-1.3-1.8-3.9-1.8-5.1,0l-6.1,8.6c-1.5,2.1,0,5,2.6,5h12.2
 C139.9,87.8,141.3,84.9,139.9,82.8z"></path>
																							<path id="ferment-svg-2-mountain-2" class="st1" d="M124.5,75.1l-12.8-18c-1.7-2.4-5.2-2.4-6.9,0L92,75.1c-2,2.8,0,6.7,3.4,6.7h25.6
 C124.5,81.8,126.5,77.9,124.5,75.1z"></path>
																							<path id="ferment-svg-2-mountain-3" class="st2" d="M133.7,81.1l-8.2-11.6c-1.7-2.4-5.2-2.4-6.9,0l-8.2,11.6c-2,2.8,0,6.7,3.5,6.7h16.5
 C133.7,87.8,135.7,83.9,133.7,81.1z"></path>
																							<path id="ferment-svg-2-mountain-4" class="st3" d="M119.5,80.2L105,59.8c-1.9-2.7-5.9-2.7-7.8,0L82.6,80.2c-2.3,3.2,0,7.6,3.9,7.6h29
 C119.5,87.8,121.8,83.4,119.5,80.2z"></path>
																						</g>
																						<g class="ferment-svg-2-trees">
																							<path id="ferment-svg-2-tree-1" class="ferment-svg-2-tree st1" d="M12.8,56.8c0.2,0,0.4-0.1,0.6-0.1c0.4-3.6,3.1-6.6,6.8-7.3c4.6-0.9,9.1,2.1,10.1,6.7
 c0.1,0.7,0.2,1.4,0.2,2.1c2.6,1.3,4.6,3.8,5.3,6.9c1,5.2-2.2,10.2-7.2,11.5c-0.9,2.2-2.6,3.9-4.9,4.8v6.3h-6v-6.3
 c-2.7-1-4.8-3.3-5.4-6.3c-0.3-1.6-0.2-3.1,0.3-4.5c-2.6-0.6-4.7-2.7-5.2-5.5C6.5,61.3,9,57.6,12.8,56.8z"
                                                    data-svg-origin="21.599488258361816 87.70000457763672"
                                                    transform="matrix(0.99878,-0.04922,0.04922,0.99878,-0.993750669203429,-0.23053367855779783)"
                                                    style="transform-origin: 0px 0px 0px;"></path>
																							<path
                                                  id="ferment-svg-2-tree-2" class="ferment-svg-2-tree st0" d="M3.7,69.3c0.1,0,0.2,0,0.3-0.1c0.3-2.1,1.9-3.9,4.1-4.4c2.8-0.6,5.5,1.2,6,4
 c0.1,0.4,0.1,0.9,0.1,1.3c1.6,0.8,2.8,2.3,3.1,4.1c0.6,3.1-1.3,6.1-4.3,6.9c-0.5,1.3-1.6,2.3-2.9,2.9v3.8H6.5V84
 c-1.6-0.6-2.9-2-3.2-3.8c-0.2-0.9-0.1-1.9,0.2-2.7c-1.5-0.4-2.8-1.6-3.1-3.3C0,72,1.4,69.7,3.7,69.3z"
                                                  data-svg-origin="8.87129756808281 87.80001068115234"
                                                  transform="matrix(0.99622,-0.0868,0.0868,0.99622,-5.605746629993966,0.2691720962350139)"
                                                  style="transform-origin: 0px 0px 0px;"></path>
																						</g>
																						<g id="ferment-svg-2-big-tablet"
                                               class="ferment-svg-2-big-tablet"
                                               data-svg-origin="86.47635650634766 70.49952125549316"
                                               transform="matrix(1,0,0,1,3.8931487499997464,0)"
                                               style="transform-origin: 0px 0px 0px;">
																							<path class="st5" d="M91,53.2h-9v2.5c-5,3-8.3,8.5-8.3,14.8S77,82.2,82,85.3v2.5h9c9.5,0,17.3-7.7,17.3-17.3
 S100.5,53.2,91,53.2z"></path>
																							<g class="ferment-svg-2-big-tablet-face"
                                                 data-svg-origin="82.10000228881836 70.50000190734863"
                                                 transform="matrix(0.99078,0.13547,-0.13547,0.99078,10.308183431511154,-10.472804576802162)"
                                                 style="transform-origin: 0px 0px 0px;">
																								<circle class="st6" cx="82.1" cy="70.5" r="17.3"></circle>
																								<path
                                                    class="st5" d="M68.8,81.5c0.3,0.4,0.7,0.8,1.1,1.2s0.8,0.8,1.2,1.1l24.3-24.3c-0.3-0.4-0.7-0.8-1.1-1.2
 c-0.4-0.4-0.8-0.8-1.2-1.1L68.8,81.5z"></path>
																							</g>
																						</g>
																						<g id="ferment-svg-2-clouds">
																							<path id="ferment-svg-2-cloud-1" class="ferment-svg-2-cloud st6" d="M121.3,20.4c0.4-4.7-2.8-9-7.5-10c-0.3-0.1-0.5-0.1-0.8-0.1c-0.6-4.7-4.1-8.7-9-9.7
 C98-0.6,92,3.4,90.8,9.5c-0.2,0.9-0.3,1.9-0.2,2.8c-3.2,1.6-5.6,4.5-6.7,8.1H121.3z"
                                                    data-svg-origin="83.90000915527344 0.38728106021881104"
                                                    transform="matrix(0.85446,0,0,0.85446,8.572048172418377,0.9670751814676394)"></path>
																							<path
                                                  id="ferment-svg-2-cloud-2" class="ferment-svg-2-cloud st6" d="M133.7,37.2c0.2-1.8-1.1-3.5-2.9-3.9c-0.1,0-0.2,0-0.3,0c-0.2-1.8-1.6-3.4-3.5-3.7
 c-2.4-0.5-4.7,1.1-5.1,3.4c-0.1,0.4-0.1,0.7-0.1,1.1c-1.2,0.6-2.2,1.7-2.6,3.1H133.7z"
                                                  data-svg-origin="119.20000457763672 29.508197784423828"
                                                  transform="matrix(0.8005,0,0,0.8005,18.79206776653139,7.876447585487716)"></path>
																							<path
                                                  id="ferment-svg-2-cloud-3" class="ferment-svg-2-cloud st6" d="M31.3,22.5c0.2-2.8-1.7-5.4-4.5-6c-0.2,0-0.3-0.1-0.5-0.1c-0.3-2.8-2.5-5.2-5.4-5.8
 C17.3,10,13.8,12.4,13,16c-0.1,0.6-0.2,1.1-0.1,1.7c-1.9,1-3.4,2.7-4,4.8H31.3z"
                                                  data-svg-origin="8.899999618530273 10.508112907409668"
                                                  transform="matrix(0.83544,0,0,0.83544,-2.6493810005515677,3.0203486201529337)"></path>
																						</g>
																						<g
                                                id="ferment-svg-2-bottle">
																							<path id="ferment-svg-2-bottle-body" class="ferment-svg-2-bottle-body st0"
                                                    d="M73.5,27.2H47.2l-5,7.5c-0.9,1.4-1.4,3.1-1.4,4.7v48.4h39.1V39.4c0-1.7-0.5-3.3-1.4-4.7
 L73.5,27.2z" data-svg-origin="40.79999923706055 27.200000762939453" transform="matrix(1,0,0,1,0,0)"
                                                    style="fill: rgb(90, 154, 250);"></path>
																							<rect
                                                  id="ferment-svg-2-bottle-cap" x="43.4" y="16.9" class="st5"
                                                  width="33.9" height="10.4"></rect>
																							<rect
                                                  id="ferment-svg-2-bottle-label" x="40.8" y="45.8" class="st5"
                                                  width="39.1" height="33.1"></rect>
																							<rect
                                                  id="ferment-svg-2-bottle-label-stripe" x="40.8" y="50.2" class="st7"
                                                  width="39.1"
                                                  height="7.5"></rect>
																						</g>
																						<g id="ferment-svg-2-drugs">
																							<path id="ferment-svg-2-drugs-pack" class="st6" d="M45.7,87.8H24.8c-2,0-3.6-1.6-3.6-3.6V35.6c0-2,1.6-3.6,3.6-3.6h20.9c2,0,3.6,1.6,3.6,3.6v48.6
 C49.3,86.2,47.7,87.8,45.7,87.8z"></path>
																							<g id="ferment-svg-2-tablet-8" class="ferment-svg-2-tablet"
                                                 data-svg-origin="28.200000762939453 40.29999923706055"
                                                 transform="matrix(0.85446,0,0,0.85446,3.2641038784470418,5.1250844163773746)"
                                                 style="transform-origin: 0px 0px 0px;">
																								<path class="st5" d="M28.7,36.6h-0.9v0.5c-1.1,0.6-1.8,1.8-1.8,3.2s0.7,2.5,1.8,3.2V44h0.9c2,0,3.7-1.7,3.7-3.7
 S30.7,36.6,28.7,36.6z"></path>
																								<circle class="ferment-svg-2-tablet-face st3" cx="27.7" cy="40.3"
                                                        r="3.7"
                                                        data-svg-origin="27.700000047683716 40.29999852180481"
                                                        transform="matrix(1,0,0,1,0,0)"
                                                        style="transform-origin: 0px 0px 0px; fill: rgb(67, 161, 233);"></circle>
																							</g>
																							<g
                                                  id="ferment-svg-2-tablet-7" class="ferment-svg-2-tablet"
                                                  data-svg-origin="40.5 40.29999923706055"
                                                  transform="matrix(0.8005,0,0,0.8005,7.23939164741212,7.299493264101335)"
                                                  style="transform-origin: 0px 0px 0px;">
																								<path class="st5" d="M41,36.6H40v0.5c-1.1,0.6-1.8,1.8-1.8,3.2s0.7,2.5,1.8,3.2V44H41c2,0,3.7-1.7,3.7-3.7
 S43,36.6,41,36.6z"></path>
																								<circle class="ferment-svg-2-tablet-face st3" cx="40" cy="40.3" r="3.7"
                                                        data-svg-origin="39.99999928474426 40.29999852180481"
                                                        transform="matrix(1,0,0,1,0,0)"
                                                        style="transform-origin: 0px 0px 0px; fill: rgb(54, 169, 225);"></circle>
																							</g>
																							<g
                                                  id="ferment-svg-2-tablet-6" class="ferment-svg-2-tablet"
                                                  data-svg-origin="28.200000762939453 52.70000076293945"
                                                  transform="matrix(0.83544,0,0,0.83544,3.8005305596263668,7.932197204070752)"
                                                  style="transform-origin: 0px 0px 0px;">
																								<path class="st5" d="M28.7,49h-0.9v0.5c-1.1,0.6-1.8,1.8-1.8,3.2s0.7,2.5,1.8,3.2v0.5h0.9
 c2,0,3.7-1.7,3.7-3.7S30.7,49,28.7,49z"></path>
																								<circle class="ferment-svg-2-tablet-face st3" cx="27.7" cy="52.7"
                                                        r="3.7"
                                                        data-svg-origin="27.700000047683716 52.700000047683716"
                                                        transform="matrix(1,0,0,1,0,0)"
                                                        style="transform-origin: 0px 0px 0px; fill: rgb(62, 164, 230);"></circle>
																							</g>
																							<g
                                                  id="ferment-svg-2-tablet-5" class="ferment-svg-2-tablet"
                                                  data-svg-origin="40.5 52.70000076293945"
                                                  transform="matrix(0.94553,0,0,0.94553,1.3658080474119956,2.130273768965003)"
                                                  style="transform-origin: 0px 0px 0px;">
																								<path class="st5" d="M41,49H40v0.5c-1.1,0.6-1.8,1.8-1.8,3.2s0.7,2.5,1.8,3.2v0.5H41c2,0,3.7-1.7,3.7-3.7
 S43,49,41,49z"></path>
																								<circle class="ferment-svg-2-tablet-face st3" cx="40" cy="52.7" r="3.7"
                                                        data-svg-origin="39.99999928474426 52.700000047683716"
                                                        transform="matrix(1,0,0,1,0,0)"
                                                        style="transform-origin: 0px 0px 0px; fill: rgb(87, 149, 247);"></circle>
																							</g>
																							<g
                                                  id="ferment-svg-2-tablet-4" class="ferment-svg-2-tablet"
                                                  data-svg-origin="28.200000762939453 65.14999580383301"
                                                  transform="matrix(0.99949,0,0,0.99949,-0.8256507388663508,-0.7168484051099338)"
                                                  style="transform-origin: 0px 0px 0px;">
																								<path class="st5" d="M28.7,61.5h-0.9V62c-1.1,0.6-1.8,1.8-1.8,3.2c0,1.3,0.7,2.5,1.8,3.2v0.5h0.9
 c2,0,3.7-1.7,3.7-3.7C32.4,63.1,30.7,61.5,28.7,61.5z"></path>
																								<circle class="ferment-svg-2-tablet-face st3" cx="27.7"
                                                        cy="65.1" r="3.7"
                                                        data-svg-origin="27.700000047683716 65.09999775886536"
                                                        transform="matrix(1,0,0,1,0,0)"
                                                        style="fill: rgb(100, 141, 255); transform-origin: 0px 0px 0px;"></circle>
																							</g>
																							<g
                                                  id="ferment-svg-2-tablet-3" class="ferment-svg-2-tablet"
                                                  data-svg-origin="40.5 65.14999580383301"
                                                  transform="matrix(0.96455,0,0,0.96455,0.5954080474121994,1.5590581149707976)"
                                                  style="transform-origin: 0px 0px 0px;">
																								<path class="st5" d="M41,61.5H40V62c-1.1,0.6-1.8,1.8-1.8,3.2c0,1.3,0.7,2.5,1.8,3.2v0.5H41
 c2,0,3.7-1.7,3.7-3.7C44.7,63.1,43,61.5,41,61.5z"></path>
																								<circle class="ferment-svg-2-tablet-face st3" cx="40" cy="65.1"
                                                        r="3.7"
                                                        data-svg-origin="39.99999928474426 65.09999775886536"
                                                        transform="matrix(1,0,0,1,0,0)"
                                                        style="fill: rgb(92, 146, 250); transform-origin: 0px 0px 0px;"></circle>
																							</g>
																							<g
                                                  id="ferment-svg-2-tablet-2" class="ferment-svg-2-tablet"
                                                  data-svg-origin="28.200000762939453 77.60000228881836"
                                                  transform="matrix(0.85446,0,0,0.85446,3.2641038784470418,10.553562740516886)"
                                                  style="transform-origin: 0px 0px 0px;">
																								<path class="st5" d="M28.7,73.9h-0.9v0.5c-1.1,0.6-1.8,1.8-1.8,3.2c0,1.3,0.7,2.5,1.8,3.2v0.5h0.9
 c2,0,3.7-1.7,3.7-3.7S30.7,73.9,28.7,73.9z"></path>
																								<circle class="ferment-svg-2-tablet-face st3" cx="27.7" cy="77.6"
                                                        r="3.7"
                                                        data-svg-origin="27.700000047683716 77.60000157356262"
                                                        transform="matrix(1,0,0,1,0,0)"
                                                        style="transform-origin: 0px 0px 0px; fill: rgb(67, 161, 233);"></circle>
																							</g>
																							<g
                                                  id="ferment-svg-2-tablet-1" class="ferment-svg-2-tablet"
                                                  data-svg-origin="40.5 77.60000228881836"
                                                  transform="matrix(0.8005,0,0,0.8005,7.23939164741212,14.740513975122253)"
                                                  style="transform-origin: 0px 0px 0px;">
																								<path class="st5" d="M41,73.9H40v0.5c-1.1,0.6-1.8,1.8-1.8,3.2c0,1.3,0.7,2.5,1.8,3.2v0.5H41
 c2,0,3.7-1.7,3.7-3.7S43,73.9,41,73.9z"></path>
																								<circle class="ferment-svg-2-tablet-face st3" cx="40" cy="77.6" r="3.7"
                                                        data-svg-origin="39.99999928474426 77.60000157356262"
                                                        transform="matrix(1,0,0,1,0,0)"
                                                        style="transform-origin: 0px 0px 0px; fill: rgb(54, 169, 225);"></circle>
																							</g>
																						</g>
																					</svg>
																				</div>
                                        <p>Зазвичай нормалізувати процеси травлення допомагають ферментні препарати. Прийом такого засобу передбачає, що застосовані ферменти будуть працювати разом з «рідними» ферментами підшлункової залози людини<sup>2</sup>. </p>
																				<p
                                            class="connected-paragraph">Традиційні ферментні препарати — це таблетки з захисною оболонкою (стійкою до дії шлункового соку), яка розчиняється у верхніх відділах тонкої кишки. Як тільки захисна оболонка розчиняється в потрібному середовищі, препарат починає діяти. </p>
																				<p
                                            class="connected-paragraph">Одні лікарські препарати містять компоненти шлункового соку (пепсин, соляну кислоту), інші - ферменти, що розщеплюють білки, жири та вуглеводи (амілаза, ліпаза, трипсин),  треті - панкреатин плюс жовчні кислоти і інші додаткові речовини<sup>2</sup>.
																				</p>
																				<p style="margin-bottom: 0">В аптеках присутні ферментні препарати, що поліпшують травлення і допомагають пацієнтам з недостатністю підшлункової залози, з неоднаковою фармакологічною активністю, здатністю розщеплювати білки, вуглеводи і жири. Ферментні препарати відрізняються між собою формою випуску, складом і кількістю  ферментів, яка виражається в одиницях по ліпазі, яка розщеплює жири. Для оцінки використовуються міжнародні стандарти, за діючими рекомендаціями яких визначається сила кожного ферментного препарату в міжнародних одиницях по ліпазі. Підбір дозування проводиться індивідуально та залежить від ступеня недостатності травлення.</p>
                                        <p>Доза препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000 залежить від дефіциту панкреатичних ферментів у дванадцятипалій кишці і встановлюється індивідуально. Якщо немає інших рекомендацій та у разі вживання важкоперетравлюваної рослинної їжі, жирної або незвичної їжі приймати  1–2 таблетки однократно. В інших випадках при виникненні розладів травлення рекомендована однократна доза становить 2–4 таблетки. При необхідності дозу препарату можна підвищити. Збільшення дози з метою зменшення симптомів захворювання, наприклад стеатореї або  симптомів хронічного панкреатиту, потрібно проводити тільки під контролем лікаря. Добова доза ліпази не повинна перевищувати 15000–20000 ОД ЄФ на 1 кг маси тіла<sup>4</sup>. </p>
																			</section>
																			<div
                                          id="triple-help-block">
																				<ul class="custom-unordered-list">
																					<li>
																						<div class="custom-list-dot"></div>
																						<span
                                                class="custom-list-item">Ферментний препарат
																							<span>Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
																							</span> містить панкреатин в дозуванні 10 000 одиниць по ліпазі.
																						</span>
																					</li>
																					<li>
																						<div
                                                class="custom-list-dot"></div>
                                            <span class="custom-list-item">Його формула сприяє травленню, зменшенню відчуття важкості і здуття живота.
																						</span>
																					</li>
																					<li>
																						<div
                                                class="custom-list-dot"></div>
																						<span class="custom-list-item">
																							<span>Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
																							</span> допомагає перетравлювати білки, жири і вуглеводи, і таким чином нормалізує процеси травлення<sup>4</sup>.
																						</span>
																					</li>
																				</ul>
																				<div
                                            class="slogan-block">
																					<img src="../assets/imgs/tripleHelpBlock/pack.png" alt="">
																						<p
                                                class="slogan">Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
																							<br>
																								<span>потрійна</span>
																								<br>
																									<span>допомога травленню!<sup>*</sup>
																									</span>
																								</p>
																							</div>
																						</div>
																						<div
                                                class="bottom-note-block">
																							<p class="footnote">
                    Адаптовано з:

																								<br>
																									<sup>1</sup> “Overview of Gastrointestinal Function & Regulation.” Ganong's Review of Medical Physiology, by Kim E. Barrett and William F. Ganong, McGraw-Hill Education, 2019, pp. 1038–1172.

																									<br>
																										<sup>2</sup> Dominguez-Muñoz JE. Diagnosis and treatment of pancreatic exocrine insufficiency. Curr Opin Gastroenterol. 2018 Sep;34(5):349-354. doi: 10.1097/MOG.0000000000000459. PMID: 29889111.

																										<br>
																											<sup>3</sup> Singh VK, Haupt ME, Geller DE, Hall JA, Quintana Diez PM. Less common etiologies of exocrine pancreatic insufficiency. World J Gastroenterol. 2017 Oct 21;23(39):7059-7076. doi: 10.3748/wjg.v23.i39.7059. PMID: 29093615; PMCID: PMC5656454.

																											<br>
																												<sup>4</sup> Інструкція для медичного застосування препарату Фестал<sup>®</sup> НЕО 10000, таблетки, вкриті оболонкою, кишковорозчинні. РП №UA/14533/01/01. Наказ МОЗ України №1994 від 21.09.2021.

                                                <!--																												<br>-->
                                                <!--																													<sup>*</sup> Формула оновлена за рахунок збільшення вмісту ліпази (на 3 000 МО) та α-амілази (на 2 500 МО) порівняно із препаратом Фестал<sup>®</sup> РП №UA2531/01/01. Наказ МОЗ України №341 від 29.03.2017.-->

																													<br>
																														<sup>*</sup> До складу препарату входять три основні панкреатичні травні ферменти: ліпаза, α-амілаза, протеази (трипсин і хімотрипсин), які чинять ліполітичну, амілолітичну і протеолітичну дію.

																													</p>
																													<p
                                                              class="adversting-text">Реклама лікарського засобу. Перед застосуванням лікарського засобу необхідно проконсультуватися з лікарем та обов’язково ознайомитися з інструкцією для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000. Зберігати у недоступному для дітей місці.
																													</p>
																													<table
                                                              class="adversting-text-table">
																														<tbody>
																															<tr class="top-line">
																																<td>
<!--																																	<router-link to="/official-notice">Офіційне повідомлення</router-link>.-->
																																</td>
																																<td>
<!--																																	<router-link to="/feedback">Зворотний зв'язок</router-link>.-->
																																</td>
																															</tr>
																															<tr class="bottom-line">
																																<td><router-link to="/official-notice">Офіційне повідомлення</router-link>.<br>
                                                                  Всі права захищені<sup>®</sup> 2021. Дата останнього перегляду 08.07.2022. Сайт призначений виключно для відвідувачів з України.
																																</td>
																																<td>

																																		<a
                                                                        href="http://www.sanofi.ua"
                                                                        style="padding-right: 5px; display: inline-block;"
                                                                        target="_blank">www.sanofi.ua</a>



																																		<a
                                                                        href="http://www.festal.ua" target="_blank">www.festal.ua</a>
																																	<br>MAT-UA-2200466-1.0-07/2022
																																</td>
																																<td>
																																	<router-link class="non-line"
                                                                               to="/instruction">Інструкція для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000, таблетки, вкриті оболонкою, кишковорозчинні.
																																												</router-link><br>РП №UA/14533/01/01. Наказ МОЗ України №1994 від 21.09.2021.
																																</td>
																															</tr>
																														</tbody>
																													</table>
																												</div>
																												<footer
                                                            id="self-medication-block">
																													<div class="word">
																														<div class="letter s"></div>
																														<div
                                                                class="letter a"></div>
																														<div class="letter m bigger-letter"></div>
																														<div class="letter o"></div>
																														<div
                                                                class="letter l"></div>
																														<div class="letter i smaller-letter"></div>
																														<div class="letter k"></div>
																														<div
                                                                class="letter u"></div>
																														<div class="letter v"></div>
																														<div class="letter a"></div>
																														<div
                                                                class="letter n"></div>
																														<div class="letter n"></div>
																														<div class="letter ia"></div>
																													</div>
																													<div
                                                              class="word">
																														<div class="letter m bigger-letter"></div>
																														<div class="letter o"></div>
																														<div
                                                                class="letter ge bigger-letter"></div>
																														<div class="letter e"></div>
																													</div>
																													<div class="word">
																														<div
                                                                class="letter b"></div>
																														<div class="letter u"></div>
																														<div class="letter t"></div>
																														<div
                                                                class="letter ii"></div>
																													</div>
																													<div class="word">
																														<div class="letter sh bigger-letter"></div>
																														<div
                                                                class="letter k"></div>
																														<div class="letter i smaller-letter"></div>
																														<div class="letter d"></div>
																														<div
                                                                class="letter l"></div>
																														<div class="letter ii"></div>
																														<div class="letter v"></div>
																														<div
                                                                class="letter ii"></div>
																														<div class="letter m bigger-letter"></div>
																													</div>
																													<div class="word">
																														<div
                                                                class="letter d"></div>
																														<div class="letter l"></div>
																														<div class="letter ia"></div>
																													</div>
																													<div
                                                              class="word">
																														<div class="letter v"></div>
																														<div class="letter a"></div>
																														<div
                                                                class="letter sh bigger-letter"></div>
																														<div class="letter o"></div>
																														<div class="letter g"></div>
																														<div
                                                                class="letter o"></div>
																													</div>
																													<div class="word">
																														<div class="letter z"></div>
																														<div
                                                                class="letter d"></div>
																														<div class="letter o"></div>
																														<div class="letter r"></div>
																														<div
                                                                class="letter o"></div>
																														<div class="letter v"></div>
																														<div
                                                                class="letter znak smaller-letter"></div>
																														<div class="letter ia"></div>
																													</div>
																													<svg
                                                              id="self-medication-svg"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              xmlns:xlink="http://www.w3.org/1999/xlink"
                                                              xml:space="preserve" x="0px" y="0px"
                                                              viewBox="0 0 568.5 58.4">
																														<g id="self-medication-svg-letters">
																															<path class="self-medication-svg-letter" d="M8.8,17.2c0-5,1.3-7.9,4.8-7.9c3.5,0,4.8,2.9,4.8,7.9V18c0,0-0.1,0.2-0.2,0.2S18,18,18,18v-0.8
 c0-4.4-1.1-6.8-4.4-6.8c-3.3,0-4.3,2.4-4.3,6.8v23.2c0,4.4,1.1,6.8,4.3,6.8c3,0,4.6-2.5,4.6-2.5s0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6s-1.3,2.5-4.6,2.5c-3.5,0-4.8-2.9-4.8-7.9V17.2z"></path>
																															<path class="self-medication-svg-letter"
                                                                    d="M24.1,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2L30,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9l-1.7,12c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L24.1,9.5z M21.1,34.8h6.6l-3.3-24.2L21.1,34.8z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M43.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																															<path class="self-medication-svg-letter" d="M45.4,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M45.9,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M60.1,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L60.1,9.7z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M70,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6
 h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6H70z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M76.2,28.5L74,30.1V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L76.2,28.5z"></path>
																															<path class="self-medication-svg-letter" d="M87,37c0,0-0.1-0.1-0.1-0.1L82,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L86.6,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L87,37z"></path>
																															<path class="self-medication-svg-letter" d="M93.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M93.7,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M93.7,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																															<path class="self-medication-svg-letter"
                                                                    d="M107.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L103,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L107.8,9.5z M104.8,34.8h6.6l-3.3-24.2L104.8,34.8z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M124.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M135.9,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M146.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L137.7,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M146,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																															<path class="self-medication-svg-letter"
                                                                    d="M166.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2
 c-0.2,0-0.3-0.2-0.3-0.2l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 l5.7,30.5l5.8-30.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																															<path class="self-medication-svg-letter" d="M168.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M168.7,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M179.1,48c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.5-20.2l-4.2-18.2c0,0,0.1-0.2,0.3-0.2
 c0.2,0,0.3,0.2,0.3,0.2l4.1,17.7l2.1,1.7V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2v19.3l2.1-1.7l4.1-17.7
 c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-4.2,18.2l4.5,20.2c0,0-0.1,0.2-0.3,0.2c-0.1,0-0.3-0.2-0.3-0.2L188,28.3l-2.1,1.7v18
 c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V30l-2.1-1.7L179.1,48z"></path>
																															<path class="self-medication-svg-letter" d="M194.2,47h8.1c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.5c-2.4,0-4-0.3-4-0.3V9.7c0,0,1.6-0.3,4-0.3h4.4
 c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-8v17.1h7.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.2V47z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M209.5,26.8c0,0,1.7-0.2,4-0.2c3.2,0,4.5,3.1,4.5,8.4v4.8c0,5.3-1.3,8.4-4.5,8.4c-2.6,0-4.4-0.3-4.4-0.3V9.7
 c0,0,1.6-0.3,4-0.3h4.3c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.9V26.8z M209.5,47.1h4c3,0,4.1-2.5,4.1-7.3V35
 c0-4.8-1.1-7.3-4.1-7.3h-4V47.1z"></path>
																															<path class="self-medication-svg-letter" d="M223.5,37c0,0-0.1-0.1-0.1-0.1l-4.9-27.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L223.1,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L223.5,37z"></path>
																															<path class="self-medication-svg-letter" d="M234.1,10.5V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5h-4.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h9.8c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6H234.1z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M249.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L240.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																															<path class="self-medication-svg-letter" d="M272.1,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C272.1,9.4,272.1,9.5,272.1,9.5"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M276.7,28.5l-2.2,1.7V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L276.7,28.5z"></path>
																															<path class="self-medication-svg-letter" d="M284.4,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6
 H284.4z"></path>
																															<path class="self-medication-svg-letter" d="M287.1,47.1c1.8,0,2.1-2.3,2.3-6.8L291,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M297.4,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H297.4z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M303.9,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L303.9,9.7z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M322.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L313.1,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46L322,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z
 "></path>
																															<path class="self-medication-svg-letter" d="M324.4,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M324.8,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M324.8,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																															<path class="self-medication-svg-letter"
                                                                    d="M344.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L335.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																															<path class="self-medication-svg-letter" d="M359.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2V48z"></path>
																															<path class="self-medication-svg-letter" d="M366.5,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2H367v8.2c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 V47.1z M376.9,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H376.9z"></path>
																															<path class="self-medication-svg-letter"
                                                                    d="M383.3,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L383.3,9.7z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M400.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L392,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M400.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M405.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M405.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M405.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																															<path class="self-medication-svg-letter"
                                                                    d="M419.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L415,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L419.8,9.5z M416.8,34.8h6.6l-3.3-24.2L416.8,34.8z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M441.6,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C441.6,9.4,441.6,9.5,441.6,9.5"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M443.6,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M444,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M455.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.7c0,0,1.6-0.3,4-0.3h3.7c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-7.2V48z"></path>
																															<path class="self-medication-svg-letter" d="M463.9,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M464.3,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M489.5,17.8v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3c0,5.4-1.3,8.7-4.5,8.7c-3.2,0-4.5-2.5-4.5-2.5
 s-0.1-0.2-0.1-0.6c0-0.4,0.1-0.6,0.1-0.6s1.5,2.5,4.5,2.5s4.1-2.6,4.1-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-1.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h1.8c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.8-7.4c-3.2,0-4.2,2.4-4.2,6.8V18c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2v-0.8c0-5,1.3-7.9,4.7-7.9C488.3,9.3,489.5,12.4,489.5,17.8"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M490.3,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M500.7,47V10.5h-6.1L493,40.4c-0.2,3.1-0.4,5.4-1.1,6.6H500.7z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M504,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M504.4,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M520,9.3c3.2,0,4.5,2.9,4.5,7.9V22c0,5-1.3,7.9-4.5,7.9c-2.3,0-3.8-0.2-3.8-0.2V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5C515.8,9.5,517.5,9.3,520,9.3 M516.2,28.8h3.8c3,0,4.1-2.4,4.1-6.8v-4.8c0-4.4-1.1-6.8-4.1-6.8h-3.8
 V28.8z"></path>
																															<path class="self-medication-svg-letter" d="M526.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M526.8,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																															<path
                                                                  class="self-medication-svg-letter" d="M538.1,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M538.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M538.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																															<path class="self-medication-svg-letter"
                                                                    d="M548.1,18.7c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2s1.1-2.7,1.1-9.2c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2C549.2,16.3,548.1,18.7,548.1,18.7"></path>
																															<path class="self-medication-svg-letter" d="M559.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L551,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M559.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																														</g>
																													</svg>
																												</footer>
																											</div>
																										</span>
      </div>
    </div>
  </div>
</template>

<script>
import actualPaddingSetter from '../modules/actualPaddingSetter';
import scrollToDestination from '../modules/scrollToDestination';
import enzymesSvgAnimate from '../modules/svg_animation/enzymesSvgAnimate';

export default {
  name: "enzymes",
  mounted() {

    let trigger25 = true;
    let trigger50 = true;
    let trigger75 = true;
    let trigger90 = true;

    window.addEventListener("scroll", () => {

      var h = document.documentElement,
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';

      var percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);
      if (percent >= 25 && percent < 50 && trigger25) {
        trigger25 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол сторінки 25%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 50 && percent < 75 && trigger50) {
        trigger50 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 50%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 75 && percent < 90 && trigger75) {
        trigger75 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 75%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 90 && trigger90) {
        trigger90 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 90%',
          'event_label': 'UA: ' + this.$route.path
        })
      }
    })
    enzymesSvgAnimate();
    let headerHeight = actualPaddingSetter();

    actualPaddingSetter();
    scrollToDestination(headerHeight);
  }
}

</script>

<style lang="less">

</style>
