/*** IMPORTS FROM imports-loader ***/
var define = false;

export default function actualPaddingSetter() {
	var contentContainer = document.querySelector('.content-container'),
		headerHeight = document.getElementById('header-container').clientHeight;
	
	contentContainer.style.paddingTop = headerHeight + 'px';

	window.addEventListener('resize', () => {
		contentContainer = document.querySelector('.content-container');
		headerHeight = document.getElementById('header-container').clientHeight;

		contentContainer.style.paddingTop = headerHeight + 'px';
	});
	
	return(headerHeight);
}
