<template>
  <div class="festalNeo" data-view>
    <div id="content">
      <div id="router-container">
        <top-menu></top-menu>
        <message-alert></message-alert>
        <span>
				<div id="festal-neo-page-container" class="content-container" style="padding-top: 60px;">
					<div
              id="part_1" class="title-block">
						<h1>Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
						</h1>
						<img
                src="../assets/imgs/festalNeoPage/pack.png" alt="">
						</div>
						<div class="page-container">
							<div
                  class="plates-in-block"></div>
							<section class="main-column">
								<p
                    class="attention-paragraph more-attention">Скільки спокус навколо! Особливо в їжі, і так хочеться продовжити це задоволення!</p>
								<img
                    class="mobile-visible" src="../assets/imgs/festalNeoPage/plate_1.png" alt="plate_1.png"
                    width="193px"
                    height="250px">
									<p>А коли зупинитися неможливо, підшлунковій залозі, що виділяє ферменти для правильного травлення, буває важко впоратися з великою кількістю їжі...<sup>1</sup>
									</p>
									<img
                      class="mobile-visible" src="../assets/imgs/festalNeoPage/plate_2.png" alt="plate_2.png"
                      width="150px"
                      height="188px">
										<p class="attention-paragraph">Хоч ми і намагаємося дотримуватися простих правил харчування, вживати здорову їжу, але з темпом сучасного життя це так рідко виходить!</p>
										<img
                        class="mobile-visible" src="../assets/imgs/festalNeoPage/path_1.png" alt="path_1.png"
                        width="12px"
                        height="67px">
											<p>І скільки б не казали, що їсти на ходу шкідливо для здоров'я, багато хто нехтує цією порадою, і тоді доводиться розплачуватися важкістю в шлунку, здуттям живота... Такі симптоми є приводом звернутися до лікаря за консультацією, який може порадити відповідний ферментний засіб.
											</p>
											<img
                          class="mobile-visible" src="../assets/imgs/festalNeoPage/plate_3.png" alt="plate_3.png"
                          width="137px"
                          height="174px">
												<p class="attention-paragraph more-attention">При цих симптомах і допомагає Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000.
												</p>
												<p
                            class="download-paragraph">Бажаєте дізнатися, як правильно приймати таблетки Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000?
													<br>
														<a
                                href="/instruction.pdf" download="instruction.pdf">Завантажуйте інструкцію для застосування.</a>
													</p>
												</section>
											</div>
											<div
                          id="triple-help-block">
												<ul class="custom-unordered-list">
													<li>
														<div class="custom-list-dot"></div>
														<span
                                class="custom-list-item">Ферментний препарат
															<span>Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
															</span> містить панкреатин в дозуванні 10 000 одиниць по ліпазі.
														</span>
													</li>
													<li>
														<div
                                class="custom-list-dot"></div>
                            <span class="custom-list-item">Його формула сприяє травленню, зменшенню відчуття важкості і здуття живота.
														</span>
													</li>
													<li>
														<div
                                class="custom-list-dot"></div>
														<span class="custom-list-item">
															<span>Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
															</span> допомагає перетравлювати білки, жири і вуглеводи, і таким чином нормалізує процеси травлення<sup>2</sup>.
														</span>
													</li>
												</ul>
												<div
                            class="slogan-block">
													<img src="../assets/imgs/tripleHelpBlock/pack.png" alt="">
														<p
                                class="slogan">Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
															<br>
																<span>потрійна</span>
																<br>
																	<span>допомога травленню!<sup>*</sup>
																	</span>
																</p>
															</div>
														</div>
														<div
                                class="bottom-note-block">
															<p class="footnote">
                    Адаптовано з:

																<br>
																	<sup>1</sup> Singh VK, Haupt ME, Geller DE, Hall JA, Quintana Diez PM. Less common etiologies of exocrine pancreatic insufficiency. World J Gastroenterol. 2017 Oct 21;23(39):7059-7076. doi: 10.3748/wjg.v23.i39.7059. PMID: 29093615; PMCID: PMC5656454.

																	<br>
																		<sup>2</sup> Інструкція для медичного застосування препарату Фестал<sup>®</sup> НЕО 10000, таблетки, вкриті оболонкою, кишковорозчинні. РП №UA/14533/01/01. Наказ МОЗ України №1994 від 21.09.2021.

<!--																		<br>-->
<!--																			<sup>*</sup> Формула оновлена за рахунок збільшення вмісту ліпази (на 3 000 МО) та α-амілази (на 2 500 МО) порівняно із препаратом Фестал<sup>®</sup> РП №UA2531/01/01 . Наказ МОЗ України №341 від 29.03.2017.-->

																			<br>
																				<sup>*</sup> До складу препарату входять три основні панкреатичні травні ферменти: ліпаза, α-амілаза, протеази (трипсин і хімотрипсин), які чинять ліполітичну, амілолітичну і протеолітичну дію.

																			</p>
																			<p
                                          class="adversting-text">Реклама лікарського засобу. Перед застосуванням лікарського засобу необхідно проконсультуватися з лікарем та обов’язково ознайомитися з інструкцією для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000. Зберігати у недоступному для дітей місці.
																			</p>
																			<table
                                          class="adversting-text-table">
																				<tbody>
																					<tr class="top-line">
																						<td>
<!--																							<router-link to="/official-notice">Офіційне повідомлення</router-link>.-->
																						</td>
																						<td>
<!--																							<router-link to="/feedback">Зворотний зв'язок</router-link>.-->
																						</td>
																					</tr>
																					<tr class="bottom-line">
																						<td><router-link to="/official-notice">Офіційне повідомлення</router-link>.<br>
                                              Всі права захищені<sup>®</sup> 2021. Дата останнього перегляду 08.07.2022. Сайт призначений виключно для відвідувачів з України.
																						</td>
																						<td>

																								<a
                                                    href="http://www.sanofi.ua" style="padding-right: 5px; display: inline-block;" target="_blank">www.sanofi.ua</a>


																								<a
                                                    href="http://www.festal.ua" target="_blank">www.festal.ua</a>
																							<br>MAT-UA-2200466-1.0-07/2022

																						</td>
																						<td>
																							<router-link class="non-line"
                                                           to="/instruction">Інструкція для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000, таблетки, вкриті оболонкою, кишковорозчинні.
																																												</router-link><br>РП №UA/14533/01/01. Наказ МОЗ України №1994 від 21.09.2021.
																						</td>
																					</tr>
																				</tbody>
																			</table>
																		</div>
																		<footer
                                        id="self-medication-block">
																			<div class="word">
																				<div class="letter s"></div>
																				<div
                                            class="letter a"></div>
																				<div class="letter m bigger-letter"></div>
																				<div class="letter o"></div>
																				<div
                                            class="letter l"></div>
																				<div class="letter i smaller-letter"></div>
																				<div class="letter k"></div>
																				<div
                                            class="letter u"></div>
																				<div class="letter v"></div>
																				<div class="letter a"></div>
																				<div
                                            class="letter n"></div>
																				<div class="letter n"></div>
																				<div class="letter ia"></div>
																			</div>
																			<div
                                          class="word">
																				<div class="letter m bigger-letter"></div>
																				<div class="letter o"></div>
																				<div
                                            class="letter ge bigger-letter"></div>
																				<div class="letter e"></div>
																			</div>
																			<div class="word">
																				<div
                                            class="letter b"></div>
																				<div class="letter u"></div>
																				<div class="letter t"></div>
																				<div
                                            class="letter ii"></div>
																			</div>
																			<div class="word">
																				<div class="letter sh bigger-letter"></div>
																				<div
                                            class="letter k"></div>
																				<div class="letter i smaller-letter"></div>
																				<div class="letter d"></div>
																				<div
                                            class="letter l"></div>
																				<div class="letter ii"></div>
																				<div class="letter v"></div>
																				<div
                                            class="letter ii"></div>
																				<div class="letter m bigger-letter"></div>
																			</div>
																			<div class="word">
																				<div
                                            class="letter d"></div>
																				<div class="letter l"></div>
																				<div class="letter ia"></div>
																			</div>
																			<div
                                          class="word">
																				<div class="letter v"></div>
																				<div class="letter a"></div>
																				<div
                                            class="letter sh bigger-letter"></div>
																				<div class="letter o"></div>
																				<div class="letter g"></div>
																				<div
                                            class="letter o"></div>
																			</div>
																			<div class="word">
																				<div class="letter z"></div>
																				<div
                                            class="letter d"></div>
																				<div class="letter o"></div>
																				<div class="letter r"></div>
																				<div
                                            class="letter o"></div>
																				<div class="letter v"></div>
																				<div
                                            class="letter znak smaller-letter"></div>
																				<div class="letter ia"></div>
																			</div>
																			<svg
                                          id="self-medication-svg"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px"
                                          y="0px"
                                          viewBox="0 0 568.5 58.4">
																				<g id="self-medication-svg-letters">
																					<path class="self-medication-svg-letter" d="M8.8,17.2c0-5,1.3-7.9,4.8-7.9c3.5,0,4.8,2.9,4.8,7.9V18c0,0-0.1,0.2-0.2,0.2S18,18,18,18v-0.8
 c0-4.4-1.1-6.8-4.4-6.8c-3.3,0-4.3,2.4-4.3,6.8v23.2c0,4.4,1.1,6.8,4.3,6.8c3,0,4.6-2.5,4.6-2.5s0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6s-1.3,2.5-4.6,2.5c-3.5,0-4.8-2.9-4.8-7.9V17.2z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M24.1,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2L30,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9l-1.7,12c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L24.1,9.5z M21.1,34.8h6.6l-3.3-24.2L21.1,34.8z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M43.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																					<path class="self-medication-svg-letter" d="M45.4,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M45.9,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M60.1,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L60.1,9.7z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M70,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6
 h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6H70z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M76.2,28.5L74,30.1V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L76.2,28.5z"></path>
																					<path class="self-medication-svg-letter" d="M87,37c0,0-0.1-0.1-0.1-0.1L82,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L86.6,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L87,37z"></path>
																					<path class="self-medication-svg-letter" d="M93.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M93.7,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M93.7,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M107.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L103,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L107.8,9.5z M104.8,34.8h6.6l-3.3-24.2L104.8,34.8z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M124.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M135.9,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M146.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L137.7,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M146,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M166.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2
 c-0.2,0-0.3-0.2-0.3-0.2l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 l5.7,30.5l5.8-30.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																					<path class="self-medication-svg-letter" d="M168.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M168.7,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M179.1,48c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.5-20.2l-4.2-18.2c0,0,0.1-0.2,0.3-0.2
 c0.2,0,0.3,0.2,0.3,0.2l4.1,17.7l2.1,1.7V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2v19.3l2.1-1.7l4.1-17.7
 c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-4.2,18.2l4.5,20.2c0,0-0.1,0.2-0.3,0.2c-0.1,0-0.3-0.2-0.3-0.2L188,28.3l-2.1,1.7v18
 c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V30l-2.1-1.7L179.1,48z"></path>
																					<path class="self-medication-svg-letter" d="M194.2,47h8.1c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.5c-2.4,0-4-0.3-4-0.3V9.7c0,0,1.6-0.3,4-0.3h4.4
 c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-8v17.1h7.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.2V47z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M209.5,26.8c0,0,1.7-0.2,4-0.2c3.2,0,4.5,3.1,4.5,8.4v4.8c0,5.3-1.3,8.4-4.5,8.4c-2.6,0-4.4-0.3-4.4-0.3V9.7
 c0,0,1.6-0.3,4-0.3h4.3c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.9V26.8z M209.5,47.1h4c3,0,4.1-2.5,4.1-7.3V35
 c0-4.8-1.1-7.3-4.1-7.3h-4V47.1z"></path>
																					<path class="self-medication-svg-letter" d="M223.5,37c0,0-0.1-0.1-0.1-0.1l-4.9-27.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L223.1,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L223.5,37z"></path>
																					<path class="self-medication-svg-letter" d="M234.1,10.5V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5h-4.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h9.8c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6H234.1z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M249.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L240.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																					<path class="self-medication-svg-letter" d="M272.1,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C272.1,9.4,272.1,9.5,272.1,9.5"></path>
																					<path
                                              class="self-medication-svg-letter" d="M276.7,28.5l-2.2,1.7V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L276.7,28.5z"></path>
																					<path class="self-medication-svg-letter" d="M284.4,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6
 H284.4z"></path>
																					<path class="self-medication-svg-letter" d="M287.1,47.1c1.8,0,2.1-2.3,2.3-6.8L291,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M297.4,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H297.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M303.9,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L303.9,9.7z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M322.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L313.1,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46L322,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z
 "></path>
																					<path class="self-medication-svg-letter" d="M324.4,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M324.8,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M324.8,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M344.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L335.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																					<path class="self-medication-svg-letter" d="M359.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2V48z"></path>
																					<path class="self-medication-svg-letter" d="M366.5,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2H367v8.2c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 V47.1z M376.9,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H376.9z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M383.3,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L383.3,9.7z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M400.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L392,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M400.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M405.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M405.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M405.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M419.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L415,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L419.8,9.5z M416.8,34.8h6.6l-3.3-24.2L416.8,34.8z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M441.6,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C441.6,9.4,441.6,9.5,441.6,9.5"></path>
																					<path
                                              class="self-medication-svg-letter" d="M443.6,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M444,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M455.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.7c0,0,1.6-0.3,4-0.3h3.7c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-7.2V48z"></path>
																					<path class="self-medication-svg-letter" d="M463.9,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M464.3,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M489.5,17.8v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3c0,5.4-1.3,8.7-4.5,8.7c-3.2,0-4.5-2.5-4.5-2.5
 s-0.1-0.2-0.1-0.6c0-0.4,0.1-0.6,0.1-0.6s1.5,2.5,4.5,2.5s4.1-2.6,4.1-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-1.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h1.8c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.8-7.4c-3.2,0-4.2,2.4-4.2,6.8V18c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2v-0.8c0-5,1.3-7.9,4.7-7.9C488.3,9.3,489.5,12.4,489.5,17.8"></path>
																					<path
                                              class="self-medication-svg-letter" d="M490.3,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M500.7,47V10.5h-6.1L493,40.4c-0.2,3.1-0.4,5.4-1.1,6.6H500.7z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M504,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M504.4,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M520,9.3c3.2,0,4.5,2.9,4.5,7.9V22c0,5-1.3,7.9-4.5,7.9c-2.3,0-3.8-0.2-3.8-0.2V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5C515.8,9.5,517.5,9.3,520,9.3 M516.2,28.8h3.8c3,0,4.1-2.4,4.1-6.8v-4.8c0-4.4-1.1-6.8-4.1-6.8h-3.8
 V28.8z"></path>
																					<path class="self-medication-svg-letter" d="M526.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M526.8,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																					<path
                                              class="self-medication-svg-letter" d="M538.1,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M538.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M538.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																					<path class="self-medication-svg-letter"
                                                d="M548.1,18.7c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2s1.1-2.7,1.1-9.2c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2C549.2,16.3,548.1,18.7,548.1,18.7"></path>
																					<path class="self-medication-svg-letter" d="M559.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L551,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M559.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																				</g>
																			</svg>
																		</footer>
																	</div>
																</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "festalNeo",
  mounted() {
    let trigger25 = true;
    let trigger50 = true;
    let trigger75 = true;
    let trigger90 = true;

    window.addEventListener("scroll", () => {

      var h = document.documentElement,
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';

      var percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);
      if (percent >= 25 && percent < 50 && trigger25) {
        trigger25 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол сторінки 25%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 50 && percent < 75 && trigger50) {
        trigger50 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 50%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 75 && percent < 90 && trigger75) {
        trigger75 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 75%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 90 && trigger90) {
        trigger90 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 90%',
          'event_label': 'UA: ' + this.$route.path
        })
      }
    });
  }
}
</script>

<style lang="less">

</style>
