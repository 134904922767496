<template>
  <div class="instruction" data-view>
    <div data-reactroot="" id="content">
      <div id="router-container">
        <top-menu></top-menu>
        <message-alert></message-alert>
        <a href="/instruction.pdf" download="instruction.pdf" class="new-btn-instruction">Завантажити інструкцію</a>
        <span>
		<div id="instruction-page-container" class="content-container" style="padding-top: 60px;">

 	<div
      class="con">
    <div class="text">

																													<span>ЗАТВЕРДЖЕНО</span>
																													<br>Наказ Мiнiстерства охорони
																														<br>здоров'я України
																															<br>№1994 від 21.09.2021
																																<br>Реєстраційне посвідчення
																																	<br>№ UA/14533/01/01
      </div>
																																					</div>
			<h1
          class="title">ІНСТРУКЦІЯ
				<br>для медичного застосування лікарського засобу
					<br>ФЕСТАЛ<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
						<br>(FESTAL<sup>®</sup>&nbsp;NEO&nbsp;10&nbsp;000)
						</h1>
						<div
                class="page-container">
							<section class="main-column">



								<span class="subtitle">Склад
									<br>
									</span>
									<p>
										<span>діюча речовина:</span> pancreatin;
									</p>
									<p>1 таблетка містить панкреатину, що відповідає мінімальній ферментативній активності:</p>
									<p>10 000 ліполітичних МО, 7 500 амілолітичних МО, 375 протеолітичних МО;</p>
									<p>
										<span>допоміжні речовини:</span> лактози моногідрат, целюлоза мікрокристалічна, натрію кроскармелоза, кросповідон, гіпромелоза, кремнію діоксид колоїдний
безводний, магнію стеарат;
									</p>
									<p>
										<span>оболонка:</span> поліетиленгліколь, суха суміш «Acryl-eze white», що містить тальк, титану діоксид (Е 171), метакрилатний сополімер, натрію лаурилсульфат,
натрію карбонат, кремнію діоксид колоїдний безводний.
									</p>
									<span
                      class="subtitle">Лікарська форма. </span>
									<p>Таблетки, вкриті оболонкою, кишковорозчинні.</p>
									<p>
										<span>Основні фізико-хімічні властивості:</span> таблетки, вкриті оболонкою, круглої форми, з двоопуклою поверхнею, білого або майже білого кольору, зі слабким
специфічним запахом. На поперечному розрізі видні два шари.
									</p>
									<span
                      class="subtitle">Фармакотерапевтична група.
										<br>
										</span>
										<p>Засоби, що поліпшують травлення, включаючи ферменти. Поліферментні препарати.</p>
										<p>Код АТХ А09А А02.</p>
										<span
                        class="subtitle">Фармакологічні властивості.
											<br>
											</span>
											<p>
												<span>Фармакодинаміка.</span>
												<br>Панкреатин – фермент підшлункової залози, що сприяє травленню. До складу препарату входять основні панкреатичні травні ферменти: ліпаза, α-амілаза,
протеази (трипсин і хімотрипсин), які чинять ліполітичну, амілолітичну і протеолітичну дію, підсилюють розщеплення білків, вуглеводів і жирів у
дванадцятипалій кишці, проксимальній частині тонкої кишки, що сприяє їх більш повному всмоктуванню. Вирішальним фактором є ферментна
активність ліпази, а також вміст трипсину.
												</p>
												<p>
													<span>Фармакокінетика.</span>
													<br>Оболонка, що покриває таблетки, не розчиняється під дією шлункового соку та захищає ферменти від їх інактивації шлунковим соком. Тільки під дією
нейтрального або злегка лужного середовища тонкого кишечнику відбувається розчинення оболонки і вивільнення ферментів.

													</p>
													<span
                              class="subtitle">Клінічні характеристики.
														<br>
														</span>
														<span class="subtitle">Показання.
															<br>
															</span>
															<p>Захворювання, які супроводжуються порушеннями процесу перетравлювання їжі у зв’язку з недостатнім виділенням травних ферментів підшлунковою
залозою, такі як хронічний панкреатит.</p>
															<p>Стани після одночасної резекції шлунка та тонкого кишечнику, стани після панкреатектомії.</p>
															<p>Функціональне прискорення проходження їжі через кишечник.</p>
															<p>Метеоризм, диспепсія, розлади кишечнику.</p>
															<p>Підготовка до рентгенологічних або ультразвукових діагностичних досліджень органів черевної порожнини (дегазація кишечнику).</p>
															<p>Для поліпшення перетравлювання їжі особам з нормальною функцією шлунково-кишкового тракту при порушеннях жувальної функції, при вживанні
важкоперетравлюваної рослинної, жирної або незвичної їжі, споживання надмірної кількості їжі.</p>
															<span
                                  class="subtitle">Протипоказання.
																<br>
																</span>
																<p>Підвищена чутливість до панкреатину свинячого походження або до допоміжних речовин препарату. Гострий панкреатит або хронічний панкреатит у
стадії загострення, гострий гепатит, механічна жовтяниця. Препарат не слід застосовувати хворим з обтураційною непрохідністю кишечнику.</p>



                		<span
                        class="subtitle">Взаємодія з іншими лікарськими засобами та інші види взаємодій.
																		<br>
																		</span>
																		<p>При застосуванні препаратів, що містять панкреатин, може зменшуватися всмоктування фолієвої кислоти, що може потребувати додаткового її
надходження в організм. При тривалому застосуванні панкреатину може зменшуватися всмоктування заліза.</p>
																		<p>Циметидин може підсилювати дію препарату.</p>
                <p>Панкреатин може зменшувати ефект гіпоглікемічних пероральних протидіабетичних препаратів акарбози і міглітолу, тому їх не слід застосовувати
одночасно.</p>
                <p>При одночасному застосуванні з антацидами, що містять кальцію карбонат і/або магнію гідроксид, з таніном, спиртовмісними засобами знижується
ефективність панкреатину.
</p>






																		<span
                                        class="subtitle">Особливості застосування.
																			<br>
																			</span>

                <p>Препарат протипоказано застосовувати при гострому панкреатиті або хронічному панкреатиті у стадії загострення. У період одужання або при
розширенні дієти препарат можна приймати тільки за призначенням лікаря.
</p>
                <p>У разі появи незвичного абдомінального дискомфорту або змін симптоматики як запобіжний захід рекомендується пройти обстеження з метою
виключення ураження кишечнику, особливо якщо пацієнт застосовує дозу понад 10000 ОД ЄФ ліпази на кілограм маси тіла на добу.
</p>
<p>Для уникнення утворення сечокислих конкрементів слід контролювати вміст сечової кислоти у сечі.</p>
                <p>У пацієнтів, які приймають панкреатин у високих дозах або протягом тривалого періоду часу, спостерігалися випадки звуження товстої кишки
(фіброзуюча колонопатія), непрохідності кишечнику та запору. У зв’язку з цим пацієнтам рекомендується проконсультуватися зі своїм лікарем у разі
стійкого утримання або посилення скарг чи виникнення будь-яких нових симптомів.
</p>
                <p>Таблетки не слід жувати або подрібнювати, оскільки це може спричинити раннє вивільнення ферментів, що може призвести до подразнення слизової
оболонки порожнини рота та/або зниження ферментної активності. Препарат не слід застосовувати хворим із рідкісними спадковими формами
непереносимості галактози, дефіцитом лактази або синдромом мальабсорбції глюкози і галактози.
</p>
                <p>Застосовувати з обережністю пацієнтам із нирковою недостатністю, гіперуринемією, пацієнтам з алергією до білків свинячого походження.
Препарат містить у своєму складі пурини, тому його слід з обережністю застосовувати пацієнтам із такими станами:
</p>
                <p>- подагра;</p>
                <p>- гіперурикемія;</p>
                <p>- ниркова недостатність.</p>
<p>
																				<span>Застосування у період вагітності або годування груддю.</span>
																				<br>Безпека застосування панкреатину вагітним жінкам не встановлена. У зв’язку з цим слід уникати застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000 під час
вагітності.<br>
  Безпека застосування панкреатину жінкам, які годують дітей грудним молоком, не встановлена, тому слід уникати застосування препарату протягом
періоду годування груддю.
																				</p>


<p>
																					<span>Здатність впливати на швидкість реакції при керуванні автотранспортом або іншими механізмами.</span>
																					<br>Застережень немає.
																					</p>
              </section>



																				<section
                                            class="main-column">

																					<span
                                              class="subtitle">Спосіб застосування та дози.
																						<br>
																						</span>
                                          <p>Доза препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000 залежить від дефіциту панкреатичних ферментів у дванадцятипалій кишці і встановлюється індивідуально. Якщо
немає інших рекомендацій та у разі вживання важкоперетравлюваної рослинної їжі, жирної або незвичної їжі приймати 1–2 таблетки однократно. В
інших випадках при виникненні розладів травлення рекомендована однократна доза становить 2–4 таблетки. При необхідності дозу препарату можна
підвищити. Збільшення дози з метою зменшення симптомів захворювання, наприклад стеатореї або симптомів хронічного панкреатиту, потрібно
проводити тільки під контролем лікаря. Добова доза ліпази не повинна перевищувати 15000–20000 ОД ЄФ на 1 кг маси тіла.
																						</p>
																						<p>Таблетки приймати під час їди, ковтаючи цілими та запиваючи значною кількістю рідини, наприклад склянкою води.</p>
																						<p>Тривалість лікування залежить від перебігу захворювання та визначається лікарем в індивідуальному порядку.</p>
																						<p>
																							<span>Діти.</span>
																							<br>Застосовувати дітям віком від 3 років. Питання про дозу препарату і тривалість лікування для дітей вирішує лікар. Препарат призначають у добовій дозі,
що необхідна для нормалізації випорожнення, під час кожного основного прийому їжі, але не більше 1500 ОД ліпази на 1 кг маси тіла дитини віком до 12
років. Для дітей віком від 12 років добова доза ферментів не повинна перевищувати 15000–20000 ОД ЄФ ліпази на 1 кг маси тіла.

																							</p>
																							<span
                                                  class="subtitle">Передозування.
																								<br>
																								</span>
																								<p>Можливе підсилення побічних ефектів. Під час прийому надзвичайно високих доз інших препаратів із порошку підшлункових залоз спостерігалися
гіперурикемія та гіперурикозурія, у дітей – запор. Лікування симптоматичне: відміна препарату, достатня гідратація.
</p>
																								<span
                                                    class="subtitle">Побічні реакції.
																									<br>
																									</span>
																									<p>Для оцінки частоти виникнення побічних ефектів використовується така класифікація: дуже часто: ≥ 1/10; часто: ≥ 1/100 і < 1/10; нечасто: ≥ 1/1000 і < 1
/100; рідко: ≥ 1/10000 і < 1/1000; дуже рідко: < 1/10000; частота невідома (оцінка не може бути проведена за наявними даними).
</p>
																									<p>
																										<span>З боку серцево-судинної системи.</span> Частота невідома: тахікардія.
																									</p>
																									<p>
																										<span>З боку імунної системи.</span><br>Дуже рідко: алергічні реакції негайного типу (шкірне висипання, кропив’янка, чхання, сльозотеча, бронхоспазм, диспное).<br>
                                                    Частота невідома: анафілактичні реакції, включно з кропив’янкою та ангіоневротичним набряком.
																									</p>
																									<p>
																										<span>З боку травного тракту.</span><br>Дуже рідко: алергічні реакції з боку травного тракту (діарея, біль у животі, нудота, зміна характеру випорожнень). Можливе виникнення відчуття
дискомфорту в епігастральній ділянці шлунка, метеоризму, кишкової коліки, кишкової непрохідності.<br>
                                                    Частота невідома: зафіксовано випадки кишкової непрохідності та запору, особливо при застосуванні високих доз ензимів панкреатину. Формування
стриктур в ілеоцекальній ділянці та у висхідній частині ободової кишки було описано після призначення порошку з підшлункових залоз у високих дозах.
Блювання, подразнення слизової оболонки порожнини рота. Біль у животі, діарея, подразнення навколо анального отвору, запор, обширний стеноз
кишечнику, фіброзуюча колонопатія.

																									</p>


                                          <p>
																										<span>З боку обміну речовин та харчування.</span><br>
                                            Частота невідома: гіперурикемія.<br>
                                            Застосування дуже високих доз препарату може призвести до підвищення рівня сечової кислоти у крові.

																									</p>

																									<p>
																										<span>З боку шкіри.</span> Частота невідома: гіперемія, свербіж.
																									</p>
																									<p>
																										<span>З боку сечостатевої системи.</span> Частота невідома: гіперурикозурія, особливо при застосуванні високих доз препарату. Можливе підвищене виділення сечової кислоти із сечею, особливо
при застосуванні високих доз препарату. Для уникнення утворення сечокислих конкрементів у таких хворих слід контролювати вміст сечової кислоти в
сечі.
																									</p>
																									<p>
																										<span>Загальні порушення.</span><br>Частота невідома: відчуття жару, загальна слабкість.
																									</p>

                                          <p>
																										<span>Повідомлення про підозрювані побічні реакції</span><br>
                                            Дуже важливо повідомляти про підозрювані побічні реакції, що виникли після реєстрації препарату, оскільки це дає змогу постійно відстежувати
співвідношення користь/ризик застосування цього лікарського засобу. Медичних працівників закликають повідомляти про будь-які підозрювані небажані
реакції через національну систему фармаконагляду.

																									</p>
																									<span
                                                      class="subtitle">Термін придатності.</span>
																									<p>2 роки.</p>
																									<span class="subtitle">Умови зберігання.
																										<br>
																										</span>
																										<p>Зберігати в оригінальній упаковці при температурі не вище 25 ºС.</p>
																										<p>Зберігати у недоступному для дітей місці.</p>
																										<span
                                                        class="subtitle">Упаковка.</span>
																										<p>№20 (20 х 1): по 20 таблеток у блістері, по 1 блістеру в картонній коробці.</p>
																										<span
                                                        class="subtitle">Категорія відпуску.</span>
																										<p>Без рецепта.</p>
																										<span
                                                        class="subtitle">Виробник.
																											<br>
																											</span>
																											<p>ТОВ «Фармекс Груп».</p>
																											<span class="subtitle">Місцезнаходження виробника та адреса місця провадження його діяльності.
																												<br>
																												</span>
																												<p>Україна, 08301, Київська обл., місто Бориспіль, вулиця Шевченка, будинок 100</p>

                                          	<span class="subtitle">Заявник.
																												<br>
																												</span>
																												<p>ТОВ "Опелла Хелскеа Україна", Україна.</p>

                                          <span class="subtitle">Місцезнаходження заявника та адреса місця проваждення його діяльності.
																												<br>
																												</span>
																												<p>Україна, 01033, м. Київ, вул. Жилянська, 48-50А.</p>


																																					<span
                                                                              class="subtitle">Дата останнього перегляду</span> 21.09.2021
																																				</section>
																																			</div>
																																			<div
                                                                          class="bottom-note-block">
<!--																																				<p class="footnote"><sup>1</sup> Формула оновлена за рахунок збільшення вмісту ліпази (на 3 000 МО) та α-амілази (на 2 500 МО) порівняно із Фестал<sup>®</sup>  НЕО (РП МОЗ України № UA / 11255/01/02 (втратило чинність 06.01.2016 року). Наказ МОЗ України № 905 від 01.12.2014. Зміни до реєстраційного посвідчення затверджені Наказом МОЗ України № 616 від 03.09.14.). Інструкція для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000. Наказ МОЗ України №462 от 24.07.2015.-->
                                                                        <!--																																					<br>-->
                                                                        <!--																																						<sup>2</sup>  Панкреатин – фермент підшлункової залози, що сприяє травленню. Показання: Для поліпшення перетравлювання їжі особам з нормальною функцією шлунково-кишкового тракту в разі порушень режиму раціонального харчування (важкість у шлунку). Метеоризм.-->
                                                                        <!--																																						<br>-->
                                                                        <!--																																							<sup>3</sup> До складу препарату входять основні панкреатичні травні ферменти: ліпаза, α-амілаза, протеази (трипсин і хімотрипсин), які чинять ліполітичну, амілолітичну і протеолітичну дію.-->
                                                                        <!--																																						</p>-->
																																						<p
                                                                                class="adversting-text">Реклама лікарського засобу. Перед застосуванням лікарського засобу необхідно проконсультуватися з лікарем та обов’язково ознайомитися з інструкцією для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000. Зберігати у недоступному для дітей місці.
																																						</p>
																																						<table
                                                                                class="adversting-text-table">
																																							<tbody>
																																								<tr class="top-line">
																																									<td>
<!--																																										<router-link to="/official-notice">Офіційне повідомлення</router-link>.-->
																																									</td>
																																									<td>
<!--																																										<router-link to="/feedback">Зворотний зв'язок</router-link>.-->
																																									</td>
																																								</tr>
																																								<tr class="bottom-line">
																																									<td><router-link
                                                                                      to="/official-notice">Офіційне повідомлення</router-link>.<br>Всі права захищені<sup>®</sup> 2021. Дата останнього перегляду 08.07.2022. Сайт призначений виключно для відвідувачів з України.
																																									</td>
																																									<td>
																																											<a
                                                                                          href="http://www.sanofi.ua"
                                                                                          style="padding-right: 5px; display: inline-block"
                                                                                          target="_blank">www.sanofi.ua</a>

																																											<a
                                                                                          href="http://www.festal.ua"
                                                                                          target="_blank">www.festal.ua</a>
																																										<br>MAT-UA-2200466-1.0-07/2022
																																									</td>
																																									<td>
																																										<router-link class="non-line"
                                                                                                 to="/instruction">Інструкція для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000, таблетки, вкриті оболонкою, кишковорозчинні.
																																												</router-link><br>РП №UA/14533/01/01. Наказ МОЗ України №1994 від 21.09.2021.
																																									</td>
																																								</tr>
																																							</tbody>
																																						</table>
																																					</div>
																																					<footer
                                                                              id="self-medication-block">
																																						<div class="word">
																																							<div class="letter s"></div>
																																							<div
                                                                                  class="letter a"></div>
																																							<div class="letter m bigger-letter"></div>
																																							<div class="letter o"></div>
																																							<div
                                                                                  class="letter l"></div>
																																							<div
                                                                                  class="letter i smaller-letter"></div>
																																							<div class="letter k"></div>
																																							<div
                                                                                  class="letter u"></div>
																																							<div class="letter v"></div>
																																							<div class="letter a"></div>
																																							<div
                                                                                  class="letter n"></div>
																																							<div class="letter n"></div>
																																							<div class="letter ia"></div>
																																						</div>
																																						<div
                                                                                class="word">
																																							<div class="letter m bigger-letter"></div>
																																							<div class="letter o"></div>
																																							<div
                                                                                  class="letter ge bigger-letter"></div>
																																							<div class="letter e"></div>
																																						</div>
																																						<div class="word">
																																							<div
                                                                                  class="letter b"></div>
																																							<div class="letter u"></div>
																																							<div class="letter t"></div>
																																							<div
                                                                                  class="letter ii"></div>
																																						</div>
																																						<div class="word">
																																							<div
                                                                                  class="letter sh bigger-letter"></div>
																																							<div
                                                                                  class="letter k"></div>
																																							<div
                                                                                  class="letter i smaller-letter"></div>
																																							<div class="letter d"></div>
																																							<div
                                                                                  class="letter l"></div>
																																							<div class="letter ii"></div>
																																							<div class="letter v"></div>
																																							<div
                                                                                  class="letter ii"></div>
																																							<div class="letter m bigger-letter"></div>
																																						</div>
																																						<div class="word">
																																							<div
                                                                                  class="letter d"></div>
																																							<div class="letter l"></div>
																																							<div class="letter ia"></div>
																																						</div>
																																						<div
                                                                                class="word">
																																							<div class="letter v"></div>
																																							<div class="letter a"></div>
																																							<div
                                                                                  class="letter sh bigger-letter"></div>
																																							<div class="letter o"></div>
																																							<div class="letter g"></div>
																																							<div
                                                                                  class="letter o"></div>
																																						</div>
																																						<div class="word">
																																							<div class="letter z"></div>
																																							<div
                                                                                  class="letter d"></div>
																																							<div class="letter o"></div>
																																							<div class="letter r"></div>
																																							<div
                                                                                  class="letter o"></div>
																																							<div class="letter v"></div>
																																							<div
                                                                                  class="letter znak smaller-letter"></div>
																																							<div class="letter ia"></div>
																																						</div>
																																						<svg
                                                                                id="self-medication-svg"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                xml:space="preserve" x="0px" y="0px"
                                                                                viewBox="0 0 568.5 58.4">
																																							<g id="self-medication-svg-letters">
																																								<path class="self-medication-svg-letter"
                                                                                      d="M8.8,17.2c0-5,1.3-7.9,4.8-7.9c3.5,0,4.8,2.9,4.8,7.9V18c0,0-0.1,0.2-0.2,0.2S18,18,18,18v-0.8
 c0-4.4-1.1-6.8-4.4-6.8c-3.3,0-4.3,2.4-4.3,6.8v23.2c0,4.4,1.1,6.8,4.3,6.8c3,0,4.6-2.5,4.6-2.5s0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6s-1.3,2.5-4.6,2.5c-3.5,0-4.8-2.9-4.8-7.9V17.2z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M24.1,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2L30,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9l-1.7,12c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L24.1,9.5z M21.1,34.8h6.6l-3.3-24.2L21.1,34.8z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M43.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M45.4,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M45.9,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M60.1,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L60.1,9.7z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M70,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6
 h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6H70z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M76.2,28.5L74,30.1V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L76.2,28.5z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M87,37c0,0-0.1-0.1-0.1-0.1L82,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L86.6,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L87,37z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M93.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M93.7,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M93.7,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M107.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L103,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L107.8,9.5z M104.8,34.8h6.6l-3.3-24.2L104.8,34.8z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M124.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M135.9,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M146.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L137.7,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M146,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M166.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2
 c-0.2,0-0.3-0.2-0.3-0.2l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 l5.7,30.5l5.8-30.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M168.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M168.7,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M179.1,48c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.5-20.2l-4.2-18.2c0,0,0.1-0.2,0.3-0.2
 c0.2,0,0.3,0.2,0.3,0.2l4.1,17.7l2.1,1.7V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2v19.3l2.1-1.7l4.1-17.7
 c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-4.2,18.2l4.5,20.2c0,0-0.1,0.2-0.3,0.2c-0.1,0-0.3-0.2-0.3-0.2L188,28.3l-2.1,1.7v18
 c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V30l-2.1-1.7L179.1,48z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M194.2,47h8.1c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.5c-2.4,0-4-0.3-4-0.3V9.7c0,0,1.6-0.3,4-0.3h4.4
 c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-8v17.1h7.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.2V47z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M209.5,26.8c0,0,1.7-0.2,4-0.2c3.2,0,4.5,3.1,4.5,8.4v4.8c0,5.3-1.3,8.4-4.5,8.4c-2.6,0-4.4-0.3-4.4-0.3V9.7
 c0,0,1.6-0.3,4-0.3h4.3c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.9V26.8z M209.5,47.1h4c3,0,4.1-2.5,4.1-7.3V35
 c0-4.8-1.1-7.3-4.1-7.3h-4V47.1z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M223.5,37c0,0-0.1-0.1-0.1-0.1l-4.9-27.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L223.1,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L223.5,37z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M234.1,10.5V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5h-4.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h9.8c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6H234.1z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M249.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L240.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M272.1,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C272.1,9.4,272.1,9.5,272.1,9.5"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M276.7,28.5l-2.2,1.7V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L276.7,28.5z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M284.4,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6
 H284.4z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M287.1,47.1c1.8,0,2.1-2.3,2.3-6.8L291,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M297.4,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H297.4z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M303.9,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L303.9,9.7z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M322.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L313.1,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46L322,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z
 "></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M324.4,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M324.8,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M324.8,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M344.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L335.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M359.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2V48z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M366.5,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2H367v8.2c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 V47.1z M376.9,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H376.9z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M383.3,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L383.3,9.7z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M400.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L392,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M400.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M405.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M405.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M405.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M419.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L415,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L419.8,9.5z M416.8,34.8h6.6l-3.3-24.2L416.8,34.8z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M441.6,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C441.6,9.4,441.6,9.5,441.6,9.5"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M443.6,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M444,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M455.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.7c0,0,1.6-0.3,4-0.3h3.7c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-7.2V48z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M463.9,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M464.3,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M489.5,17.8v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3c0,5.4-1.3,8.7-4.5,8.7c-3.2,0-4.5-2.5-4.5-2.5
 s-0.1-0.2-0.1-0.6c0-0.4,0.1-0.6,0.1-0.6s1.5,2.5,4.5,2.5s4.1-2.6,4.1-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-1.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h1.8c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.8-7.4c-3.2,0-4.2,2.4-4.2,6.8V18c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2v-0.8c0-5,1.3-7.9,4.7-7.9C488.3,9.3,489.5,12.4,489.5,17.8"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M490.3,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M500.7,47V10.5h-6.1L493,40.4c-0.2,3.1-0.4,5.4-1.1,6.6H500.7z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M504,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M504.4,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M520,9.3c3.2,0,4.5,2.9,4.5,7.9V22c0,5-1.3,7.9-4.5,7.9c-2.3,0-3.8-0.2-3.8-0.2V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5C515.8,9.5,517.5,9.3,520,9.3 M516.2,28.8h3.8c3,0,4.1-2.4,4.1-6.8v-4.8c0-4.4-1.1-6.8-4.1-6.8h-3.8
 V28.8z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M526.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M526.8,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																																								<path
                                                                                    class="self-medication-svg-letter"
                                                                                    d="M538.1,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M538.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M538.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M548.1,18.7c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2s1.1-2.7,1.1-9.2c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2C549.2,16.3,548.1,18.7,548.1,18.7"></path>
																																								<path class="self-medication-svg-letter"
                                                                                      d="M559.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L551,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M559.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																																							</g>
																																						</svg>
																																					</footer>
																																				</div>
																																			</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "instruction",
  mounted() {
    let trigger25 = true;
    let trigger50 = true;
    let trigger75 = true;
    let trigger90 = true;

    window.addEventListener("scroll", () => {

      var h = document.documentElement,
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';

      var percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);
      if (percent >= 25 && percent < 50 && trigger25) {
        trigger25 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол сторінки 25%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 50 && percent < 75 && trigger50) {
        trigger50 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 50%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 75 && percent < 90 && trigger75) {
        trigger75 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 75%',
          'event_label': 'UA: ' + this.$route.path
        })
      } else if (percent >= 90 && trigger90) {
        trigger90 = false
        dataLayer.push({
          'event': 'click',
          'event_category': 'скрол  сторінки 90%',
          'event_label': 'UA: ' + this.$route.path
        })
      }
    })
  }
}
</script>

<style lang="less">

@import "../styles/variables_mixins";

.content-container {
  .con {

    padding: 20px 0;
    width: 67%;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;

    .text {
      margin: 0 0 0 auto;
      text-align: center;
      font-family: @font-book;

      span {
        font-family: @font-bold;
      }
    }
  }
}

.new-btn-instruction {
  width: 140px;
  height: 40px;

  top: calc(120px + 50px);
  right: 5vw;
  z-index: 500;
  position: fixed;

  line-height: 1em;
  text-decoration: none;


  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;


  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 1);
  background: linear-gradient(to bottom, rgba(33, 162, 232, 1) 0%, rgba(31, 95, 178, 1) 100%);
  border-radius: 13px;
  border: none;
  outline: none;
  border-bottom: 2px #1d3987 solid;

  font-family: 'OfficinaSansCyrillic-Book_Regular';
  font-size: 17px;
  color: #fff;
  text-align: center;
  @media screen and (max-width: 1024px) {
    display: none;
  }

  &:before {
    content: '';
    width: 140px;
    height: 40px;

    top: 2px;
    left: 0;
    z-index: -1;
    opacity: 0.8;
    position: absolute;

    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(31, 95, 178, 1) 100%);
    border-radius: 13px;
  }


}
</style>
