<template>
  <div class="home" data-view>
    <div data-reactroot="" id="content">
      <div id="router-container">
        <top-menu></top-menu>
        <message-alert></message-alert>
        <span>
				<div id="home-page-container" class="content-container" style="padding-top: 60px;">
					<section
              class="top-scroll-block" style="height: 640px;">
						<div class="middle-text-block">
							<div
                  class="pack-img-block">
								<img src="../assets/imgs/homePage/pack.png" alt="festal_pack">
								</div>
								<div
                    class="middle-text">
									<h1>Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
										</h1>
										<h2>
												Потрійна допомога<br>травленню*
											</h2>
										</div>
									</div>
									<svg
                      class="scroll-arrows-btn"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 38.6" xml:space="preserve">
										<path class="border"
                          d="M10,38L10,38c-5.1,0-9.4-4.2-9.4-9.4V10c0-5.1,4.2-9.4,9.4-9.4h0c5.1,0,9.4,4.2,9.4,9.4v18.6 C19.4,33.8,15.2,38,10,38z"></path>
										<polyline
                        class="arrow arrow-2" points="13.7,24.9 10,28.6 6.3,24.9 "
                        data-svg-origin="6.300000190734863 24.899999618530273"
                        transform="matrix(1,0,0,1,0,0.9758559999999994)" style="opacity: 0.820775;"></polyline>
										<polyline
                        class="arrow arrow-1" points="13.7,18.2 10,21.9 6.3,18.2 "
                        data-svg-origin="6.300000190734863 18.200000762939453"
                        transform="matrix(1,0,0,1,0,-1.2833440000000018)" style="opacity: 0.425415;"></polyline>
										<polyline
                        class="arrow arrow-3" points="13.7,12.2 10,15.9 6.3,12.2 "
                        data-svg-origin="6.300000190734863 12.199999809265137" transform="matrix(1,0,0,1,0,-2)"
                        style="opacity: 0.3;"></polyline>
									</svg>
									<div class="mobile-top-block">
											<h1>Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000
										</h1>
											<img
                          src="../assets/imgs/homePage/pack_mobile.png" alt="festal_pack">
											<h2>
												Потрійна допомога<br>травленню*
											</h2>
													<div
                              class="mobile-triple-helper mobile-triple-helper-1">
														<span class="helper-number">1</span>
														<h1
                                class="helper-title">Сприяє
															<br>травленню<sup>1</sup>
															</h1>
														</div>
														<div
                                class="mobile-triple-helper mobile-triple-helper-2">
															<span class="helper-number">2</span>
															<h1
                                  class="helper-title">Зменшує<br>відчуття<br>важкості<sup>1</sup>
																	</h1>
																</div>
																<div
                                    class="mobile-triple-helper mobile-triple-helper-3">
																	<span class="helper-number">3</span>
																	<h1
                                      class="helper-title">Зменшує<br>здуття<br>живота<sup>1</sup>
																			</h1>
																		</div>
																	</div>
																</section>
																<section
                                    class="main-column">
																	<svg class="full-line"
                                       xmlns="http://www.w3.org/2000/svg"
                                       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                       viewBox="0 0 1095.5 3275.6" xml:space="preserve">
																		<path class="path path-1" d="M993.5,74.5C619.4,84.6,303.1,227.3,170.8,426.5"></path>
																		<path
                                        class="path path-2"
                                        d="M130.7,515.5c0,0-108.6,45.5-126.4,164.5c-12.2,81.4,7.4,287.9,294.5,321.7c266.5,31.4,290.8,126.3,261.8,235.1c-28.7,107.5-65,308.8,297.9,308.8"></path>
																		<path
                                        class="path path-3"
                                        d="M901.9,1544.2c0,0,191.9-11,191.9,92.6c0,103.6-190.4,280.3-419.4,163.3c-213.5-109.1-477.4-214.8-543.6-3.3c-54.4,173.8,250.3,413,250.3,413"></path>
																		<path
                                        class="path path-4"
                                        d="M368.7,2219.1c0,0-330.2,24.9-330.2,265.4c0,311.5,669.5,380.7,799.9,790.6"></path>
																		<g
                                        class="flag flag-white">
																			<circle class="flag-dot flag-dot-white" cx="1015" cy="72.2" r="7.1"></circle>
																			<line
                                          class="flag-line flag-line-white" x1="1015" y1="55.7" x2="1015"
                                          y2="2.4"></line>
																			<polygon
                                          class="flag-colors flag-colors-white"
                                          points="1060,29 1015,29 1015,2.4 1060,2.4 1052.9,15.7"></polygon>
																		</g>
																		<g
                                        class="flag flag-blue flag-blue-1">
																			<circle class="flag-dot flag-dot-blue" cx="149" cy="507" r="7.1"></circle>
																			<line
                                          class="flag-line flag-line-blue" x1="149" y1="490.5" x2="149"
                                          y2="437.2"></line>
																			<polygon
                                          class="flag-colors flag-colors-blue"
                                          points="194,463.8 149,463.8 149,437.2 194,437.2 186.9,450.5"></polygon>
																		</g>
																		<g
                                        class="flag flag-blue flag-blue-2"
                                        data-svg-origin="869.2000122070312 1476.800048828125"
                                        transform="matrix(1.3,0,0,1.3,-260.7600036621095,-443.0400146484376)"
                                        style="opacity: 0;">
																			<circle class="flag-dot flag-dot-blue" cx="876.3" cy="1546.6" r="7.1"></circle>
																			<line
                                          class="flag-line flag-line-blue" x1="876.3" y1="1530.1" x2="876.3"
                                          y2="1476.8"></line>
																			<polygon
                                          class="flag-colors flag-colors-blue"
                                          points="921.3,1503.4 876.3,1503.4 876.3,1476.8 921.3,1476.8 914.2,1490.1"></polygon>
																		</g>
																		<g
                                        class="flag flag-blue flag-blue-3"
                                        data-svg-origin="384.9999694824219 2153.800048828125"
                                        transform="matrix(1.3,0,0,1.3,-115.4999908447266,-646.1400146484375)"
                                        style="opacity: 0;">
																			<circle class="flag-dot flag-dot-blue" cx="392.1" cy="2223.6" r="7.1"></circle>
																			<line
                                          class="flag-line flag-line-blue" x1="392.1" y1="2207" x2="392.1"
                                          y2="2153.8"></line>
																			<polygon
                                          class="flag-colors flag-colors-blue"
                                          points="437.1,2180.4 392.1,2180.4 392.1,2153.8 437.1,2153.8 430,2167.1"></polygon>
																		</g>
																	</svg>
																	<div
                                      class="dish-block dish-block-1">
																		<div class="dish-title-block dish-title-block-1"
                                         style="opacity: 0; transform: matrix(1, 0, 0, 1, -25, 0);">
																			<span
                                          class="dish-number">1</span>
																			<h1
                                          class="dish-title">Сприяє
																				<br>травленню<sup>1</sup>
																				</h1>
																			</div>
																			<img class="dish dish-1"
                                           src="../assets/imgs/homePage/dish1.png"
                                           alt="yum_dish_1"
                                           style="opacity: 0; transform: matrix(1, 0, 0, 1, 0, 40);">
																				<img
                                            class="dish-promotion-text dish-promotion-text-1"
                                            src="../assets/imgs/homePage/map/textUA/text_1.svg"
                                            alt=""
                                            style="opacity: 0; transform: matrix(0.88632, 0.15628, -0.15628, 0.88632, 0, 40);">
																				</div>
																				<div
                                            class="dish-block dish-block-2">
																					<div class="dish-title-block dish-title-block-2"
                                               style="opacity: 0; transform: matrix(1, 0, 0, 1, -25, 0);">
																						<span
                                                class="dish-number">2</span>
																						<h1
                                                class="dish-title">Зменшує<br>відчуття<br>важкості<sup>1</sup>
																								</h1>
																							</div>
																							<img
                                                  class="dish-promotion-text dish-promotion-text-2"
                                                  src="../assets/imgs/homePage/map/textUA/text_2.svg"
                                                  alt="" style="opacity: 0; transform: matrix(0.9, 0, 0, 0.9, 0, 40);">
																								<img
                                                    class="dish dish-2"
                                                    src="../assets/imgs/homePage/dish2.png"
                                                    alt="yum_dish_2"
                                                    style="opacity: 0; transform: matrix(0.86603, 0.5, -0.5, 0.86603, 0, 40);">
																								</div>
																								<div
                                                    class="dish-block dish-block-3">
																									<div class="dish-title-block dish-title-block-3"
                                                       style="opacity: 0; transform: matrix(1, 0, 0, 1, -25, 0);">
																										<span
                                                        class="dish-number">3</span>
																										<h1
                                                        class="dish-title">Зменшує<br>здуття<br>живота<sup>1</sup>
																												</h1>
																											</div>
																											<img
                                                          class="dish-promotion-text dish-promotion-text-4"
                                                          src="../assets/imgs/homePage/map/textUA/text_4.svg"
                                                          alt=""
                                                          style="opacity: 0; transform: matrix(0.23293, 0.86933, -0.86933, 0.23293, 0, 40);">
																												<img
                                                            class="dish dish-3" src="../assets/imgs/homePage/dish3.png"
                                                            alt="yum_dish_3"
                                                            style="opacity: 0; transform: matrix(1, 0, 0, 1, 0, 40);">
																												</div>
																												<div
                                                            class="dish-block dish-block-4">
																													<img class="dish-promotion-text dish-promotion-text-6"
                                                               src="../assets/imgs/homePage/map/textUA/text_6.svg"
                                                               alt=""
                                                               style="opacity: 0; transform: matrix(0.84572, -0.30781, 0.30781, 0.84572, 0, 40);">
																														<img
                                                                class="dish dish-4"
                                                                src="../assets/imgs/homePage/dish4.png" alt="yum_dish_4"
                                                                style="opacity: 0; transform: matrix(1, 0, 0, 1, 0, 40);">
																														</div>
																														<img
                                                                class="path-promotion-text path-promotion-text-3"
                                                                src="../assets/imgs/homePage/map/textUA/text_3.svg"
                                                                alt="" style="opacity: 0;">
																															<img class="path-promotion-text path-promotion-text-5"
                                                                   src="../assets/imgs/homePage/map/textUA/text_5.svg"
                                                                   alt=""
                                                                   style="opacity: 0;">
																																<div class="bottom-text-block"
                                                                     style="opacity: 0; transform: matrix(1, 0, 0, 1, -35, 0);">
																																	<div
                                                                      class="pack-img-block">
																																		<img src="../assets/imgs/homePage/pack2.png"
                                                                         alt="festal_pack">
																																		</div>
																																		<span
                                                                        class="slogan-impressions">
																																			<span>Насолоджуйся</span>
																																			<br>їжею з
																																				<br>
                                                                      <span>Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000!</span>
																																				</span>
																																				<span
                                                                            class="slogan-triple-help">Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000. Потрійна допомога травленню!<sup>*</sup>
																																				</span>
																																			</div>
																																		</section>
																																		<section
                                                                        class="mobile-bottom-text-block">
																																			<div class="flex-container">
																																				<span
                                                                            class="slogan-impressions">
																																					<span>Більше</span>
																																					<br>смакових вражень з
																																						<br>
																																							<span>Фестал® НЕО 10 000!</span>
																																						</span>
																																						<img
                                                                                src="../assets/imgs/homePage/dish_mobile.png"
                                                                                alt="festal_pack">
                                                                        <span
                                                                            class="slogan-triple-help">Потрійна допомога травленню!<sup>*</sup>
																																						</span>
																																						</div>

																																					</section>
																																					<div
                                                                              class="bottom-note-block">
																																						<p class="footnote">
<!--                                                                              <sup>1</sup> За рахунок збільшення вмісту ліпази (на 3 000 МО) та α-амілази (на 2 500 МО) порівняно із препаратом Фестал<sup>®</sup> РП №UA2531/01/01. Наказ МОЗ України №341 від 29.03.2017.-->
                                                                              <sup>1</sup> Інструкція для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000, таблетки, вкриті оболонкою, кишковорозчинні. РП №UA/14533/01/01. Наказ МОЗ України №1994 від 21.09.2021.
                                                                              <!--                                                                              <br><sup>3</sup> Ферментная недостаточность поджелудочной железы: проблема и ее коррекция// Здоровье-Украины//ЛІКАР-ПАЦІЄНТ// №20 (369) – Жовтень 2015 р. С. 18-19.-->

                                                                              <br><sup>*</sup> До складу препарату входять основні панкреатичні травні ферменти: ліпаза, α-амілаза, протеази (трипсин і хімотрипсин), які чинять ліполітичну, амілолітичну і протеолітичну дію.

																																								</p>
																																								<p
                                                                                    class="adversting-text">Реклама лікарського засобу. Перед застосуванням лікарського засобу необхідно проконсультуватися з лікарем та обов’язково ознайомитися з інструкцією для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000. Зберігати у недоступному для дітей місці.
																																								</p>
																																								<table
                                                                                    class="adversting-text-table">
																																									<tbody>
																																										<tr class="top-line">
																																											<td>

																																											</td>
																																											<td>
<!--																																												<router-link to="/feedback">Зворотний зв'язок</router-link>.-->
																																											</td>
																																										</tr>
																																										<tr
                                                                                        class="bottom-line" >

																																											<td><router-link to="/official-notice">Офіційне повідомлення</router-link>.<br>
                                                                                        Усі права збережені<sup>®</sup> 2021. Дата останнього перегляду 08.07.2022. Сайт призначений виключно для відвідувачів з України.
																																											</td>
																																											<td>

																																													<a
                                                                                              href="http://www.sanofi.ua" style="padding-right: 5px; display: inline-block;"
                                                                                              target="_blank">www.sanofi.ua</a>



																																													<a
                                                                                              href="http://www.festal.ua"
                                                                                              target="_blank">www.festal.ua</a>
																																												<br>MAT-UA-2200466-1.0-07/2022
																																											</td>
																																											<td >
																																												<router-link class="non-line"
                                                                                                     to="/instruction">Інструкція для медичного застосування препарату Фестал<sup>®</sup>&nbsp;НЕО&nbsp;10&nbsp;000, таблетки, вкриті оболонкою, кишковорозчинні.
																																												</router-link><br>РП №UA/14533/01/01. Наказ МОЗ України №1994 від 21.09.2021.
																																											</td>
																																										</tr>
																																									</tbody>
																																								</table>
																																							</div>
																																							<footer
                                                                                  id="self-medication-block">
																																								<div class="word">
																																									<div class="letter s"></div>
																																									<div
                                                                                      class="letter a"></div>
																																									<div
                                                                                      class="letter m bigger-letter"></div>
																																									<div
                                                                                      class="letter o"></div>
																																									<div
                                                                                      class="letter l"></div>
																																									<div
                                                                                      class="letter i smaller-letter"></div>
																																									<div
                                                                                      class="letter k"></div>
																																									<div
                                                                                      class="letter u"></div>
																																									<div class="letter v"></div>
																																									<div class="letter a"></div>
																																									<div
                                                                                      class="letter n"></div>
																																									<div class="letter n"></div>
																																									<div class="letter ia"></div>
																																								</div>
																																								<div
                                                                                    class="word">
																																									<div
                                                                                      class="letter m bigger-letter"></div>
																																									<div class="letter o"></div>
																																									<div
                                                                                      class="letter ge bigger-letter"></div>
																																									<div class="letter e"></div>
																																								</div>
																																								<div class="word">
																																									<div
                                                                                      class="letter b"></div>
																																									<div class="letter u"></div>
																																									<div class="letter t"></div>
																																									<div
                                                                                      class="letter ii"></div>
																																								</div>
																																								<div class="word">
																																									<div
                                                                                      class="letter sh bigger-letter"></div>
																																									<div
                                                                                      class="letter k"></div>
																																									<div
                                                                                      class="letter i smaller-letter"></div>
																																									<div
                                                                                      class="letter d"></div>
																																									<div
                                                                                      class="letter l"></div>
																																									<div class="letter ii"></div>
																																									<div class="letter v"></div>
																																									<div
                                                                                      class="letter ii"></div>
																																									<div
                                                                                      class="letter m bigger-letter"></div>
																																								</div>
																																								<div class="word">
																																									<div
                                                                                      class="letter d"></div>
																																									<div class="letter l"></div>
																																									<div class="letter ia"></div>
																																								</div>
																																								<div
                                                                                    class="word">
																																									<div class="letter v"></div>
																																									<div class="letter a"></div>
																																									<div
                                                                                      class="letter sh bigger-letter"></div>
																																									<div class="letter o"></div>
																																									<div
                                                                                      class="letter g"></div>
																																									<div
                                                                                      class="letter o"></div>
																																								</div>
																																								<div class="word">
																																									<div class="letter z"></div>
																																									<div
                                                                                      class="letter d"></div>
																																									<div class="letter o"></div>
																																									<div class="letter r"></div>
																																									<div
                                                                                      class="letter o"></div>
																																									<div class="letter v"></div>
																																									<div
                                                                                      class="letter znak smaller-letter"></div>
																																									<div class="letter ia"></div>
																																								</div>
																																								<svg
                                                                                    id="self-medication-svg"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                    xml:space="preserve" x="0px" y="0px"
                                                                                    viewBox="0 0 568.5 58.4">
																																									<g id="self-medication-svg-letters">
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M8.8,17.2c0-5,1.3-7.9,4.8-7.9c3.5,0,4.8,2.9,4.8,7.9V18c0,0-0.1,0.2-0.2,0.2S18,18,18,18v-0.8
 c0-4.4-1.1-6.8-4.4-6.8c-3.3,0-4.3,2.4-4.3,6.8v23.2c0,4.4,1.1,6.8,4.3,6.8c3,0,4.6-2.5,4.6-2.5s0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6s-1.3,2.5-4.6,2.5c-3.5,0-4.8-2.9-4.8-7.9V17.2z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M24.1,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2L30,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9l-1.7,12c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L24.1,9.5z M21.1,34.8h6.6l-3.3-24.2L21.1,34.8z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M43.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M45.4,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M45.9,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M60.1,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L60.1,9.7z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M70,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6
 h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6H70z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M76.2,28.5L74,30.1V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L76.2,28.5z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M87,37c0,0-0.1-0.1-0.1-0.1L82,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L86.6,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L87,37z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M93.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M93.7,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M93.7,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M107.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L103,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L107.8,9.5z M104.8,34.8h6.6l-3.3-24.2L104.8,34.8z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M124.3,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M135.9,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V28.8h-8.8V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2v18.1h8.8V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M146.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L137.7,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M146,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M166.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2
 c-0.2,0-0.3-0.2-0.3-0.2l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 l5.7,30.5l5.8-30.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M168.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M168.7,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M179.1,48c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.5-20.2l-4.2-18.2c0,0,0.1-0.2,0.3-0.2
 c0.2,0,0.3,0.2,0.3,0.2l4.1,17.7l2.1,1.7V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2v19.3l2.1-1.7l4.1-17.7
 c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-4.2,18.2l4.5,20.2c0,0-0.1,0.2-0.3,0.2c-0.1,0-0.3-0.2-0.3-0.2L188,28.3l-2.1,1.7v18
 c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V30l-2.1-1.7L179.1,48z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M194.2,47h8.1c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.5c-2.4,0-4-0.3-4-0.3V9.7c0,0,1.6-0.3,4-0.3h4.4
 c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-8v17.1h7.2c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.2V47z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M209.5,26.8c0,0,1.7-0.2,4-0.2c3.2,0,4.5,3.1,4.5,8.4v4.8c0,5.3-1.3,8.4-4.5,8.4c-2.6,0-4.4-0.3-4.4-0.3V9.7
 c0,0,1.6-0.3,4-0.3h4.3c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-7.9V26.8z M209.5,47.1h4c3,0,4.1-2.5,4.1-7.3V35
 c0-4.8-1.1-7.3-4.1-7.3h-4V47.1z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M223.5,37c0,0-0.1-0.1-0.1-0.1l-4.9-27.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l4.7,26.4l4.6-26.4
 c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2L223.1,42c-0.7,3.9-1.4,6.2-2.9,6.2c-0.6,0-0.9-0.2-0.9-0.2s-0.1-0.2-0.1-0.6
 s0.1-0.6,0.1-0.6s0.3,0.2,0.9,0.2c1.3,0,2-2,2.5-5.1L223.5,37z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M234.1,10.5V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5h-4.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h9.8c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6H234.1z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M249.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L240.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M272.1,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C272.1,9.4,272.1,9.5,272.1,9.5"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M276.7,28.5l-2.2,1.7V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2V29l2.2-1.7l4.9-17.8c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l-5.1,18.4l5.3,20c0,0-0.1,0.2-0.3,0.2
 c-0.1,0-0.3-0.2-0.3-0.2L276.7,28.5z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M284.4,47V10.5h-1.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6h4.2c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-1.9V47h1.9c0,0,0.1,0.2,0.1,0.6c0,0.3-0.1,0.6-0.1,0.6h-4.2c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6
 H284.4z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M287.1,47.1c1.8,0,2.1-2.3,2.3-6.8L291,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M297.4,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H297.4z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M303.9,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L303.9,9.7z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M322.4,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L313.1,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46L322,9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48z
 "></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M324.4,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M324.8,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M324.8,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M344.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V11.5L335.4,48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V46l8.9-36.4c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V48
 z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M359.1,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V12.1l-5.5,29c0,0-0.1,0.2-0.3,0.2s-0.3-0.2-0.3-0.2
 l-5.4-28.9V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.6c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2l5.7,30.5l5.8-30.6
 c0,0,0.1-0.2,0.2-0.2s0.2,0.2,0.2,0.2V48z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M366.5,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2H367v8.2c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 V47.1z M376.9,47V10.5h-6.1l-1.6,29.8c-0.2,3.1-0.4,5.4-1.1,6.6H376.9z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M383.3,9.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V10.5
 h-6.1l-1.6,29.8c-0.3,5-0.7,7.9-2.7,7.9c0,0-0.1-0.2-0.1-0.6c0-0.3,0.1-0.6,0.1-0.6c1.7,0,2-2.4,2.2-6.8L383.3,9.7z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M400.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L392,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M400.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M405.2,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M405.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M405.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M419.8,9.5c0,0,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.2l5.3,38.4c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2
 l-1.7-12h-6.9L415,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2L419.8,9.5z M416.8,34.8h6.6l-3.3-24.2L416.8,34.8z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M441.6,9.5v38.3c0,0-1.5,0.3-3.9,0.3h-7.1c-2.4,0-4-0.3-4-0.3V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2
 V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.2V47h6.9V9.5c0,0,0.1-0.2,0.2-0.2C441.6,9.4,441.6,9.5,441.6,9.5"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M443.6,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M444,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M455.8,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V9.7c0,0,1.6-0.3,4-0.3h3.7c0,0,0.1,0.2,0.1,0.6
 c0,0.3-0.1,0.6-0.1,0.6h-7.2V48z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M463.9,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M464.3,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M489.5,17.8v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3c0,5.4-1.3,8.7-4.5,8.7c-3.2,0-4.5-2.5-4.5-2.5
 s-0.1-0.2-0.1-0.6c0-0.4,0.1-0.6,0.1-0.6s1.5,2.5,4.5,2.5s4.1-2.6,4.1-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-1.8c0,0-0.1-0.2-0.1-0.6
 c0-0.3,0.1-0.6,0.1-0.6h1.8c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.8-7.4c-3.2,0-4.2,2.4-4.2,6.8V18c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2v-0.8c0-5,1.3-7.9,4.7-7.9C488.3,9.3,489.5,12.4,489.5,17.8"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M490.3,47.1c1.8,0,2.1-2.3,2.3-6.8l1.7-30.7c0,0,1.3-0.3,3.4-0.3c2.1,0,3.5,0.3,3.5,0.3V47
 c1.1,0,1.8,0.2,1.8,0.2v9.1c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2v-8.2h-11.8v8.2c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V47.1z M500.7,47V10.5h-6.1L493,40.4c-0.2,3.1-0.4,5.4-1.1,6.6H500.7z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M504,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2z
 M504.4,40.4c0,4.4,1.2,6.8,4.5,6.8c3.3,0,4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8c-3.3,0-4.5,2.4-4.5,6.8V40.4z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M520,9.3c3.2,0,4.5,2.9,4.5,7.9V22c0,5-1.3,7.9-4.5,7.9c-2.3,0-3.8-0.2-3.8-0.2V48c0,0-0.1,0.2-0.2,0.2
 c-0.1,0-0.2-0.2-0.2-0.2V9.5C515.8,9.5,517.5,9.3,520,9.3 M516.2,28.8h3.8c3,0,4.1-2.4,4.1-6.8v-4.8c0-4.4-1.1-6.8-4.1-6.8h-3.8
 V28.8z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M526.3,17.2c0-5,1.4-7.9,4.9-7.9c3.5,0,4.9,2.9,4.9,7.9v23.2c0,5-1.4,7.9-4.9,7.9c-3.5,0-4.9-2.9-4.9-7.9V17.2
 z M526.8,40.4c0,4.4,1.2,6.8,4.5,6.8s4.5-2.4,4.5-6.8V17.2c0-4.4-1.2-6.8-4.5-6.8s-4.5,2.4-4.5,6.8V40.4z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M538.1,9.5c0,0,1.8-0.3,4.4-0.3c3,0,4.3,3.1,4.3,8.5v2.5c0,4.1-0.8,6.9-2.5,8c2,1,2.8,3.9,2.8,8.1v3.3
 c0,5.4-1.3,8.7-4.6,8.7c-2.6,0-4.4-0.3-4.4-0.3V9.5z M538.6,27.7h4c2.8,0,3.9-2.5,3.9-7.4v-2.5c0-4.9-1-7.4-3.9-7.4h-4V27.7z
 M538.6,47.1h4c3.1,0,4.2-2.6,4.2-7.6v-3.3c0-5-1.1-7.6-4.2-7.6h-4V47.1z"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M548.1,18.7c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2s1.1-2.7,1.1-9.2c0,0,0.1-0.2,0.2-0.2
 c0.1,0,0.2,0.2,0.2,0.2C549.2,16.3,548.1,18.7,548.1,18.7"></path>
																																										<path
                                                                                        class="self-medication-svg-letter"
                                                                                        d="M559.7,48c0,0-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2V29.9c0,0-1.7,0.2-4,0.2h-0.1L551,48
 c0,0-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.3-0.2l4.2-17.9c-2.8-0.3-3.9-3.4-3.9-8.4v-4c0-5.3,1.3-8.4,4.5-8.4c2.6,0,4.4,0.3,4.4,0.3
 V48z M559.3,10.4h-4c-3,0-4.1,2.5-4.1,7.3v4c0,4.8,1.1,7.3,4.1,7.3h4V10.4z"></path>
																																									</g>
																																								</svg>
																																							</footer>
																																						</div>
																																					</span>
      </div>
    </div>
  </div>
</template>

<script>


import {TweenMax, TimelineMax} from 'gsap'
import ScrollMagic from 'scrollmagic'
import 'ScrollMagicGSAP'

import "gsap/ScrollToPlugin"
import animateDashedPath from '../modules/animateDashedPath';
import actualPaddingSetter from '../modules/actualPaddingSetter';

export default {
  name: 'home',


  mounted() {

    let trigger25 = true;
    let trigger50 = true;
    let trigger75 = true;
    let trigger90 = true;

    window.addEventListener("scroll", () => {

          var h = document.documentElement,
              b = document.body,
              st = 'scrollTop',
              sh = 'scrollHeight';

          var percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);
          if (percent >= 25 && percent < 50 && trigger25) {
            trigger25 = false
            dataLayer.push({
              'event': 'click',
              'event_category': 'скрол сторінки 25%',
              'event_label': 'UA: ' + this.$route.path
            })
          } else if (percent >= 50 && percent < 75 && trigger50) {
            trigger50 = false
            dataLayer.push({
              'event': 'click',
              'event_category': 'скрол  сторінки 50%',
              'event_label': 'UA: ' + this.$route.path
            })
          } else if (percent >= 75 && percent < 90 && trigger75) {
            trigger75 = false
            dataLayer.push({
              'event': 'click',
              'event_category': 'скрол  сторінки 75%',
              'event_label': 'UA: ' + this.$route.path
            })
          } else if (percent >= 90 && trigger90) {
            trigger90 = false
            dataLayer.push({
              'event': 'click',
              'event_category': 'скрол  сторінки 90%',
              'event_label': 'UA: ' + this.$route.path
            })
          }
    })
    actualPaddingSetter();
    this.arrowsAnimation();
    this.changeArrowsColor();
    this.topBlockHeightSetter();

    // Animating Variables
    var dashLength = 10,
        drawAnimationDuration = 3000;
    // Paths
    var pathFirst = document.querySelector('.path-2'),
        pathSecond = document.querySelector('.path-3'),
        pathThird = document.querySelector('.path-4');


    // Dish Blocks TimeLine
    const tlDishBlock = new TimelineMax(),
        tlDishBlock_1 = new TimelineMax({ease: Power4.easeIn}),
        tlDishBlock_2 = new TimelineMax({ease: Power4.easeIn}),
        tlDishBlock_3 = new TimelineMax({ease: Power4.easeIn}),
        tlDishBlock_4 = new TimelineMax({ease: Power4.easeIn});


    // Starter CSS Values
    tlDishBlock
        .set('.dish-title-block',
            {
              x: -25,
              opacity: 0
            }
        )
        .set('.dish',
            {
              y: 40,
              opacity: 0
            }
        )
        .set('.dish-promotion-text',
            {
              scale: .9,
              y: 40,
              opacity: 0
            }
        )
        .set('.path-promotion-text',
            {opacity: 0}
        )
        .set('.bottom-text-block',
            {
              x: -35,
              opacity: 0
            }
        );

    // TimeLine Animation Properties
    tlDishBlock_1
        .add('start')
        .to('.dish-1', .8,
            {
              y: 0,
              opacity: 1
            }, 'start'
        )
        .to('.dish-promotion-text-1', 1,
            {
              scale: 1,
              y: 0,
              opacity: 1
            }, 'start'
        )
        .to('.dish-title-block-1', 1,
            {
              x: 0,
              opacity: 1,
              onComplete: animateDashedPath.bind(this, pathFirst, dashLength, drawAnimationDuration)
            }, 'start'
        )
        .fromTo('.flag-blue-2', .5,
            {
              opacity: 0,
              scale: 1.3
            },
            {
              opacity: 1,
              scale: 1
            }, '+=3'
        );

    tlDishBlock_2
        .add('start')
        .to('.dish-2', .8,
            {
              y: 0,
              opacity: 1
            }, 'start'
        )
        .to('.dish-promotion-text-2', 1,
            {
              scale: 1,
              y: 0,
              opacity: 1
            }, 'start'
        )
        .to('.dish-title-block-2', 1,
            {
              x: 0,
              opacity: 1,
              onComplete: animateDashedPath.bind(this, pathSecond, dashLength, drawAnimationDuration)
            }, 'start'
        )
        .to('.path-promotion-text-3', 1,
            {
              x: 0,
              opacity: 1
            }, '+=2'
        )
        .fromTo('.flag-blue-3', .5,
            {
              opacity: 0,
              scale: 1.3
            },
            {
              opacity: 1,
              scale: 1
            }
        );

    tlDishBlock_3
        .add('start')
        .to('.dish-3', .8,
            {
              y: 0,
              opacity: 1
            }, 'start'
        )
        .to('.dish-promotion-text-4', 1,
            {
              scale: 1,
              y: 0,
              opacity: 1
            }, 'start'
        )
        .to('.dish-title-block-3', 1,
            {
              x: 0,
              opacity: 1,
              onComplete: animateDashedPath.bind(this, pathThird, dashLength, drawAnimationDuration)
            }, 'start'
        )
        .to('.path-promotion-text-5', 1,
            {
              x: 0,
              opacity: 1
            }, '+=2'
        );

    tlDishBlock_4
        .add('start')
        .to('.dish-4', .8,
            {
              y: 0,
              opacity: 1
            }, 'start'
        )
        .to('.dish-promotion-text-6', 1,
            {
              scale: 1,
              y: 0,
              opacity: 1
            }, 'start'
        )
        .to('.dish-title-block-4', 1,
            {
              x: 0,
              opacity: 1
            }, 'start'
        )
        .to('.bottom-text-block', 1,
            {
              x: 0,
              opacity: 1
            }, 'start'
        );


    // Scroll Animating

    // Init ScrollMagic
    let controller = new ScrollMagic.Controller();

    // Scenes Build
    const dishScene_1 = new ScrollMagic.Scene({
      triggerElement: '.dish-block-1',
      offset: 200,
      reverse: false
    });

    dishScene_1
        .setTween(tlDishBlock_1)
        .addTo(controller);

    const dishScene_2 = new ScrollMagic.Scene({
      triggerElement: '.dish-block-2',
      offset: 200,
      reverse: false
    });

    dishScene_2
        .setTween(tlDishBlock_2)
        .addTo(controller);

    const dishScene_3 = new ScrollMagic.Scene({
      triggerElement: '.dish-block-3',
      reverse: false
    });

    dishScene_3
        .setTween(tlDishBlock_3)
        .addTo(controller);

    const dishScene_4 = new ScrollMagic.Scene({
      triggerElement: '.dish-block-4',
      reverse: false
    });

    dishScene_4
        .setTween(tlDishBlock_4)
        .addTo(controller);
  },
  methods: {
    arrowsAnimation() {
      // Scroll Arrows TimeLine
      var scrollArrows = document.getElementsByClassName('arrow'),
          tlScrollArrows = new TimelineMax({repeat: -1, yoyo: true});

      tlScrollArrows
          .staggerFromTo(scrollArrows, 0.5,
              {
                opacity: .3,
                y: -2
              },
              {
                opacity: 1,
                y: 2
              }, 0.2);
    },
    changeArrowsColor() {
      window.addEventListener("scroll", () => {
        window.pageYOffset > 100
            ? document.querySelector('.scroll-arrows-btn').classList.add('on-scroll')
            : document.querySelector('.scroll-arrows-btn').classList.remove('on-scroll')

        document.body.scrollHeight - window.pageYOffset < 1500
            ? (
                document.querySelector('.scroll-arrows-btn').classList.add('hide'),
                    this.setState({start: 0})
            )
            : document.querySelector('.scroll-arrows-btn').classList.remove('hide')
      });
    },
    topBlockHeightSetter() {
      let topBlock = document.querySelector('.top-scroll-block'),
          headerHeight = actualPaddingSetter(),
          footerHeight = document.querySelector('#self-medication-block').clientHeight,
          windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
          topBlockVisibleWidth = 1025;

      if (window.innerWidth > topBlockVisibleWidth) {
        topBlock.style.height = windowHeight - headerHeight - footerHeight + 'px';
      }

      window.addEventListener('resize', () => {
        topBlock = document.querySelector('.top-scroll-block');
        headerHeight = actualPaddingSetter();
        footerHeight = document.querySelector('#self-medication-block').clientHeight;
        windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

        if (window.innerWidth > topBlockVisibleWidth) {
          topBlock.style.height = windowHeight - headerHeight - footerHeight + 'px';
        }
      });
    }
  }
}
</script>

<style lang="less">
.home {

}
</style>
