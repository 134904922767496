<template>
  <div class="message-alert-wrapper">

    <div v-bind:class="[{ active: isActive }]" id="alert-container" class="alert-container">
      <div class="alert-block"><h1>Ви залишаєте ВЕБ-САЙТ САНОФІ</h1>
        <p>Ви збираєтеся покинути веб-сайт компанії Санофі <br> Чи бажаєте продовжити?</p>
        <div class="buttons-flex-container"><a :onclick="`dataLayer.push({'event': 'click', 'event_category': 'купити фестал, tabletki.ua', 'event_label':'UA: ${$route.path}'})`" class="leave-btn"
                                               href="https://tabletki.ua/Фестал-нео-10-000/pharmacy/kiev/"
                                               target="_blank">tabletki.ua</a>
          <button @click="closeAlert()" class="close-btn">закрити</button>
        </div>
      </div>
    </div>

    <button
        class="buy-festal-btn visible" @click="openAlert()">
      Купити<span><span>Фестал<sup>®</sup></span><span>нео&nbsp;10&nbsp;000</span></span></button>
  </div>
</template>

<script>
export default {
  name: "message-alert",
  data: () => ({
    isActive: false,
  }),


  methods: {
    openAlert() {
      this.isActive = true;

    },
    closeAlert() {
      this.isActive = false;
    }
  }
}
</script>

<style lang="less" scoped>

.alert-container {
  transition: all 1s ease;


  &.active {
    transform: translateX(0);
  }
}
</style>
