/*** IMPORTS FROM imports-loader ***/
var define = false;

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import topMenu from './components/topMenu'
import messageAlert from './components/message-alert'


Vue.component("top-menu", topMenu);
Vue.component("message-alert", messageAlert);


Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

